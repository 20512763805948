import React, { useEffect } from 'react';
import { Route, Switch, Redirect, Link, withRouter } from 'react-router-dom';
import Home from './pages/Home';
import Book from './pages/Book';
// import HTMLPage from "./pages/HTMLPage";
import Check from "./pages/Check";
import Entry from "./pages/Entry";
import Gramatika from './pages/Gramatika';
import Test from "./pages/Test";
import Testbox from "./pages/Testbox";
//import Box from "./components/Box";
import Search from "./pages/Search";
import Hooktest from "./pages/Hooktest";
import Content from "./components/Content";
import Chain from "./pages/Chain";
import Conjugation from "./pages/Conjugation";
import Tools from "./pages/Tools";
//import Lessons from "./pages/Lessons";
// import Lesson from "./pages/Lesson";
import Lesson3 from "./pages/v2/PageLesson";
import SimpleTense from "./pages/SimpleTense";
import Declension from "./pages/Declension";
// import Page from "./pages/desktop/Page";
import ScrollToTop from "./components/ScrollToTop";
import Theory from './pages/desktop/Theory';
import Statistika from './pages/Statistika';
import Copyright from './pages/Copyright';


function NotFound() {
    return(
        <Content className="service">
            <h3>404: Šīs lapas nav</h3>
            <Link to="/">Uz sākumu</Link>
        </Content>
    );
}

/*function NotReady() {
    return (
        <Content className="service">
            <h3>Šī lapa vēl nav gatava ¯\\_(ツ)_/¯</h3>
        </Content>
    )
}*/

/*const BodyClass = withRouter(({history}) => {
    useEffect(() => {
        const unlisten = history.listen((x) => {
            console.log("BodyClass: something changed", x);
        });
        return () => {
            unlisten();
        }
    }, []);
    return (null);
});*/

const Redirect2last = () => {
    const lastLesson = localStorage.getItem("lastLesson2") || "olu-uzglabāšanas-noteikumi";
    // console.log("read lastLesson2:", lastLesson);
    return <Redirect to={`/vingrinājumi/${lastLesson}/`} />
}

function Routes(props) {
    const { data, box, setNav, inputRef, setFocus } = props;

    //console.log("Routes render");

    return (
        <>
        <ScrollToTop />
        <Switch>

            <Route exact path="/" component={() => <Home data={data} box={box} setNav={setNav} />} />
            <Route exact path="/pārbaude" component={() => <Check data={data} />} />
            <Route exact path="/statistika" component={() => <Statistika data={data} />} />
            <Route exact path="/autortiesības" component={() => <Copyright />} />
            <Route exact path="/hooktest" component={() => <Hooktest data={data} setNav={setNav} />} />
            <Route exact path="/test" component={() => <Test data={data} box={box} setNav={setNav} />} />
            <Route exact path="/tools" component={() => <Tools data={data} box={box} />} />

            <Route
                exact path="/teorija/darbības-vārds/konjugēšana"
                component={() =>
                <Conjugation data={data} box={box} setNav={setNav} />
                } />
            <Route
                exact path="/teorija/darbības-vārds/vienkāršie-laiki"
                component={() =>
                <SimpleTense data={data} box={box} setNav={setNav} />
                } />

            <Route
                exact path="/teorija/lietvārds/locīšana">
                    <Redirect to="/teorija/lietvārds/locīšana/tabula/" />
            </Route>

            <Route
                exact path="/teorija/lietvārds/locīšana/tabula"
                component={() =>
                <Declension data={data} box={box} setNav={setNav} />
                } />

            <Route path="/teorija">
                <Theory {...{ data, box, setNav }} />
            </Route>

            <Route exact path="/testbox" component={() => <Testbox data={data} />} />

            <Route
                exact path="/meklēšana"
                component={(props) =>
                <Search data={data} box={box} inputRef={inputRef} setFocus={setFocus} setNav={setNav} />
                } />
            <Route
                path="/vārdi/ķēde/:lemma"
                component={(props) => <Chain url={props} data={data} box={box} setNav={setNav} />} />
            <Route
                path="/vārdi/:type/:lemma/:homonym"
                component={(props) => <Entry url={props} data={data} box={box} setNav={setNav} />} />
            <Route
                path="/vārdi/:type/:lemma"
                component={(props) => <Entry url={props} data={data} box={box} setNav={setNav} />} />

            <Route
                exact path="/vingrinājumi/:name"
                component={(route) =>
                <Lesson3 {...{ data, box, setNav, route }} />
                } />

            <Route exact path="/vingrinājumi">{
                <Redirect2last />
            }</Route>

            <Route
                exact path="/dienasgrāmata/:year/:month/:day"
                component={(props) =>
                <Book url={props} data={data} box={box} setNav={setNav} />
                } />
            <Route
                exact path="/dienasgrāmata"
                component={(props) =>
                <Book url={props} data={data} box={box} setNav={setNav} />
                } />

            
            <Route exact path="/gramatika/:section/:page"
                component={(props)=> <Gramatika url={props} data={data} box={box} setNav={setNav} />} />
            
            <Route component={NotFound} />
        </Switch>
        </>
    )
}

//export default Routes;

export default React.memo(Routes,
    (prev, next)=>{
        return (prev.data === next.data);
    }
);
