import React, { useEffect, useCallback } from "react";
import Content from "../components/Content";
import { Days } from "../components/Days";
import "../css/Days.css";
import "../css/Book.css";

function Book({ url, data, box, setNav }) {

    //console.log("Book render");

    //console.log("Book: url:", url);

    useEffect(()=>{
        /*window.addEventListener("load", ()=>{
            console.log("window loaded");
        });*/
        const obj = document.getElementById("doodle");
        //console.log(obj);
        if (obj) {
            console.log(obj);
            console.log(obj.contentDocument);
            console.log(obj.contentDocument.childNodes);
            console.log(obj.contentDocument.childNodes[0]);
        }
    });

    const params = url.match.params;
    let bookmark;

    if (params) {
        if (["year", "month", "day"].every(param => params.hasOwnProperty(param))) {
            bookmark = new Date(parseInt(params.year), parseInt(params.month)-1, parseInt(params.day));
            //console.log("bookmark:", bookmark);
        }
    }

    return(
        <Content className="book">
            <Days {...{ data, box, bookmark } } pager />
        </Content>
    )
}

export default Book;
