import React from 'react';
import { labels } from "../../labels";
import { aovmap } from "../../prototypes";
import { Wordform, Label } from "../Wordform";
import { transform } from "../../lang/lang2";



export function RectionCas({ morphs, word }) {

    return (
        word.hasOwnProperty("rection") ?
        <>
        <div className="label"><Label>{labels.rection}</Label></div>
        <div className="list">{ aovmap(word.rection, (rection, i) => <Label key={i} className="big">{labels.cas[rection.cas]}</Label>) }</div>
        </> : null
    )
}
