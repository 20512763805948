
import React, { useContext, useEffect, useState, useMemo } from "react";
import DataContext from "../DataContext";
import { objectsEqual } from "../../../prototypes";
import { filterWords } from "../../../lang/parse";
import QParse from "./QParse";
import { useFetch } from "../../../io";

export const useHTML = ({ src, qprops, className }) => {

    const [html, status, error] = useFetch(src, "text/html");

    if (status === "fetched") {
        const text = html.replace(/>\s+</g, "><");
        return [<QParse {...{ text, qprops, className }} />, status, error]
    } else {
        return [null, status, error]
    }
};

const QHTML = React.memo(({ src, qprops, className }) => {
    // console.log("QHTML render");
    // console.log("QHTML src:", src);
    // console.log("QHTML: qprops state:", qprops.state);

    const { data } = useContext(DataContext);

    // console.log("QHTML data:", data);

    // console.log(`%cstatus before fetch: ${status}`, "color:darkblue");
    // console.log(`%chtml before fetch: ${html}`, "color:darkblue");

    const [html, status, error] = useFetch(src, "text/html");

    // console.log("QHTML status:", status);

    const filteredWords = useMemo(()=>{
        if (status === "fetched") {
            let plainText =
                html.replace(/<[^>]+>/g, " ");
            const qaAttrs = 
                html.match(/[qa]=['"]([^'"]+)['"]/g);
            if (qaAttrs) plainText += qaAttrs
                .map(str => str.replace(/[qa]=['"]([^'"]+)['"]/, "$1"))
                .join(" ");
            // console.log(plainText);
            // console.log(qaAttrs);
            return filterWords(plainText, data.words);
        }
        return null
    }, [status, html, data.words]);


    if (status === "fetched" && filteredWords) {

        const text = html.replace(/>\s+</g, "><");

        return (
            <QParse {...{ text, qprops, className }} />
        )
        
    }

    return null;
    
}, (prev, next) => prev.src===next.src);

export default QHTML;
