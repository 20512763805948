
export const presentations = {
    "time12": {
        "human": { pres: "humanTime", mask: "$HH:$MM" },
        "lv": {
            which: "$AA[cas:0] un $MM[cas:0] ([viena]minūte|minūtes)[lv:minūte]",
            from: "no $AA[cas:2,count:1] un $MM[cas:12] ([vienas]minūtes|minūtēm)[lv:minūte]",
            until: "līdz $AA[cas:2,count:1] un $MM[cas:2] ([vienai]minūtei|minūtēm)[lv:minūte]",
            when: "$AA[cas:5,count:1] un $MM[cas:5] ([vienā]minūtē|minūtēs)[lv:minūte]"
        },
        "ru": {
            which: "$AA[cas:0] ([один]час|[два,три,четыре]часа|часов) $MM[cas:0] ([одна]минута|[две,три,четыре]минуты|минут)",
            from: "с $AA[cas:1] ([одного]часа|часов) $MM[cas:1] ([одной]минуты|минут)",
            until: "до $AA[cas:1] ([одного]часа|часов) $MM[cas:1] ([одной]минуты|минут)",
            when: "в $AA[cas:3] ([один]час|[два,три,четыре]часа|часов) $MM[cas:3] ([одну]минуту|[две,три,четыре]минуты|минут)"
        }
    },
    "dayMonth": {
        "lv": {
            which: "$day[cas:0] $month[cas:0]",
            from: "no $day[cas:1] $month[cas:1]",
            until: "līdz $day[cas:2] $month[cas:2]",
            when: "$day[cas:5] $month[cas:5]"
        },
        "ru": {
            which: "$day[cas:0] $month[cas:1]",
            from: "(со[второго]|с) $day[cas:1] $month[cas:1]",
            until: "до $day[cas:1] $month[cas:1]",
            when: "$day[cas:1] $month[cas:1]"
        }
    },
    "dayTime": {
        "human": { pres: "humanDayTime", mask: "$HH:$MM" },
        "lv": { which: "", from: "", until: "", when: "" },
        "ru": { which: "", from: "", until: "", when: "" }
    },
    "humanDayTime": {
        "lv": [
            {
                mask: { from: [7,0], to: [11,59] },
                which: "rīts[lv]", from: "no rīta[lv:rīts]", until: "līdz rītam[lv:rīts]", when: "rītā[lv:rīts]"
            },
            {
                mask: { from: [12,0], to: [16,59] },
                which: "diena[lv]", from: "no pusdienas[lv:pusdiena]", until: "līdz dienas[lv:diena] beigām[lv:beigas]", when: "dienā[lv:diena]"
            },
            {
                mask: { from: [17,0], to: [21,59] },
                which: "vakars[lv]", from: "no vakara[lv:vakars]", until: "līdz vakaram[lv:vakars]", when: "vakarā[lv:vakars]"
            },
            {
                mask: { from: [22,0], to: [23,59] },
                which: "vēls vakars[lv:vakars]", from: "no vēla vakara[lv:vakars]",
                until: "līdz vēlam vakaram[lv:vakars]", when: "vēlā vakarā[lv:vakars]"
            },
            {
                mask: { from: [0,0], to: [3,59] },
                which: "nakts[lv:nakts]", from: "no nakts[lv:nakts]", until: "līdz naktij[lv:nakts]", when: "naktī[lv:nakts]"
            },
            {
                mask: { from: [4,0], to: [6,59] },
                which: "agrs rīts[lv]", from: "no agra rīta[lv:rīts]", until: "līdz agram rītam[lv:rīts]", when: "agrā rītā[lv:rīts]"
            }
        ],
        "ru": [
            {
                mask: { from: [7,0], to: [11,59] },
                which: "утро", from: "с утра", until: "до утра", when: "утром"
            },
            {
                mask: { from: [12,0], to: [16,59] },
                which: "день", from: "с полудня", until: "до конца дня", when: "днем"
            },
            {
                mask: { from: [17,0], to: [21,59] },
                which: "вечер", from: "с вечера", until: "до вечера", when: "вечером"
            },
            {
                mask: { from: [22,0], to: [23,59] },
                which: "поздний вечер", from: "с позднего вечера",
                until: "до позднего вечера", when: "поздним вечером"
            },
            {
                mask: { from: [0,0], to: [3,59] },
                which: "ночь", from: "с ночи", until: "до ночи", when: "ночью"
            },
            {
                mask: { from: [4,0], to: [6,59] },
                which: "раннее утро", from: "с раннего утра", until: "до раннего утра", when: "ранним утром"
            }
        ]
    },
    "humanTime": {
        "lv": [
            {
                mask: "12:00",
                which: "pusdiena",
                from: "no pusdienas",
                until: "līdz pusdienai",
                when: "pusdienā"
            },
            {
                mask: "(12|00):30",
                which: "pusviens",
                from: "no pusvieniem",
                until: "līdz pusvieniem",
                when: "pusvienos"
            },
            {
                mask: "\\d+:30",
                which: "pus$AA+1[cas:0]",
                from: "no pus$AA+1[cas:2]",
                until: "līdz pus$AA+1[cas:2]",
                when: "pus$AA+1[cas:5]"
            },
            {
                mask: "\\d+:00",
                which: "tieši $AA[cas:0]",
                from: "no $AA[cas:2]",
                until: "līdz $AA[cas:2]",
                when: "$AA[cas:5]"
            },
            {
                mask: "\\d+:(05|10|20|25)",
                which: "$MM[cas:0] minūtes[lv:minūte] pāri $AA[cas:2,count:1]",
                from: "no $MM[cas:12] ([vienas]minūtes|minūtēm)[lv:minūte] pāri $AA[cas:2,count:1]",
                until: "līdz $MM[cas:2] ([vienai]minūtei|minūtēm)[lv:minūte] pāri $AA[cas:2,count:1]",
                when: "$MM[cas:5] ([vienā]minūtē|minūtēs)[lv:minūte] pāri $AA[cas:2,count:1]"
            },
            {
                mask: "\\d+:15",
                which: "ceturksnis[lv] pāri $AA[cas:2,count:1]",
                from: "no ceturkšņa[lv:ceturksnis] pāri $AA[cas:2,count:1]",
                until: "līdz ceturksnim[lv:ceturksnis] pāri $AA[cas:2,count:1]",
                when: "ceturksnī[lv:ceturksnis] pāri $AA[cas:2,count:1]"
            },
            {
                mask: "\\d+:(35|40|50|55|56|58|59)",
                which: "bez 60-$MM[cas:12] ([vienas]minūtes|minūtēm)[lv:minūte] $AA+1[cas:0]",
                from: "no bez 60-$MM[cas:12] ([vienas]minūtes|minūtēm)[lv:minūte] $AA+1[cas:2,count:1]",
                until: "līdz bez 60-$MM[cas:12] ([vienas]minūtes|minūtēm)[lv:minūte] $AA+1[cas:2,count:1]",
                when: "bez 60-$MM[cas:12] ([vienas]minūtes|minūtēm)[lv:minūte] $AA+1[cas:5,count:1]"
            },
            {
                mask: "\\d+:45",
                which: "bez ceturkšņa[lv:ceturksnis] $AA+1[cas:0]",
                from: "no bez ceturkšņa[lv:ceturksnis] $AA+1[cas:2,count:1]",
                until: "līdz bez ceturkšņa[lv:ceturksnis] $AA+1[cas:2,count:1]",
                when: "bez ceturkšņa[lv:ceturksnis] $AA+1[cas:5,count:1]"
            },
            {
                mask: "\\d+:57",
                which: "bez trim[sv:trīs] minūtēm[lv:minūte] $AA+1[cas:0]",
                from: "no bez trim[sv:trīs] minūtēm[lv:minūte] $AA+1[cas:2,count:1]",
                until: "līdz bez trim[sv:trīs] minūtēm[lv:minūte] $AA+1[cas:2,count:1]",
                when: "bez bez trim[sv:trīs] minūtēm[lv:minūte] $AA+1[cas:5,count:1]"
            }
        ],
        "ru": [
            {
                mask: "12:00",
                which: "полдень",
                from: "с полудня",
                until: "до полудня",
                when: "в полдень"
            },
            {
                mask: "(01|13):00",
                which: "ровно час",
                from: "с часу",
                until: "до часу",
                when: "в час"
            },
            {
                mask: "\\d+:30",
                which: "(пол-[одиннадцатого]|пол)$aa+1[cas:1,gender:0]",
                from: "с (пол-[одиннадцатого]|пол)$aa+1[cas:1,gender:0]",
                until: "до (пол-[одиннадцатого]|пол)$aa+1[cas:1,gender:0]",
                when: "в (пол-[одиннадцатого]|пол)$aa+1[cas:1,gender:0]"
            },
            {
                mask: "\\d+:00",
                which: "ровно $AA[cas:0]",
                from: "с $AA[cas:1]",
                until: "до $AA[cas:1]",
                when: "в $AA[cas:3]"
            },
            {
                mask: "\\d+:(05|10|20|25)",
                which: "$MM[cas:0] минут $aa+1[cas:1,gender:0]",
                from: "с $MM[cas:1] минут $aa+1[cas:1,gender:0]",
                until: "до $MM[cas:1] минут $aa+1[cas:1,gender:0]",
                when: "в $MM[cas:3] минут $aa+1[cas:1,gender:0]"
            },
            {
                mask: "\\d+:15",
                which: "четверть $aa+1[cas:1,gender:0]",
                from: "с четверти $aa+1[cas:1,gender:0]",
                until: "до четверти $aa+1[cas:1,gender:0]",
                when: "в четверть $aa+1[cas:1,gender:0]"
            },
            {
                mask: "(12|00):(35|40|50|55|56|57|58|59)",
                which: "без 60-$MM[cas:1,gender:1] ([одной]минуты|минут) час",
                from: "с без 60-$MM[cas:1,gender:1] ([одной]минуты|минут) часа",
                until: "до без 60-$MM[cas:1,gender:1] ([одной]минуты|минут) часа",
                when: "в без 60-$MM[cas:1,gender:1] ([одной]минуты|минут) час"
            },
            {
                mask: "\\d+:(35|40|50|55|56|57|58|59)",
                which: "без 60-$MM[cas:1,gender:1] ([одной]минуты|минут) $AA+1[cas:0]",
                from: "с без 60-$MM[cas:1,gender:1] ([одной]минуты|минут) $AA+1[cas:1]",
                until: "до без 60-$MM[cas:1,gender:1] ([одной]минуты|минут) $AA+1[cas:1]",
                when: "в без 60-$MM[cas:1,gender:1] ([одной]минуты|минут) $AA+1[cas:3]"
            },
            {
                mask: "(12|00):45",
                which: "без четверти час",
                from: "с без четверти часа",
                until: "до без четверти часа",
                when: "в без четверти час"
            },
            {
                mask: "\\d+:45",
                which: "без четверти $AA+1[cas:0]",
                from: "с без четверти $AA+1[cas:0]",
                until: "до без четверти $AA+1[cas:0]",
                when: "в без четверти $AA+1[cas:0]"
            }
        ]
    }
};

