import React from 'react';
import { getRandomInt } from "../prototypes";

const Background = React.memo(() => {

// 

    const backgrounds = {
        day: [

            {
                "name": "cross-garden",
                "author": {
                    "en": "Henri-Edmond Cross",
                    "lv": "Anrī Edmons Kross",
                    "ru": "Анри Кросс"
                },
                "title": {
                    "en": "Garden of the Painter at Saint Clair",
                    "lv": "Mākslinieka dārzs Sentklērā",
                    "ru": "Сад художника в Сент-Клере"
                },
                "year": 1908
            },

            /* {
                "name": "bellows-winter",
                "author": {
                    "en": "George Wesley Bellows",
                    "lv": "Džordžs Veslijs Bellovs",
                    "ru": "Джордж Уэсли Беллоуз"
                },
                "title": {
                    "en": "Love of Winter",
                    "lv": "Ziemas mīlestība",
                    "ru": "Зимняя любовь"
                },
                "year": 1914
            },
            {
                "name": "paris-blizzard",
                "author": {
                    "en": "Walter Paris",
                    "lv": "Volters Periss",
                    "ru": "Уолтер Пэрис"
                },
                "title": {
                    "en": "The Great Blizzard of 1899",
                    "lv": "1899. gada liela sniegavētra",
                    "ru": "Великая метель 1899 года"
                },
                "year": 1899
            }, */
            
            /* {
                "name": "bruegel-harvesters",
                "author": {
                    "en": "Pieter Bruegel the Elder",
                    "lv": "Pīters Brēgels Vecākais",
                    "ru": "Питер Брейгель-старший"
                },
                "title": {
                    "en": "The Harvesters",
                    "lv": "Pļāvēji",
                    "ru": "Жатва"
                },
                "year": 1565
            }, */

            /* {
                "name": "caillebotte-paris",
                "author": {
                    "en": "Gustave Caillebotte",
                    "lv": "Gistavs Kaibots",
                    "ru": "Гюстав Кайботт"
                },
                "title": {
                    "en": "Paris Street; Rainy Day",
                    "lv": "Iela Parīzē, lietaina diena",
                    "ru": "Парижская улица в дождливую погоду"
                },
                "year": 1877
            } */

            /* {
                "name": "monet-beach",
                "title": {
                    "en": "The Beach at Sainte-Adresse",
                    "lv": "Pludmale pie Sentadreses",
                    "ru": "Пляж в Сент-Адрессе"
                },
                "author": {
                    "en": "Claude Monet",
                    "lv": "Klods Monē",
                    "ru": "Клод Моне"
                },
                "year": 1867
            } */

            /* {
                "name": "hokusai-sumida",
                "author": {
                    "en": "Katsushika Hokusai",
                    "lv": "Kacušika Hokusai",
                    "ru": "Кацусика Хокусай"
                },
                "title": {
                    "en": "Snow on the Sumida River",
                    "lv": "Sniegs uz Sumida upes",
                    "ru": "Снег на реке Сумида"
                },
                "year": 1833
            }, */
            /* {
                "name": "vangogh-beach",
                "title": {
                    "en": "Fishing Boats on the Beach at Saintes-Maries",
                    "lv": "Zvejas laivas Senmarī pludmalē",
                    "ru": "Рыбацкие лодки на берегу в Сен-Мари"
                },
                "author": {
                    "en": "Vincent van Gogh",
                    "lv": "Vinsents van Gogs",
                    "ru": "Винсент ван Гог"
                },
                "year": 1888
            } */
            /* {
                "name": "cezanne-marseilles",
                "title": {
                    "en": "The Gulf of Marseilles Seen from L'Estaque",
                    "lv": "Skats uz Marseļas līci no Lestakas",
                    "ru": "Вид на залив в Марселе со стороны Эстака"
                },
                "author": {
                    "en": "Paul Cézanne",
                    "lv": "Pols Sezans",
                    "ru": "Поль Сезанн"
                },
                "year": 1885
            } */
        ],
        night: [
            {
                "name": "nighthawks",
                "title": {
                    "en": "Nighthawks",
                    "lv": "Naktsputni",
                    "ru": "Полуночники"
                },
                "author": {
                    "en": "Edward Hopper",
                    "lv": "Edvards Hopers",
                    "ru": "Эдвард Хоппер"
                },
                "year": 1942
            },
            {
                "name": "dahl-copenhagen",
                "title": {
                    "en": "Copenhagen Harbor by Moonlight",
                    "lv": "Kopenhāgenas osta mēnesnicā",
                    "ru": "Гавань в Копенгагене в лунном свете"
                },
                "author": {
                    "en": "Johan Christian Dahl",
                    "lv": "Johans Kristians Dāls",
                    "ru": "Юхан Кристиан Даль"
                },
                "year": 1846
            },
            {
                "name": "chappel-lamplighter",
                "title": {
                    "en": "The Lamp Lighter",
                    "lv": "Laternu uzraugs",
                    "ru": "Фонарщик"
                },
                "author": {
                    "en": "William P. Chappel",
                    "lv": "Viljams Čepels",
                    "ru": "Уильям Чеппел"
                },
                "year": 1870
            }
        ]
    };

    const day = getRandomInt(0, backgrounds.day.length-1);
    const night = getRandomInt(0, backgrounds.night.length-1);


    return (
        <div className="nameplate">
            <div className="day">
                <div className="title">
                    <div>{backgrounds.day[day].title.lv}</div>
                    <div className="trans">{backgrounds.day[day].title[window.secLang]}</div>
                </div>
                <div className="author">
                    <div>{backgrounds.day[day].author.lv}</div>
                    <div className="trans">{backgrounds.day[day].author[window.secLang]}</div>
                </div>
                <div className="year">{backgrounds.day[day].year}</div>
            </div>
            <div className="night">
                <div className="title">
                    <div>{backgrounds.night[night].title.lv}</div>
                    <div className="trans">{backgrounds.night[night].title[window.secLang]}</div>
                </div>
                <div className="author">
                    <div>{backgrounds.night[night].author.lv}</div>
                    <div className="trans">{backgrounds.night[night].author[window.secLang]}</div>
                </div>
                <div className="year">{backgrounds.night[night].year}</div>
            </div>
        <link rel="stylesheet" type="text/css" href={`/assets/v9/css/bg/day/${backgrounds.day[day].name}.css`} />
        <link rel="stylesheet" type="text/css" href={`/assets/v9/css/bg/night/${backgrounds.night[night].name}.css`} />
        </div>
    )
});

export default Background;
