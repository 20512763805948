import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
// import { searches } from "../search/searches";

import "./Content.css";

function Content({ className, children, ...props }) {

    const history = useHistory();

    const id = useRef();

    const classList = ["content"];
    if (className) classList.push(className);

    useEffect(()=>{
        //console.log("Content mount");
        //console.log(history);
        if (id.current && !/search/.test(className)) {
            //console.log("set focus to content");
            id.current.focus();
        }
    }, [history.location.pathname, className]);

    /*
    function startSearch(inputValue, lang) {
        if (inputValue === "") {
            localStorage.removeItem(searches.general.cookieName);
        } else {
            localStorage.setItem(searches.general.cookieName, JSON.stringify({ str: inputValue, lang }))
        }
        sessionStorage.removeItem("selectionStart");
        history.push("/meklēšana/");
    }
    */

    const cprops = {
        ...props,
        className: classList.join(" "),
        tabIndex: "0"
    };
    delete cprops.children;

    return (
        <div {...cprops} ref={id}>{children}</div>
    )
}

export default Content;