import React from 'react';
import { labels } from "../../labels";
import { Wordform, Label } from "../Wordform";
import { transform } from "../../lang/lang2";

function Imperative(props) {

    let
        imp1 = transform(props.morphs, props.word, {tense: 1, person: 2}),
        imp2 = transform(props.morphs, props.word, {tense: 4, person: 5});

    if (imp1+imp2 === "") return null;

    return (
        <>
        <h2><Label>{labels.transformations.moods.imperative}</Label></h2>

        <div className="transform big">
            { imp1 &&
            <>
            <Label className="label">{labels.person.find(p=>p.person===2)}</Label>
            <Wordform className="form" hyphenate={false} form={imp1} />
            </>
            }
            { imp2 &&
            <>
            <Label className="label">{labels.person.find(p=>p.person===5)}</Label>
            <Wordform className="form" hyphenate={false} form={imp2} />
            </>
            }
        </div>
        </>
    );
}

export default Imperative;