import React from "react";
import { objectsEqual } from "../../../prototypes";
import qcomponents from "./qcomponents";
import { Node } from "./renderNode";
import DataContext from "../DataContext";

const QParse = React.memo(({ text, qprops, className, nowrap }) => {

    // console.log("QParse qprops.state:", qprops.state);

    // console.log("********************************************************");
    // console.log("QParse render");
    // console.log("text:", text);
    // console.log("QPARSE STATE:");

    /* if (qprops.state) if (qprops.state.length > 0) {
        qprops.state.forEach(q => {
            // console.log(q);
            console.log(`%cid: ${q.exerciseId}${q.id.replace(q.exerciseId, "/")} type: ${q.type}`, "color:blue");
            console.log("correct answers:", JSON.stringify(q.correct));
            console.log("given answer:", q.given);
            console.log("result:", q.result);
        })
    } else {
        console.log(qprops.state);
    } */

    /* parse html, pass qnodes with qprops to QNode, assemble react components */

    const { data, box } = React.useContext(DataContext);

    let html = Array.isArray(text) ? text.join("") : text;

    // console.log("html:", html);

    qcomponents.forEach(qc => {
        const { tag } = qc;
        const rx = new RegExp(`<${tag}\\s[^>]+\\/>|<${tag}\\s*\\/>`);
        if (rx.test(html)) {
            const rx = new RegExp(`(<${tag}\\s[^>]+)\\/>`, "g");
            html = html.replace(rx, `$1></${tag}>`);
        }
    });

    const parser = new DOMParser();

    const node = [...parser.parseFromString(html, "text/html").body.childNodes];
    /* .filter(node => {
        // console.log("nodeName:", node.nodeName);
        // console.log("nodeType:", node.nodeType);
        // if (node.nodeType===3) console.log("nodeValue===''?", node.nodeValue.trim()==="");
        return node.nodeType === 3 ? node.nodeValue.trim()!=="" : true}
        ); */

    // console.log("QParse: node from text:", node);

    // const parsed = renderNode({ node, props: { data, box }, qprops });

    const Wrap = nowrap ? ({ children }) => (<React.Fragment>{children}</React.Fragment>) : ({ children }) => (<div className={className || null}>{children}</div>);

    return (
        <Wrap>
            <Node {...{ node, props: { data, box }, qprops }} />
        </Wrap>
    )

}, (prev, next) => {

    if (prev.text === next.text && prev.qprops && next.qprops) {
        const prevState = prev.qprops.state.filter(state => state.id.indexOf(prev.qprops.id) === 0);
        const nextState = next.qprops.state.filter(state => state.id.indexOf(next.qprops.id) === 0);
        return objectsEqual(prevState, nextState);
    }

    return false;
});

export default QParse;
