import React, { useState } from 'react';
import "./Moon.css";
import { day, night } from "../i/icons";

function Moon(props) {

    function moo() {
        props.setTheme((prev) => ( prev === "day" ? "night" : "day" ));
    }

    return (
        <button style={{zIndex: 9}} className="moon" onClick={() => moo()}>{props.theme==="night"?day:night}</button>
    )
}

export default Moon;