import React, { useMemo, useState, useLayoutEffect, useRef, useEffect } from "react";
import { str2word } from "../../../lang/parse";
import { Rarr } from "../../../i/icons";

const rules = [
    {
        name: "g-dz",
        className: "ruleFlexP2",
        morphType: "stem",
        morph: "g", alt: "dz",
        words: ["bēgt[dv]"],
        param: { tense: 1, person: 2 },
        component: RuleAltMorph
    },
    {
        name: "k-c",
        className: "ruleFlexP2",
        morphType: "stem",
        morph: "k", alt: "c",
        words: ["pirkt[dv]"],
        param: { tense: 1, person: 2 },
        component: RuleAltMorph
    },
    {
        name: "ā-ā",
        className: "ruleFlexP3",
        morphType: "postfix",
        morph: "ā", alt: "ā",
        words: ["domāt[dv]"],
        param: { tense: 1, person: [2,3] },
        component: RuleAltMorph
    },
    {
        name: "o-o",
        className: "ruleFlexP3",
        morphType: "postfix",
        morph: "o", alt: "o",
        words: ["dzīvot[dv]"],
        param: { tense: 1, person: [2,3] },
        component: RuleAltMorph
    },
    {
        name: "ē-ē",
        className: "ruleFlexP3",
        morphType: "postfix",
        morph: "ē", alt: "ē",
        words: ["ārstēt[dv]"],
        param: { tense: 1, person: [2,3] },
        component: RuleAltMorph
    },
    {
        name: "inā-i",
        className: "ruleFlexP2",
        morphType: "postfix",
        morph: ["ī", "ā", "ē"], alt: "i",
        words: ["gaidīt[dv]", "turpināt[dv]", "cerēt[dv]"],
        param: { tense: 1, person: 2 },
        component: RuleAltMorph
    },
    {
        name: "inā-a",
        className: "ruleFlexP3",
        morphType: "postfix",
        morph: ["ī", "ā"], alt: "a",
        words: ["gaidīt[dv]", "turpināt[dv]"],
        param: { tense: 1, person: 3 },
        component: RuleAltMorph
    },
    {
        name: "ē-",
        className: "ruleFlexP3",
        morphType: "postfix",
        morph: "ē", alt: "\u00A0",
        words: ["cerēt[dv]"],
        param: { tense: 1, person: 3 },
        component: RuleAltMorph
    },
    {
        name: "noSoften",
        className: "ruleFlexP2",
        words: ["celt[dv]"],
        param: { tense: 1, person: 2 },
        component: RuleNoSoften
    },
    {
        name: "proti-saki",
        className: "ruleFlexP2",
        words: ["saprast[dv]", "teikt[dv]"],
        param: { tense: 1, person: 2 },
        component: RuleAddFlex
    },
    {
        name: "-sti",
        className: "ruleFlexP2",
        words: ["salt[dv]"],
        param: { tense: 1, person: 2 },
        component: RuleAddFlex
    },
    {
        name: "saka",
        className: "ruleFlexP3",
        words: ["teikt[dv]"],
        param: { tense: 1, person: 3 },
        component: RuleAddFlex
    },
    {
        name: "-ā-reflexive",
        className: "ruleLongA",
        words: ["mācīties[dv]", "turpināties[dv]"],
        morphType: "postfix",
        param: { tense: 1, person: [3, 4, 5] },
        component: RuleLongA
    },
    {
        name: "-ā-nonReflexive",
        className: "ruleLongA",
        words: ["gaidīt[dv]", "turpināt[dv]"],
        morphType: "postfix",
        param: { tense: 1, person: [4, 5] },
        component: RuleLongA
    }
];


function RuleAddFlex({ classList, id, rule }) {

    const className = "addFlex " + classList.join(" ");

    //console.log(rule.name);

    let res;
    switch (rule.name) {
        case "proti-saki": {
            res =
                
                    <div className={className} ref={id}>
                        { ["prot", "saprot", "sak"].map((stem, i) => (
                            <div key={i}>{stem}<span className="flex">i</span></div>
                        )) }
                    </div>;
                
            break;
        }
        case "saka": {
            res =
                <RulesGroup label={{ru:<>Добавляется <b>a</b>:</>}}>
                    <div className={className} ref={id}>
                        sak<span className="flex">a</span>
                    </div>
                </RulesGroup>;
            break;
        }
        case "-sti": {
            res =
                <div className={className} ref={id}>
                    -st<span className="flex">i</span>
                </div>;
            break;
        }
    }

    return res;
}

function RuleAltMorph({ classList, id, rule }) {

    const className = "alternation " + classList.join(" ");

    return (
        <div className={className} ref={id}>
            
            {
                Array.isArray(rule.morph) ?
                    rule.morph.map((morph, i, arr) => <React.Fragment key={i}><div className="origin morph">{morph}</div>{i<arr.length-1?", ":""}</React.Fragment>) :
                    <div className="origin morph">{rule.morph}</div>
            }
            <Rarr />
            <div className="alt morph">{rule.alt}</div>
        </div>
    )
}

function RuleNoSoften({ classList, id, rule }) {

    const className = "soften striked " + classList.join(" ");

    const softens = ["ļ", "š", "ž", "bj", "mj", "pj"];

    return (
        <div className={className} ref={id}>
            { softens.map((soften, i) => (
                <div key={i}>{soften}</div>
            )) }
        </div>
    )
}

function RuleLongA({ classList, id, rule }) {

    //let className = "ruleLongA";
    //if (classList) className += " " + classList.join(" ");

    let res = null;
    switch (rule.name) {
        case "-ā-nonReflexive": {
            res = "-ām, -āt";
            break;
        }
        case "-ā-reflexive": {
            res = "-ās, -āmies, -āties";
            break;
        }
    }

    return (
        <div className={classList.join(" ")} ref={id}>
            { res }
        </div>
    )
}

export const Rule = ({ name, word, data, setConnectedRules }) => {

    const ref = useRef();

    const rule = useMemo(()=>{
        //console.log(name);
        const r = rules.find(r => r.name === name);
        const words = r.words.map(wordStr=>str2word(data, wordStr).word);
        return { ...r, words }
    }, [name]);

    useLayoutEffect(()=>{
        if (ref.current) {
            //console.log("ref element to add:", ref.current);
            setConnectedRules((prev) => {
                const updated = [...prev];
                const index = updated.findIndex(rule=>rule.name===name);
                if (index >= 0) {
                    updated[index] = { ref, ...rule }
                } else {
                    updated.push({ ref, ...rule })
                }
                return updated;
            });
        }
    }, []);

    const classList = ["rule", "connected"];

    /*Object.keys(rule.param).forEach(key => {
        const className = Array.isArray(rule.param[key]) ?
            rule.param[key].map(val => key + val).join(" ") : key + rule.param[key];
        classList.push(className);
    });*/
    if (rule.hasOwnProperty("className")) {
        classList.push(rule.className);
    }

    return <rule.component {...{ classList, rule, id: ref }} />
};

export const RuleSetHeaderPersonList = ({ person }) => {
    //console.log(person);
    const Span = ({ label }) => <span className="person" title={label[window.secLang]}>{label.lv}</span>;

    return (
        <header>{
            Array.isArray(person) ?
                person.map((p, i, arr) => (<React.Fragment key={i}><Span label={p}/>{i<arr.length-1?", ":""}</React.Fragment>)) :
                <Span label={person}/>
        }</header>
    )
};

export const RulesGroup = ({ children, label }) => {
    return (
        <div className="rule group">
            { label ? <header>
                <div className="label trans">{label[window.secLang]}</div>
            </header> : null}
            <div className="rules">{children}</div>
        </div>
    )
};

export function RuleSet({ word, group, data, ruleSet, setConnectedRules }) {

    const classList = ["ruleSet"];

    if (ruleSet.className) { classList.push(ruleSet.className) }

    const header = <ruleSet.header.component {...ruleSet.header.props} />;
    const rules = <Rules {...{ word, group, data, rules: ruleSet.rules, setConnectedRules }} />;
    return (
        <div className={classList.join(" ")}>{header}{rules}</div>
    )
}

export function Rules({ word, group, data, rules, setConnectedRules }) {

    return (
        rules.map((rule, i) => (
            rule.hasOwnProperty("rules") ?
                <rule.component key={i} {...rule.props} data={data} setConnectedRules={setConnectedRules}>
                    <Rules {...{ word, group, data, rules: rule.rules, setConnectedRules }} />
                </rule.component> :
                <rule.component key={i} {...rule.props} data={data} setConnectedRules={setConnectedRules} />
        ))
    )
}
