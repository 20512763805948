
import React, { useMemo } from "react";
import Spinner from "../../components/Spinner";
import Content from "../../components/Content";
import { useFetches } from "../../io";
import { chain2path } from "../../lang/parse";
import Lesson from "../../components/common/lesson/Lesson";
import { Footer, CopyUrl } from "../../components/Footer";
import DataContext from "../../components/common/DataContext";

function Lessons({ route, data, box }) {

    //console.log("Lesson render");
    // console.log("Lesson: name: ", route.match.params.name);

    const args = {
        fileNames: ["lessons1h.json", "lessons2h.json"],
        path: "/json/v9/lessons/",
        mimeType: "application/json",
        cacheParam: { cache: "no-store" }
    };

    const [lessonsData, status, error] = useFetches(args);

    // console.log(status);
    // console.log(lessonsData);

    const lesson = useMemo(() => {
        // console.log("useMemo[lessonsData]");        
        if (lessonsData && status === "fetched" && !error) {
            localStorage.setItem("lastLesson2", route.match.params.name);
            // console.log("save lastLesson2:", route.match.params.name);
            // console.log("saved lastLesson2:", localStorage.getItem("lastLesson2"));
            return (lessonsData.find(l => chain2path({lv:{default:l.title}}) === route.match.params.name))
        }
        return null;
    }, [lessonsData, status, error, route.match.params.name]);

    if (error) { return <div>{error}</div> }
    if (status !== "fetched") { return <Spinner /> }

    // console.log(lessonData);

    return (
        <DataContext.Provider value={{ data, box }}>
            <Content className="lesson">
                <Lesson {...{ lesson } } />
                <Footer><CopyUrl /></Footer>
            </Content>
        </DataContext.Provider>
    )

}

export default Lessons;
