import { menuer } from "../nav/logic";

const nav = (theoryData) => {

    // console.log(theoryData);
    
    return theoryData
        .map(section => ({...menuer(section, "parts", "menu", ["title", "href", "default", "page"]) }));

}

export default nav;
