import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { useFetch } from "../../../io";

const QSVG = React.memo(({ src, href, className, width, height }) => {

    // const [data, setData] = useState();

    let style = null;

    if (width && height) {
        style = { width: width+"px", height: height+"px" }
    }

    const [data, status, error] = useFetch(src, "text/html");

    // console.log(status);

    /* useEffect(() => {
        async function fetchData() {
            const svg = await fetch(src)
                .then(res => res.text())
                .then(res => {

                    //console.log(parse(res));

                    return parse(res);

                    //const parser = new DOMParser();

                    //return parser.parseFromString(res, "image/svg+xml");
                });
            setData(svg);
        }
        fetchData();
    }, [src]); */

    const cn = "qsvg" + (className ? " "+className : "");

    if (status==="fetched") {

        const parsed = parse(data);

        return (
            <div className={cn} style={style}>{
                href ? <Link to={href}>{parsed}</Link> : parsed
            }</div>
        )

    }

    if (error) console.log(error);

    return null;

    

}, (prev, next) => prev.src===next.src && prev.href===next.href);

export default QSVG;
