import React from 'react';
import { labels } from "../../labels";
import { Wordform, Label } from "../Wordform";
import { transform, pronounsDative } from "../../lang/lang2";

function Debitive(props) {
    return (
        <>
        <h2><Label>{labels.transformations.moods.debitive}</Label></h2>

        <table className="transform">
            <tbody>
            <tr>
                <th>{
                    <>
                    {pronounsDative.slice(0, -2).map((p, i) => (
                        <React.Fragment key={i}>
                            <Label className="comma" key={i}>{p}</Label>
                            {i===4 ? <br /> : ""}
                        </React.Fragment>
                    ))}
                    {<Label>{pronounsDative.slice(-2)[0]}</Label>} un {<Label>{pronounsDative.slice(-1)[0]}</Label>}
                    </>
                }</th>
                <td className="big">
                    {[0, 1, 2].map((tense, i) => (
                        <Wordform className="comma" key={tense} form={transform(props.morphs, props.aux, {person: 3, tense})} />
                    ))}
                </td>
                <td className="big">
                    <Wordform hyphenate={false}
                                className="big" form={transform(props.morphs, props.word, {tense: 5})} />
                </td>
            </tr>
            </tbody>
        </table>
        </>
    )
}

export default Debitive;