import React from 'react';
import { labels } from "../../labels";
import { Wordform, Label } from "../Wordform";
import { transform } from "../../lang/lang2";

function DeclensionAdverb({ morphs, word }) {

    const basic = !(word.rule === 41 || word.rule === 42);

    return (
        (word.rule === 40 || word.rule === 42) ?
            <>
                <div className="label"><Label>{labels.adjectives.comparative}</Label></div>
                <Wordform className="big" hyphenate={false} form={
                transform(morphs, word, { basic, comp: 1 })} />

                <div className="label"><Label>{labels.adjectives.superlative}</Label></div>
                <Wordform className="big" hyphenate={false} form={
                transform(morphs, word, { basic, comp: 2 })} />

            </> : null
    )
}

export default DeclensionAdverb;
