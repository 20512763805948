import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { searches } from "../search/searches";
import { lemma2str, detectLang, wordType, nounCount, nounGender } from "../lang/lang2";
import { chain2str, entities2unicode } from "../lang/parse";
import { Textarea, sizer, IconX } from "../components/Input";
import { TransWord, TransDetails, Formula } from "./Wordform";
import { GenderIcon } from "../i/icons";

import "../css/EntryLemma.css";

function handleBlur(setState, state) {
    setTimeout(()=> {setState(state) }, 100);
}

export function Lemma(props) {

    const { word, queryStr, data, box } = props;

    //console.log("Lemma render");

    const lemma = word.hasOwnProperty("rule") ? lemma2str(word.lemma) : entities2unicode(chain2str(word));

    const id = props.id;

    const [inputValue, setinputValue] = useState(lemma);

    const [x, setX] = useState();

    let history = useHistory();

    function handleChange(e) {
        setinputValue(e.currentTarget.value);
    }

    function startSearch(inputValue, lang) {

        if (inputValue === "") {
            localStorage.removeItem(searches.general.cookieName);
        } else {
            localStorage.setItem(searches.general.cookieName, JSON.stringify({ str: inputValue, lang }))
        }
        history.push("/meklēšana/");
    }

    let timeoutId;

    //console.log(localStorage.getItem(searches.general.cookieName));

    useEffect(()=>{
        //console.log("Lemma: useEffect[word] lemma:", lemma);
        setinputValue(queryStr||lemma);
    }, [word, queryStr]);

    useEffect(()=>{
        if (id.current) {
            sizer(id.current);
        }
        const lang = detectLang(inputValue);
        if (inputValue.trim() !== lemma && lang !== false) {
            //console.log("we should start search for", inputValue);
            clearTimeout(timeoutId);
            timeoutId = setTimeout(()=>{
                startSearch(inputValue, lang);
            }, 500);
        }
        return () => { clearTimeout(timeoutId) }
    }, [inputValue]);

    let md = false;


    const input = <Textarea className="search" id={id} onChange={handleChange} value={inputValue}
                            onFocus={(e)=>{ setX(true); e.currentTarget.select() }}
                            onBlur={()=>{ if (!md) setX(false) }} />;

    return (<div className="searchForm">
        {input}
        <IconX onMouseDown={()=>{ md = true; }} handleClick={{x: ()=>{ startSearch("", "lv")}, v: ()=>{console.log("icon content clicked")} }} x={x} word={word} />
        <div className="trans"><TransWord word={word} /></div>
        <TransDetails {...{ word, data, box }} />
    </div>)
}

