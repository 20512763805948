import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
//import { print } from "../print";
import { diffDays, getRandomInt } from "../prototypes";
import { date2time, addDays } from "../lang/time";
//import { punctuation, wordType } from "../lang/lang2";
//import { convertFormulas } from "../lang/alternation";
//import { time2text } from "../lang/time";
import TimeText from "../components/TimeText";
import Content from "../components/Content";
import Background from "../components/Background";
import { Clock, PlayPause } from "../components/Clock";
import Calendar from "../components/Calendar";
import { Runajiet } from "../components/Logo";
import { Days } from "../components/Days";
import { labels } from "../labels";

import "./Home.css";
import "../css/Days.css";

/*
function Percentage(props) {

    let percent = (props.done / props.left) * 100;
    if (percent > 100) percent = 100;

    return (
        <div className="percent">
            {
                percent > 0 ?
                    <div className={"done"+(percent===100?" complete":"")} style={{ width: percent+"%" }}>&thinsp;</div> :
                    null
            }
            {
                percent < 100 ?
                    <div className={"left"+(percent===0?" complete":"")} style={{ width: (100-percent)+"%" }}>&thinsp;</div> :
                    null
            }
        </div>
    )

}
function Home0(props) {

    const rx = new RegExp(`[${punctuation}]`);

    console.log("Дамы оживляются! Русский миллиардер Абрамович развелся с женой".split(rx));

    console.log(props.data.words[0]);

    const { words, chains } = props.data;

    const syn = {
        "lv": "lietvārdi", "dv": "darbības vārdi", "iv": "īpašības vārdi",
        "av": "apstākļa vārdi", "sv": "skaitļa vārdi"
    };

    const prevCounts = [
        { "dv": 3216 }, { "lv": 2193 }, { "iv": 190 }, { "av": 104 }, { "sv": 24 }, { "ke": 5307 }
    ];

    let counts = ["dv", "lv", "iv", "av", "sv", "ke"]
        .map(type => (
            {
                type,
                name: type === "ke" ? "izteicieni" : syn[type],
                count: type === "ke" ? chains.length : words.filter(item=>wordType(item).short===type).length
            }
        ));

    return(
        <>
        <Content className="home">
            <h1>Jaunā versija</h1>
            {
                counts.map((group, i) => (
                    <div key={i} className="group">
                        <h2>{group.name}</h2>
                        <div className="count">{group.count}</div>
                        <div className="count prev">{
                            "iepriekš: "+(prevCounts.find(type=>type.hasOwnProperty(group.type))[group.type])
                        }</div>
                        <Percentage
                            done={group.count}
                            left={prevCounts.find(type=>type.hasOwnProperty(group.type))[group.type]} />
                    </div>
                ))
            }


        </Content>
        </>
    );
}
*/

function minFontSize(fontSize) {
    if (fontSize.length === 1) { return { fontSize: fontSize[0]} }
    if (fontSize.length > 1) {
        return { fontSize: Math.min(...fontSize.map(s=>parseInt(s))) + "px" }
    }
    return null;
}

const TimeFAQ = React.memo(({ time, data, box, questionOfTime, setQuestionOfTime }) => {

    //console.log("TimeFAQ render");

    const questions = ["which", "from", "until", "when"];

    const [fontSize, setFontSize] = useState([]);

    const adjustFontSize = { "18px": 1, "16.5px": 23, "15px": 37, "14px": 41, "13px": 47 };
    
    /*useEffect(()=>{
        if (fontSize.length > 0) {
            console.log("fontSize:", fontSize);
        }
    }, [fontSize]);*/

    return (
        <div className="timeFAQ">

            <div className="answer" style={minFontSize(fontSize)}>
                <TimeText
                    {...{time, data, box, adjustFontSize, setFontSize, index: 0 }}
                    question={questionOfTime} pres="time12" />
                <TimeText
                    {...{time, data, box, adjustFontSize, setFontSize, index: 1 }}
                    className="trans" question={questionOfTime} pres="time12" lang={window.secLang} />
            </div>

            <div className="questions">{questions.map((question, q) => (
                <div key={q}
                     className={`question${question===questionOfTime?" active":""}`}
                     onClick={()=>{ setQuestionOfTime(question) }}>
                    <div>{labels.time.questions[question].short.lv}</div>
                    <div className="trans" key={q}>{labels.time.questions[question].short[window.secLang]}</div>
                </div>
            ))}</div>
        </div>
    )
},
    (prev, next) => (
        prev.time.mm === next.time.mm &&
        prev.time.day === next.time.day &&
        prev.questionOfTime === next.questionOfTime
    ));

const DayFAQ = React.memo(({ date, data, box, questionOfDay, setQuestionOfDay })=>{
    const questions = ["which", "from", "until", "when"];

    //console.log("DayFAQ render");

    return (

        <div className="dayFAQ">
            <div className="answer">
                <TimeText time={date2time(date)} data={data} box={box} question={questionOfDay} pres="dayMonth" />
                <TimeText
                    className="trans" time={date2time(date)} data={data} box={box}
                    question={questionOfDay} pres="dayMonth" lang={window.secLang} />
            </div>

            <div className="questions">{questions.map((question, q) => (
                <div key={q}
                     className={`question${question===questionOfDay?" active":""}`}
                     onClick={()=>{ setQuestionOfDay(question) }}>
                    <div>{labels.date.questions[question].short.lv}</div>
                    <div className="trans" key={q}>{labels.date.questions[question].short[window.secLang]}</div>
                </div>
            ))}</div>
        </div>
    )
},
    (prev, next)=>(
        prev.date.getDate() === next.date.getDate() &&
        prev.date.getMonth() === next.date.getMonth() &&
        prev.questionOfDay === next.questionOfDay
    )
);

function Home(props) {

    //console.log("Home render");

    //const { morphs, words } = props.data;

    const box = props.box;

    const date = new Date();

    const [time, setTime] = useState(
        date2time(date)
    );
    
    const [range, setRange] = useState({
        start: date, end: date
    });

    const [hidden, setHidden] = useState(false);

    const [data, setData] = useState(()=>{
        //console.log("Home: initial setData");
        const d = { morphs: props.data.morphs, words: props.data.words.filter(w=>w.hasOwnProperty("value"))};
        //console.log(d);
        return d;
    });

    const [started, start] = useState(false);

    const [questionOfTime, setQuestionOfTime] = useState("which");

    const [ questionOfDay, setQuestionOfDay] = useState("which");

    function checkTime(t) {
        /*console.log(
            time2text(t, "lv", "from", "time12", props.data)
        );*/
        //setTextTime(() => time2text(t, "lv", "from", "time12", props.data, props.box));
    }

    //let timeoutId;

    function backgroundClick(e) {
        //console.log("bg clicked");
        //console.log(e.currentTarget.parentNode.tagName);
        //console.log([...e.target.classList]);
        //console.log(["content", "home"].every(c => [...e.target.classList].includes(c)));
        const bgClicked = ["content", "home"].every(c => [...e.target.classList].includes(c));
        const html = document.documentElement;
        if (bgClicked) {
            html.classList.toggle("hidden");
        } else {
            html.classList.toggle("hidden", false);
        }
    }

    useEffect(()=>{
        //console.log("Home useEffect[]");
        document.body.classList.add("home");
        document.body.addEventListener("click", backgroundClick);
        document.title = "Runājiet говорите";
        props.setNav({});
        start(true);
        if (time) checkTime(time);

        return ()=>{
            document.body.classList.remove("home");
            document.body.removeEventListener("click", backgroundClick);
            start(false);
        };

    }, []);

    /* useEffect(()=>{
        console.log(`start: ${range.start}`);
        console.log(`end: ${range.end}`);
    }, [range]); */

    /*useEffect(()=>{
        console.log(time);
    },[time]);*/

    const exactDays = 2;
    //const diaryBookmark = addDays(date, -exactDays);

    const [lastDayInDiary, setLastDayInDiary] = useState();

    const days = useMemo(() => {
        //console.log("days run");
        return <Days {...{ startDate: date, exactDays, data: props.data, box, setLastDayInDiary } } />
    }, [date.getDate(), date.getMonth(), date.getFullYear()]);

    const diaryBookmark = useMemo(() => {
        // console.log("last day in diary:", lastDayInDiary);
        return addDays(lastDayInDiary, -exactDays);
    }, [lastDayInDiary]);

    const runajiet = useMemo(() => <Runajiet />, []);

    return(
        <Content className={`home${hidden?" hidden": ""}`}>
            <Background />
            <div className="main col">
                {runajiet}
                {days}
                {diaryBookmark ?
                <div className="archive">
                    <Link
                        to={`/dienasgrāmata/${diaryBookmark.getFullYear()}/${diaryBookmark.getMonth()+1}/${diaryBookmark.getDate()}/`}
                        title={labels.ui.linkArchive[window.secLang]}>{labels.ui.linkArchive.lv}</Link>
                </div> : null}
            </div>
            <div className="col right">

                <div className="clock">
                    <Clock className="clockFace" width="250" height="250" {...{ time, setTime, start, started } } />
                    <PlayPause
                        width="50" height="50"
                        started={started}
                        onClick={()=>{ start( prevState => !prevState ) }} />
                    
                    <TimeFAQ {...{ time, data, box, questionOfTime, setQuestionOfTime } } />

                    <div className="dayTime">
                        <TimeText time={time} data={data}
                              box={box} question="which" pres="dayTime" />
                        <TimeText className="trans" time={time} data={data}
                              box={box} question="which" pres="dayTime" lang={window.secLang} />
                    </div>

                    <div className="questionOfTime">
                        <div>{labels.time.questions[questionOfTime].full.lv}?</div>
                        <div className="trans">{labels.time.questions[questionOfTime].full[window.secLang]}?</div>
                    </div>

                </div>
                
                <div className="calendar">
                    <Calendar {...{ date, range, setRange } } data={data} box={box} />
                    { (()=>{
                        if (range.start && range.end) {
                            if (range.start.toDateString() !== range.end.toDateString()) {
                                return (
                                    <>
                                    <div>
                                        <TimeText time={date2time(range.start)} data={data} box={box} question="from" pres="dayMonth" />
                                        <TimeText time={date2time(range.end)} data={data} box={box} question="until" pres="dayMonth" />
                                    </div>
                                    <div className="trans">
                                        <TimeText time={date2time(range.start)}
                                                  data={data} box={box} question="from" pres="dayMonth" lang={window.secLang} />
                                        <TimeText time={date2time(range.end)}
                                                  data={data} box={box} question="until" pres="dayMonth" lang={window.secLang} />
                                    </div>
                                    </>
                                )
                            }
                        }
                        return <DayFAQ {...{date: range.start || date, data, box, questionOfDay, setQuestionOfDay} } />
                    })()
                    }
                </div>
                
            </div>
        </Content>
    )
}

export default Home;
