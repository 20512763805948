import React from "react";
import { generateId, camelCase, objectsEqual } from "../../../prototypes";
import { parsed2html, parseChain, entities2unicode } from "../../../lang/parse";
import qcomponents, { splitState } from "./qcomponents";
import QNode from "./QNode";
import { Link } from "react-router-dom";
import { containsLang } from "../../../lang/lang2";

const qtags = qcomponents.map(qc => qc.tag);

// console.log(qtags);

export function attrs2props(node) {
    const nodeProps = {};
    if (node.hasAttributes()) {
        [...node.attributes].forEach(attr => {
            // if (attr.name==="icon") { console.log(attr.name) }
            nodeProps[camelCase(attr.name)] = attr.value;
        });
    }
    return nodeProps;
}

export function findNodes(node, nodeNames) {

    let res;

    if (Array.isArray(node)) {
        res = [];
        for (let i=0, l=node.length; i<l; i++) {
            const found = findNodes(node[i], nodeNames);
            if (found) {
                if (Array.isArray(found)) {
                    if (found.length > 0) {
                        res.push(found);
                    }
                } else {
                    res.push(found);
                }
            };
        }
    } else {
        if (node.nodeType === 1) {
            if (nodeNames.includes(node.nodeName.toLowerCase())) {
                res = node;
            } else {
                if (node.hasChildNodes()) {
                    const children = [...node.childNodes];
                    res = findNodes(children, nodeNames);
                }
            }
        }
    }

    return Array.isArray(res) ? res.length === 1 ? res[0] : res.flatten() : res;

}

let counter;

export const MemNode = React.memo(({ node, props, qprops={}, count }) => {
    return (
        <Node {...{ node, props, qprops, count }} />
    )
}, (prev, next) => objectsEqual(prev.node, next.node));

const secLang = (node) => {

    const res = false;

    if (["td", "th", "div", "p", "li", "h1", "h2", "h3", "h4"].includes(node.nodeName.toLowerCase())) {
        const text = node.textContent;
        if (text.length > 4) {
            // console.log(text);
            // console.log(containsLang(text, window.secLang));
            return containsLang(text, window.secLang);
        }
    }

    return res;
}


export const Node = ({ node, props, qprops={}, count }) => {

    // console.log("Node qprops.state:", qprops.state);

    if (count === undefined) { counter = 0 };

    const { data, box } = props;

    if (Array.isArray(node)) {
        // console.log("node is an array");
        return node.map((n, i) => {
            return <Node key={i} {...{node: n, props, qprops, count: counter}} />;
        });
    } else {
        // console.log(`node ${node.nodeName.toLowerCase()} is a node of type ${node.nodeType}`);
        let nodeProps = { };
        // if (secLang(node)) if (!node.hasAttribute("lang")) node.setAttribute("lang", window.secLang);

        if (node.nodeType === 1) {
            nodeProps = attrs2props(node);
        }
        if (node.nodeType === 8) {
            return "";
        }

        if (node.hasChildNodes()) {

            // console.log(`node ${node.nodeName.toLowerCase()} has children`);

            if (node.nodeType === 1) {

                if (node.nodeName.toLowerCase() === "a") {
                    nodeProps.rel = "noopener noreferrer";
                }

                if (node.nodeName.toLowerCase() === "qlink") {

                    const pathname = "/" + (decodeURI(window.location.pathname)).substr(window.baseName.length);

                    // console.log(decodeURI(pathname));
                    // console.log(document.title);

                    return (
                        <Link {...{
                            ...nodeProps,
                            to: { pathname: nodeProps.to, state: { href: pathname, title: document.title }}
                        } }>
                            <Node {...{ node: [...node.childNodes], props, qprops, count: counter}} />
                        </Link>
                    )
                }

                //console.log("node is element");
                if (node.childNodes.length > 1) {
                    //console.log("element has children > 1");
                    return (
                        <Element {...{ tagName: node.nodeName.toLowerCase(), nodeProps }}>
                            <Node {...{ node: [...node.childNodes], props, qprops, count: counter}} />
                        </Element>
                    )

                } else {
                    //console.log("element has child");
                    if (node.childNodes[0].nodeType === 3) {
                        // console.log("element with single text node:", node.nodeName.toLowerCase());
                        //console.log("child is a text node");

                        return (
                            <Static {...{ tagName: node.nodeName.toLowerCase(), nodeProps }}>
                                {
                                parsed2html(
                                    { data, box }, parseChain(data, {lv:{default:node.childNodes[0].nodeValue}})
                                )
                                }
                            </Static>
                        )

                    } else {
                        //console.log("child is an element");
                        return (
                            <Element {...{ tagName: node.nodeName.toLowerCase(), nodeProps }}>
                                <Node {...{ node: node.childNodes[0], props, qprops, count: counter }} />
                            </Element>
                        )
                    }
                }

            }

        } else {

            // console.log("node has no children");
            // console.log(node.nodeName);

            if (node.nodeType === 3) {
                // console.log("node is a text node");
                // console.log("nodeValue:", node.nodeValue);
                if (node.nodeValue.trim()==="") {
                    // console.log(`empty text node, ${node.nodeValue.charCodeAt(0)}`);
                    return node.nodeValue === " " ? "\u00A0" : entities2unicode(node.nodeValue);
                }
                return <span>{parsed2html(
                    { data, box }, parseChain(data, {lv:{default:node.nodeValue}})
                )}</span>;

            }
            if (node.nodeType === 1) {

                // console.log(`node ${node.nodeName.toLowerCase()} is an element`);

                if (qtags.includes(node.nodeName.toLowerCase())) {

                    // console.log("qcomponent:", node.nodeName.toLowerCase());

                    let id;
                    if (!node.hasAttribute("id")) {
                        if (qprops.id) {
                            id = qprops.id + counter;
                            counter++;
                        } else {
                            id = generateId();
                        }
                    } else {
                        id = node.getAttribute("id");
                    }
                    // console.log("Node: qprops:", qprops);

                    const qpropsWithSplitState = splitState(qprops, id);

                    const updatedQprops = {...qpropsWithSplitState, id };

                    if (qprops.id) { updatedQprops.exerciseId = qprops.id }

                    // console.log("updatedQprops:", updatedQprops);

                    // console.log("qprops split state:", qpropsWithSplitState.state);

                    // console.log("Node key id before render:", id);

                    return <QNode key={id} {...{
                        qprops: updatedQprops,
                        node
                    }} />;
                }
                return <Static {...{ tagName: node.nodeName.toLowerCase(), nodeProps }} />;
            }
        }

    }

};

function qcounter(id) {
    const counters = JSON.parse(window.sessionStorage.getItem("qcount"))||[];
    const index = counters.findIndex(c => c.id === id);
    let counter;
    if (index < 0) {
        counter = 1;
        counters.push({ id, counter })
    } else {
        counter = counters[index].counter + 1;
        counters[index].counter = counter;
    }
    window.sessionStorage.setItem("qcount", JSON.stringify(counters));
    return counter;
}

const Element = ({ tagName, nodeProps, children }) => {
    const T = tagName;
    return <T {...nodeProps}>{children}</T>;
}

const Static = React.memo(({ tagName, nodeProps, children }) => {
    const T = tagName;
    return <T {...nodeProps}>{children}</T>;
}, ()=>{
    // console.log("memo");
    return false;
});

function renderNode({ node, props, qprops }, count=0) {

    if (count === 0) counter = 0;
    
    const { data, box } = props;

    //console.log(node);
    if (Array.isArray(node)) {
        //console.log("node is an array");
        return node.map(n => {
            return renderNode({node: n, props, qprops}, counter);
        });
    } else {
        //console.log("node is a node");
        const nodeProps = { key: generateId() };
        if (node.nodeType === 1) {
            if (node.hasAttributes()) {
                [...node.attributes].forEach(attr => {
                    nodeProps[camelCase(attr.name)] = attr.value;
                })
            }
        }

        if (node.hasChildNodes()) {
            //console.log("node has children");
            if (node.nodeType === 1) {

                if (node.nodeName.toLowerCase() === "a") {
                    nodeProps.rel = "noopener noreferrer";
                }

                //console.log("node is element");
                if (node.childNodes.length > 1) {
                    //console.log("element has children > 1");
                    return React.createElement(
                        node.nodeName.toLowerCase(),
                        nodeProps,
                        renderNode({ node: [...node.childNodes], props, qprops }, counter)
                    );

                } else {
                    //console.log("element has child");
                    if (node.childNodes[0].nodeType === 3) {
                        //console.log("element with single text node:", node.nodeName.toLowerCase());
                        //console.log("child is a text node");
                        return React.createElement(
                            node.nodeName.toLowerCase(),
                            nodeProps,
                            parsed2html(
                                { data, box }, parseChain(data, {lv:{default:node.childNodes[0].nodeValue}})
                            )
                        );

                    } else {
                        //console.log("child is an element");
                        return React.createElement(
                            node.nodeName.toLowerCase(),
                            nodeProps,
                            renderNode({ node: node.childNodes[0], props, qprops }, counter)
                        );
                    }
                }

            }

        } else {

            //console.log("node has no children");

            if (node.nodeType === 3) {
                //console.log("node is a text node");
                return <span key={generateId()}>{parsed2html(
                    { data, box }, parseChain(data, {lv:{default:node.nodeValue}})
                )}</span>;

            }
            if (node.nodeType === 1) {
                //console.log("node is an element");
                if (qtags.includes(node.nodeName.toLowerCase())) {
                    counter++;
                    //console.log("count of qnodes:", counter);
                    const id = qprops.id ? qprops.id + counter : generateId();

                    console.log("create qnode");

                    // console.log("qprops.state:", qprops.state);

                    const qpropsWithSplitState = splitState(qprops, id);

                    // console.log("qprops split state:", qpropsWithSplitState.state);

                    return <QNode key={id} {...{
                        qprops: {...qpropsWithSplitState, counter, id },
                        node
                    }} />;
                }
                return React.createElement(
                    node.nodeName.toLowerCase(),
                    nodeProps
                );

            }
        }
    }
}

export default renderNode;

