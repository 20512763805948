import React, { useState, useEffect, useMemo, useRef, useLayoutEffect, useCallback } from "react";
import Content from "../components/Content";
import { aiove, rxToggleClass } from "../prototypes";
import { str2word } from "../lang/parse";
import { transform } from "../lang/lang2";
import { labels } from "../labels";
import { groups, Groups } from "../components/grammar/verbs/LiveGroups";
import { Words } from "../components/grammar/LiveWords";
import { Wordform, TransWord, Formula } from "../components/Wordform";
import { Connect } from "../components/svg";
import "../css/Grammar.css";
import "../css/SimpleTense.css";
import "../components/grammar/LiveTable.css";
import "../components/grammar/LiveWords.css";
import "../components/grammar/verbs/LiveRules.css";

const symbols = [
    {
        rule: [11, 12, 13],
        morphs: [
            { param: { person: 0, tense: 1 }, flex: "t" },
            { param: { person: 1, tense: [0, 1] }, flex: "u" },
            { param: { person: 1, tense: 2 }, flex: "šu" },
            { param: { person: 2, tense: 0 }, flex: "i" },
            { param: { person: 2, tense: 1 }, flex: "" },
            { param: { person: 2, tense: 2 }, flex: "si" },
            { param: { person: [3, 6], tense: 0 }, flex: "a" },
            { param: { person: [3, 6], tense: 1 }, flex: "" },
            { param: { person: [3, 6], tense: 2 }, flex: "s" },
            { param: { person: 4, tense: 0 }, flex: "ām" },
            { param: { person: 4, tense: 1 }, flex: "am" },
            { param: { person: 4, tense: 2 }, flex: "sim" },
            { param: { person: 5, tense: 0 }, flex: "āt" },
            { param: { person: 5, tense: 1 }, flex: "at" },
            { param: { person: 5, tense: 2 }, flex: "siet" }
        ]
    },
    {
        rule: [111, 121, 131],
        morphs: [
            { param: { person: 0, tense: 1 }, flex: "ties" },
            { param: { person: 1, tense: [0, 1] }, flex: "os" },
            { param: { person: 1, tense: 2 }, flex: "šos" },
            { param: { person: 2, tense: 0 }, flex: "ies" },
            { param: { person: 2, tense: 1 }, flex: "ies" },
            { param: { person: 2, tense: 2 }, flex: "sies" },
            { param: { person: [3, 6], tense: 0 }, flex: "ās" },
            { param: { person: [3, 6], tense: 1 }, flex: "as" },
            { param: { person: [3, 6], tense: 2 }, flex: "sies" },
            { param: { person: 4, tense: 0 }, flex: "āmies" },
            { param: { person: 4, tense: 1 }, flex: "amies" },
            { param: { person: 4, tense: 2 }, flex: "simies" },
            { param: { person: 5, tense: 0 }, flex: "āties" },
            { param: { person: 5, tense: 1 }, flex: "aties" },
            { param: { person: 5, tense: 2 }, flex: "sieties" }
        ]
    }
];

function getSymbol(rules, param) {
    return symbols
        .find(symbolSet => {
            return Array.isArray(symbolSet.rule) ?
                symbolSet.rule.some(r => aiove(rules, r)) :
                aiove(rules, symbolSet.rule)
        })
        .morphs.find(morph => (
            Object.keys(morph.param).every(key => aiove(morph.param[key], param[key]))
        ));
}

function getRef(param, connected) {
    //console.log(param);
    const conn = connected.find(c => Object.keys(param).every(key => param[key]===c.param[key]));
    if (conn) {
        //console.log("connection found:", conn);
    } else {
        //console.log("ERROR: connection not found");
    }
    return conn ? { ref: conn.ref } : {}
}

function Row({ person, word, data, setConnectedForms }) {

    const connected = [
        { param: { tense: 1, person: 2 }, ref: useRef() },
        { param: { tense: 1, person: 3 }, ref: useRef() },
        { param: { tense: 1, person: 4 }, ref: useRef() },
        { param: { tense: 1, person: 5 }, ref: useRef() }
    ];

    useLayoutEffect(()=>{
        if (word) {
            //console.log(connected[0].ref);
            connected.forEach(conn => {
                if (conn.ref.current) {
                    //console.log("ref applied:", conn.ref.current);
                    //setConnectedForms([]);
                    setConnectedForms((prev)=> {
                        const updated = [...prev];
                        const index = updated.findIndex(c => c.ref.current === conn.ref.current);
                        if (index >= 0) {
                            updated[index] = conn;
                        } else {
                            updated.push(conn);
                        }
                        return updated;
                    });
                }
            });
        }
    }, [word]);

    return (
         [[11,12,13],[111,121,131]].map((rules, i) =>(
            <React.Fragment key={i}>
                <tr key={"row"+i} className={word && rules.includes(word.rule) ? "form" : "symbol"}>
                    <td><div className="symbol flex infinitive">{
                        getSymbol(rules, {tense: 1, person: 0}).flex
                    }</div></td>
                    {
                        [0, 1, 2].map((tense, i) => (
                            <td key={i}
                                className={`form tense${tense} person${person}${rules[0]===111?" reflexive":" nonReflexive"}`}>{
                                <Form {...{ word, data, tense, person, rules, connected }} />
                            }</td>
                        ))
                    }
                </tr>
                <tr className="spacer">
                    <td>&nbsp;</td>
                </tr>
            </React.Fragment>
        ))
    )
}

const Form = ({ word, data, tense, person, rules, connected }) => {

    const form = word && rules.includes(word.rule);

    const classList = form ? ["form"] : ["symbol", "flex"];

    const id = getRef({tense, person}, connected);

    if (id.ref) {
        classList.push("connected");
        //classList.push(`tense${tense}`);
        //classList.push(`person${person}`);
        /*if (!form && rules[0]===11) {
            classList.push("empty");
        }*/
    }

    return (
        form ?
            <div className={classList.join(" ")} {...id }>
                <Wordform
                    form={transform(data.morphs, word, {tense, person})}
                    hyphenate={false}
                />
            </div> :
            <div className={classList.join(" ")}>
                { getSymbol(rules, {tense, person}).flex }
            </div>
    )

};

const Table = React.memo(({ word, data, box, setConnectedForms }) => {

    //console.log("Table render");

    const getPronoun = (person, lang) => labels.person
        .filter(p => person===3 ? (p.person===3||p.person===6) : p.person === person)
        .map(p => p[lang]).join(", ");

    const getQuestion = (person, tense, lang) => labels.verbs.questions
        .find(q => q.tense === tense).person[person][lang];

    const HeaderRow = ({ person }) => (

        person !== 3 ?

            <tr className="header">
                <td className="person" title={getPronoun(person, window.secLang)}>{
                    getPronoun(person, "lv")
                }</td>
                {
                    [0, 1, 2].map((tense, i) => (
                        <td className="question" key={i}
                            title={getQuestion(person, tense, window.secLang)}>
                            {getQuestion(person, tense, "lv")}
                        </td>
                    ))
                }
            </tr>
            :
            <>
            <tr className="header">
                <td className="person" colSpan="4" title={getPronoun(person, window.secLang)}>{
                    getPronoun(person, "lv")
                }</td>
            </tr>
            <tr>
                <td>&nbsp;</td>
                {
                    [0, 1, 2].map((tense, i) => (
                    <td className="question" key={i}
                        title={getQuestion(person, tense, window.secLang)}>
                        {getQuestion(person, tense, "lv")}
                    </td>))
                }
            </tr>
            </>

    );

    return (
        <div className="table">
            <table>
                <tbody>{
                    [1, 2, 3, 4, 5].map((person, i) => (
                        <React.Fragment key={i}>
                            <HeaderRow person={person} />
                            <Row {...{person, word, data, setConnectedForms }} />
                        </React.Fragment>
                    ))
                }</tbody>
            </table>
        </div>
    )
}, (prev, next) => prev.word === next.word);


function LiveTable({ data, box }) {

    //console.log("LiveTable render");

    const [word, setWord] = useState();

    const [connectedForms, setConnectedForms] = useState([]);

    const [connectedRules, setConnectedRules] = useState([]);

    const [connections, setConnections] = useState([]);

    useEffect(()=>{

        setConnections((prev) => {

            if (prev.length > 0) {
                for (let i=0, l=prev.length; i<l; i++) {
                    rxToggleClass(prev[i].A.ref.current, /active/, false);
                    rxToggleClass(prev[i].B.ref.current, /active/, false);
                }
            }

            return [];
        });

        if (word) {

            //console.log("current word:", word);
            //console.log(connected.map(c=>c.ref));
            //console.log(connectedRules);

            const wordRules = connectedRules
                .filter(cr => cr.words.some(w => w.id===word.id));

            //console.log(wordRules);

            wordRules.forEach((wordRule, i) => {
                if (wordRule.ref.current) {
                    const connectTo = connectedForms
                        .filter(conn => Object.keys(conn.param)
                            .every(key => aiove(wordRule.param[key], conn.param[key])));
                    //console.log("connect to:", connectTo);
                    connectTo.forEach(conn => {
                        if (conn.ref.current) {

                            //console.log("connected rule:", wordRule);
                            //console.log("connected form:", conn);

                            //console.log("connect from:", wordRule.ref.current);
                            //console.log("connect to:", conn.ref.current);
                            //console.log(wordRule.ref.current instanceof Element || wordRule.ref.current instanceof HTMLDocument);
                            setConnections((prev) => {
                                conn.ref.current.classList.add("active");
                                wordRule.ref.current.classList.add("active");
                                /*Object.keys(conn.param).forEach(key => {
                                    conn.ref.current.classList.add(`${key}${conn.param[key]}`);
                                    wordRule.ref.current.classList.add(`${key}${conn.param[key]}`);
                                });*/
                                const updated = [...prev];
                                const index = updated
                                    .findIndex(connection =>
                                        connection.A.ref.current === wordRule.ref.current &&
                                        connection.B.ref.current === conn.ref.current
                                    );
                                if (index >= 0) {
                                    updated[index] = {
                                        A: wordRule, B: conn
                                    }
                                } else {
                                    updated.push({ A: wordRule, B: conn })
                                }
                                return updated;
                            });
                        }
                    });
                }
            });
        }

    }, [word, connectedRules, connectedForms]);

    /*useMemo(()=>{
        console.log(connections);
    }, [connections]);*/

    return (
        <div className="live">
            <Words {...{ setWord, word, data, box, groups }} />
            <Table {...{ word, data, box, setConnectedForms }} />
            <Groups {...{ word, data, box }} setConnectedRules={setConnectedRules} />
            <Connections connections={connections} word={word} />   
        </div>
    )
}

function Connections({ connections, word }) {

    //console.log("Connections render");

    /*connections.forEach(c => {
        console.log(c.A);
        console.log(c.B);
    });*/

    return (connections.length > 0 ? connections.map((conn, i) => (
        (()=>{
            const classList = [];
            const param = {...conn.A.param, ...conn.B.param};
            //console.log(param);
            /*Object.keys(param).forEach(key => {
                classList.push(`${key}${param[key]}`);
            });*/
            if (conn.A.hasOwnProperty("className")) {
                classList.push(conn.A.className);
                if (conn.B.ref.current) {
                    rxToggleClass(conn.B.ref.current, /^rule/, false);
                    conn.B.ref.current.classList.add(conn.A.className);
                }
            }
            return (
                <Connect
                    key={i} refA={conn.A.ref} refB={conn.B.ref}
                    redrawTrigger={word}
                    classList={classList}
                    />
            )
        })()
    )) : null)
}

function SimpleTense({ data, box, setNav }) {

    //console.log("SimpleTense render");

    const words = groups.map(group => group.words).flatten().map(word => str2word(data, word).word);

    //console.log(words);
    useEffect(()=>{
        document.title = "Vienkāršie laiki";
    },[]);
    
    return (
        <Content className="grammar verbs">
            <h1>Vienkāršie laiki</h1>
            <LiveTable {...{ data: { morphs: data.morphs, words }, box }} />
        </Content>
    )
}

export default SimpleTense;
