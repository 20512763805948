
import { getType, generateId } from "./prototypes";
import { morphNames, getMorph, getRuleByMorph, getKey, getLemma, transform } from "./lang/lang2";
//import { labels } from "./labels";

function inherit(word, group) {

    let res = {};

    Object.keys(group).forEach(key => {
        //console.log("key:", key);
        if (key !== "words") res[key] = morphNames.includes(key) ?
            getType(group[key] !== "array") ? [].concat(group[key]) : group[key] : group[key];
    });

    Object.keys(word).forEach(key => {

        if (morphNames.includes(key)) {

            [].concat(word[key]).forEach((val, index) => {

                if (getType(val) === "number") {
                    //console.log(key, val);
                    //console.log(res[key][index]);
                    if (res[key][index] === undefined) {
                        console.log("WARNING: no group key '" + key + "' for parameter " + val);
                    } else {
                        if (/:\d+$/.test(res[key][index])) {
                            res[key][index] = res[key][index].replace(/:\d+$/, ":" + val);
                        } else {
                            res[key][index] += ":" + val;
                            //console.log(res[key][index]);
                        }
                    }
                }

                if (getType(val) === "string") {
                    //console.log(val);
                    if (key === "root") {
                        if (res[key][index] === undefined) {
                            console.log("ERROR: no parent root with index of child root");
                        } else {
                            res[key][index] += ":" + val;
                            // console.log(res[key][index]);
                        }
                    } else {
                        if (res[key] === undefined) res[key] = [];
                        res[key].push(val);
                    }
                    //console.log(key, ":", res[key]);
                }

                if (getType(val) === "array") {
                    console.log(key, "value is array");
                    res[key] = word[key];
                }

            });

        } else {
            if (key === "lemma") {
                //console.log(word);
                if (word.lemma.hasOwnProperty("param")) {
                    res.lemmaParam = word.lemma.param;
                } else {
                    console.log("no param for lemma, word:", word.lemma, word);
                }
            } else {
                res[key] = word[key];
            }
        }

    });

    // console.log("inherited:", res);

    return res;
}

function assemble(word, morphs) {

    if (!word.hasOwnProperty("root")) return word;

    let res = { morphs: [] };

    Object.keys(word).forEach(key => {
        if (!morphNames.includes(key)) res[key] = word[key];
    });

    let morph;

    //if (res.hasOwnProperty("alt")) console.log(res.alt);

    morphNames.forEach(morphName => {
        //console.log("morph name:", morphName);
        if (word.hasOwnProperty(morphName)) {
            morph = {};
            morph[morphName] = [];
            //console.log(morphName, word[morphName]);
            word[morphName].forEach(val => {
                if (res.hasOwnProperty("alt") && morphName === "root") {
                    // console.log(word, res.alt);
                    const clone = JSON.parse(JSON.stringify(getMorph(morphs, morphName, val)));
                    //console.log(clone.origin);
                    clone.origin.alt = res.alt;
                    clone.origin.id = generateId();
                    morph[morphName].push(clone);
                } else {
                    morph[morphName].push(getMorph(morphs, morphName, val));
                }
            });
            //console.log("morph:", morph);
            res.morphs.push(morph[morphName]);
        }
    });

    res.morphs = res.morphs.flatten();

    //console.log(res);

    if (!res.hasOwnProperty("rule")) {
        //console.log(word);
        res.rule = getRuleByMorph(res.morphs);
        //console.log("rule:", res.rule);
    } else {
        if (Array.isArray(res.rule)) if (res.rule.length === 1) res.rule = res.rule[0];
    }

    return res;

}

function wordExists(word, words) {
    function id(wordObject) {
        let res = "";
        wordObject.morphs.forEach(morph => {
             res += morph.origin.id || "";
        });
        return res + "-R:" + wordObject.rule;
    }
    let wordId = id(word);
    //console.log(wordId);
    for (let i=0; i<words.length; i++) {
        if (id(words[i]) === wordId) return true;
    }
    return false;
}

function splitByRules(word, words) {
    if (word.hasOwnProperty("rule")) {
        if (getType(word.rule) === "array") {
            if (word.rule.length > 1) {
                // console.log("split word:", word);
                let res = [], clone;
                word.rule.forEach((rule, ruleIndex) => {
                    clone = JSON.parse(JSON.stringify(word));
                    clone.rule = rule;
                    if (wordExists(clone, words)) {
                        console.log("WARNING: skip split by rules: word exists",
                            (JSON.stringify(clone.morphs.map(m => m.origin.morph)) + ", rule: " + clone.rule )  );
                    } else {
                        clone.morphs = [];
                        word.morphs.forEach(morph => { clone.morphs.push(morph) });
                        // keep original id for the first rule of the list
                        if (ruleIndex > 0) clone.id = generateId();
                        res.push(clone);
                    }
                });
                return res;
            }
        }
    }
    return word;
}

export function mapper(morphs, data) {

    Object.keys(morphs).forEach(key => { morphs[key].forEach(morph => { morph.id = generateId() }) });

    let words = [];

    console.time("inherit");
    
    let simpleWords = data
        .filter(record => record.hasOwnProperty("words"))
        .map(group => group.words.map(word => inherit(word, group)))
        .flatten();

    console.timeEnd("inherit");

    // console.log(simpleWords);

    console.time("assemble");

    simpleWords = simpleWords.map(word => assemble(word, morphs));

    console.timeEnd("assemble");

    console.time("splitByRules");

    simpleWords = simpleWords.map(word => splitByRules(word, simpleWords)).flatten();

    console.timeEnd("splitByRules");

    //console.time("flatten");

    //simpleWords = simpleWords.flatten();

    //console.timeEnd("flatten");

    console.time("lemma");
/*
    simpleWords.forEach((word, index) => {
        word.lemma = getLemma(morphs, word);
    });
*/

    for (let i = 0, len = simpleWords.length; i < len; i++) {
        simpleWords[i].lemma = getLemma(morphs, simpleWords[i]);
        /*if (simpleWords[i].lemma.length < 1) {
            console.log("houston we have a problem");
        }*/
    }

    console.timeEnd("lemma");

    words = words.concat(simpleWords);

    //console.time("compounds");

    let compoundWords = [], compoundWord;

    data.filter(record => record.hasOwnProperty("compound")).forEach(word => {

        compoundWord = { morphs: [] };

        word.compound.forEach((ref, index) => {
            //let origin;
            //console.log(ref);
            const parts = ref.split(":");
            const nick = parts[0];
            //console.log("nick", nick);
            if (parts.length > 1) {

                // if there are params then it could be morph or word

                if (parts.slice(1).some(part => morphNames.includes(part))) {
                    //console.log("it is a morph, sir!");
                    let morph = getMorph(morphs, parts.slice(1).find(part => morphNames.includes(part)), nick);
                    if (parts.slice(1).length > 1) {
                        // param exists
                        if (parts.slice(1).length > 2) console.log("WARNING: more params than needed:", parts);

                        let param = parts.slice(1).find(part => !morphNames.includes(part));
                        if (/^\d+$/.test(param)) {
                            morph.alt = parseInt(param);
                        } else {
                            console.log(`WARNING: param for ${nick} is not a number: ${param}`);
                            morph.alt = param;
                        }
                    }
                    //console.log(morph);
                    compoundWord.morphs.push(morph);
                } else {

                    //console.log("it is a word, ma'am!");
                    let simpleWord = simpleWords.find(w => w.nick === nick);
                    if (simpleWord) {
                        let param = {};
                        if (parts.slice(1).length > 1) {
                            // param exists
                            parts.slice(1).forEach(part => {
                                if (part.split("=").length < 2) {
                                    console.log(`ERROR: wrong syntax in ${part}, should be a=b`);
                                } else {
                                    let key = part.split("=")[0], val = part.split("=")[1];
                                    if (/^\d+$/.test(val)) {
                                        val = parseInt(val)
                                    } else { console.log(`WARNING: value ${val} should be number`) }
                                    param[key] = val;
                                }
                            });
                        }
                        //console.log("param:", param);
                        if (index < word.compound.length-1) {
                            // this is not the last compound
                            let compoundForm = transform(morphs, simpleWord, param).filter(form => form[getKey(form, morphNames)] !== "");
                            //console.log(compoundForm);
                            if (!compoundWord.hasOwnProperty("compoundForms")) compoundWord.compoundForms = [];
                            compoundWord.compoundForms.push(compoundForm);
                        } else {
                            Object.keys(simpleWord).forEach(key => {
                                if (!["id", window.secLang, "nick", "lemma", "morphs"].includes(key)) {
                                    //console.log("copy key from simpleWord:", key);
                                    compoundWord[key] = simpleWord[key];
                                }
                            });
                            simpleWord.morphs.forEach(morph => {
                                let morphCopy ={};
                                Object.keys(morph).forEach(key => { morphCopy[key] = morph[key] });
                                compoundWord.morphs.push(morphCopy);
                            });
                        }

                    } else {
                        console.log(`ERROR: no word with nick ${nick}`);
                    }
                }

                //console.log(origin);
            } else {

                // if there is no param then it is a simple word
                let simpleWord = simpleWords.find(w => w.nick === nick);
                if (simpleWord) {
                    if (index < word.compound.length-1) {
                        // this is not the last compound
                        //console.log(simpleWord);
                        //console.log(transform(morphs, simpleWord));
                        let compoundForm = transform(morphs, simpleWord).filter(form => form[getKey(form, morphNames)] !== "");
                        if (!compoundWord.hasOwnProperty("compoundForms")) compoundWord.compoundForms = [];
                        compoundWord.compoundForms.push(compoundForm);
                    } else {
                        //console.log("last word");
                        Object.keys(simpleWord).forEach(key => {
                            if (!["id", window.secLang, "nick", "lemma", "morphs"].includes(key)) {
                                //console.log("copy key from simpleWord:", key);
                                compoundWord[key] = simpleWord[key];
                            }
                        });
                        simpleWord.morphs.forEach(morph => {
                            let morphCopy ={};
                            Object.keys(morph).forEach(key => { morphCopy[key] = morph[key] });
                            compoundWord.morphs.push(morphCopy);
                        });
                        //console.log(compoundWord);
                    }
                } else {
                    console.log(`ERROR: no word with nick ${nick}`);
                }
            }
        });

        Object.keys(word).forEach(key => {
            if (key !== "compound") {
                //console.log("copy key from word:", key);
                compoundWord[key] = word[key];
            }
        });

        //console.log(compoundWord);

        if (!compoundWord.hasOwnProperty("rule")) {
            // console.log("has no rule:", compoundWord);
            compoundWord.rule = getRuleByMorph(compoundWord.morphs);
            //console.log("rule set by morph:", compoundWord.rule);
        }

        //compoundWord.rule = word.rule || 0;

        compoundWords.push(compoundWord);

    });

    compoundWords = compoundWords.map(word => splitByRules(word, simpleWords)).flatten();

    compoundWords.forEach((word, index) => {
        //console.log("--"+ index +"--");
        word.lemma = getLemma(morphs, word);
        //console.log(word.lemma);
    });

    //console.log(compoundWords);

    //console.log(transform(morphs, compoundWords[4], { cas: 1, count: 1 }));

    words = words.concat(compoundWords);

    //console.timeEnd("compounds");

    /*console.time("chains");

    const chains = data
        .filter(record => !record.hasOwnProperty("root") && hasDeepProperty(record, "lv.default"));

    console.timeEnd("chains");

    console.log("chains:", chains.length);

    console.log(chains[435]);

    words = words.concat(chains);*/

    return words;
}