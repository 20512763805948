import React from "react";
import Content from "../components/Content";

import "./Copyright.css";

// what is this&

const Copyright = ({ data }) => {

    return (
        <Content className="copyright">
            <h1>Autortiesības</h1>
            <ol>
                <li><i>Mācīšanās platforma runajiet.lv</i> ir digitāls mācību līdzeklis latviešu valodas kā otrās valodas un latviešu valodas kā&nbsp;sveš&shy;valodas apguvei.</li>
                <li><i>Mācīšanās platforma runajiet.lv</i> satura (dizains, dizaina elementi, ilustrācijas, teksts (turpmāk tekstā&nbsp;&mdash; materiāli) autori ir Romāns Voroņežskis, Valentīna Larsone (turpmāk tekstā&nbsp;&mdash; <i>mācīšanās plat&shy;formas runajiet.lv</i> autortiesību īpašnieki) vai atsaucē no&shy;rādī&shy;tais autors.</li> 
                <li><i>Mācīšanās platformu runajiet.lv</i> uztur autortiesību īpašnieki, kam pieder domēnu runajiet.lv, govorite.lv lietošanas tiesības un tajā izvietotais saturs.</li>
                <li><i>Mācīšanās platformu runajiet.lv</i> saturs ir izstrādes procesā un&nbsp;ir&nbsp;pie&shy;ejams bez&nbsp;maksas.</li>
                <li>Materiāli nedrīkst tikt kopēti, pavairoti, pārstrādāti, izplatīti, pub&shy;li&shy;cēti vai izmantoti citā veidā bez piekrišanas no&nbsp;<i>mācī&shy;šanās platformas runajiet.lv</i> autortiesību īpašniekiem.</li>
            </ol>

        </Content>
    );
};

export default Copyright;
