import { useEffect, useReducer } from "react";
import { useFetch, useFetches } from "../../../io";

import lessonNav from "../lesson/nav";
import theoryNav from "../theory/nav";
import { aovmap, partition, deepCopy } from "../../../prototypes";
import { inject, menuer } from "./logic";

const localnav = [
    { filename: "/json/v9/grammar/nouns.json", mapper: false },
    // { filename: "/json/v9/grammar/verbs.json", mapper: false },
    { filename: "/json/v9/lessons/lessons1h.json", mapper: x => aovmap(x, y => ({...y, type: "lesson"})) },
    { filename: "/json/v9/lessons/lessons2h.json", mapper: x => aovmap(x, y => ({...y, type: "lesson"})) }
];

const desktopNav = ({ global, local }) => {

    let res = deepCopy(global);

    const [lessons, rest] = partition(local, x => x.type==="lesson");

    theoryNav(rest).forEach(item => {

        res = inject(
            res, "menu", item.menu, "menu",
            obj => {
                // console.log("parent pages:", obj.pages);
                // console.log("item page:", item.page);
                return obj.pages ? obj.pages.includes(item.page) : false
            }
        );

    });
    
    res = inject(
        res, "menu", lessonNav(lessons), "menu",
        obj => obj.pages ? obj.pages.includes("lesson") : false
    );

    return res;

}

const useNav = ({ isMobile }) => {

    const json = isMobile ? `/json/v9/${window.secLang}/nav-mobile.json` : "/json/v9/nav.json";

    const [global, status, error] = useFetch(json, "application/json", { cache: "no-store" });

    const [local, localStatus, localError] = useFetches({
        fileNames: localnav.map(n=>n.filename),
        mappers: localnav.map(n=>n.mapper),
        cacheParam: { cache: "no-store" }
    });

    const initialState = {
		status: 'idle',
		error: null,
		data: [],
	};

	const [state, dispatch] = useReducer((state, action) => {
		switch (action.type) {
			case 'FETCHING':
				return { ...initialState, status: 'fetching' };
			case 'FETCHED':
				return { ...initialState, status: 'fetched', data: action.payload };
			case 'FETCH_ERROR':
				return { ...initialState, status: 'error', error: action.payload };
			default:
				return state;
		}
    }, initialState);

    useEffect(()=>{

        let cancelRequest = false;

        if (status === "fetched") {

            // console.log(localStatus);

            if (localStatus === "fetched") {

                let res;

                if (isMobile) {

                    res = deepCopy(global);
                    res = menuer(res, "menu", "menu", true, ["href"], [""], "/");
                    // console.log(res);
                    // res = menuer(res, "menu", "menu", ["title", "default", "sample", "visible"], ["href"], [""], "/");


                } else {
                    res = desktopNav({ global, local });
                    res = menuer(res, "menu", "menu", ["title", "default", "abc"], ["href"], ["/"], "/", "abc", "abcs");

                }
                // console.log(res);
                // console.log(res);

                if (cancelRequest) return;
				dispatch({ type: 'FETCHED', payload: res });

            }
        }

        return ()=> { cancelRequest = true; }

    }, [global, status, localStatus]);

    return [state.data, state.status, state.error];


};

export default useNav;
