import React, { useEffect, useContext, useMemo, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { Arrow } from "../../i/icons";
import { useFetch } from "../../io";
import { entities2unicode } from "../../lang/parse";
import { deepCopy, objectsEqual } from "../../prototypes";
import DataContext from "../common/DataContext";
import { findChild, menuer } from "../common/nav/logic";

const Item = ({ item, parent }) => {

    // console.log("Item parent:", parent);

    // console.log(item);

    if (item.visible === false) return null;

    return (
        item.hasOwnProperty("href") ?
        <div className="item">
            <Link to={{ pathname: item.href, state: parent }}>
                <div className="title">
                    { entities2unicode(item.title) }
                </div>
                { item.sample && <div className="sample">
                    { entities2unicode(item.sample) }
                </div> }
            </Link>
        </div> :
        <Nav {...{item}} />
    )
};

const Parent = ({ parent, referrer: global }) => {

    const location = useLocation();

    // console.log("Parent parent:", parent);
    // console.log("Parent global:", global);

    // console.log("Parent location state:", location.state);
    // console.log("Parent object:", parent);

    const referrer = global && location.state && location.state.href !== parent.href;


    return (
        <>
        { referrer && <div className="parent">
            <Link to={location.state.href}>
                <Arrow left />
                Назад: {entities2unicode(location.state.title).toLowerCase()}
            </Link>
        </div> }
        <div className={`parent${referrer?" light":""}`}>
            <Link to={parent.href}>
                { !referrer && <Arrow left />}
                {entities2unicode(parent.title)}
            </Link>
        </div>
        </>
    )
}

const Nav = ({ className, item, parent, children, section, pathname }) => {

    // console.log("Nav parent:", parent);

    // const { href, title } = item;
    const classList = ["nav"];
    if (className) classList.push(className);

    // console.log("Nav className:", className);
    // console.log("Nav section:", section);
    // console.log("pathname?", pathname);

    const title = item && (
        section ? section.href === pathname ?
            null :
            <Link to={item.href}>{entities2unicode(item.title)}</Link> :
            entities2unicode(item.title)
    );

    // console.log(`Nav ${className||""} item:`, item);
    // console.log(`Nav ${className||""} title:`, !!title);

    const referrer = section === undefined && className === "local" ? false : !section;

    return (
        <div className={classList.join(" ")}>
                <div className="head">
                    { parent && <Parent parent={parent} referrer={referrer} /> }
                    { title && <div className="page title">{title}</div> } 
                </div>
                { children }
                { 
                    item && item.menu &&

                        <div className="items">
                            {item.menu.map((menuitem, i) => <Item key={i} item={menuitem} parent={{ href: item.href, title: item.title }} />)}
                        </div>

                }

        </div>
    )

};

const Local = ({ children, section }) => {

    const [sections, status, error] = useFetch(`/json/v9/${window.secLang}/sections.json`, "application/json", { cache: "no-store" });

    let { pathname } = useLocation();

    const context = useContext(DataContext);

    if (error) console.log(error);
    if (status !== "fetched") return null;

    let menu = sections[section.section];

    // if (!section) return <div>section not found</div>

    menu = menuer(menu, "menu", "menu", true, ["href"], [section.href], "/");

    // console.log("local menu:", menu);

    const nav = {...section, menu };

    // console.log("Local nav:", nav);

    // const rx = new RegExp(`^${section.href}`)

    // pathname = pathname.replace(rx, "");

    // console.log("local pathname:", pathname);

    const item = findChild(nav, obj => obj.href === pathname, "menu");

    // console.log("Local item:", item);

    if (item) document.title = item.title;

    const parent =
    (()=>{


        let p = false;
        if (item){

            p = findChild(nav, obj => obj.hasOwnProperty("menu") ? obj.menu.some(i=>i.href===item.href) : false, "menu");
            if (p) {
                if (!p.hasOwnProperty("href")) {
                    p = findChild(nav, obj => obj.hasOwnProperty("menu") ? obj.menu.some(i=>(objectsEqual(i,p))) : false, "menu");
                }
            }
        }
        // console.log("p:", p);



        return p && p.href === nav.href ? false : p;
    })();

    // console.log("Local parent:", parent);

    return (
        /* <div>{children}</div> */

        <DataContext.Provider value={{...context, nav }}>
        
        <Nav className="local" {...{ item, pathname, parent }}>
            {children}
        </Nav>

        </DataContext.Provider>
    )
}

const Index =({ children, className }) => {

    const location = useLocation();

    let { pathname } = location;
    if (pathname[pathname.length-1]!=="/") pathname += "/";

    const { nav } = useContext(DataContext);

    // console.log(nav);

    const item = useMemo(()=>(findChild(nav, obj => obj.href === pathname, "menu")), [nav, pathname]);

    useEffect(()=>{
        if (item) {
            document.title = entities2unicode(item.title);
        }
    }, [item]);

    // console.log(pathname);
    // console.log(item);

    const initState = item ? !item.default : false;

    const [open, setOpen] = useState(initState);

    // if (!item) return null;

    let parent, firstParent, section = item && (item.hasOwnProperty("section") ? item : false);

    if (item) {
        parent = findChild(nav, obj => obj.hasOwnProperty("menu") ? obj.menu.some(i=>i.href===item.href) : false, "menu");
    } else {
        // console.log(nav);

        let folders = pathname.split("/").filter(x=>x!=="");

        for (let i=0, l=folders.length; i<l; i++) {
            folders.pop();
            const href = ("/" + folders.join("/") + "/").replace("//", "/");
            parent = findChild(nav, obj => obj.href === href, "menu");
            if (parent) {
                if (i===0) {
                    firstParent = deepCopy(parent);
                }
                if (parent.hasOwnProperty("section")) {
                    section = deepCopy(parent);
                    folders.pop();
                    const href = ("/" + folders.join("/") + "/").replace("//", "/");
                    parent = findChild(nav, obj => obj.href === href, "menu");
                    // console.log("parent of section:", parent);
                    break;
                }
            }
        }

        if (!section) parent = firstParent;

    }

    // console.log("Index item:", item);
    // console.log("Index parent:", parent);
    // console.log("Nav section:", section);

    // console.log(children);
    const wrap = section ? <Local section={section}>{children}</Local> : children;

    return (
        <Nav {...{ item: (section||item), parent, open, setOpen, className, section, pathname }}>{ wrap }</Nav>
    );

};

export default Index;
