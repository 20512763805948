import React, { useContext, useEffect, useState, useMemo, useReducer } from "react";
import { Switch, Route, useRouteMatch, useParams } from 'react-router-dom';
import { fetchData } from "../../io";
import Spinner from "../../components/Spinner";
import DataContext from "../../components/common/DataContext";
import Content from "../../components/Content";
import { Footer, CopyUrl } from "../../components/Footer";
import TheoryComp from "../../components/common/theory/Theory";

/* const theory = [
    {
        section: "lietvārds", fileName: "/json/v9/grammar/nouns"
    }
]; */

const src = ["/json/v9/grammar/nouns"];


const Theory = ({ data, box, setNav }) => {

    const { path } = useRouteMatch();
    // console.log(path, url);
    const [ theory, setData ] = useState();

    useEffect(()=>{
        // console.log(`fetch data`);
        // const src = theory.find(t => t.section === partName).fileName;
        // console.log(src);
        fetchData({
            fileNames: src,
            setData
        });
    }, []);

    if (!theory) { return <Spinner /> }
    if (theory.error) { return <div>{theory.error.message}</div> }
    if (theory.length > 1) { console.log(`WARNING: json contains more than one record`) }

    return (
        <DataContext.Provider value={{ data, box, setNav }}>
        <Content className="theory">
        <Switch>
            <Route exact path={path}>
                <h2>index</h2>
            </Route>
            <Route path={`${path}/:partName`}>
                <Section {...{ theory }} />
            </Route>
        </Switch>
        <Footer><CopyUrl/></Footer>
        </Content>
        </DataContext.Provider>
    )

}

const Section = ({ theory }) => {

    // const { path, url } = useRouteMatch();
    const { partName } = useParams();

    // console.log(partName);
    // console.log(url);
    // console.log(theory);
    
    const section = theory.find(sec => sec.href === partName);

    // console.log(section);

    /* useEffect(()=>{
        console.log(`fetch data for part ${partName}`);
        const src = theory.find(t => t.section === partName).fileName;
        console.log(src);
        fetchData({
            fileNames: src,
            setData
        });
    }, [partName]); */

  
    const classList = ["lesson"];
    if (section.className) classList.push(section.className);

    return (
        <div className={classList.join(" ")}>
            <TheoryComp {...{ section }} />
        </div>
    )

}


export default Theory;
