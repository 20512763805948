import React, { useContext, useEffect, useMemo, useReducer, useRef, useState } from "react";
// import DataContext from "../DataContext";
// import { alternation } from "../../../lang/alternation";
import { useFetch } from "../../../io";
import { aiove, cap, deepCopy, getRandomInt, objectsEqual, partition, shuffleArray, _ } from "../../../prototypes";
import hash from "object-hash";
import { labels } from "../../../labels";
import { Ghost } from "../ui";
import { entities2unicode } from "../../../lang/parse";
import { foreign, foreignTransform, getForeignWord, quantity2cas, quantity2count } from "../../../lang/foreign";
import DataContext from "../DataContext";

// console.log(window.secLang);

/* const words = {
    round: getForeignWord(window.secLang, "ui", "round")
}; */

const check = (car, answer) => {

    // console.log(car, answer);

    return car.answer === answer;
}

const update = (trains, car, carUpdates, trainUpdates) => {

    const uTrains = deepCopy(trains);

    const trainIndex = uTrains.findIndex(train=>train.name===car.trainName);

    if (trainIndex < 0) {
        console.log("error in state: no such train", car.trainName);
        return trains;
    }

    if (trainUpdates) {
        uTrains[trainIndex] = {...uTrains[trainIndex], ...trainUpdates}
    }

    const carIndex = uTrains[trainIndex].cars.findIndex(uCar=>uCar.hash===car.hash);
    const res = {
        ...car, ...carUpdates
    };
    if (carIndex < 0) {
        uTrains[trainIndex].cars.push(res)
    } else {
        uTrains[trainIndex].cars[carIndex] = res
    }

    return uTrains;

};

const reducer = (state, action) => {

    const updated = deepCopy(state);
    
    switch (action.type) {
        case "load cars": {
            action.payload.cars.forEach(car => {
                if (!updated.cars.find(c => c.hash===car.hash)) {
                    updated.cars.push(car);
                }
            });
            return updated;
        }
        case "give": {
            // console.log("give");
            const correct = check(action.payload.car, action.payload.answer);
            const res = {...updated,
                answer: { value: action.payload.answer, correct },
                trains: update(
                    updated.trains, action.payload.car,
                    { answered: true, correct }
                )
            }
            return res;
        }
        case "unanswer": {
            // console.log("unanswer");
            action.payload.cars.forEach(car => {
                updated.trains = update(updated.trains, car, { answered: false });
            });
            return updated;
        }
        case "reset": {
            delete updated.answer;
            action.payload.cars.forEach(car => {
                updated.trains = update(updated.trains, car, { answered: null, correct: null });
            });
            return updated;
        }
    }
};

const Answers = ({ answers, correct, incorrect, id }) => {

    const classList = ["count"];

    let count = answers && answers.length;
    let label = "";
    if (correct) {
        classList.push("correct");
        label = labels.ui.correct[window.secLang];
        // count = answers && answers.filter(a=>a.correct===true).length;
        // console.log("correct", count);
    }
    if (incorrect) {
        classList.push("incorrect");
        label = labels.ui.incorrect[window.secLang];
        // count = answers && answers.filter(a=>a.correct===false).length;
        // console.log("incorrect", count);
    }
    if (!count) classList.push("empty");

    return (
        <div className={classList.join(" ")}>
            <div>{label}</div>
            <div ref={id} className="number">
                { count > 0 ? count : `\u00A0` }
            </div>
        </div>
    )
};

const Decoys = ({ car, dispatch }) => {

    const mixed = useMemo(()=>shuffleArray([...car.decoys, car.answer]), [car]);

    return (
        <div className="decoys">
            { mixed.map((decoy, i)=> (
                <button key={i} onClick={ ()=> { dispatch(decoy) } }>
                    { decoy }
                </button>
            )) }
        </div>
    )

};

const Sentence = React.memo(({ car, id, answer, ghost, round }) => {

    // console.log("round received:", round.current);
    // console.log("SENTENCE render");
    // console.log("ghost received:", ghost);
    // console.log("car received:", car);
    // console.log("round received:", round);

   /*  const [local, setLocal] = useState();

    useEffect(()=>{
        setLocal(prev => !objectsEqual(prev, ghost) ? ghost : prev);
    }); */

    const classList = ["answer"];

    if (answer) {
        if (answer.correct===true) classList.push("correct");
        if (answer.correct===false) classList.push("incorrect");
    }

    return (
        <div className="sentence">
            { car.sentence.map((part, i) => {
                const parts = entities2unicode(part).split("|");
                const original = parts[0] === "[]" ?
                <Ghost className={classList.join(" ")} to={ghost}>
                    <div ref={id}>{answer ? answer.value : `\u00A0`}</div>
                </Ghost> :
                <div>{parts[0]}</div>;
                return(
                    <div className="part" key={i}>
                        {original}
                        <div className="trans">{parts[1]}</div>
                    </div>
                )
            }) }
        </div>
    )
}, (prev, next) => {
    const answers = objectsEqual(prev.answer, next.answer);
    const rounds = prev.round === next.round;
    const cars = objectsEqual(prev.car, next.car);

    // console.log("equal answers?", answers);
    // console.log("equal rounds?", rounds);
    // console.log("equal cars?", cars);

    /* if (answers && rounds && cars) {
        console.log("Do not rerender")
    } else {
        console.log("Rerender")
    }
    console.log("-------------------------------"); */

    return answers && rounds && cars;
});

const timereducer = (state, action) => {
    switch (action.type) {
        case "delete": {
            clearTimeout(state[action.payload]);
            const { [action.payload]: _, ...res } = state;
            return res;
        }
        case "set": {
            const [key, f, ms] = action.payload;
            return {...state, [key]: setTimeout(f, ms) }
        }
        case "reset": {
            Object.keys(state).forEach(key => {
                clearTimeout(state[key]);
            });
            return {};
        }
    } 
};

const delayedSet = ({ state, setState, initValue, key, value, timeouts, dispatch, ms }) => {

    if (state === initValue) {
        setState(value);
    } else {
        if (timeouts[key]) {
            dispatch({ type: "delete", payload: key });
        }
        // console.log(`set timeout to value ${next?next.sentence.join(" "):next}`);
        if (!objectsEqual(state, value)) {
            dispatch({ type: "set", payload: [key, ()=>{
                setState(value);
                dispatch({ type: "delete", payload: key });
            }, ms] });
        }
    }
}

const Train = ({ trainName, state, dispatch }) => {

    const [timeouts, timedispatch] = useReducer(timereducer, {});

    const { uiLabels } = useContext(DataContext);

    useEffect(()=>{
        return ()=> {
            console.log("clean train");
            timedispatch({ type: "reset" });
        }
    }, []);

    const round = useRef(1);

    const train = useMemo(()=> {
        // console.log(state);
        const res = state.trains
            .filter(train => aiove(trainName, train.name))
            .reduce((acc, val) => (
                { name: acc.name + ", " + val.name, cars: [...acc.cars, ...val.cars] }
            ), { name: "", cars: [] });
        res.cars = shuffleArray(res.cars);
        // console.log(res);
        return res;
    }, [state, trainName]);

    /* const unanswered = useMemo(()=> {
        return train && train.cars.find(car=>!car.answered);
    }, [state, train]); */

    const [unanswered, setUnanswered] = useState(null);
    const [answered, setAnswered] = useState(null);
    const [answer, setAnswer] = useState(null);
    const [correct, setCorrect] = useState(null);
    const [incorrect, setIncorrect] = useState(null);


    const idAnswer = useRef();
    const idCorrect = useRef();
    const idIncorrect = useRef();

    const ghost = useMemo(()=>{
        if (answer && idCorrect.current && idIncorrect.current) {
            if (answer.correct===true) return idCorrect.current;
            if (answer.correct===false) return idIncorrect.current;
        }
        return null;
    }, [answer, idCorrect, idIncorrect])

    /* useEffect(()=>{
        console.log("timeouts:", timeouts);
    }, [timeouts]) */

    useEffect(()=>{

        if (train) {

            // console.log("set local states");

            if (state.answer) {
                setAnswer(state.answer);
            }

            delayedSet({
                state: correct, setState: setCorrect, initValue: null,
                key: "correct", value: train.cars.filter(car=>car.correct===true),
                timeouts, dispatch: timedispatch, ms: 330
            });

            delayedSet({
                state: incorrect, setState: setIncorrect, initValue: null,
                key: "incorrect", value: train.cars.filter(car=>car.correct===false),
                timeouts, dispatch: timedispatch, ms: 330
            });

            delayedSet({
                state: unanswered, setState: setUnanswered, initValue: null,
                key: "unanswered", value: train.cars.find(car=>!car.answered),
                timeouts, dispatch: timedispatch, ms: 600
            });

            delayedSet({
                state: answered, setState: setAnswered, initValue: null,
                key: "answered", value: train.cars.filter(car=>car.answered),
                timeouts, dispatch: timedispatch, ms: 500
            });

            // console.log(state.answer);

            
        }

    }, [state, train]);


    useEffect(()=>{
        // console.log("answered:", answered);
        if (answered) {
            if (answered.length === train.cars.length) {
                // console.log("all answered");
                const incorrect = answered.filter(a=>a.correct===false);
                if (incorrect.length > 0) {
                    // console.log("should start next round");
                    round.current++;
                    // if (round.current < 30) {
                    setAnswered(null);
                    dispatch({ type: "unanswer", payload: { cars: incorrect } });
                    // }
                } else {
                    // console.log("finished");
                }
            }
            if (answered.length === 0) round.current = 1;
        }
    }, [answered, train]);

    useEffect(()=>{
        if (answer && round.current) {
            // console.log("unanswered or round changed, set answer to null");
            setAnswer(null);
        }
        // console.log("unanswered:", unanswered);
    }, [unanswered, round.current])

    // console.log(unanswered);

    return (

        <div className="train">

        <div className="answers">
            <Answers incorrect answers={incorrect} id={idIncorrect} />
            <Answers correct answers={correct} id={idCorrect} />
        </div>

        {
        unanswered ?
        <>
            <h3>{`${cap(foreignTransform(uiLabels.round.word, uiLabels.round, { cas: 0, count: 0 }))} ${round.current}`}</h3>

            <Sentence id={idAnswer} round={round.current} car={unanswered} answer={answer} ghost={ghost} />

            <Decoys car={unanswered} dispatch={
                decoy => {
                    dispatch({ type: "give", payload: { car: unanswered, answer: decoy } });
                }
            } />

        </> :
        state.answer &&
        <div className="result">
            <h3>{round.current===1?"Отлично. Справились за":"Справились за"} {`${round.current} ${foreignTransform(uiLabels.round.word, uiLabels.round, { cas: quantity2cas(round.current), count: quantity2count(round.current) })}`}</h3>
            <button onClick={()=>{ dispatch({ type: "reset", payload: { cars: train.cars } }) }}>Повторить</button>
        </div>

        }
        </div>

    )
    
}

const Trains = ({ trains, trainName }) => {

    const initValue = trains.map(train =>
        ({...train,
            cars: train.cars.map(car =>
                ({...car, trainName: train.name, hash: hash({...car, trainName: train.name})})
            )
        }));

    const [state, dispatch] = useReducer(reducer, { trains: initValue });

    /* const train = useMemo(()=>(
        initValue.find(train => train.name === trainName)
    ), [trainName, initValue]); */

    return (
        <Train {...{ trainName, state, dispatch }} />
    )

};


const Index = ({ train: trainName }) => {

    // const { data } = useContext(DataContext);

    const uiLabels = {
        "round": getForeignWord(window.secLang, "ui", "round")
    }

    // console.log(uiLabels);

    const context = useContext(DataContext);

    const [trains, status, error] = useFetch(`/json/v9/${window.secLang}/trains/trains.json`, "application/json", { cache: "no-store" });

    if (error) console.log(error);

    if (status !== "fetched") return null;

    // const trainName = Array.isArray(train) ? train.join(", ") : train;

    return (
        <DataContext.Provider value={{...context, uiLabels }}>
            <Trains {...{ trainName, trains }} />
        </DataContext.Provider>
    )
}

export default Index;
