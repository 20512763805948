import React, { useEffect, useState, useRef } from "react";
import { getType, generateId } from "../../prototypes";
import { icons } from "../../i/icons";
import { parsed2html } from "../../lang/parse";

function C(props) {

    return (
        <input
            defaultChecked={props.checked}
            name={props.name}
            type="radio"/>
    )
}

function Question(props) {

    const { question } = props;
    //console.log("Question: question:", question);

    let res = [];

    question.forEach((part, i) => {

        if (getType(part) === "object") {
            res.push(part.usedForm);
        } else {
            if (/icon:[a-z]+/.test(part)) {

                const subparts = part.split(/(icon:[a-z]+)/).filter(s=>s!=="");

                //console.log("Question: subparts:", subparts);

                subparts.forEach((subpart, i) => {
                    if (/^icon:[a-z]+$/.test(subpart)) {
                        const name = /^icon:([a-z]+)$/.exec(subpart)[1];
                        //console.log("Question: icon name:", name);
                        if (icons.hasOwnProperty(name)) {
                            const Icon = icons[name];
                            res.push(<Icon key={i} width={11} height={22} />);
                        } else { res.push(`[${name}]`) }
                    } else {
                        res.push(subpart);
                    }
                });

            } else {
                res.push(part);
            }
        }

    });

    return (
        <>{res}</>
    )
}

function QRadio(props) {

    //console.log("QRadio props", props.lessonId);

    //console.log("QRadio: props.id:", props.id);

    let savedAnswer = props.savedAnswers[props.id] ?
        props.savedAnswers[props.id].type === "qradio" ?
            props.savedAnswers[props.id].value : "" : "";

    //console.log(savedAnswer);

    let state = [false, false, false];
    if (getType(savedAnswer) === "number") state[savedAnswer] = true;

    const ref = useRef(props.id);

    const [checked, setChecked] = useState(state);
    const [changed, setChanged] = useState();

    //console.log(props);

    function updateRefs() {
        props.setRefs(prev => {
            //console.log("QRadio useEffect(): prev refs length:", prev.length);
            let updated = [...prev];
            const index = updated.findIndex(ref => ref.id === props.id);
            if (index > -1) {
                updated[index] = { id: props.id, ref };
            } else {
                updated.push({ id: props.id, ref });
            }
            return updated;
            //{ id: props.id, ref }
        });
    }

    useEffect(updateRefs);

    useEffect(()=>{
        //console.log("checked changed", checked);
        //console.log(ref.current);
        const label = ref.current.querySelectorAll("label")[checked.findIndex(c => c===true)];
        //console.log(label);
        if (label) {
            const theFuckingSpan = label.querySelector("span");
            //console.log(theFuckingSpan);
            if (!theFuckingSpan.classList.contains("checked")) {
                theFuckingSpan.classList.add("checked");
            }
        }
        props.setAnswers(prev => {
            let updatedAnswers = prev ? {...prev} : {};
            updatedAnswers[props.id] = {
                type: "qradio",
                value: checked.findIndex(c => c===true),
                exercise: props.custom.id
            };
            return updatedAnswers;
        });
        //state = [...checked];
    }, [checked]);

    useEffect(()=>{
        if (changed === true) {
            ref.current.classList.add("changed");
        }
        //return () => { setChanged(false); }
    });

    function handleClick(e, i) {
        //const element = e.currentTarget;
        e.preventDefault();

        const savedLesson = JSON
            .parse(localStorage.getItem("lessons"))
            .find(l => l.lesson === props.lessonId);

        if (["error", "correct"].some(status => ref.current.classList.contains(status))) {
            setChanged(true);
        }

        setChecked(prev => {
            const updated = savedLesson === undefined ? [false, false, false] : [...prev];
            updated[i] = !updated[i];
            if (updated[i]) {
                for (let j=0; j<updated.length; j++) {
                    if (updated[j] && j!==i) updated[j] = false;
                }
            }
            return updated;
        });
    }
    
    return (
        <div key={generateId()} className={`qRadio${props.split?" split":""}`} id={props.id} ref={ref}>{
            props.questions.map((question, i) => {
                console.log("i:", i);
                console.log("question:", question);
                console.log("checked[i]", checked[i]);
                return(
            <label onClick={(e) => { console.log("click"); handleClick(e, i)} } key={i}>
                <C checked={checked[i]} name={props.id} />
                <span className={checked[i]?"checked":null}><Question question={question} /></span>
            </label>
        )})}</div>
    );
}

export default QRadio;
