import React, { useContext, useEffect } from "react";
import QParse from "../q/QParse";
import { Node } from "../q/renderNode";
import { objectsEqual, deepCopy } from "../../../prototypes";
import { labels } from "../../../labels";
import { reducer } from "./logic";
import ParsedText from "../../ParsedText";
import DataContext from "../DataContext";


const Exercise = React.memo(({ exercise, state, dispatch, number, timeout=1500 }) => {

    // console.log("Exercise render", exercise);
    // console.log("state at this moment:", state);

    const initialLocalState = deepCopy(state).filter(s => s.id.indexOf(exercise.id)===0);
    // const initialLocalState = useMemo(()=>(deepCopy(state).filter(s => s.id.indexOf(exercise.id)===0)), [exercise.id]);

    let forkDispatch = dispatch, forkState = state, localTimeout = timeout;

    const { data, box } = useContext(DataContext);

    // const memReducer = useCallback(reducer, [exercise.id]);

    const [localState, localDispatch] = React.useReducer(reducer, initialLocalState);

    if (exercise.check === "after") {
        localTimeout = 0;
        forkDispatch = action => { localDispatch(action) };
        // console.log("assign forkState to filtered state:", state.filter(s => s.id.indexOf(exercise.id)===0));
        forkState = initialLocalState; //state.filter(s => s.id.indexOf(exercise.id)===0);
    }

    function flush() {
        // console.log(`%cFLUSH`, "color: darkcyan");
        dispatch({ type: "flush", payload: localState });

        /* if (localState.length > 0) {
            console.log("flush");
            dispatch({ type: "flush", payload: localState });
        } */
    }

    useEffect(()=>{
        localDispatch({ type: "replace", payload: initialLocalState });
        return ()=> { localDispatch({ type: "removeall"} ) }
    }, [exercise.id, state]);

    useEffect(()=>{
        // console.log("flushing", exercise.id);
        flush();
    }, [exercise.id]);

    /* useEffect(()=>{
        // console.log("current exercise id:", exercise.id);
        // console.log("initialLocalState:", initialLocalState);
        // console.log("useEffect localState", localState);
    }, [localState]) */

    const qprops = {
        state: forkState,
        dispatch: forkDispatch, timeout: localTimeout,
        exerciseId: exercise.id
    };

    if (exercise.hints) {
        switch (exercise.hints) {
            case "none": { qprops.nobox = true; qprops.nolink = true; break; }
            case "link": { qprops.nobox = true; }
        }
    }

    return (
        <div className="exercise" id={exercise.id}>
            {
                exercise.css && <link rel="stylesheet" type="text/css" href={`/assets/v9/css/${exercise.id}.css`} />
            }
            {
                number && <div className="number">{number}</div>
            }
            {
                exercise.intro && <div className="intro" key="intro">{
                    <ParsedText text={exercise.intro} {...{ data, box }} />
                }</div>
            }
            <Node {...{ node: exercise.node, props: { data, box }, qprops }}/>
            {
                exercise.check === "after" &&
                <button onClick={flush} className="check" 
                    title={labels.ui.buttonCheck[window.secLang]}
                    disabled={
                        (()=>{
                            const res = state.filter(a => a.id.indexOf(exercise.id)===0).some(a => a.giveup);
                            // console.log("disabled?", res);
                            return res;
                        })()
                    }>
                    {labels.ui.buttonCheck.lv}
                </button>
            }
        </div>
    )


}, (prev, next)=>{

    // return false;

    const prevState = prev.state.filter(state => state.id.indexOf(prev.exercise.id)===0);
    const nextState = next.state.filter(state => state.id.indexOf(next.exercise.id)===0);
    // console.log();
    // console.log("exercise prev and next state equal:", objectsEqual(prevState, nextState));

    // return false;
    return objectsEqual(prevState, nextState) && prev.exercise.id === next.exercise.id;
});


const oldExercise = React.memo(({ exercise, state, dispatch, exerciseNumber, part, partNumber }) => {

    // console.log(`Exercise render ${exercise.id}`);

    let forkDispatch = dispatch, forkState = state, timeout = 1500;

    const { data, box, lesson } = useContext(DataContext);

    /* const [localState, setLocalState] = React.useState(()=>{
        console.log("Exercise state:", state.filter(s => s.id.indexOf(exercise.id)===0));
        return deepCopy(state.filter(s => s.id.indexOf(exercise.id)===0));
    }); */

    // console.log(`Exercise ${exercise.id} receives state:`, state);

    // console.log(`filtered state:`, state.filter(s => s.id.indexOf(exercise.id)===0));

    const [localState, localDispatch] = React.useReducer(reducer, state.filter(s => s.id.indexOf(exercise.id)===0));

    if (exercise.check === "after") {
        timeout = 0;
        forkDispatch = action => {
            if (action.type === "correct") {
                dispatch(action);
            } else {
                localDispatch(action);
            }
        };
        // console.log("assign forkState to filtered state:", state.filter(s => s.id.indexOf(exercise.id)===0));
        forkState = state.filter(s => s.id.indexOf(exercise.id)===0);
    }

    function flush() {
        if (localState.length > 0) {
            console.log("flush");
            dispatch({ type: "flush", payload: localState });
        }
    }

    useEffect(()=>{
        if (exercise.check === "after") {
            console.log("exercise mount");
            if (localState.length > 0) {
                console.log("localState", localState);
                flush();
            }
        }
    }, [exercise, localState]);

    /* React.useEffect(()=>{
        if (exercise.check === "after") {
            console.log("Exercise with aftercheck state changed");
        }
    }, [state]); */

    /* React.useEffect(()=> {
        // console.log(`Exercise ${exercise.id} mounted`);
        console.log("localState changed:", localState);
        // forkState = localState;
        // flush();
    }, [localState]) */

    return (
        <div className="exercise" id={exercise.id}>
            {
                exercise.css && <link rel="stylesheet" type="text/css" href={`/assets/v9/css/${exercise.id}.css`} />
            }
            {
                exercise.intro && <div className="intro" key="intro">{
                    <ParsedText text={exercise.intro} {...{ data, box }} />
                }</div>
            }
            <div className="task" key={"t"+exerciseNumber}>
                {
                    lesson.parts.length > 1 ?
                        part.exercises.length > 1 ?
                        <span className="number">{
                            (partNumber+1) + "." +
                            (exerciseNumber+1)}</span> : "" :
                        part.exercises.length > 1 ?
                        <span className="number">{
                            (exerciseNumber+1)}</span> : ""
                }
                <ParsedText {...{ data, box }} text={exercise.task} />
            </div>
            <QParse className="default" {...{ text: exercise.default,
                qprops: { state: forkState, dispatch: forkDispatch, id: exercise.id, timeout } }} />
            {exercise.check === "after" &&
            <button onClick={flush} className="check" 
                title={labels.ui.buttonCheck[window.secLang]}
                disabled={
                    (()=>{
                        const res = state.filter(a => a.id.indexOf(exercise.id)===0).some(a => a.giveup);
                        // console.log("disabled?", res);
                        return res;
                    })()
                }>
                {labels.ui.buttonCheck.lv}
            </button>}
        </div>
    )
}, (prev, next)=>{

    // return false;

    const prevState = prev.state.filter(state => state.id.indexOf(prev.exercise.id)===0);
    const nextState = next.state.filter(state => state.id.indexOf(next.exercise.id)===0);
    // console.log();
    // console.log("exercise prev and next state equal:", objectsEqual(prevState, nextState));

    // return false;
    return objectsEqual(prevState, nextState);
})

export default Exercise;
