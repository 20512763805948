import React from "react";
import { Link } from 'react-router-dom';
import { entities2unicode } from "../../lang/parse";

function QLink({ href, text }) {

    return (
        <Link to={href}>{entities2unicode(text)}</Link>
    )
}

export default QLink;
