import React from 'react';
import "./Spinner.css";

const Spinner0 = () => {
    return (
        <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 80 80"
    >
      <circle cx="40" cy="40" r="40" fill="#0F0F0F" fillOpacity="0.25"></circle>
      <clipPath id="jug">
        <path d="M16.008 40.492c-2.109-1.016-4.104-2.868-5.728-5.334-3.372-5.116-5.187-12.705-3.824-18.038C8.927 7.45 17.066 5 23.85 5c.188 0 .374.005.558.014h36.105l-4.575 20.699C64.217 30.52 69.79 39.504 69.79 49.785c0 11.163-6.572 20.798-16.042 25.215H30.462c-9.47-4.417-16.042-14.052-16.042-25.215 0-3.258.559-6.385 1.588-9.293zm9.075-29.205l3.189 14.426a27.904 27.904 0 00-9.626 9.316 4.861 4.861 0 01-.756-.457c-.919-.661-1.757-1.636-2.509-2.776-2.469-3.746-4.004-9.258-3.006-13.164 1.6-6.258 7.085-7.522 11.475-7.522.443 0 .853.062 1.233.177z"></path>
        <animateTransform
          id="aniRotate"
          attributeName="transform"
          attributeType="XML"
          begin="aniFirstScale.end"
          dur="1.5s"
          from="0 40 40"
          to="160 40 40"
          type="rotate"
        ></animateTransform>
      </clipPath>
      <g>
        <g
          style={{
            WebkitTransformOrigin: "center",
            MsTransformOrigin: "center",
            transformOrigin: "center",
          }}
        >
          <g
            style={{
              WebkitTransformOrigin: "center",
              MsTransformOrigin: "center",
              transformOrigin: "center",
            }}
          >
            <path
              fill="#fff"
              fillOpacity="0.4"
              d="M16.008 40.492c-2.109-1.016-4.104-2.868-5.728-5.334-3.372-5.116-5.187-12.705-3.824-18.038C8.927 7.45 17.066 5 23.85 5c.188 0 .374.005.558.014h36.105l-4.575 20.699C64.217 30.52 69.79 39.504 69.79 49.785c0 11.163-6.572 20.798-16.042 25.215H30.462c-9.47-4.417-16.042-14.052-16.042-25.215 0-3.258.559-6.385 1.588-9.293zm9.075-29.205l3.189 14.426a27.904 27.904 0 00-9.626 9.316 4.861 4.861 0 01-.756-.457c-.919-.661-1.757-1.636-2.509-2.776-2.469-3.746-4.004-9.258-3.006-13.164 1.6-6.258 7.085-7.522 11.475-7.522.443 0 .853.062 1.233.177z"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                begin="aniFirstScale.end"
                dur="1.5s"
                from="0 40 40"
                to="160 40 40"
                type="rotate"
              ></animateTransform>
            </path>
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              begin="aniFirstScale.end"
              dur="1.5s"
              from="0.7"
              to="0.03"
              type="scale"
            ></animateTransform>
          </g>
          <g
            clip-path="url(#jug)"
            style={{
              WebkitTransformOrigin: "center",
              MsTransformOrigin: "center",
              transformOrigin: "center",
            }}
          >
            <path fill="#fff" d="M0 0H80V80H0z">
              <animate
                attributeName="y"
                begin="aniFirstScale.end"
                dur="1.5s"
                values="0;90"
              ></animate>
            </path>
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              begin="aniFirstScale.end"
              dur="1.5s"
              from="0.7"
              to="0.03"
              type="scale"
            ></animateTransform>
          </g>
          <animateTransform
            id="aniFirstScale"
            attributeName="transform"
            attributeType="XML"
            begin="0s;aniRotate.end"
            dur="0.5s"
            from="2"
            to="0.7"
            type="scale"
          ></animateTransform>
        </g>
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          dur="2s"
          from="-2,-8"
          repeatCount="indefinite"
          to="0,-19"
          type="translate"
        ></animateTransform>
      </g>
      <g
        style={{
          WebkitTransformOrigin: "center",
          MsTransformOrigin: "center",
          transformOrigin: "center",
        }}
      >
        <g>
          <rect width="5" height="0" x="49" y="5" fill="#fff" rx="2">
            <animate
              attributeName="height"
              begin="aniFirstScale.end"
              dur="1s"
              values="0;160"
            ></animate>
          </rect>
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            begin="aniFirstScale.end"
            dur="1.5s"
            from="3,0.5"
            to="9.5,49"
            type="translate"
          ></animateTransform>
        </g>
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          begin="aniFirstScale.end"
          dur="1.5s"
          from="1 1"
          to="0.3 1"
          type="scale"
        ></animateTransform>
      </g>
      <path fill="#fff" d="M0 80H80V240H0z">
        <animate
          attributeName="y"
          begin="aniFirstScale.end + 0.5s"
          dur="1s"
          values="80;-80"
        ></animate>
      </path>
    </svg>
    )
}

function Spinner({ local }) {

    return (<div className={`loading${local?" local":""}`}>
        <div className="loader">
            <svg className="flower" viewBox="0 0 160 160" version="1.1"
                 xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve"
                 style={
                    {fillRule: "evenodd", clipRule: "evenodd", strokeLinejoin: "round", strokeMiterlimit: "2"}
                 }>
                <path style={{transition: "stroke 1s ease"}} d="M29.516,27.688c7.581,10.45 19.746,28.078 29.058,45.67c3.466,-19.582 13.339,-32.593 27.276,-39.975c20.375,-10.792 43.066,-2.621 48.261,0.061l-1.533,2.971l0.029,0.015c0,0 -13.761,25.851 -27.804,57.277c-6.32,14.141 -13.782,32.858 -18.357,44.557c-2.125,0.235 -4.276,0.355 -6.446,0.355c-3.969,0 -7.847,-0.395 -11.596,-1.149c-0.262,-0.735 -0.536,-1.499 -0.821,-2.288c-0.906,-2.327 -1.918,-5.024 -2.943,-7.981c-4.543,-12.067 -10.747,-27.415 -17.696,-41.458c-8.24,-16.655 -19.899,-34.023 -27.949,-45.305c3.019,-4.642 6.553,-8.92 10.521,-12.75Zm82.237,16.194c-5.667,-0.378 -12.196,0.347 -18.415,3.64c-13.337,7.065 -20.492,21.616 -20.113,44.032c0.147,8.715 2.463,18.166 5.059,26.229c3.99,-9.923 8.584,-21.044 12.699,-30.252c7.733,-17.306 15.383,-32.948 20.77,-43.649Z"/>
            </svg>
            <svg className="arc"
                 viewBox="0 0 160 160" version="1.1" xmlns="http://www.w3.org/2000/svg"
                 xmlSpace="preserve"
                 style={
                    {fillRule: "evenodd", clipRule: "evenodd", strokeLinejoin: "round", strokeMiterlimit: "2"}
                 }>
                <path style={{transition: "stroke 1s ease"}} d="M37.887,39.54c-3.552,-5.189 -8.544,-12.103 -8.544,-12.103c-14.277,13.759 -22.343,32.734 -22.343,52.563c0,40.29 32.71,73 73,73c28.37,0 54.171,-16.437 66.16,-42.149l-13.232,-6.17c-9.591,20.57 -30.232,33.719 -52.928,33.719c-32.232,0 -58.4,-26.168 -58.4,-58.4c0,-15.112 5.857,-29.605 16.287,-40.46Z"/>
            </svg>
        </div>
    </div>);

}

export default Spinner;
