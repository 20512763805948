import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { getType } from "../prototypes";
import { morphNames, getKey, wordType, lemma2str } from "../lang/lang2";
import { alternation } from "../lang/alternation";
import { entities2unicode, chain2path, word2href, chain2str, parsed2html, parseChain } from "../lang/parse";
import useLongPress from "./useLongPress";
import "./Wordform.css";

export function Formula(props) {

    if (props.word.rule.toString()[0] !== "1") {
        //console.log(`WARNING: '${ lemma2str(props.word.lemma)}' is not a verb`);
        return null
    }

    //console.log(props.word);

    let lastRoot = props.word.morphs.filter(m => m.origin.type==="root");

    // if origin root altered, then formula is not being used
    if (lastRoot[lastRoot.length-1].hasOwnProperty("alt") && props.word.rule.toString()[1] === "1") return null;

    //console.log(lastRoot);

    lastRoot = lastRoot[lastRoot.length-1].origin;


    let formula;
    if (props.word.rule.toString()[1] === "1") {

        if (props.word.hasOwnProperty("altName")) {
            formula = props.word.altName;
        } else {
            if (lastRoot.hasOwnProperty("alt")) {
                const alt = alternation.find(a => a.hasOwnProperty(lastRoot.alt.toString()));
                if (alt.hasOwnProperty("name")) {
                    formula = alt.name;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        }

    } else {
        formula = props.word.rule.toString()[1] === "2" ? "j-j-" : "j--";
    }

    //console.log(formula);

    function format(formula) {
        return formula
            .split("-").map(
                (part, i) => (
                    <span
                        key={i}
                        className={part===""?"x":undefined}>{part===""?"\u00d7":part}</span>
                )
            )
    }

    function formulaClassName() {
        return props.word.rule.toString()[1]==="1"?"formula irregular":"formula";
    }

    if (Array.isArray(formula)) {
        if (props.showOnlyFirst===true) {
            formula = formula.slice(0, 1);
        }
    } else {
        formula = [formula]
    }

    return (
        <>
        {formula.map((f, i) => (
            <span
                key={i}
                className={formulaClassName()}>{
                format(f)
            }</span>
        ))}
        </>
    );
}

export function LongLink(props) {
    const longPress = useLongPress(props.onStart, props.onStop, window.longPress);
    const className = "longPress" + (props.className ? " "+props.className : "");
    return <Link onClick={props.onClick} className={className} to={props.to} {...longPress}>{props.children}</Link>
}

function LongSpan(props) {
    const longPress = useLongPress(props.onStart, props.onStop, window.longPress);
    const className = "longPress" + (props.className ? " "+props.className : "");
    return <span onClick={props.onClick} className={className} {...longPress}>{props.children}</span>
}

export function LongNavLink(props) {
    const { id, box, words } = props;
    const word = words.find(w=>w.id === id);
    const onStart = (e)=>{ box.show(e, word) };
    const onStop = (e)=>{ box.mouseMove(e, word) };
    const onClick = (e)=>{ box.hide(e, word) };
    const longPress = useLongPress(onStart, onStop, window.longPress);
    if (!word) {console.log("WARNING: no word with id", id); return null;}
    return <NavLink
        exact activeClassName="selected"
        onClick={onClick} className={props.className + " longPress"} to={word2href(word)} {...longPress}>{props.children}</NavLink>
}


export function WordLong(props) {
    let { word, form, box } = props;
    if (!form) form = wordType(word).short === "ke" ? chain2str(word) : lemma2str(word.lemma);
    if (props.hyphenate === false) form = form.replace(/&shy;/g, "");
    let homonym = "";
    if (props.showHomonym !== false)
        if (word.homonym) {
            form += `&thinsp;&#${9311+word.homonym};`;
            //homonym = <sub>{word.homonym}</sub>;
        }

    const boxProp = box ? {
        onStart: (e)=>{ box.show(e, word) },
        onStop: (e)=>{ box.mouseMove(e, word) },
        onClick: (e)=>{ box.hide(e, word) }
    } : {};

    return (
        <LongLink
            className={props.className||""}
            to={word2href(word)}
            {...boxProp}
        >{entities2unicode(form)}{homonym}</LongLink>
    );
}

export function Word(props) {

    //console.log(props);

    let { word, form, box, nolink, nohyphenate, showHomonym, className } = props;
    if (!form) form = wordType(word).short === "ke" ? chain2str(word) : lemma2str(word.lemma);
    if (nohyphenate) form = form.replace(/&shy;/g, "");
    if (showHomonym) {
        if (word.homonym) {
            form += `&thinsp;&#${9311 + word.homonym};`;
        }
    }

    form = entities2unicode(form);

    let Component, componentProps = className ? { className } : {};

    function boxProp() {
        return {
            onStart: (e)=>{ box.show(e, word) },
            onStop: (e)=>{ box.mouseMove(e, word) },
            onClick: (e)=>{ box.hide(e, word) }
        }
    }

    if (box) componentProps = {...componentProps, ...boxProp() };
    if (!nolink) componentProps.to = word2href(word);

    if (nolink) {
        Component = box ? LongSpan : Span;
    } else {
        Component = box ? LongLink : Link;
    }

    return (
        <Component {...componentProps}>{form}</Component>
    );
}

function Span(props) {
    return (
        <span {...props}>{props.children}</span>
    )
}

export function Label(props) {
    const lv = [].concat(props.children).map(child => child.lv).join(", ").replace(/\[[^\]]+\]/g, "");
    const sl = [].concat(props.children).map(child => child[window.secLang]).join(", ");
    //console.log(lv);
    return(
        <>
        <span className={props.className} title={sl}>{lv}</span>
        </>
    )
}

/*export function Word(props) {
    let { word, form } = props;
    if (props.hyphenate === false) form = form.replace(/&shy;/g, "");
    const lemma = lemma2str(word.lemma);
    return (
        <Link className={props.className} to={`/vārdi/${wordType(word).full}/${lemma}/`}>{entities2unicode(form)}</Link>
    );
}*/

export function ChainLong(props) {
    let { chain, box, form } = props;
    //if (props.hyphenate === false) form = form.replace(/&shy;/g, "");
    if (!form) form = chain2str(chain);

    const boxProp = box ? {
        onStart: (e)=>{ box.show(e, chain) },
        onStop: (e)=>{ box.mouseMove(e, chain) },
        onClick: (e)=>{ box.hide(e, chain) }
    } : {};

    return (
        <LongLink
            className={props.className}
            to={word2href(chain)}
            {...boxProp}
        >{entities2unicode(form)}</LongLink>
    );
}

export function FamilyChain(props) {
    let { chain } = props;
    //if (props.hyphenate === false) form = form.replace(/&shy;/g, "");
    const lemma = chain2path(chain);
    return (
        <Link
            className={props.className}
            to={`/vārdi/ķēde/${lemma}/`}>
            <div>{props.children}</div>
            <div className="trans">{entities2unicode(chain[window.secLang].default)}</div>
        </Link>
    );
}

export function TransWord(props) {

    if (wordType(props.word).short==="ke") return(
        <div className={`trans ${props.className||""}`}>{entities2unicode(props.word[window.secLang].default)}</div>
    );

    const translation = props.word[window.secLang].trans;

    return <span className={`trans ${props.className||""}`}>{
        (getType(translation)==="array") ?
            translation.map((t, i) => (
                <span key={i} className="sense">
                    {translation.length > 1 && props.numerate !== false ? `${i+1}.\u00A0` : ""}
                    {
                        (props.max ? [].concat(t).slice(0, props.max) : [].concat(t)).map((v, j, arr) => (
                            <span key={j} className={j===arr.length-1&&j<[].concat(t).length-1?"ellip":undefined}>
                                {entities2unicode(v)}
                            </span>
                        ))
                    }
                </span>
            )) : entities2unicode(translation)
    }</span>;
}

export function TransDetails({ data, box, word, className }) {

    const text = word[window.secLang].detailed;

    return(
        text ?
            <div className={`trans details ${className||""}`}>{
                /\[[^\]]+\]/.test(text) ?
                    parsed2html({data, box}, parseChain(data, {lv:{default:text}})) : entities2unicode(text)
            }</div> : null
    );
}

export function Chainform(props) {
    return(
        <span className={props.className}>{
            entities2unicode(chain2str(props.chain, props.form))
        }</span>
    )
}

export function Wordform(props) {
    if (props.form === "") return null;
    return (
        <span className={props.className}>{
            props.form
                .map((m, i) => {
                    const morphName = getKey(m, morphNames);
                    return (
                        [].concat(m[morphName]).map((s, i) => {
                            let className = morphName;
                            if ([12,121,13,131].includes(props.rule) && morphName==="flex" && s==="j")
                                className += " jj";
                            if ([11, 111].includes(props.rule) && morphName==="root") {
                                //console.log(props.word);
                                if (props.word && props.tense!==undefined) {
                                    let root = props.word.morphs
                                        .filter(morph=>morph.origin.type==="root");
                                    //console.log(root[root.length-1].origin.alt);
                                    root = root[root.length-1];
                                    // if root is altered, no formula used - no highlight needed
                                    if (!root.hasOwnProperty("alt") && getType(root.origin.alt) === "number") {
                                        const alt = alternation
                                            .find(a=>a.hasOwnProperty(root.origin.alt.toString()));
                                        //console.log([].concat(alt.name)[0]);

                                        if (alt.hasOwnProperty("name")) {

                                            const hi = [].concat(alt.name)[0].split("-")[props.tense];
                                            //console.log(hi);
                                            const rx = new RegExp(`(${hi})`);
                                            //console.log(s.split(rx));

                                            const splitted = s.split(rx).filter(str=>str !== "");
                                            const index = splitted.lastIndexOf(hi);

                                            return (
                                                <span key={i} className={className}>{
                                                    splitted.map((str, j)=>(
                                                        <span
                                                            key={j}
                                                            className={
                                                            rx.test(str) && j===index ?
                                                            "jj":
                                                            undefined}>{str}</span>
                                                    ))
                                                }</span>
                                            );

                                        }
                                    }
                                }
                            }
                            //console.log(className);
                            if ((morphName === "postfix" || morphName === "prefix") &&
                                props.hyphenate !== false) {
                                return (
                                    <span key={i} className={className}>{`${s}\u00AD`}</span>
                                    /*<>
                                     &shy;&shy;
                                     <span key={i} className={morphName}>{s}</span>
                                     </>*/
                                )
                            } else {
                                return (
                                    <span key={i} className={className}>{s}</span>
                                )
                            }
                        })
                    )
                })
        }</span>
    )
}

