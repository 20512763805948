import React from "react";
import { entities2unicode, parseChain, renderNode, parsed2html } from "../lang/parse";

/**
 * @return {null}
 */
function ParsedText(props) {

    //console.log("ParsedText: props:", props);

    if (!props.text) return null;
    
    const ptext = Array.isArray(props.text) ? props.text.join("") : props.text;
    
    if (!/<[^>]+>/.test(ptext) &&
            !/\[[^\]]+\]/.test(ptext) &&
                !/\{[^\{\}]+\}/.test(ptext)) return entities2unicode(ptext);
    

    let wordProps = { data: props.data };
    if (props.box) wordProps.box = props.box;
    if (props.className) wordProps.className = props.className;
    if (props.nolink) wordProps.nolink = true;

    if (/<[^>]+>[^<>]*<\/[^>]+>/.test(ptext)) {

        //console.log("this is fucking html");

        const parser = new DOMParser();
        let text = ptext;
        if (!/^</.test(text) || (/^<[^>]+\/>/).test(text)) {
            text = "<span>" + text + "</span>";
            //console.log(text);
        }

        let nodes = [...parser.parseFromString(text, "text/html").body.childNodes];


        //console.log(parser.parseFromString("<div><span></span><br/><qtext a='b' /></div>", "text/html").body);

        //console.log(nodes);

        //const res =
        return renderNode(nodes, wordProps);
        //console.log(res);
        //return res;

    } else {
        return parsed2html(wordProps, parseChain(props.data, {lv:{default:ptext}}));
    }
    
}

export default ParsedText;
