import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { isMobileOnly } from "react-device-detect";

import "./index.css";
import "./css/colors.css";
import "./css/ui.css";
import "./scss/ui.scss";

//let sheet = window.document.styleSheets[0];
//sheet.insertRule('@font-face { color: red; }', sheet.cssRules.length);

//console.log(sheet);
//console.log(sheet.insertRule);

const defaultTheme = "day";


//console.log(localStorage.getItem("theme"));

if (!isMobileOnly) {
    const theme = localStorage.getItem("theme") || defaultTheme;
    document.body.classList.add(theme);

    const today = new Date();
    //console.log(today.getDay());
    if ([5, 6, 0].includes(today.getDay())) {
        document.body.classList.add("friday");
    }
}
/*
if (today.getDay() === 6) {
    document.body.classList.add("saturday");
}
*/

window.baseHref = "/2";
window.baseName = /runajiet|govorite/.test(window.location.hostname) ? "/2/" : "/";
//console.log(window.location.hostname);

/*
const vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);

window.addEventListener('resize', () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
});
*/


ReactDOM.render(
    <BrowserRouter basename={window.baseName}>
        <App />
    </BrowserRouter>,
    document.getElementById("root")
);
