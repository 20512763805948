import React from "react";
import { str2word } from "../../lang/parse";
import { Wordform, TransWord, Formula } from "../Wordform";

export function Words({ setWord, word, data, box, groups }) {

    //console.log(groups);

    const wordProps = wordStr => {
        const thisWord = str2word(data, wordStr).word;
        let active = word ? thisWord.id === word.id : false;
        //console.log("this word from str:", thisWord);
        //console.log("word:", word);
        //console.log("active?", active);
        let res;
        if (active) {
            res = { word: thisWord, active };
            //console.log("res for active:", res);
        } else {
            res = { word: thisWord, onClick: ()=>{ setWord(thisWord) } };
            //console.log("res for word:", res);
        }
        return res;
    };

    const classList = ["clear"];
    if (!word) classList.push("active");

    return (

        <div className="wordsCol">

            <div className={classList.join(" ")} onClick={()=>{setWord(null)}}>
                <div>Tabula bez vārdiem</div>
                <div className="trans">таблица без слов</div>
            </div>
            <header>
                <div>ievietot vārdus:</div>
                <div className="trans">подставить слова:</div>
            </header>

            <div className="wordsContainer">
                <div className="words">
                    {
                        groups.map((group, i) => (
                            <div key={i} className="group">
                                <header>
                                    <div>{group.title.lv}</div>
                                </header>
                                {
                                    group.words.map((wordStr, i) => (
                                        <Word key={i} {...wordProps(wordStr)} />
                                    ))
                                }</div>
                        ))
                    }</div>
            </div>
        </div>

    )
}

function Word({ word, active, onClick }) {

    //console.log("Word render");
    //console.log("word:", word);
    const classList = ["word"];

    if (active) classList.push("active");

    return (
        <div className={classList.join(" ")} onClick={onClick||null}>
            <div className="lemma">
                <Wordform form={word.lemma} />
                <span className="formulas"><Formula word={word} /></span>
            </div>
            <div className="trans"><TransWord word={word} numerate={false} max={2} /></div>
        </div>
    )
}
