import React from 'react';
import { Link } from 'react-router-dom';
import { wordType, transform, countExists, nounGender, nounCount } from "../lang/lang2";
import { chain2str, word2href, entities2unicode } from "../lang/parse";
import { TransWord, Wordform, Word, Formula } from "../components/Wordform";
import { labels } from "../labels";
import { GenderIcon } from "../i/icons";
import { generateId } from "../prototypes";
// import useLongPress from "./useLongPress";
import "./Snippets.css";

export function SnipCard(props) {
    return <Link to={word2href(props.item)} className={"card "+(props.className||"")} key={props.item.id}>
        <SnipWord {...props} />
    </Link>
}

export function SnipWide(props) {
    return <Link to={word2href(props.item)} className={"wide "+(props.className||"")} key={props.item.id}>
        <SnipChain {...props} />
    </Link>
}

export function SnipChain(props) {
    return (
        <div className="snippet chain">
            <div className="mainLink">{entities2unicode(chain2str(props.item))}</div>
            <div className="trans"><TransWord word={props.item} /></div>
        </div>
    )
}

export function SnipWordShort(props) {

    const {item} = props;

    /*const onStart = (e)=>{ box.show(e, item) };
    const onStop = (e)=>{ box.mouseMove(e, item) };
    const onClick = (e)=>{ box.hide(e, item); props.handleClick(item) };*/

    //const longPress = useLongPress(onStart, onStop, window.longPress);

    return (
        <div className={"snippet short "+(props.className||"")} id={item.id} onClick={(event)=>{props.handleClick(event, item)}} >
            <Word word={props.item} className="mainLink" nolink />
            <div className="trans"><TransWord max={2} word={props.item} /></div>
        </div>
    );
}

export function SnipWord(props) {

    const { item, data } = props;

    const type = wordType(item).short;

    return(<div className="snippet word" key={generateId()}>

        <div className="head">
            
            <Wordform className="mainLink" form={item.lemma} hyphenate={false} />

            {
                type === "lv" &&
                <span className="icon"><GenderIcon
                    type={type}
                    key={generateId()}
                    width={9} height={18} count={nounCount(item)} gender={nounGender(item)} /></span>
            }
            {
                type === "dv" &&
                <span className="formulas"><Formula word={item} /></span>
            }

            <TransWord word={item} max={(props.transLimit!==false && 2)} />


        </div>

        {
            type === "dv" ?
                <>
                {[1, 2, 3].map(person => (
                    <div className="tense" key={"p"+person}>
                        <span className="person"
                              title={labels.person.find(p=>p.person===person)[window.secLang]}>{
                            labels.person.find(p=>p.person===person).lv
                        }</span>
                        {[0, 1, 2].map(tense => (
                            <Wordform key={`t${tense}p${1}`}
                                      className="tense"
                                      form={transform(data.morphs, item, { tense, person: person })} />)
                        )}
                    </div>
                ))}
                <div className="tense compound" key="p4">{
                    [0, 1].map(gender => (
                        [0, 1].map(count => (
                            <React.Fragment key={generateId()}>
                                <GenderIcon
                                    key={generateId()}
                                    width={6} height={12} count={count} gender={gender} />
                                &thinsp;
                                <Wordform
                                    key={generateId()}
                                    className="tense"
                                    form={transform(data.morphs, item, { tense: 3, gender, count })} />
                            </React.Fragment>
                        ))
                    ))
                }</div>
                </> : type === "lv" ?
                <>
                {
                    (item.rule.toString()[1]==="7"?[0, 1, 3]:[0, 1, 2, 3, 5]).map(cas => (
                        <div className="cas" key={generateId()}>
                            <span key={`q${cas}`} className="question">{labels.casQuestionShort[cas].lv}</span>
                            {
                                [0, 1].map(count => (
                                    countExists(count, item) ?
                                    <Wordform
                                        className="cas"
                                        key={`c${cas}c${count}`}
                                        form={transform(data.morphs, item, { cas, count })} /> : ""

                                ))
                            }
                        </div>
                    ))
                }
                </> : null
        }
    </div>)
}
