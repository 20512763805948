import React from "react";
import { labels } from "../../../labels";
import { RuleSetHeaderPersonList, RulesGroup, Rule, RuleSet } from "./LiveRules";

const diffGroup = {
    key: "conjugation",
    compare: (word, keyValue) => {
        return (
            word.rule.toString()[0]==="1" &&
            parseInt(word.rule.toString()[1])===keyValue
        )
    }
};

export const groups = [
    {
        conjugation: 1,
        title: labels.verbs.groups["1"],
        words: ["bēgt[dv]", "pirkt[dv]", "salt[dv]", "celt[dv]", "saprast[dv]", "teikt[dv]"],
        ruleSet: [
            {
                className: "person2 nonReflexive",
                header: {
                    component: RuleSetHeaderPersonList,
                    props: { person: labels.person.find(p=>p.person===2) }
                },
                rules: [
                    {
                        component: RulesGroup, props: {},
                        rules: [
                            {
                                component: RulesGroup, props: { label: { ru: "Меняется конец:" } },
                                rules: [
                                    { component: Rule, props: { name: "g-dz" } },
                                    { component: Rule, props: { name: "k-c" } }
                                ]
                            },
                            {
                                component: RulesGroup, props: { label: { ru: "Исчезает смягчение:" } },
                                rules: [
                                    { component: Rule, props: { name: "noSoften" } }
                                ]
                            }
                        ]
                    },
                    { component: RulesGroup, props: { },
                        rules: [
                            {
                                component: RulesGroup,
                                props: { label: { ru: <>Добавляется <b>i</b> в словах:</> } },
                                rules: [
                                    { component: Rule, props: { name: "proti-saki" } }
                                ]
                            },
                            {
                                component: RulesGroup, props: { label: { ru: <>после <b>st</b>:</> } },
                                rules: [
                                    { component: Rule, props: { name: "-sti" } }
                                ]
                            }

                        ]
                    }
                ]
            },
            {
                className: "person3 nonReflexive",
                header: {
                    component: RuleSetHeaderPersonList,
                    props: { person: labels.person.filter(p=>[3,6].includes(p.person)) }
                },
                rules: [
                    { component: Rule, props: { name: "saka" } }
                ]
            }
        ]
    },
    {
        conjugation: 2,
        title: labels.verbs.groups["2"],
        words: ["domāt[dv]", "dzīvot[dv]", "ārstēt[dv]", "pastaigāties[dv]"],
        ruleSet: [
            {
                className: "person23 nonReflexive",
                header: {
                    component: RuleSetHeaderPersonList,
                    props: { person: labels.person.filter(p=>[2,3,6].includes(p.person)) }
                },
                rules: [
                    {
                        component: RulesGroup, props: { label: { ru: "В конце сохраняются:" } },
                        rules: [
                            { component: Rule, props: { name: "ā-ā" } },
                            { component: Rule, props: { name: "o-o" } },
                            { component: Rule, props: { name: "ē-ē" } }
                        ]
                    }
                ]
            }
        ]
    },
    {
        conjugation: 3,
        title: labels.verbs.groups["3"],
        words: ["cerēt[dv]", "gaidīt[dv]", "turpināt[dv]", "turpināties[dv]", "mācīties[dv]"],
        ruleSet: [
            {
                className: "person2 nonReflexive",
                header: {
                    component: RuleSetHeaderPersonList,
                    props: { person: labels.person.find(p=>p.person===2) }
                },
                rules: [
                    {
                        component: RulesGroup,
                        props: {
                            label: {
                                ru: <><b>ī</b>, <b>ā</b> и&nbsp;<b>ē</b> превращаются в&nbsp;<b>i</b></>
                            }
                        },
                        rules: [
                            { component: Rule, props: { name: "inā-i" } }
                        ]
                    }
                ]
            },
            {
                className: "person3 nonReflexive",
                header: {
                    component: RuleSetHeaderPersonList,
                    props: { person: labels.person.filter(p=>[3,6].includes(p.person)) }
                },
                rules: [
                    {
                        component: RulesGroup, props: {},
                        rules: [
                            {
                                component: RulesGroup, props: { label: { ru: <><b>ē</b> исчезает</> } },
                                rules: [
                                    { component: Rule, props: { name: "ē-" } }
                                ]
                            },
                            {
                                component: RulesGroup,
                                props: { label: { ru: <><b>ī</b> и <b>ā</b> превращаются в&nbsp;<b>a</b></> } },
                                rules: [
                                    { component: Rule, props: { name: "inā-a" } }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                className: "person345",
                header: {
                    component: RuleSetHeaderPersonList,
                    props: {
                        person: labels.person
                            .filter(p=>[3,6].includes(p.person))
                            .concat(labels.person.filter(p=>[4,5].includes(p.person)))
                    }
                },
                rules: [
                    {
                        component: RulesGroup, props: { label: { ru: <><OrangeMark/>В словах с&nbsp;<b>i</b>, <b>inā</b> появляется долгота в&nbsp;<b>ā</b></> } },
                        rules: [
                            { component: Rule, props: { name: "-ā-nonReflexive" } },
                            { component: Rule, props: { name: "-ā-reflexive" } }
                            /*{ component: Rule, props: { name: "āt" } },
                            { component: Rule, props: { name: "āties" } },
                            { component: Rule, props: { name: "ām" } },
                            { component: Rule, props: { name: "āmies" } },
                            { component: Rule, props: { name: "ās" } }*/
                        ]
                    }
                ]
            }
        ]
    }
];

function OrangeMark() {

    return (
        <div className="orangeMark">&nbsp;</div>
    );
}

export function Groups({ word, data, box, setConnectedRules }) {

    return (
        <div className="groups">{
            groups
                .map((group, i) =>
                    <Group key={i}
                        {...{ group, word, data, box, setConnectedRules }}
                           active={word ? diffGroup.compare(word, group.conjugation) : null} />
                )
        }</div>
    )
}

function Group({ word, group, active, setConnectedRules, data, box }) {

    const classList = ["group"];
    if (active===true) classList.push("active");
    if (active===false) classList.push("disabled");

    return (
        <div className={classList.join(" ")}>
            <header>
                <div>{group.title.lv}</div>
                <div className="trans">{group.title[window.secLang]}</div>
            </header>
            {
                group.ruleSet
                    .map((ruleSet, i) => (
                        <RuleSet key={i} {...{word, data, group, ruleSet, setConnectedRules}} />
                    ))
            }
        </div>
    )
}
