import React, { useState, useEffect, useMemo  } from 'react';
import { Link } from 'react-router-dom';
import { detectLang, lemma2str, transform } from "../lang/lang2";
import { word2href, removeDiactritics } from "../lang/parse";
import Content from "../components/Content";
import { SnipWordShort } from "../components/Snippets";
import { Wordform, Formula } from "../components/Wordform";
import Spinner from "../components/Spinner";
import { labels } from "../labels";
import "./Conjugation.css";

function Table(props) {

    /*useEffect(()=>{
        //console.log(props.activeItem);
    }, [props.activeItem]);*/

    const tenses = labels.tense.slice(0, 3);

    return (
        <div className="table">
            <table className="simpleTense">
                <tbody>
                <tr>
                <th>&nbsp;</th>
                { tenses.map((tense, i) => (
                    <th key={i}>{tense.lv}</th>
                )) }
                </tr>
                { [1, 2, 3, 4, 5].map((person, i) => (
                    <tr key={i}>
                        <th key={"label"+i}>{labels.person.find(label=>label.person===person).lv}</th>
                        { [0, 1, 2].map((tense, j)=>(
                            <td key={j}>{
                                props.activeItem ?
                                    <Wordform
                                        form={transform(props.morphs, props.activeItem, { person, tense })}
                                        hyphenate={false}

                                        rule={[1,2,3,4,5].includes(person)&&props.activeItem.rule}
                                        word={[1,2,3,4,5].includes(person)&&props.activeItem}
                                        tense={tense}
                                    />
                                    : "\u00A0"
                            }</td>
                        )) }
                    </tr>
                )) }
                </tbody>
            </table>
        </div>
    )
}

const List = React.memo(function(props) {

    useEffect(()=>{
        //console.log("list useEffect");
        //console.log(props.items.length);
        if (props.items.length > 0) {
            props.setActiveItem(props.items[0], props.group);
        } else {
            props.setActiveItem(null, props.group)
        }
    }, [props.items]);

    return (
        <div className="list">
            { props.items ? props.items.map((item, i) => (
                <SnipWordShort
                    className={i===0&&"active"}
                    key={i} item={item}
                    handleClick={ ()=> { props.setActiveItem(item, props.group) }}
                />
            )) : "lejupielādēju..." }
        </div>
    )
}, (propsPrev, propsNext) => { return propsPrev.items === propsNext.items });

function Head(props) {
    return (
        <div className="head" title={labels.verbs.groups[props.group+1][window.secLang]}>{
            labels.verbs.groups[props.group+1].lv
        }</div>
    );
}

function Lemma(props) {
    return (
        <h1>{
            props.activeItem ?
                <Link to={word2href(props.activeItem)}>
                    <Wordform className="lemma" form={props.activeItem.lemma} />
                    <Formula word={props.activeItem} showOnlyFirst={true} />
                </Link>
                : "\u00A0"
        }</h1>
    )
}

function Column(props) {
    return (
        <div className={`group${props.group+1}`}>
            <Head group={props.group} />
            <Lemma activeItem={props.activeItem} group={props.group} />
            <Table activeItem={props.activeItem} morphs={props.morphs} />
            <List items={props.items} group={props.group} setActiveItem={props.setActiveItem} />
        </div>
    )
}

function sortVerbs(verbs) {
    let sorted = [];
    for (let i=0; i<3; i++) {
        sorted[i] = verbs.filter(verb => parseInt(verb.rule.toString()[1])-1 === i)
    }
    return sorted;
}

function Input(props) {

    const inputValue = props.value;

    function change(event) {
        props.onChange(event.target.value);
    }

    return (
        <div className="searchForm">
            <div className="stat"><span title="глаголов в словаре">{
                `${props.count} darbības vārdu vārdnīcā`
            }</span></div>
            <div className="input">
                <input
                    onChange={change} value={inputValue}
                    className={detectLang(inputValue)||"unknown"} />
                <div>Ievadiet vārda sākumu<br/>
                    <span className="trans">введите начало слова</span></div>
            </div>
            <div></div>
        </div>
    )

}

const Conjugation = React.memo( function(props) {

    console.log("Conjugation render");

    let timeoutId = "";

    const [data] = useState(
        () => {
            return {
                chains: [],
                    words:
                (()=>{
                    console.log("filtering all words...");
                    return props.data.words.filter(word=>word.rule.toString()[0]==="1");
                })(),
                    morphs: props.data.morphs
            }
        }
    );
    const [inputValue, setInputValue] = useState("");
    const [query, setQuery] = useState(null);
    const [verbs, setVerbs] = useState(null);
    const [activeVerbs, setActiveVerbs] = useState([null, null, null]);
    const [status, setStatus] = useState(false);
    const [preselectedVerbs, setPreselectedVerbs] = useState();

    useEffect(()=>{

        setPreselectedVerbs(
            sortVerbs(
                (()=>{
                    //console.log("filtering preselected...");
                    return data.words
                        .filter(word => {
                            if (word.rule.toString()[1] === "1") {
                                return word.lemma.every(morph => !morph.hasOwnProperty("prefix")) &&
                                        word.morphs.find(
                                            morph =>
                                                morph.origin.type === "root" &&
                                                morph.origin.hasOwnProperty("alt")
                                        )
                            } else {
                                return word.lemma.every(morph => !morph.hasOwnProperty("prefix"))
                            }
                        })
                })()
            )
        );

        props.setNav(
            {
                page: "conjugation"
            }
        );

        document.title = "Darbības vārdu konjuģēšana";

    }, []);

    function setActiveVerb(verb, group) {
        setActiveVerbs(prevAV => {
            return prevAV.map((v, i) => i === group ? verb : v);
        });
    }

    useEffect(()=>{
        //if (activeVerbs[0]) console.log(document.getElementById(activeVerbs[0].id));
        //console.log("activeVerbs changed");
        [0, 1, 2].forEach(group => {
            if (activeVerbs[group]) {
                //console.log(activeVerbs[group]);
                const element = document.getElementById(activeVerbs[group].id);
                if (element)
                    if (!element.classList.contains("active")) {
                        const prevActive = element.parentNode.querySelector(".active");
                        if (prevActive) prevActive.classList.remove("active");
                        element.classList.add("active");
                    }
            }
        });
    }, [activeVerbs]);

    useEffect(()=>{
        //console.log("inputValue changed");
        const lang = detectLang(inputValue);
        //console.log(lang);
        if (lang) setQuery({ str: inputValue.trim().toLowerCase(), lang });

    }, [inputValue]);

    useEffect(()=>{

        if (query === null) return;

        clearTimeout(timeoutId);

        if (query.str.length === 0) {
            //console.log("should I reset verbs?");
            timeoutId = setTimeout(
                ()=>{
                    setVerbs(preselectedVerbs);
                }, 40
            );
        } else {
            const func = (query.lang === "lv") ?
                ()=> {
                    const querySyn = removeDiactritics(query.str);
                    const filteredVerbs = data.words
                        .filter(
                            word => removeDiactritics(lemma2str(word.lemma)
                                .toLowerCase()).indexOf(querySyn) === 0
                        );
                    setVerbs(sortVerbs(filteredVerbs));
                } :
                ()=> {
                    const filteredVerbs = data.words.filter(word => {
                        if (word.hasOwnProperty(query.lang)) {
                            if (Array.isArray(word[query.lang].trans)) {
                                return word[query.lang].trans.flatten()
                                    .some(translation => translation.toLowerCase().indexOf(query.str)===0)
                            } else {
                                return word[query.lang].trans.toLowerCase().indexOf(query.str)===0;
                            }
                        }
                        return false;
                    });
                    setVerbs(sortVerbs(filteredVerbs));
                };

            timeoutId = setTimeout(
                func, 40
            );
        }

    }, [query]);

    useEffect(()=>{
        //console.log("useEffect verbs only when verbs changes");
        setStatus(verbs !== null);
    }, [verbs]);

    return (
        status ?
        <Content className="conjugation">
            <div className="columns">
                { [0, 1, 2].map((group, i)=>(
                    <Column
                        key={i} group={group} items={verbs[group]}
                        setActiveItem={setActiveVerb} activeItem={activeVerbs[i]}
                        morphs={data.morphs}
                    />
                )) }
            </div>
            <Input value={inputValue} onChange={setInputValue} count={data.words.length} />
        </Content> : <Spinner />
    );
});

export default Conjugation;
