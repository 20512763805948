import { wordType } from "../lang/lang2";
import { hasDeepProperty, getDeepPropValue, aiove, aov } from "../prototypes";
import { seekers, converters, comparators } from "./seekers";
import { searches } from "./searches";

export function finder(data, combinedData, query, handleSearchResults, box, timeout, searchName, handleClick) {

    //console.log("finder started");

    const length = combinedData.length;

    //console.log("combinedData length:", length);

    let searchResults = [], ids = [];
    const langSeekers = seekers[query.lang].filter(seeker => aiove(seeker.search, searchName));

    if (langSeekers.length === 0) {
        console.log("no seekers for this type of search");
        return;
    }

    const portion = 300;
    let index = 0;
    let seekerIndex = 0;

    function process() {

        const seeker = langSeekers[seekerIndex];

        //console.log(seeker.n + ": processing from", index);

        for (; index < length; index++) {

            if (ids.indexOf(combinedData[index].id) > -1) continue;

            const item = combinedData[index];
            const t =  wordType(item).short;

            let converter, value, changed;

            if (aiove(seeker.type, t)) {

                aov(seeker.key, (key)=>{
                    //console.log(`checking ${key}`);
                    if (hasDeepProperty(item, key)) {
                        value = getDeepPropValue(item, key);
                        //console.log(`value is ${value}`);

                        aov(seeker.func, (func)=>{
                            converter = func.converter ? converters[func.converter] : false;
                            if (converter) {
                                value = converter(value);
                                //console.log(`converted value is ${value}`);
                            }
                            aov(func.comparator, (comparator)=>{
                                if (comparators[comparator]) {

                                    const found = comparators[comparator](query, value);
                                    if (found) {
                                        //console.log("found");
                                        changed = seeker.component({ item, query, box, data, handleClick });
                                        ids.push(item.id);
                                        return true; // break;
                                    }

                                }
                                if (changed) return true;
                            });
                            if (changed) return true;
                        });
                    }
                    if (changed) return true;
                });

                if (changed) {
                    searchResults.push(changed);
                    handleSearchResults(searchResults.slice(0));
                }
            } else {
                //console.log(`wrong seeker for ${t}`);
            }
            if (index + 1 < length && (index+1) % portion === 0) {
                //console.log(`${seeker.n}: ---- next ${portion} ----`);
                timeout.process = setTimeout(function () {
                    process(seeker);
                }, 1);
                index++;
                break;
            }
        }
        //console.log(seeker.n + ": final index:", index);
        if (index === length) {
            //console.log(seeker.n + ": END");
            if (seekerIndex + 1 < langSeekers.length) {
                //console.log("start next");
                seekerIndex++;
                index = 0;
                process();
            } else {
                console.timeEnd("process");
                //console.log("search end");
                //console.log("results:", searchResults.length);
                if (searchResults.length === 0) handleSearchResults([]);
                localStorage.setItem(searches[searchName].cookieName, JSON.stringify(query));
                //console.log(searchResults);
            }
        }
    }

    console.time("process");

    process();

}

/*
function process() {

    const seeker = langSeekers[seekerIndex];

    //console.log(seeker.n + ": processing from", index);

    for (; index < length; index++) {

        if (ids.indexOf(data[index].id) > -1) continue;

        const item = data[index];
        const t =  wordType(item).short;

        let converter, value, changed;

        if (aiove(seeker.type, t)) {

            aov(seeker.key, (key)=>{
                //console.log(`checking ${key}`);
                if (hasDeepProperty(item, key)) {
                    value = getDeepPropValue(item, key);
                    //console.log(`value is ${value}`);

                    aov(seeker.func, (func)=>{
                        converter = func.converter ? converters[func.converter] : false;
                        if (converter) {
                            value = converter(value);
                            //console.log(`converted value is ${value}`);
                        }
                        aov(func.comparator, (comparator)=>{
                            if (comparators[comparator]) {

                                const found = comparators[comparator](query, value);
                                if (found) {
                                    //console.log("found");
                                    changed = seeker.component({ item, query, box, data: props.data });
                                    ids.push(item.id);
                                    return true; // break;
                                }

                            }
                            if (changed) return true;
                        });
                        if (changed) return true;
                    });
                }
                if (changed) return true;
            });

            if (changed) {
                searchResults.push(changed);
                props.handleSearchResults(searchResults.slice(0));
                //changed = false;
            }
        } else {
            //console.log(`wrong seeker for ${t}`);
        }
        if (index + 1 < length && (index+1) % portion == 0) {
            //console.log(`${seeker.n}: ---- next ${portion} ----`);
            timeoutId = setTimeout(function () {
                process(seeker);
            }, 1);
            index++;
            break;
        }
    }
    //console.log(seeker.n + ": final index:", index);
    if (index === length) {
        //console.log(seeker.n + ": END");
        if (seekerIndex + 1 < langSeekers.length) {
            //console.log("start next");
            seekerIndex++;
            index = 0;
            process();
        } else {
            console.timeEnd("process");
            //console.log("search end");
            //console.log("results:", searchResults.length);
            if (searchResults.length === 0) props.handleSearchResults([]);
            if (cookieName) localStorage.setItem(cookieName, JSON.stringify(query));
            //console.log(searchResults);
        }
    }
}
*/
/*

function process0() {
    console.time("process");

    for (; index < length; index++) {

        const item = data[index];

        const relatedSeekers = langSeekers
            .filter(seeker => [].concat(seeker.type).includes(wordType(item).short))
            .map(seeker => seeker.func);
        //console.log(relatedSeekers);

        let converter, value, changed;

        for (const seeker of relatedSeekers) {
            converter = seeker.converter ? converters[seeker.converter] : false;
            for (const key of [].concat(seeker.key)) {
                if (hasDeepProperty(item, key)) {
                    value = getDeepPropValue(item, key);
                    if (converter) value = converter(value);
                    for (const comparator of [].concat(seeker.comparator)) {
                        if (comparators[comparator]) if (comparators[comparator](query, value)) {
                            searchResults.push(item);
                            changed = true;
                            break;
                        }
                    }
                }
            }
        }

        if (changed) {
            const mapped = searchResults.map(item => (
                <div key={item.id}>{item.lemma ?
                    lemma2str(item.lemma) :
                    chain2str(item)} &mdash; {item.id}</div>
            ));
            props.handleSearchResults(mapped);
        }

        const portion = 300;

        if (index + 1 < length && index % portion == 0) {
            console.log(`---- next ${portion} ----`);
            timeoutId = setTimeout(function () {
                process();
            }, 1);
            index++;
            break;
        }

    }
    console.timeEnd("process");
}
*/
