import React from "react";
import { parseChain, entities2unicode, chain2words } from "../lang/parse";
import { ChainLong, Word } from "./Wordform";

function src(index, media) {
    const i = index < 10 ? "0"+index : index.toString();
    return `/json/v9/media/${media.id}-${i}`;
}

function parseHeader(data, chain, header, activeChainWrap, box) {
    let arr = parseChain(data, { lv: { default: header } } );

    arr = arr.map((part, i) => {
        //console.log(part);
        if (/^\{[^{}]+}/.test(part)) {
            return activeChainWrap(entities2unicode(part.replace(/[{}]/g, "")), i);
        }
        if (part.hasOwnProperty("chain")) {
            return part.chain.id !== chain.id ?
                <ChainLong key={i} chain={part.chain} form={part.usedForm} box={box} /> :
                activeChainWrap(entities2unicode(part.usedForm), i);
        }
        return entities2unicode(part);
    });

    return arr;
}

export function Media(props) {

    if (!props.media) return null;

    let media = props.media.filter(m => m.id === props.chain.id);

    if (media.length < 1) return null;

    media = media.reduce((acc, curr) => {
        acc.sources.push(...curr.sources); return acc;
    }, { id: media[0].id, sources: [] });

    //console.log(media);
    /*media.sources.forEach(source => {
     console.log(parseHeader(
     chains, words, props.chain, source.header,
     (str, i)=>(<span key={i}>{str}</span>), props.box));
     });*/

    //console.time("words");
    //console.log(props.data);
    //const usedWords = chain2words(props.data, props.chain);
    //console.log(usedWords);
    //console.timeEnd("words");

    return (
        <div className="media">
            {props.mediaHeader ? <header className="media">{props.mediaHeader}</header> : null }
            <div className="items">
                { media.sources.map((source, i) => (
                    <div key={i} className="item">
                        <a key={i} href={source.link} target="_blank" rel="noopener noreferrer">{
                            (<>
                            {
                                source.hasOwnProperty("img") ?
                                    source.img !== false ?
                                        (<div className="screenshot"><img src={
                                            src(source.img, media)+"-1x.jpg"
                                            } srcSet={src(source.img, media)+"-2x.jpg 2x"} alt="" /></div>) :
                                        (<div className="dummy">&nbsp;</div>) :
                                    (<div className="screenshot"><img src={src(1, media)+"-1x.jpg"} srcSet={src(1, media)+"-2x.jpg 2x"} alt="" /></div>)
                            }
                            <div className="header">{parseHeader(
                                props.data, props.chain, source.header,
                                (str, i)=>(<span key={i}>{str}</span>), props.box)}</div>
                            {(props.translate && source.hasOwnProperty("trans")) ?
                                source.trans.hasOwnProperty(window.secLang) &&
                                <div className="trans">{entities2unicode(source.trans[window.secLang])}</div> : null}
                            <div className="source">{source.source}</div>
                            </>)
                        }</a>
                    </div>
                )) }
            </div>
            {props.listwords ? <div className="wordsList">
                {props.wordsListHeader ? <header className="wordsList">{props.wordsListHeader}</header> : null }
                <div>{chain2words(props.data, props.chain).map((word, i)=>(
                    <Word word={word} key={i} box={props.box} />
                ))}</div>
            </div> : null}
        </div>
    );
}
