import React, { useState, useEffect } from 'react';
import Content from "../components/Content";
import Search from "../components/Search";
import { SearchResults, Something } from "../components/Search";
import "./Search.css";


function SearchPage(props) {

    const [searchResults, setSearchResults] = useState(null);

    function handleSearchResults(sr) {
        //console.log("result:", sr.length);
        setSearchResults(sr);
    }

    //console.log(removeDiactritic("Šajā attēlā"));

/*    function testAov() {
        let count = 0;
        let arr = [0, 1, 2, 3, 4];
        aov(arr, (test, index)=>{
            if (index > 2) {
                console.log(`index = ${index}, exiting`);
                return false;
            }
            count++;
        });
        console.log(count);
    }*/

    const { setFocus } = props;

    useEffect(()=>{
        props.setNav({
            component: "navSearch",
            menu: [],
            active: {}
        });
    }, []);

    useEffect(()=>{
        setFocus();
    }, [setFocus]);

    return (
        <>
        <Content className="search" onClick={setFocus}>
            <Search
                data={props.data} handleSearchResults={handleSearchResults}
                id={props.inputRef} box={props.box} search="general" />
            <SearchResults searchResults={searchResults}>
                <Something />
            </SearchResults>
        </Content>
        </>
    );
}

export default SearchPage;