import { useState, useEffect, useCallback } from 'react';
import { absorbEvent } from "../prototypes";

export default function useLongPress(onStart = ()=>{}, onStop, ms = 300) {
    const [longStart, setLongStart] = useState({start: false});
    const [done, setDone] = useState(false);

    useEffect(() => {
        //console.log("run useEffect");
        let timerId;
        if (longStart.start) {
            timerId = setTimeout(() => {
                onStart(longStart.event);
                setDone(true);
            }, ms);
        } else {
            if (onStop && done) {
                onStop(longStart.event);
                setDone(false);
            }
            clearTimeout(timerId);
        }

        return () => {
            clearTimeout(timerId);
        };
    }, [onStart, onStop, done, ms, longStart]);

    /*useEffect(() => {
        if (onStop && done && !longStart.start) onStop(longStart.event);
    }, [done, longStart]);*/

    const start = useCallback((e) => {
        //absorb(e);
        e.persist();
        setLongStart({start: true, event: e});
        //setLongDone(false);
        //setEvent(e);
    }, []);
    const stop = useCallback((e) => {
        e.persist();
        setLongStart({start: false, event: e});
    }, []);

    return {
        onMouseOver: start,
        //onMouseMove: stop,
        onMouseLeave: stop,
        onTouchStart: start,
        onTouchEnd: stop,
        //onTouchCancel: absorb,
        //onTouchMove: absorb,
        onContextMenu: absorbEvent
    };
}
