import React from 'react';
import { labels } from "../../labels";
import { Wordform, Label } from "../Wordform";
import { transform } from "../../lang/lang2";
import { GenderIcon } from "../../i/icons";

function TableTransform({ morphs, word, definite, comp }) {

    return (
        <React.Fragment>
            <h3>
                { comp > 0 ? <><Label>{labels.adjectives[ ["", "comparative", "superlative"][comp] ]}</Label>{", "}</> : null }
                <Label>{labels.adjectives[(definite?"definite":"indefinite")]}</Label>
            </h3>
            <table className="transform">
                <tbody>
                <tr>
                    <th />
                    {[0, 1].map((gender, i) => (
                        [0, 1].map((count, j) => (
                            <th key={`${i}${j}`}>
                                <GenderIcon width="10" height="20" gender={gender} count={count} />
                            </th>
                        ))
                    ))}
                </tr>
                { (definite?[0, 1, 2, 3, 4, 5, 6]:[0, 1, 2, 3, 4, 5]).map((cas, r) => (
                    <tr key={r}>
                        <th><Label>{labels.cas[cas]}</Label></th>
                        {[0, 1].map((gender, i) => (
                            [0, 1].map((count, j) => (
                                <td key={`${i}${j}`}>
                                    {
                                        (()=>{
                                            const param = { cas, count, gender, definite };
                                            if (comp > 0) { param.comp = comp }
                                            const form =
                                                transform(
                                                    morphs, word, param
                                                );
                                            if (cas === 4 && form) {
                                                return (
                                                    <>
                                                    <span>ar </span>
                                                    <Wordform form={form} />
                                                    </>
                                                )
                                            }
                                            return <Wordform form={form} />
                                        })()
                                    }
                                </td>
                            ))
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </React.Fragment>
    )
}

function DeclensionAdjective(props) {
    const { morphs, word } = props;

    const definites = word.rule === 3 ? [false, true] : word.rule === 30 ? [false] : word.rule === 31 ? [true] : [];
    
    const comparatives = word.nocomp ? [0] : [0, 1, 2];

    return (
        <>
        <h2><Label>{labels.transformations.declension}</Label></h2>
        {
        comparatives.map((comp, c) => (
            definites.map((definite, d) => {
                //console.log(`comp ${comp}, definite ${definite}`);
                return (comp !== 2 || (comp === 2 && definite !== false)) ?
                    <TableTransform key={"c"+c+"d"+d} {...{morphs, word, definite, comp} } /> : null
            })
        ))
        }
        </>
    )
}

export default DeclensionAdjective;