import React, { useState, useEffect } from 'react';

function Hooktest(props) {

    console.log(props);

    const newNav = [
        { name: "test", href: "/test/" }
    ];

    props.setNav(newNav);

    return (
        <div>test</div>
    );
}

export default Hooktest;
