
import React from "react";

export const searches = {
    "general": {
        cookieName: "query",
        searchName: "general",
        loader: "meklēju..."
    },
    "conjugation": {
        cookieName: "conjugationQuery",
        searchName: "conjugation",
        loader: <div>meklējam...</div>
    }
};