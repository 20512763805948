import React, { useEffect, useRef, useMemo } from 'react';
import { absorbEvent, rad2deg } from "../prototypes";
import { date2time } from "../lang/time";
import "./Clock.css";

const cx = 100, cy = 100;

let center;

const
    minuteHandColor = {
        name: "minuteHand",
        x1: cx, x2: cx, y1: cy + 5.5, y2: 15,
        id: "minutehandcolor"
    },
    minuteHandShadow = {
        name: "minuteHand",
        x1: cx, x2: cx, y1: cy + 5.5, y2: 15,
        id: "minutehandshadow"
    },
    minuteHand = {
        name: "minuteHand",
        x1: cx, x2: cx, y1: cy + 5.5, y2: 15,
        id: "minutehand"
    },
    secondHand = {
        name: "secondHand",
        x1: cx, x2: cx, y1: cy + 11, y2: 2,
        id: "secondhand"
    },
    hourHand = {
        name: "hourHand",
        x1: cx, x2: cx, y1: cy, y2: 52,
        id: "hourhand"
    };

function Hand(props) {
    //console.log(props.time);

    //console.log("hand");

    //const ref = useRef();

    //const [events, setEvents] = useState();

    let line = <line ref={props.id}
                     draggable="true"
        {...props.type} transform={getTransform(props.time)[props.type.name]}
        //{...props.events}
    />;

    useEffect(()=>{
        if (props.events && props.time && props.id) {
            // console.log("assign events");
            Object.keys(props.events).forEach(key => {
                props.id.current.addEventListener(
                    key.toLowerCase().substring(2),
                    (e)=>{ props.events[key](e, props.time, props.setTime, props.setStarted, props.faceRef) }
                );
            });
        }
    }, []);

    return props.time ? line : null;
}

const Face = React.memo((props) => {

    //console.log("face");

    let marks;

    if (props.marks) {

        marks = [];
        const markMinuteLength = 5, markHourLength = 7;

        for (let i = 1; i <= 60; i++) {
            var mark = {
                x1: cx, y1: 1,
                x2: cx,
                transform: "rotate(" + (i * 360 / 60) + " " + cx + " " + cy + ")"
            };
            if (i % 5 == 0) {
                mark.y2 = markHourLength;
                mark.className = "mark hour";
            } else {
                mark.y2 = markMinuteLength;
                mark.className = "mark minute";
            }

            marks.push(mark);

        }

    }
    //console.log(marks);

    return (
        <>
            <circle ref={props.id} r="100" cx="100" cy="100" className="face" />
            { marks ? <g>{ marks.map((mark, i) => (<line key={i} {...mark} />))}</g> : null }
        </>
    )
}, ()=>true );

function getTransform(time) {
    return {
        secondHand: `rotate(${time.ss * 6 + time.ms * 0.006} ${cx} ${cy})`,
        minuteHand: `rotate(${time.mm * 6 + time.ss / 10} ${cx} ${cy})`,
        hourHand: `rotate(${(time.hh>12?time.hh-12:time.hh) * 30 + time.mm / 2} ${cx} ${cy})`
    }
}

function getAngle(point, center) {
    let quarter;
    if (point.x < center.x && point.y < center.y) quarter = 1;
    if (point.x >= center.x && point.y < center.y) quarter = 2;
    if (point.x >= center.x && point.y >= center.y) quarter = 3;
    if (point.x < center.x && point.y >= center.y) quarter = 4;
    //console.log(quarter);

    let alpha = Math.atan( Math.abs(point.y-center.y) / Math.abs(point.x-center.x) );

    if (quarter === 1) alpha = alpha + 3 * Math.PI / 2;
    if (quarter === 2) alpha = Math.PI / 2 - alpha;
    if (quarter === 3) alpha = alpha + Math.PI / 2;
    if (quarter === 4) alpha = 3 * Math.PI / 2 - alpha;

    return rad2deg(alpha);
}

function getTime(angle, handId, time) {

    let hours, hh12, dh, minutes, rem, newTime = time; // JSON.parse(JSON.stringify(time));

    if (handId === "minutehand") {
        minutes = angle / 6;
        if (newTime.mm > 45 && minutes < 15) {
            newTime.hh = newTime.hh === 23 ? 0 : newTime.hh + 1;
        }
        if (newTime.mm < 15 && minutes > 45) {
            newTime.hh = newTime.hh === 0 ? 23 : newTime.hh - 1;
        }
        newTime.mm = Math.floor(minutes);
    }
    if (handId === "hourhand") {
        hours = angle / 30;
        //console.log("hours:", hours.round(2));
        hh12 = newTime.hh > 12 ? newTime.hh - 12 : newTime.hh;
        if (hh12 === 0) hh12 = 12;
        if (hh12 >= 9 && hours <= 3) {
            dh = 12 - hh12 + hours;
        } else {
            dh = hours - hh12;
        }
        newTime.hh += Math.floor(dh);
        rem = dh % 1;
        if (newTime.hh < 0) newTime.hh += 24;
        if (newTime.hh > 23) newTime.hh -= 24;

        newTime.mm = Math.floor((rem < 0 ? 1 + rem : rem) * 60);
    }

    return newTime;
}

let intervalId;

let finger = null;

let hand = null;

let offset;

function start(setTime) {
    //console.log("start fired");
    intervalId = setInterval(()=>{
        setTime(()=>date2time(new Date()));
    }, 200);
    //console.log(intervalId);
    //setStarted(true);
}

function stop(setStarted) {
    //console.log("stop fired");
    //console.log(intervalId);
    clearInterval(intervalId);
    setStarted(false);
}

//console.log("clock.js");

function dummy() {
    console.log("dummy");
}

function releaseHand() {
    console.log("release");
    hand = null;
    document.documentElement.removeEventListener("mouseup", releaseHand);
    document.documentElement.removeEventListener("mousemove", mouseMove);
    //document.documentElement.addEventListener("mousemove", dummy);
    //document.documentElement.removeEventListener("mousemove", dummy);
}

let mouseMove;

function moveHand(event, setTime) {
    if (hand !== null) {
        event.preventDefault();
        const angle = getAngle({ x: event.clientX - offset.x, y: event.clientY - offset.y }, center);
        setTime(prevTime => {
            return {...prevTime, ...getTime(angle, hand.id, prevTime)}
        });
    } else {
        console.log("mouse move");
    }
}

const handEvents = {
    onMouseDown: (event, time, setTime, setStarted, faceRef) => {
        //console.log("mouse down");
        //console.log("Clock: handEvents: faceRef:", faceRef);
        if (!faceRef.current) { console.log("Clock: handEvents: mouse down: no face element"); return; }
        const face = faceRef.current.getBoundingClientRect();
        //console.log("Clock: handEvents mouse down: face clientRect:", face);
        offset = { x: face.left, y: face.top };
        //const face = faceRef.current.getBoundingClientRect();
        //console.log("Clock: handEvents mouse down: face clientRect:", face);
        //const offset = { x: face.left, y: face.top };
        hand = event.currentTarget;
        stop(setStarted);
        setTime(p=>{
            //console.log(`Clock stopped from mouse at ${p.hh}:${p.mm}:${p.ss}`);
            return {...p, ss: 0, ms: 0}
        });
        document.documentElement.addEventListener("mouseup", releaseHand);
        mouseMove = (e) => { moveHand(e, setTime) };
        document.documentElement.addEventListener("mousemove", mouseMove );
    },
    onTouchStart: (event, time, setTime, setStarted, faceRef) => {
        //event.persist();
        console.log(event);
        event.preventDefault();
        console.log("touchStart");
        const hand = event.currentTarget;
        console.log(hand.id);
        const touch = event.changedTouches[0];
        console.log("finger:", touch.identifier);
        console.log("prev finger:", finger);
        if (finger !== null) if (touch.identifier !== finger) {
            console.log("it is another finger, ignore it");
            return;
        }
        console.log(hand.id);
        if (!faceRef.current) { console.log("Clock: handEvents: mouse down: no face element"); return; }
        const face = faceRef.current.getBoundingClientRect();
        //console.log("Clock: handEvents mouse down: face clientRect:", face);
        offset = { x: face.left, y: face.top };
        stop(setStarted);
        setTime(p=>{
           // console.log(`Clock stopped from finger at ${p.hh}:${p.mm}:${p.ss}`);
            return {...p, ss: 0, ms: 0}
        });
        finger = touch.identifier;
    },
    onTouchEnd: (event, time, setTime) => {
        event.preventDefault();
        console.log("touchEnd");
        const touch = event.changedTouches[0];
        console.log("finger:", touch.identifier);
        if (finger !== null) if (touch.identifier !== finger) {
            console.log("it is another finger, ignore it");
            return;
        }
        finger = null;
    },
    onTouchMove: (event, time, setTime, setStarted, faceRef) => {
        event.preventDefault();
        const touch = event.changedTouches[0];
        const hand = event.currentTarget;
        //console.log("finger:", touch.identifier);
        if (finger !== null) if (touch.identifier !== finger) {
            console.log("it is another finger, ignore it");
            return;
        }
        const angle = getAngle({ x: touch.clientX - offset.x, y: touch.clientY - offset.y }, center);
        setTime(prevTime => {
            return { ...prevTime, ...getTime(angle, hand.id, prevTime) }
        });
    },
    onContextMenu: event => {
        //event.persist();
        absorbEvent(event);
    }
};

export const PlayPause = React.memo((props) => {
    //console.log("playpause");
    return (
        <svg
            className="touchObject button" viewBox="0 0 100 100"
            width={props.width} height={props.height}
            onClick={props.onClick}>

            <circle className="playPause" cx="50" cy="50" r="50" />
            {
                props.started ?
                    <g>
                        <rect x="32.89" y="34.07" width="13.87" height="31.87" />
                        <rect x="53.23" y="34.07" width="13.87" height="31.87" />
                    </g> :
                    <polygon points="73.35 49.99 38.32 29.76 38.32 70.21 73.35 49.99" />
            }

        </svg>
    )
}, (prev, next) => prev.started === next.started );

export function Clock(props) {

    //console.log("hello");

    const {time, setTime, started} = props;
    
    const setStarted = props.start;

    const minuteRef = useRef(), hourRef = useRef(), faceRef = useRef();

    useEffect(()=>{
        //console.log("use effect");
        center = { x: props.width / 2, y: props.height / 2 };

        if (faceRef.current) {
            const face = faceRef.current.getBoundingClientRect();
            //console.log("Clock: handEvents mouse down: face clientRect:", face);
            offset = { x: face.left, y: face.top };
        }

        return ()=>{ stop(setStarted) }
    }, []);

    useEffect(()=>{
        //console.log("Clock: started changed:", started);
        if (started) {
            start(setTime);
        } else {
            stop(setStarted)
        }
    }, [started]);

    return (
        <svg className={`touchObject${props.className?" "+props.className:""}`} viewBox="0 0 200 200" width={props.width} height={props.height}>
            <Face id={faceRef} />
            <Hand type={secondHand} time={time} started={started} />
            <Hand
                type={hourHand} time={time} events={handEvents} id={hourRef}
                setTime={setTime} setStarted={setStarted} faceRef={faceRef} />
            <Hand type={minuteHandShadow} time={time} />
            <Hand type={minuteHandColor} time={time} />
            <Hand type={minuteHand} time={time} events={handEvents} id={minuteRef}
                  setTime={setTime} setStarted={setStarted} faceRef={faceRef} />
        </svg>
    );

}
