import React from "react";

function Donut({ totalQuestions, totalErrors, totalCorrect }) {

    const totalPercent = totalCorrect / totalQuestions * 100;

    const totalErrorPercent = totalErrors / totalQuestions * 100;

    const percentage = Math.round(totalPercent) + "%";


    return(

        <svg viewBox="0 0 42 42" className="donut">
            <circle cx="21" cy="21" r="15.9155" className="hole" />
            <circle cx="21" cy="21" r="15.9155" className="ring" />
            <circle cx="21" cy="21" r="15.9155" className="segment correct"
                    style={{ strokeDasharray: totalPercent + " " + (100-totalPercent) }} />
            <circle cx="21" cy="21" r="15.9155" className="segment error"
                    style={{
                strokeDashoffset: (50-totalPercent).toString(),
                strokeDasharray: totalErrorPercent + " " + (100-totalErrorPercent) }}/>
            <text x="50%" y="50%" className="percentage">{percentage}</text>
        </svg>

    );

}

export default Donut;
