import React from "react";
import { objectsEqual } from "../../../prototypes";
import { check, deleteAnswers } from "./logic";
import { ButtonConfirm } from "../../Button";
import { labels } from "../../../labels";
import { parsed2html, parseChain } from "../../../lang/parse";
import DataContext from "../DataContext";
import Donut from "../Donut";
import { numerals } from "./Lesson";

function parsednum(n, key, data, box) {
    return (
        <>
        { n > 0 && <span className="number">{n}&nbsp;</span>}
        {
        parsed2html(
            { data, box }, parseChain(data, {lv: {
                default: (numerals.cas
                    .find(num => num.count.includes(n))||numerals.default)[key]
            }})
        )
        }
        </>
    )
}

function Stats({ totalAnswered, totalQuestions, totalCorrect, totalErrors }) {

    const { data, box } = React.useContext(DataContext);

    if (totalAnswered === 0) {
        return (
            <div className="stats noanswers">{
                parsednum(totalQuestions, "questions", data, box)
            }</div>
        )
    }

    const stats = [totalQuestions, totalAnswered, totalCorrect, totalErrors];
    const keys = ["questions", "answers", "correct", "error"];

    return <div className="stats">{ stats.map((n, i) => (
        <div key={i} className={[keys[i]]}>{
                parsednum(n, keys[i], data, box)
        }</div>

    )) }</div>
}

const Results = React.memo(({ state, dispatch }) => {

    // console.log("Results render, state.length:", state.length);

    /* if (state.some(answer => !answer.hasOwnProperty("correct"))) {
        console.log("correct answers missing");
    } else {
        check(state, dispatch);
    } */

    // console.log(state);

    const totalQuestions = state.length;
    const totalCorrect = state.filter(a => a.result === true).length;
    const totalErrors = state.filter(a => a.result === false).length;
    const totalAnswered = totalCorrect + totalErrors;

    // console.log(state);
    // console.log(totalQuestions, totalCorrect, totalErrors, totalAnswered);

    return(
        <div className="results">

            <h2>{ labels.lesson.result.lv }</h2>            

            <Donut {...{ totalQuestions, totalCorrect, totalErrors }} />

            <Stats {...{ totalAnswered, totalQuestions, totalCorrect, totalErrors }} />

            { totalAnswered > 0 &&
            <div className="buttons">
                
                { state.some(answer => answer.giveup) ?
                <div>
                    <button
                        onClick={()=>{ dispatch({ type: "reset" }) }}
                        title={labels.ui.buttonReturn[window.secLang]}>{labels.ui.buttonReturn.lv}</button>
                </div>
                :
                <div>
                    <ButtonConfirm {...{
                                reset: state,
                                handleClick: ()=>{
                                    dispatch({ type: "clearall" });
                                },
                                disabled: ()=>{
                                    //totalAnswered===0
                                    return false;
                                },
                                label: labels.ui.buttonClearAnswers,
                                className: "reset"
                            }} />
                </div>
                }
                { !state.some(answer => answer.giveup) &&
                <div>
                    <ButtonConfirm {...{
                        reset: state,
                        handleClick: ()=> { dispatch({ type: "giveup" }) },
                        disabled: ()=> {
                            //console.log("ButtonConfirm: props.giveUp", props.giveUp);
                            // return totalErrors===0||props.giveUp;
    
                            return false;
                        },
                        label: {lv:"Сдаюсь", ru:null},
                        labelYes: {lv:"Сдаетесь?", ru:null},
                        labelNo: {lv:"Нет", ru:null},
                        className: "giveUp"
                        }} />
                </div>
                }

            </div>
            }


        </div>
    )
}, (prev, next) => {
    return objectsEqual(prev.state, next.state)
});

export default Results;
