import React from 'react';
import Content from "../components/Content";
import { transform, lemma2str } from "../lang/lang2";
import { alternation } from "../lang/alternation";
import "./Check.css";
import "../prototypes";

function rootForms(rootStr, altNum) {
    const key = altNum.toString();
    const alt = alternation.find(a => a.hasOwnProperty(key));
    return (alt && alt.forms.map(f => rootStr.replace(alt[key], f)).join("–")) || "???";
}

function Check(props) {

    const { morphs, words } = props.data;

    //console.log(parseInt("ad"));

    const verbs =
        words
            .filter(word =>
                word.rule.toString().substr(0, 2)==="11" &&
                word.morphs.find(m => m.origin.type==="root").origin.hasOwnProperty("alt")
            )
            .map(verb=>{ return {
                ...verb,
                alt: verb.morphs.find(m => m.origin.type==="root").origin.alt,
                root: verb.morphs.find(m => m.origin.type==="root").origin.nick
            } })
            .sort((a, b) => a.alt-b.alt)
            .uniqueByKey("root");

    console.log(verbs[11]);

    const verbsNot1WithAltRoot = words
        .filter(
            word => word.rule.toString()[0] === "1" && word.rule.toString()[1] !== "1" &&
            word.morphs.find(m => m.origin.type==="root").origin.hasOwnProperty("alt")
        ).map(verb=>{ return {
            ...verb,
            alt: verb.morphs.find(m => m.origin.type==="root").origin.alt,
            root: verb.morphs.find(m => m.origin.type==="root").origin.nick,
            rootStr: verb.morphs.find(m => m.origin.type==="root").origin.morph
        } })
        .sort((a, b) => a.alt-b.alt)
        .uniqueByKey("root");

    console.log(verbsNot1WithAltRoot.length);

    return (
        <Content className="check">
            <h2>Darbības vārdi, 1. grupa</h2>
            <table className="words">
                <tbody>
                { verbs.map((word, i) => (
                    <React.Fragment key={i}>
                        { i===0 ?
                            (<tr><th colSpan="2">&nbsp;</th><th className="light" colSpan="2">tagadne</th></tr>):null}
                        { (i) % 25 === 0 ?
                            (<tr><th>&nbsp;</th><th>nenoteiksme</th><th className="light">tu</th><th className="light">viņš</th><th>divdabis vīr.</th><th>divdabis siev.</th><th className="light">pavēles</th></tr>) : null }
                        <tr key={word.id}>
                            <td className="number">{i+1}.</td>
                            <td key={"lemma"+word.id}>{lemma2str(word.lemma)}</td>
                            <td className="light" key={"p2"+word.id}>{lemma2str(transform(morphs, word, { tense: 1, person: 2 }))}</td>
                            <td className="light" key={"p3"+word.id}>{lemma2str(transform(morphs, word, { tense: 1, person: 3 }))}</td>
                            <td key={"t3m"+word.id}>{lemma2str(transform(morphs, word, { tense: 3, gender: 0, count: 0 }))}</td>
                            <td key={"t3f"+word.id}>{lemma2str(transform(morphs, word, { tense: 3, gender: 1, count: 0 }))}</td>
                            <td className="light" key={"t4"+word.id}>{lemma2str(transform(morphs, word, { tense: 4, person: 5 }))}</td>
                        </tr>
                    </React.Fragment>
                )) }
                </tbody>
            </table>
            <h2>2. un 3. grupas darbības vārdi, kuriem ir maināma sakne</h2>
            <table className="words">
                <tbody>
                { verbsNot1WithAltRoot.map((word, i) => (
                    <React.Fragment key={i}>
                        { (i) % 20 === 0 ?
                            (<tr>
                                <th>&nbsp;</th><th>nenoteiksme</th>
                                <th colSpan="2" className="light">tu</th>
                                <th colSpan="2" className="light">viņš</th>
                            </tr>) : null }
                        <tr key={word.id}>
                            <td className="number">{i+1}.</td>
                            <td key={"lemma"+word.id}>{lemma2str(word.lemma)}</td>
                            <td className="light" key={"p02"+word.id}>{lemma2str(transform(morphs, word, { tense: 0, person: 2 }))}</td>
                            <td className="light" key={"p12"+word.id}>{lemma2str(transform(morphs, word, { tense: 1, person: 2 }))}</td>
                            <td className="light" key={"p03"+word.id}>{lemma2str(transform(morphs, word, { tense: 0, person: 3 }))}</td>
                            <td className="light" key={"p13"+word.id}>{lemma2str(transform(morphs, word, { tense: 1, person: 3 }))}</td>
                            <td key={"roo"+word.id}>{rootForms(word.rootStr, word.alt)}</td>
                        </tr>
                    </React.Fragment>
                )) }
                </tbody>
            </table>
        </Content>
    )

}

export default Check;