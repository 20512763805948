
export const labels = {
    "cas": [
        { "lv": "nominatīvs[lv]", "ru": "именительный" },
        { "lv": "ģenitīvs[lv]", "ru": "родительный" },
        { "lv": "datīvs[lv]", "ru": "дательный" },
        { "lv": "akuzatīvs[lv]", "ru": "винительный" },
        { "lv": "instrumentālis[lv]", "ru": "творительный" },
        { "lv": "lokatīvs[lv]", "ru": "местный" },
        { "lv": "vokatīvs[lv]", "ru": "звательный" }
    ],
    "cases": { "lv": "locījumi", "ru": "падежи" },
    "casQuestionShort": [
        { "lv": "kas?", "ru": "кто/что?" },
        { "lv": "kā?", "ru": "кого/чего?" },
        { "lv": "kam?", "ru": "кому/чему?" },
        { "lv": "ko?", "ru": "кого/что?" },
        { "lv": "ar ko?", "ru": "кем/чем?" },
        { "lv": "kur?", "ru": "где/когда?" },
        { "lv": "kas!", "ru": "кто!" }
    ],
    "tense": [
        { "lv": "pagātne", "ru": "прошлое" },
        { "lv": "tagadne", "ru": "настоящее" },
        { "lv": "nākotne", "ru": "будущее" },
        { "lv": "saliktais[iv:salikts] laiks[lv]", "ru": "составное время" }
    ],
    "rection": {
        "lv": "pārvaldījums", "ru": "управление",
        "prepositions": { "lv": "pārvaldījums", "ru": "управление" }
    },
    "person": [
        { "lv": "", "ru": "", "person": 0 },
        { "lv": "es", "ru": "я", "person": 1, "gender": [0, 1] },
        { "lv": "tu", "ru": "ты", "person": 2, "gender": [0, 1] },
        { "lv": "viņš", "ru": "он", "person": 3, "gender": 0 },
        { "lv": "viņa", "ru": "она", "person": 3, "gender": 1 },
        { "lv": "mēs", "ru": "мы", "person": 4, "gender": [0, 1] },
        { "lv": "jūs", "ru": "вы", "person": 5, "gender": [0, 1] },
        { "lv": "viņi", "ru": "они", "person": 6, "gender": 0 },
        { "lv": "viņas", "ru": "они", "person": 6, "gender": 1 }
    ],
    "count": [
        { "lv": "vienskaitlis[lv]", "ru": "единственное число" },
        { "lv": "daudzskaitlis[lv]", "ru": "множественное число" }
    ],
    "transformations": {
        "declension": {"lv": "locīšana[lv]", "ru": "склонение"},
        "conjugation": {"lv": "konjugācija[lv]", "ru": "спряжение"},
        "tenses": {
            "simple": { "lv": "vienkāršie[iv:vienkāršs] laiki[lv:laiks]", "ru": "простое время" },
            "compound": { "lv": "saliktie[iv:saliktais] laiki[lv:laiks]", "ru": "составное время" }
        },
        "mood": {"lv": "izteiksme[lv]", "ru": "наклонение"},
        "moods": {
            "imperative": {"lv": "pavēles[lv] izteiksme[lv]", "ru": "повелительное наклонение"},
            "relative": {"lv": "atstāstījuma[lv] izteiksme[lv]"},
            "conditional": {"lv": "vēlējuma[lv] izteiksme[lv]"},
            "debitive": {"lv": "vajadzības[lv] izteiksme[lv]", "ru": "долженствовательное наклонение"}
        }
    },
    "adjectives": {
        "definite": {
            "lv": "ar noteiktajām[iv:noteikts] galotnēm[lv:galotne]", "ru": "с определенными окончаниями"
        },
        "indefinite": {
            "lv": "ar nenoteiktajām[iv:noteikts] galotnēm[lv:galotne]", "ru": "с неопределенными окончаниями"
        },
        "comparative": {
            "lv": "pārākā pakāpe", "ru": "сравнительная степень"
        },
        "superlative": {
            "lv": "vispārākā pakāpe", "ru": "превосходная степень"
        }
    },
    "nouns": {
        "groups": {
            1: { "lv": "I grupa", "ru": "I группа" },
            2: { "lv": "II grupa", "ru": "II группа" },
            3: { "lv": "III grupa", "ru": "III группа" },
            4: { "lv": "IV grupa", "ru": "IV группа" },
            5: { "lv": "V grupa", "ru": "V группа" },
            6: { "lv": "VI grupa", "ru": "VI группа" },
            7: { "lv": "Atgriezeniskie", "ru": "возвратные" },
            "lv": "grupas", "ru": "группы"
        }
    },
    "verbs": {
        "groups": {
            1: {
                "lv": "I konjugācija un nekārtnie", "ru": "I спряжение и неправильные"
            },
            2: {
                "lv": "II konjugācija", "ru": "II спряжение"
            },
            3: {
                "lv": "III konjugācija", "ru": "III спряжение"
            }
        },
        "questions": [
            { "tense": 0, "person": [
                { },
                { "lv": "ko darīju", "ru": "что делал" },
                { "lv": "ko darīji", "ru": "что делал" },
                { "lv": "ko darīja", "ru": "что делал" },
                { "lv": "ko darījām", "ru": "что делали" },
                { "lv": "ko darījāt", "ru": "что делали" },
                { "lv": "ko darīja", "ru": "что делали" }
            ] },
            { "tense": 1, "person": [
                { "lv": "ko darīt", "ru": "что делать" },
                { "lv": "ko daru", "ru": "что делаю" },
                { "lv": "ko dari", "ru": "что делаешь" },
                { "lv": "ko dara", "ru": "что делает" },
                { "lv": "ko darām", "ru": "что делаем" },
                { "lv": "ko darāt", "ru": "что делаете" },
                { "lv": "ko dara", "ru": "что делают" }
            ] },
            { "tense": 2, "person": [
                { },
                { "lv": "ko darīšu", "ru": "что буду делать" },
                { "lv": "ko darīsi", "ru": "что будешь делать" },
                { "lv": "ko darīs", "ru": "что будет делать" },
                { "lv": "ko darīsim", "ru": "что будем делать" },
                { "lv": "ko darīsiet", "ru": "что будете делать" },
                { "lv": "ko darīs", "ru": "что будут делать" }
            ] }
        ]
    },
    "ui": {
        "goToPage": { "lv": "pāriet uz lapu", "ru": "перейти на страницу" },
        "goToWordPage": { "lv": "pāriet uz vārda lapu", "ru": "перейти на страницу слова" },
        "goToChainPage": { "lv": "pāriet uz izteiciena lapu", "ru": "перейти на страницу фразы" },
        "buttonCheck": { "lv": "Pārbaudīt", "ru": "проверить" },
        "buttonClearAnswers": { "lv": "Izdzēst atbildes", "ru": "очистить ответы" },
        "buttonSure": { "lv": "Tiešām?", "ru": "точно?" },
        "buttonNo": { "lv": "Nē", "ru": "нет" },
        "buttonYes": { "lv": "Jā", "ru": "да" },
        "buttonGiveUp": { "lv": "Padodos", "ru": "сдаюсь" },
        "buttonGiveUpSure": { "lv": "Padodaties?", "ru": "сдаетесь?" },
        "buttonReturn": { "lv": "Pavērst atpakaļ", "ru": "вернуть назад" },
        "buttonPrev": { "lv": "Iepriekšējās dienas", "ru": "предыдущие дни" },
        "buttonNext": { "lv": "Nākamās dienas", "ru": "следующие дни" },
        "linkDiary": { "lv": "Dienasgrāmata", "ru": "дневник" },
        "linkArchive": { "lv": "Arhīvs", "ru": "архив" },
        "search": { "lv": "Meklēšana", "ru": "поиск" },
        "correct": { "lv": "pareizi", "ru": "правильно" },
        "incorrect": { "lv": "nepareizi", "ru": "неправильно" },
    },
    "trains": {
        "round": [
            {
                
            }
        ]
    },
    "lesson": {
        "result": { "lv": "Jūsu rezultāts", "ru": "ваш результат" }
    },
    "time": {
        "questions": {
            "which": {
                "short": { "lv": "Cik", "ru": "Который" },
                "full": { "lv": "Cik ir pulkstenis", "ru": "Который час" }
            },
            "from": {
                "short": { "lv": "No cikiem", "ru": "С которого" },
                "full": { "lv": "No cikiem", "ru": "С которого часа" }
            },
            "until": {
                "short": { "lv": "Līdz cikiem", "ru": "До которого" },
                "full": { "lv": "Līdz cikiem", "ru": "До которого часа" }
            },
            "when": {
                "short": { "lv": "Cikos", "ru": "Во сколько" },
                "full": { "lv": "Cikos", "ru": "В котором часу" }
            }
        }
    },
    "date": {
        "questions": {
            "which": {
                "short": { "lv": "Kurš", "ru": "Какое" },
                "full": { "lv": "Kurš datums", "ru": "Какое сегодня число" }
            },
            "from": {
                "short": { "lv": "No kura", "ru": "С какого" },
                "full": { "lv": "No kura datuma", "ru": "С какого числа" }
            },
            "until": {
                "short": { "lv": "Līdz kuram", "ru": "До какого" },
                "full": { "lv": "Līdz kuram datumam", "ru": "До какого" }
            },
            "when": {
                "short": { "lv": "Kad", "ru": "Когда" },
                "full": { "lv": "Kurā datuma", "ru": "Какого числа" }
            }
        }
    }

};
