import React, { useState, useEffect, useLayoutEffect, useRef, useMemo } from "react";

function curve({ x1, y1, x2, y2 }) {

    const k = .2;

    const dx = Math.abs(x2 - x1) * k;
    const dy = Math.abs(y2 - y1) * k;

    let qx, qy;
    if (y1 <= y2 && x1 > x2) {
        // top right
        qx = x2 + dx;
        qy = y1 + dy;
    }
    if (y1 <= y2 && x1 <= x2) {
        // top left
        qx = x2 - dx;
        qy = y1 + dy;
    }
    if (y1 > y2 && x1 <= x2) {
        // bottom left - top right
        qx = x1 + dx;
        qy = y2 + dy;
    }
    if (y1 > y2 && x1 > x2) {
        // bottom right
        qx = x1 - dx;
        qy = y2 + dy;
    }

    const M = { x: x1, y: y1 };
    const Q = {
        x1: qx,
        y1: qy,
        x2,
        y2 
    };
    return {
        d: `M ${M.x} ${M.y} Q ${Q.x1} ${Q.y1} ${Q.x2} ${Q.y2}`,
        cp: { cx: Q.x1, cy: Q.y1 }
    }
}

function from(rectA, rectB) {
    let h, v;
    const cA = { x: rectA.left + (rectA.right - rectA.left) / 2, y: rectA.top + (rectA.bottom - rectA.top) / 2 };
    const cB = { x: rectB.left + (rectB.right - rectB.left) / 2, y: rectB.top + (rectB.bottom - rectB.top) / 2 };
    //console.log("center A:", cA);
    //console.log("center B:", cB);
    h = (cA.x <= cB.x) ? "left" : "right";
    v = (cA.y >= cB.y) ? "bottom" : "top";
    return `${v} ${h}`;
}

function intersects(rectA, rectB) {
    let h, v;
    v = rectA.bottom >= rectB.top && rectA.top <= rectB.bottom;
    h = rectA.right >= rectB.left && rectA.left <= rectB.right;
    return v && h ? "both" : v ? "vertical" : h ? "horizontal" : "none";
}

const vmid = rect => rect.bottom - (rect.bottom-rect.top) / 2;
const hmid = rect => rect.right - (rect.right-rect.left) / 2;

const getWidth = () => window.innerWidth
|| document.documentElement.clientWidth
|| document.body.clientWidth;

const getHeight = () => window.innerHeight
|| document.documentElement.clientHeight
|| document.body.clientHeight;

function useWindowSize() {
    // save current window width in the state object
    let [size, setSize] = useState({ width: getWidth(), height: getHeight() });
    useEffect(() => {
        let timeoutId = null;
        const resizeListener = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                setSize({ width: getWidth(), height: getHeight() })
            }, 150);
        };
        window.addEventListener('resize', resizeListener);
        return () => {
            clearTimeout(timeoutId);
            window.removeEventListener('resize', resizeListener);
        }
    }, []);
    return size;
}

function Connector({ start, finish, directionFrom, id, style, classList }) {

    if (!start || !finish) return null;

    //console.log(start, finish);
    //console.log(style);

    const strokeWidth = style ? (style.strokeWidth || 2) : 2;

    //console.log(strokeWidth);

    const
        left = Math.min(start.x, finish.x),
        top = Math.min(start.y, finish.y),
        width = Math.abs(start.x - finish.x),
        height = Math.abs(start.y - finish.y);

    //console.log(left, top, width, height);

    //console.log(directionFrom);

    let connection;

    const minX = 0, minY = 0,
        maxX = Math.round(width + strokeWidth * 2),
        maxY = Math.round(height + strokeWidth * 2);

    switch (directionFrom) {
        case "top left": {
            connection = { x1: minX, y1: minY, x2: maxX, y2: maxY };
            break;
        }
        case "bottom left": {
            connection = { x1: minX, y1: maxY, x2: maxX, y2: minY };
            break;
        }
        case "top right": {
            connection = { x1: maxX, y1: minY, x2: minX, y2: maxY };
            break;
        }
        case "bottom right": {
            connection = { x1: maxX, y1: maxY, x2: minX, y2: minY }
        }
    }

    const px = n => Math.round(n);

    const svgStyle = {
        position: "absolute",
        left: px(left),
        top: px(top),
        width: px(width + 6),
        height: px(height + 6)
    };

    const pathStyle = {
        strokeLinecap: "round", stroke: "black", fill: "transparent", ...style
    };

    const c = curve(connection);

    const viewBox = `-${strokeWidth/2} -${strokeWidth/2} ${svgStyle.width + strokeWidth} ${svgStyle.height + strokeWidth}`;
    //const viewBox = `0 0 ${width} ${height}`;

    return (
        <svg className={`connector ${classList.join(" ")}`} viewBox={viewBox} style={svgStyle} ref={id} preserveAspectRatio="none">
            <path vectorEffect="non-scaling-stroke"
                  style={pathStyle}
                  d={c.d}
            />
        </svg>
    )

}

function getConnectorPosition(refA, refB) {

    //console.log("refB:", refB);

    const brickA = refA.current;
    const brickB = refB.current;

    //console.log("brick B:", brickB);

    const rectA = brickA.getBoundingClientRect();
    const rectB = brickB.getBoundingClientRect();

    //console.log("rect B:", rectB);

    const directionFrom = from(rectA, rectB);
    //console.log(brickA, directionFrom);

    const intersection = intersects(rectA, rectB);
    //console.log(`intersects ${intersection}`);

    const positions = [
        {
            directionFrom: "top left",
            intersection: "none",
            start: {x: rectA.right, y: vmid(rectA)},
            finish: {x: rectB.left, y: rectB.top}
        },
        {
            directionFrom: "bottom left",
            intersection: "none",
            start: {x: rectA.right, y: rectA.top},
            finish: {x: rectB.left, y: vmid(rectB)}
        },
        {
            directionFrom: "top right",
            intersection: "none",
            start: {x: rectA.left, y: vmid(rectA)},
            finish: {x: rectB.right, y: rectB.top}
        },
        {
            directionFrom: "bottom right",
            intersection: "none",
            start: {x: rectA.left, y: rectA.top},
            finish: {x: rectB.right, y: vmid(rectB)}
        },
        {
            directionFrom: ["top left", "bottom left"],
            intersection: "vertical",
            start: {x: rectA.right, y: vmid(rectA)},
            finish: {x: rectB.left, y: vmid(rectB)}
        },
        {
            directionFrom: ["top right", "bottom right"],
            intersection: "vertical",
            start: {x: rectA.left, y: vmid(rectA)},
            finish: {x: rectB.right, y: vmid(rectB)}
        },
        {
            directionFrom: ["top left", "top right"],
            intersection: "horizontal",
            start: {x: hmid(rectA), y: rectA.bottom},
            finish: {x: hmid(rectB), y: rectB.top}
        },
        {
            directionFrom: ["bottom left", "bottom right"],
            intersection: "horizontal",
            start: {x: hmid(rectA), y: rectA.top},
            finish: {x: hmid(rectB), y: rectB.bottom}
        }
    ];

    const position = positions
        .find(p =>
            (Array.isArray(p.directionFrom) ?
                p.directionFrom.includes(directionFrom) :
            p.directionFrom === directionFrom) &&
            p.intersection === intersection
        );

    return position ? {
        start: {
            x: position.start.x + window.scrollX,
            y: position.start.y + window.scrollY
        },
        finish: {
            x: position.finish.x + window.scrollX,
            y: position.finish.y + window.scrollY
        },
        directionFrom
    } : false;
}

export function Connect({ refA, refB, redrawTrigger, style, classList }) {
    
    /*const Dot = ({ center, type, id }) => {

        const style = {
            A: { fill: "blue", fillOpacity: "0.5" },
            B: { fill: "red", fillOpacity: "0.5" }
        };

        return (
            <svg ref={id} style={{ position: "absolute", left: center.x, top: center.y, width: 6, height: 6 }}>
                <circle r="3" cx="3" cy="3" {...style[type]} />
            </svg>
        )
    };*/

    //console.log("Connect render");

    const refConnector = useRef();

    const windowSize = useWindowSize();

    /*const position = useMemo(()=>{
        if (refA.current && refB.current) {
            console.log("----------------------------------");
            console.log("redraw trigger:", redrawTrigger);
            const newPos = getConnectorPosition(refA, refB);
            console.log(newPos);
            return newPos;
        } else {
            return false
        }
    }, [windowSize, refA, refB, redrawTrigger]);*/

    const [position, setPosition] = useState();

    useLayoutEffect(()=>{
        if (refA.current && refB.current) {
            //console.log("----------------------------------");
            //console.log("redraw trigger:", redrawTrigger);
            const newPos = getConnectorPosition(refA, refB);
            //console.log(newPos);
            setPosition(newPos);
        }
        return ()=>{ setPosition(null) }
    }, [windowSize, refA, refB, redrawTrigger]);


    /*useEffect(()=>{
        if (position) {
            if (refDotA.current && refDotB.current) {
                const dotA = refDotA.current;
                const dotB = refDotB.current;
                dotA.style.left = (Math.round(position.start.x) - 3) + "px";
                dotA.style.top = (Math.round(position.start.y) - 3) + "px";
                dotB.style.left = (Math.round(position.finish.x) - 3) + "px";
                dotB.style.top = (Math.round(position.finish.y) - 3) + "px";
            }
        }
    }, [position]);*/

    const q = refA && refB ? refA.current && refB.current ? true : false: false;

    return (
        q ? <Connector classList={classList} {...position} id={refConnector} style={style} /> : null
    )
}
