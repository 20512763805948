import React, { useRef } from 'react';
import { removePunctuation, removeEntities } from "../search/seekers";
import "./Footer.css";

export function Google(props) {

    const googleQuery =
        "https://www.google.com/search?q=" +
        removePunctuation(removeEntities(props.lemma)).replace(/\s/g, "+");

    //console.log(googleQuery);

    return (
        <a className="google" target="_blank" rel="noopener noreferrer" href={googleQuery}>
            <div className="mainLink">Paguglēt šo frāzi</div>
            <div className="trans">Погуглить эту фразу</div>
        </a>
    )
}

export function CopyUrl({ solo, url }) {

    const clipboardRef = useRef();

    function copyToClipboard() {
        clipboardRef.current.select();
        document.execCommand("copy");
    }

    return (
        <div className="clipboard">
        <textarea readOnly ref={clipboardRef} value={url||decodeURI(window.location.href)} />
        <button onClick={copyToClipboard}>
            <div className="mainLink">{url||decodeURI(window.location.href)}</div>
            { !solo && <><div>Kopēt skaistu saiti bez %C4%81%AB</div>
            <div className="trans">Скопировать красивую ссылку без %C4%81%AB</div></> }
        </button>
        </div>
    )
}

export function Footer(props) {
    return <div className="footer">{props.children}</div>
}
