import React from 'react';
import "../css/Sidebar.css";

function Sidebar(props) {

    //console.log("Sidebar render");
    //console.log(props);

    return (
        <div className="sidebar">
            {props.children}
        </div>
    )
}

export default Sidebar;
