import React, { useMemo } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import Page from "../../pages/m/Page";
import Nav from "./Nav";

import "../../scss/mobile/ui.scss";
import { CopyUrl } from "../Footer";


const Routes = () => {

    // const { section } = useParams();

    const { pathname } = useLocation();

    // const routeMatch = useRouteMatch();

    // console.log("section:", section);
    // const location = useLocation();
    // console.log("location:", location);
    // console.log("routeMatch:", routeMatch);

    // const url = decodeURI(window.location.href);
    const url = `${window.location.protocol}//${window.location.host}${window.baseName}${pathname.substr(1)}`;

    return (
        <Switch>
            {/* <Route exact path="/">
                <Redirect to="/gramatika" />
            </Route> */}
            <Route exact path="/">
                <Nav />
                <CopyUrl solo />
            </Route>
            {/* <Route path="/vingrināt">
                <Nav>
                    <Page />
                </Nav>
                <CopyUrl solo />
            </Route> */}
            <Route path={`/:section`}>
                <Nav>
                    <Page />
                </Nav>
                <CopyUrl solo url={url} />
            </Route>
        </Switch>
    )


}

export default Routes;
