import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

import "../../../scss/desktop/Nav2.scss";
import { findChild } from "./logic";
import { Radio } from "../ui";
import { entities2unicode } from "../../../lang/parse";
import { objectsEqual } from "../../../prototypes";

const Filter = ({ filter, setFilter }) => {
    return (
        <div className="filter">
            <Radio labelAll="все уровни" {...{state: filter, setState: setFilter }} />
        </div>
    )
};

const checkFilter = (item, filter) => {
    const filterState = filter.find(f => f.label === item.abc);
    if (filterState) return filterState.checked;
    return null;
}

const Opener = ({ open }) => {
    const transform = open ? "rotate(90,50,50) translate(-10)" : null;
    return (
        <div className="opener">
            <svg viewBox="0 0 100 100">
                <polygon points="89 50, 30 84, 30 16" transform={transform} />
            </svg>
        </div>
    )
};

const Item = ({ item, header, open, location, handleClick, filter }) => {

    if (filter) {
        if (item.hasOwnProperty("abc")) {
            // console.log(`${item.title.lv} filter:`, filter.map(f=>`${f.label}: ${f.checked}`));
            // console.log(filter);
            // console.log(item.abc);
            if (checkFilter(item, filter) === false && item.href !== location.pathname) {
                // console.log("is it opened?", item.href === location.pathname);
                return null
            };
        }
    }
    const classList = ["item"];

    if (header) classList.push("header");

    if (item.href === location.pathname) classList.push("active");

    const props = { className: classList.join(" ") };

    if (handleClick) props.onClick = handleClick;

    const link = item.href && item.href !== location.pathname && item.default;

    const Wrap = link ?
        ({children}) => <Link className="title" to={item.href}>{children}</Link> :
        ({children}) => <div className="title">{children}</div>;

    return (
        <>
        <div {...props}>
            { header && handleClick && <Opener {...{ open }} /> }
            <Wrap>
                <div>
                    { `${entities2unicode(item.title.lv)} ` }
                    { filter && item.hasOwnProperty("abc") && <div className="abc">{ item.abc }</div> }
                </div>
                { item.title[window.secLang] && <div className="trans">{entities2unicode(item.title[window.secLang])}</div> }
            </Wrap>
        </div>
        </>
    )
}

const toggleActiveAndOpen = (item, location, classList) => {
    
    const add = (arr, val) => !arr.includes(val) ? [...arr, val] : arr;
    const remove = (arr, val) => arr.includes(val) ? arr.filter(e=>e!==val) : arr;

    if (location.pathname.indexOf(item.href)===0 || findChild(item.menu, child=>child.href===location.pathname, "menu")) {
        return add(add(classList, "open"), "active");
    }
    return remove(classList, "active")
};

const Menu = ({ item, location, level, filter, setFilter }) => {

    // if (initialFilter) console.log(`${item.title.lv} initial filter:`, initialFilter.map(f=>`${f.label}: ${f.checked}`));

    const initialClassList = toggleActiveAndOpen(item, location, ["menu", `level${level}`]);

    if (!item.menu.some(item => item.hasOwnProperty("menu"))) initialClassList.push("nosublevel");

    const [classList, setClassList] = useState(initialClassList);

    useEffect(()=>{
        setClassList(prev => {
            return toggleActiveAndOpen(item, location, prev);
        });
    }, [location]);

    const everyItemInMenuIsOff = (item.menu.every(item => {
        if (item.hasOwnProperty("abc")) {
            if (checkFilter(item, filter) === false && item.href !== location.pathname) {
                return true
            };
        }
        return false;
    }));

    if (everyItemInMenuIsOff) return null;

    // const [filter, setFilter] = useState( globalFilter || (item.abcs||[]).map(f => ({ label: f, checked: true })) );

    const handleClick = () => {
        setClassList(prev => {
            // console.log(item.href);
            // console.log(item.default);
            // console.log(location.pathname);
            /* if (item.href && item.href !== location.pathname && item.default) {
                return !prev.includes("open") ? [...prev, "open"] : prev;
            } */
            const res = prev.includes("open") ? prev.filter(c=>c!=="open") : [...prev, "open"];
            // console.log(res);
            return res;
        });
    }

    return (
        <div className={classList.join(" ")}>
            <Item {...{ item, location, open: classList.includes("open") }} header handleClick={handleClick} />
            { filter.length > 1 && level === 1 && <Filter {...{ filter, setFilter }} /> }
            <div className="contents">
                <Nav menu={item.menu} location={location} level={level} filter={filter} />
            </div>
        </div>
    )
    
}

const Nav = ({ menu, location, level=0, filter, setFilter }) => {

    if (Array.isArray(menu)) {
        return menu.map((item, i) => (
            <Nav key={i} menu={item} {...{ location, level, filter, setFilter }} />
        ));
    } else {
        if (level === 0) {
            // create top level filter first
            return <NavFilter {...{ menu, location }} />
        } 
        if (menu.hasOwnProperty("menu")) {
            return <Menu item={menu} location={location} level={level + 1} filter={filter} setFilter={setFilter} />
        }
        return <Item item={menu} location={location} filter={filter} />
    }
    
}

const NavFilter = ({ menu, location }) => {

    const [filter, setFilter] = useState((menu.abcs||[]).map(f => ({ label: f, checked: true })));

    if (menu.hasOwnProperty("menu")) {
        return <Menu item={menu} location={location} level={1} filter={filter} setFilter={setFilter} />
    }
    return <Item item={menu} location={location} filter={filter} />

}

const HomeLink = ({ location }) => {
    const home = <><div>Sākums</div><div className="trans">начало</div></>;
    return (
        location.pathname === "/" ? <div className="home active">{home}</div> : <div className="home">
            <Link to="/">{home}</Link>
        </div>
    )
};

const Index = React.memo(({ nav }) => {

    const location = useLocation();

    // console.log(nav);

    useEffect(()=>{

        const item = findChild(nav, obj => obj.href === location.pathname, "menu");
        if (item) {
            document.title = item.title.lv;
        }

    }, [nav, location]);

    return (
        <div style={{ position: "relative", flexGrow: 1 }}>
            <SimpleBar style={{ position: "absolute", top: 0, left: 0, bottom: 0, width: "100%", zIndex: "1" }}>
                <div className="nav">
                    <HomeLink location={location} />
                    <Nav menu={nav} location={location} />
                </div>
            </SimpleBar>
        </div>
    )
}, (prev, next) => objectsEqual(prev.nav, next.nav));

export default Index;
