import React from "react";
import Content from "../components/Content";
import { rules } from "../lang/lang2";

import "./Statistika.css";

const types = {
    lv: "lietvārdu",
    dv: "darbības vārdu",
    iv: "īpašības vārdu",
    av: "apstākļa vārdu",
    sv: "skaitļa vārdu",
    pv: "prievārdu"
};

const Statistika = ({ data }) => {
    const { morphs, words, chains } = data;

    // console.log(data);

    return (
        <Content className="stats">
            <h1>Statistika</h1>
            <table>
                <tbody>
                    <tr className="subtitle">
                        <td>Vārdu pēc vārdšķirām:</td>
                        <td></td>
                    </tr>
                    {
                        Object.entries(types).map(([key, val], i) => (
                            <tr key={i}>
                                <td>{ val }:</td>
                                <td>{ words.filter(w => rules[key].includes(w.rule)).length }</td>
                            </tr>
                        ))
                    }
                    <tr className="subtotal">
                        <td>Vārdu kopā:</td>
                        <td>{words.length}</td>
                    </tr>
                    <tr className="subtotal">
                        <td>Vārdu savienojumu:</td>
                        <td>{chains.length}</td>
                    </tr>
                    <tr className="total">
                        <td>Šķirkļu kopā:</td>
                        <td>{words.length + chains.length}</td>
                    </tr>

                </tbody>
            </table>
        </Content>
    );
};

export default Statistika;
