import React from "react";
import { Switch, useRouteMatch, Route } from "react-router-dom";
import Content from "../../components/mobile/Content";


const Page = () => {

    // const params = useParams();

    const routeMatch = useRouteMatch();

    // const location = useLocation();

    // console.log(params);
    // console.log(routeMatch);
    // console.log(location);

    return (
        <Switch>
            <Route exact path={routeMatch.url}>
                <Content />
            </Route>
            <Route path={`${routeMatch.url}/:section`}>
                <Page />
            </Route>
        </Switch>
    )
}

export default Page;
