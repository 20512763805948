import React, { useState, useEffect } from "react";
import { objectsEqual, toggleClass } from "../../../prototypes";
import { parsed2html } from "../../../lang/parse";
import DataContext from "../DataContext";
import { removeResultClassNames, addResultClassNames } from "./qcomponents";
import { correctAnswers } from "../lesson/logic";
import { inputSizer } from "../../Input";

let timeoutId, dispatched = false;

const Answer = ({ state }) => {

    const answers = correctAnswers("qtext", state.correct);

    return (
        <div className="answer">{ answers[0].answer }</div>
    )
};

const QText = React.memo(({
    id, exerciseId, state, dispatch, questions, timeout, size, nobox, nolink, icon
}) => {

    const reservedWidth = icon === undefined ? 0 : 24;

    let { box } = React.useContext(DataContext);

    if (nobox) box = false;

    // console.log("QText rest of props:", props);

    // console.log("=== QText: ===");
    // console.log("id:", id);
    // console.log("exerciseId:", exerciseId);
    // console.log("lessonId:", lessonId);

    // console.log("QText render", id);
    // console.log(state);

    // console.log("QText timeout", timeout);

    /* if (questions) {
        console.log("QText questions:", questions[0]);
        console.log("parsed questions:", parsed2html({ box }, questions[0]));
    } */

    // const value = state.given||"";

    const [classList, setClassList] = useState(["qtext"]);

    const [value, setValue] = useState(state.given||"");

    const [resIcon, setResIcon] = useState("");


    useEffect(()=>{
        // console.log("useEffect [state.result]");
        // console.log("qtext result:", state.result);
        if (state.result === true || state.result === false) {
            addResultClassNames(setClassList, state.result);
            setResIcon(state.result ? "\uD83D\uDC4D" : "\uD83D\uDC4E");
        }
        if (state.result === null) {
            removeResultClassNames(setClassList);
            setResIcon("");
        }
        // console.log("qtext result:", state.result);
    }, [state.result, state.flush]);

    useEffect(()=>{
        if (state.given === false) {
            // if (value.trim() !== "") {
                // console.log("QText: state.given was set to false while the value was:", value);
                removeResultClassNames(setClassList);
                setResIcon("");
                setValue("");                
            // }
        }
    }, [state.given]);

    useEffect(()=>{
        if (state.giveup === true) {
            setClassList(prev => toggleClass("add", { classList: prev, className: "giveup" }));
            // console.log(correctAnswers("qtext", state.correct));
        } else {
            setClassList(prev => toggleClass("remove", { classList: prev, className: "giveup" }));
        }
    }, [state.giveup]);

    function handleChange(e) {
        const input = e.currentTarget;
        // console.log("onchange");
        const newValue = input.value;
        // console.log(value, newValue);
        const prevClassList = [...classList];
        clearTimeout(timeoutId);
        setClassList(prev => {
            let res = toggleClass("remove", { classList: prev, className: "error" });
            res = toggleClass("remove", { classList: res, className: "correct" });
            return res;
        });
        setValue(newValue);
        inputSizer(input, reservedWidth);
        //console.log(newValue);
        dispatched = false;
        if (newValue.trim() === "") {
            // dispatch({ [answerId]: { type: "qtext", value: "", exercise: id } });
            dispatch({ type: "give", id, exerciseId, payload: "" });
            dispatched = true;
        } else {
            timeoutId = setTimeout(
                ()=>{
                    dispatch({ type: "give", id, exerciseId, payload: newValue.trim() });
                    dispatched = true;
                    // if check==after restore classlist
                    if (timeout > 0) setClassList(prevClassList);
                },
                timeout
            );
        }
    }

    function handleBlur(e) {
        //console.log("blur");
        if (!dispatched) {
            clearTimeout(timeoutId);
            if (timeout > 0) {
                if (value.trim() !== "") addResultClassNames(setClassList, state.result);
            }
            dispatch({ type: "give", id, exerciseId, payload: e.currentTarget.value.trim() });
            // dispatch({ [answerId]: { type: "qtext", value: e.currentTarget.value, exercise: id } });
            dispatched = true;
        }
    }


    useEffect(()=>{
        return ()=>{ clearTimeout(timeoutId) }
    }, []);

    /* const classList = ["qtext"];
    if (state.hasOwnProperty("result")) {
        if (state.result === true) classList.push("correct");
        if (state.result === false) classList.push("error");
    } */

    return (
        <div className={classList.join(" ")}>

            <input disabled={state.giveup} autoComplete="off" spellCheck="false" value={value} onChange={handleChange} onBlur={handleBlur}
                size={size||"12"} name={id} />
            { icon !== undefined && <div className="resIcon">{resIcon}</div>}
            <div className="question">{
                questions ?
                parsed2html({ box, nolink }, questions[0]) : "\u00A0"
            }</div>

            { state.giveup && <Answer state={state} />}

        </div>
    )

}, (prev, next) => {
    // console.log("qtext prev.state:", prev.state);
    // console.log("qtext next.state:", next.state);
    // return true;
    // console.log(`QText ${next.id} states equal?`, objectsEqual(prev.state, next.state));
    return objectsEqual(prev.state, next.state);
});

export default QText;
