import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function Copyright0(props) {

    return (
        <div className="copyright">&copy; LVIAP 2020</div>
    )
}

export function Copyright() {

    return (
        <div className="copyright">&copy; 2020&nbsp;&nbsp;&nbsp;&nbsp;<br/>V. Larsone, R. Voroņežskis</div>
    )
}

export function WhereIsEverything() {

    // const location = useLocation();

    const year = (new Date()).getFullYear();

    return (
        <div className="copyright">
            &copy; {year}. Radošais kolektīvs<br />
            {/* <Link to="/autortiesības">Informācija par autortiesībām</Link><br /> */}
            <a href="mailto:pasts@runajiet.lv?Subject=mājas%20lapa">pasts@runajiet.lv</a>
        </div>
    )
}

function WhereIsEverything0() {

    const location = useLocation();

    return (
        location.pathname === "/" ?
        <div className="gone">
            <header>
                <div>Kur viss pazuda</div>
                <div className="trans">Куда все пропало</div>
            </header>
            <div className="there">
                <div className="trans">Сайт LVIAP находится по&nbsp;адресу:</div>
                <div>LVIAP vietne atrodas adresē:</div>
            </div>
            <div><a href="https://www.lviap.lv/">lviap.lv</a></div>
        </div> : null
    )
}
