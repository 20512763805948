
import { soft, alt, verb11, verb13, voc21 } from "./lang2";

export const transformations = [
    {
        "rule": [702, 7001],
        "transformations": [
            // NUMERALS APPLIED TO PLURAL
            { "param": { "cas": 0, "count": 1, "gender": 0, "applied": true, "definite": false }, "flex": "i" },
            { "param": { "cas": 1, "count": 1, "gender": 0, "applied": true, "definite": false }, "flex": "u" },
            { "param": { "cas": [2, 4], "count": 1, "gender": 0, "applied": true, "definite": false }, "flex": "iem" },
            { "param": { "cas": 3, "count": 1, "gender": 0, "applied": true, "definite": false }, "flex": "us" },
            { "param": { "cas": 5, "count": 1, "gender": 0, "applied": true, "definite": false }, "flex": "os" },
            { "param": { "cas": 0, "count": 1, "gender": 1, "applied": true, "definite": false }, "flex": "as" },
            { "param": { "cas": 1, "count": 1, "gender": 1, "applied": true, "definite": false }, "flex": "u" },
            { "param": { "cas": [2, 4], "count": 1, "gender": 1, "applied": true, "definite": false }, "flex": "ām" },
            { "param": { "cas": 3, "count": 1, "gender": 1, "applied": true, "definite": false }, "flex": "as" },
            { "param": { "cas": 5, "count": 1, "gender": 1, "applied": true, "definite": false }, "flex": "ās" },

            // NUMERALS DEFINITE APPLIED TO PLURAL
            { "param": { "cas": [0, 6], "count": 1, "gender": 0, "applied": true, "definite": true }, "flex": "ie" },
            { "param": { "cas": 1, "count": 1, "gender": 0, "applied": true, "definite": true }, "flex": "o" },
            { "param": { "cas": [2, 4], "count": 1, "gender": 0, "applied": true, "definite": true }, "flex": "ajiem" },
            { "param": { "cas": 3, "count": 1, "gender": 0, "applied": true, "definite": true }, "flex": "os" },
            { "param": { "cas": 5, "count": 1, "gender": 0, "applied": true, "definite": true }, "flex": "ajos" },
            { "param": { "cas": [0, 6], "count": 1, "gender": 1, "applied": true, "definite": true }, "flex": "ās" },
            { "param": { "cas": 1, "count": 1, "gender": 1, "applied": true, "definite": true }, "flex": "o" },
            { "param": { "cas": [2, 4], "count": 1, "gender": 1, "applied": true, "definite": true }, "flex": "ajām" },
            { "param": { "cas": 3, "count": 1, "gender": 1, "applied": true, "definite": true }, "flex": "ās" },
            { "param": { "cas": 5, "count": 1, "gender": 1, "applied": true, "definite": true }, "flex": "ajās" }
        ]
    },
    {
        "rule": 7011, // applied miljons, miljards, simts, desmits
        "transformations": [
            { "param": { "cas": 0, "count": 1, "gender": [0, 1], "applied": true  }, "flex": "s" },
            { "param": { "cas": 1, "count": 1, "gender": [0, 1], "applied": true }, "flex": "a" },
            { "param": { "cas": 2, "count": 1, "gender": [0, 1], "applied": true  }, "flex": "am" },
            { "param": { "cas": [3, 4], "count": 1, "gender": [0, 1], "applied": true }, "flex": "u" },
            { "param": { "cas": 5, "count": 1, "gender": [0, 1], "applied": true}, "flex": "ā" }
        ]
    },
    {
        "rule": 7012, // applied tūkstotis
        "transformations": [
            { "param": { "cas": 0, "count": 1, "gender": [0, 1], "applied": true }, "flex": "is" },
            { "param": { "cas": 1, "count": 1, "gender": [0, 1], "applied": true }, "stem": soft, "flex": "a" },
            { "param": { "cas": 2, "count": 1, "gender": [0, 1], "applied": true }, "flex": "im" },
            { "param": { "cas": [3, 4, 6], "count": 1, "gender": [0, 1], "applied": true }, "flex": "i" },
            { "param": { "cas": 5, "count": 1, "gender": [0, 1], "applied": true }, "flex": "ī" }
        ]
    },
    {
        "rule": 7015, // applied nulle
        "transformations": [
            { "param": { "cas": [0, 6], "count": 1, "gender": [0, 1], "applied": true, "definite": [true, false] }, "flex": "e" },
            { "param": { "cas": 1, "count": 1, "gender": [0, 1], "applied": true, "definite": [true, false] }, "flex": "es" },
            { "param": { "cas": 2, "count": 1, "gender": [0, 1], "applied": true, "definite": [true, false] }, "flex": "ei" },
            { "param": { "cas": [3, 4], "count": 1, "gender": [0, 1], "applied": true, "definite": [true, false] }, "flex": "i" },
            { "param": { "cas": 5, "count": 1, "gender": [0, 1], "applied": true, "definite": [true, false] }, "flex": "ē" }
        ]
    },
    {
        "rule": 7001,
        "transformations": [
            // NUMERALS APPLIED TO SINGULAR
            { "param": { "cas": 0, "count": 0, "gender": 0, "applied": true, "definite": false }, "flex": "s" },
            { "param": { "cas": 1, "count": 0, "gender": 0, "applied": true, "definite": false }, "flex": "a" },
            { "param": { "cas": 2, "count": 0, "gender": 0, "applied": true, "definite": false }, "flex": "am" },
            { "param": { "cas": [3, 4], "count": 0, "gender": 0, "applied": true, "definite": false }, "flex": "u" },
            { "param": { "cas": 5, "count": 0, "gender": 0, "definite": false }, "flex": "ā" },
            { "param": { "cas": 0, "count": 0, "gender": 1, "applied": true, "definite": false }, "flex": "a" },
            { "param": { "cas": 1, "count": 0, "gender": 1, "applied": true, "definite": false }, "flex": "as" },
            { "param": { "cas": 2, "count": 0, "gender": 1, "applied": true, "definite": false }, "flex": "ai" },
            { "param": { "cas": [3, 4], "count": 0, "gender": 1, "applied": true, "definite": false }, "flex": "u" },
            { "param": { "cas": 5, "count": 0, "gender": 1, "applied": true, "definite": false }, "flex": "ā" },

            // NUMERALS DEFINITE APPLIED TO SINGULAR
            { "param": { "cas": [0, 6], "count": 0, "gender": 0, "definite": true }, "flex": "ais" },
            { "param": { "cas": 1, "count": 0, "gender": 0, "definite": true }, "flex": "ā" },
            { "param": { "cas": 2, "count": 0, "gender": 0, "definite": true }, "flex": "ajam" },
            { "param": { "cas": [3, 4], "count": 0, "gender": 0, "definite": true }, "flex": "o" },
            { "param": { "cas": 5, "count": 0, "gender": 0, "definite": true }, "flex": "ajā" },
            { "param": { "cas": [0, 6], "count": 0, "gender": 1, "definite": true }, "flex": "ā" },
            { "param": { "cas": 1, "count": 0, "gender": 1, "definite": true }, "flex": "ās" },
            { "param": { "cas": 2, "count": 0, "gender": 1, "definite": true }, "flex": "ajai" },
            { "param": { "cas": [3, 4], "count": 0, "gender": 1, "definite": true }, "flex": "o" },
            { "param": { "cas": 5, "count": 0, "gender": 1, "definite": true }, "flex": "ajā" }
        ]
    },
    {
        "rule": [7001, 7011],
        "transformations": [
            // STAND-ALONE NUMERALS DECLENSION I
            { "param": { "cas": 0, "count": 0, "applied": false }, "flex": "s" },
            { "param": { "cas": 1, "count": 0, "applied": false }, "flex": "a" },
            { "param": { "cas": 2, "count": 0, "applied": false }, "flex": "am" },
            { "param": { "cas": [3, 4], "count": 0, "applied": false }, "flex": "u" },
            { "param": { "cas": 5, "count": 0, "applied": false }, "flex": "ā" },
            { "param": { "cas": 6, "count": 0, "applied": false }, "flex": "s" },
            { "param": { "cas": [0, 6], "count": 1, "applied": false }, "flex": "i" },
            { "param": { "cas": 1, "count": 1, "applied": false }, "flex": "u" },
            { "param": { "cas": [2, 4], "count": 1, "applied": false }, "flex": "iem" },
            { "param": { "cas": 3, "count": 1, "applied": false }, "flex": "us" },
            { "param": { "cas": 5, "count": 1, "applied": false }, "flex": "os" }
        ]
    },
    {
        "rule": 7012,
        "transformations": [
            // STAND-ALONE NUMERALS DECLENSION II
            { "param": { "cas": 0, "count": 0, "applied": false }, "flex": "is" },
            { "param": { "cas": 1, "count": 0, "applied": false }, "stem": soft, "flex": "a" },
            { "param": { "cas": 2, "count": 0, "applied": false }, "flex": "im" },
            { "param": { "cas": [3, 4, 6], "count": 0, "applied": false }, "flex": "i" },
            { "param": { "cas": 5, "count": 0, "applied": false }, "flex": "ī" },
            { "param": { "cas": [0, 1, 2, 3, 4, 5, 6], "count": 1, "applied": false }, "stem": soft },
            { "param": { "cas": [0, 6], "count": 1, "applied": false }, "flex": "i" },
            { "param": { "cas": 1, "count": 1, "applied": false }, "flex": "u" },
            { "param": { "cas": [2, 4], "count": 1, "applied": false }, "flex": "iem" },
            { "param": { "cas": 3, "count": 1, "applied": false }, "flex": "us" },
            { "param": { "cas": 5, "count": 1, "applied": false }, "flex": "os" }
        ]
    },
    {
        "rule": 7015,
        "transformations": [
            // STAND-ALONE NUMERALS DECLENSION V
            { "param": { "cas": [0, 6], "count": 0, "applied": false }, "flex": "e" },
            { "param": { "cas": 1, "count": 0, "applied": false }, "flex": "es" },
            { "param": { "cas": 2, "count": 0, "applied": false }, "flex": "ei" },
            { "param": { "cas": [3, 4], "count": 0, "applied": false }, "flex": "i" },
            { "param": { "cas": 5, "count": 0, "applied": false }, "flex": "ē" },
            { "param": { "cas": [0, 6], "count": 1, "applied": false }, "flex": "es" },
            { "param": { "cas": 1, "count": 1, "applied": false }, "stem": soft, "flex": "u" },
            { "param": { "cas": [2, 4], "count": 1, "applied": false }, "flex": "ēm" },
            { "param": { "cas": 3, "count": 1, "applied": false }, "flex": "es" },
            { "param": { "cas": 5, "count": 1, "applied": false }, "flex": "ēs" }
        ]
    },
    {
        "rule": 71,
        "transformations": [
            { "param": { "cas": [0, 6], "count": 0, "gender": 0 }, "flex": "ais" },
            { "param": { "cas": 1, "count": 0, "gender": 0 }, "flex": "ā" },
            { "param": { "cas": 2, "count": 0, "gender": 0 }, "flex": "ajam" },
            { "param": { "cas": [3, 4], "count": 0, "gender": 0 }, "flex": "o" },
            { "param": { "cas": 5, "count": 0, "gender": 0 }, "flex": "ajā" },
            { "param": { "cas": [0, 6], "count": 1, "gender": 0 }, "flex": "ie" },
            { "param": { "cas": 1, "count": 1, "gender": 0 }, "flex": "o" },
            { "param": { "cas": [2, 4], "count": 1, "gender": 0 }, "flex": "ajiem" },
            { "param": { "cas": 3, "count": 1, "gender": 0 }, "flex": "os" },
            { "param": { "cas": 5, "count": 1, "gender": 0 }, "flex": "ajos" },

            { "param": { "cas": [0, 6], "count": 0, "gender": 1 }, "flex": "ā" },
            { "param": { "cas": 1, "count": 0, "gender": 1 }, "flex": "ās" },
            { "param": { "cas": 2, "count": 0, "gender": 1 }, "flex": "ajai" },
            { "param": { "cas": [3, 4], "count": 0, "gender": 1 }, "flex": "o" },
            { "param": { "cas": 5, "count": 0, "gender": 1 }, "flex": "ajā" },
            { "param": { "cas": [0, 6], "count": 1, "gender": 1 }, "flex": "ās" },
            { "param": { "cas": 1, "count": 1, "gender": 1 }, "flex": "o" },
            { "param": { "cas": [2, 4], "count": 1, "gender": 1 }, "flex": "ajām" },
            { "param": { "cas": 3, "count": 1, "gender": 1 }, "flex": "ās" },
            { "param": { "cas": 5, "count": 1, "gender": 1 }, "flex": "ajās" }
        ]
    },
    {
        "rule": 40,
        "transformations": [
            { "param": { "basic": true }, "flex": "i" },
            { "param": { "basic": true, "comp": 1 }, "postfix": "+āk", "flex": "" },
            { "param": { "basic": true, "comp": 2 }, "prefix": "vis+", "postfix": "+āk", "flex": "" }
        ]
    },
    {
        "rule": 41,
        "transformations": [
            { "param": { "basic": false }, "flex": "" }
        ]
    },
    {
        "rule": 42,
        "transformations": [
            { "param": { "basic": false }, "flex": "" },
            { "param": { "basic": false, "comp": 1 }, "postfix": "+āk", "flex": "" },
            { "param": { "basic": false, "comp": 2 }, "prefix": "vis+", "postfix": "+āk", "flex": "" }
        ]
    },
    {
        "rule": [3, 30],
        "transformations": [
            // ADJECTIVES DECLENSION
            { "param": { "cas": 0, "count": 0, "gender": 0, "definite": false }, "flex": "s" },
            { "param": { "cas": 1, "count": 0, "gender": 0, "definite": false }, "flex": "a" },
            { "param": { "cas": 2, "count": 0, "gender": 0, "definite": false }, "flex": "am" },
            { "param": { "cas": [3, 4], "count": 0, "gender": 0, "definite": false }, "flex": "u" },
            { "param": { "cas": 5, "count": 0, "gender": 0, "definite": false }, "flex": "ā" },
            { "param": { "cas": 0, "count": 1, "gender": 0, "definite": false }, "flex": "i" },
            { "param": { "cas": 1, "count": 1, "gender": 0, "definite": false }, "flex": "u" },
            { "param": { "cas": [2, 4], "count": 1, "gender": 0, "definite": false }, "flex": "iem" },
            { "param": { "cas": 3, "count": 1, "gender": 0, "definite": false }, "flex": "us" },
            { "param": { "cas": 5, "count": 1, "gender": 0, "definite": false }, "flex": "os" },

            { "param": { "cas": 0, "count": 0, "gender": 1, "definite": false }, "flex": "a" },
            { "param": { "cas": 1, "count": 0, "gender": 1, "definite": false }, "flex": "as" },
            { "param": { "cas": 2, "count": 0, "gender": 1, "definite": false }, "flex": "ai" },
            { "param": { "cas": [3, 4], "count": 0, "gender": 1, "definite": false }, "flex": "u" },
            { "param": { "cas": 5, "count": 0, "gender": 1, "definite": false }, "flex": "ā" },
            { "param": { "cas": 0, "count": 1, "gender": 1, "definite": false }, "flex": "as" },
            { "param": { "cas": 1, "count": 1, "gender": 1, "definite": false }, "flex": "u" },
            { "param": { "cas": [2, 4], "count": 1, "gender": 1, "definite": false }, "flex": "ām" },
            { "param": { "cas": 3, "count": 1, "gender": 1, "definite": false }, "flex": "as" },
            { "param": { "cas": 5, "count": 1, "gender": 1, "definite": false }, "flex": "ās" },
            { "param": { "comp": 1 }, "postfix": "+āk" },
            { "param": { "comp": 2 }, "prefix": "vis+", "postfix": "+āk" }
        ]
    },
    {
        "rule": [3, 31],
        "transformations": [
            // DEFINITE ADJECTIVES DECLENSION
            { "param": { "cas": [0, 6], "count": 0, "gender": 0, "definite": true }, "flex": "ais" },
            { "param": { "cas": 1, "count": 0, "gender": 0, "definite": true }, "flex": "ā" },
            { "param": { "cas": 2, "count": 0, "gender": 0, "definite": true }, "flex": "ajam" },
            { "param": { "cas": [3, 4], "count": 0, "gender": 0, "definite": true }, "flex": "o" },
            { "param": { "cas": 5, "count": 0, "gender": 0, "definite": true }, "flex": "ajā" },
            { "param": { "cas": [0, 6], "count": 1, "gender": 0, "definite": true }, "flex": "ie" },
            { "param": { "cas": 1, "count": 1, "gender": 0, "definite": true }, "flex": "o" },
            { "param": { "cas": [2, 4], "count": 1, "gender": 0, "definite": true }, "flex": "ajiem" },
            { "param": { "cas": 3, "count": 1, "gender": 0, "definite": true }, "flex": "os" },
            { "param": { "cas": 5, "count": 1, "gender": 0, "definite": true }, "flex": "ajos" },

            { "param": { "cas": [0, 6], "count": 0, "gender": 1, "definite": true }, "flex": "ā" },
            { "param": { "cas": 1, "count": 0, "gender": 1, "definite": true }, "flex": "ās" },
            { "param": { "cas": 2, "count": 0, "gender": 1, "definite": true }, "flex": "ajai" },
            { "param": { "cas": [3, 4], "count": 0, "gender": 1, "definite": true }, "flex": "o" },
            { "param": { "cas": 5, "count": 0, "gender": 1, "definite": true }, "flex": "ajā" },
            { "param": { "cas": [0, 6], "count": 1, "gender": 1, "definite": true }, "flex": "ās" },
            { "param": { "cas": 1, "count": 1, "gender": 1, "definite": true }, "flex": "o" },
            { "param": { "cas": [2, 4], "count": 1, "gender": 1, "definite": true }, "flex": "ajām" },
            { "param": { "cas": 3, "count": 1, "gender": 1, "definite": true }, "flex": "ās" },
            { "param": { "cas": 5, "count": 1, "gender": 1, "definite": true }, "flex": "ajās" }
        ]
    },
    {
        "rule": [21, 210, 211, 212],
        "transformations": [
            // DECLENSION
            { "param": { "cas": 0, "count": 0 }, "flex": "s" },
            { "param": { "cas": 1, "count": 0 }, "flex": "a" },
            { "param": { "cas": 2, "count": 0 }, "flex": "am" },
            { "param": { "cas": [3, 4], "count": 0 }, "flex": "u" },
            { "param": { "cas": 5, "count": 0 }, "flex": "ā" },
            { "param": { "cas": 6, "count": 0 }, "flex": voc21 },
            { "param": { "cas": [0, 6], "count": 1 }, "flex": "i" },
            { "param": { "cas": 1, "count": 1 }, "flex": "u" },
            { "param": { "cas": [2, 4], "count": 1 }, "flex": "iem" },
            { "param": { "cas": 3, "count": 1 }, "flex": "us" },
            { "param": { "cas": 5, "count": 1 }, "flex": "os" }
        ]
    },
    {
        "rule": [22, 220, 221, 222],
        "transformations": [
            // DECLENSION
            { "param": { "cas": 0, "count": 0 }, "flex": "is" },
            { "param": { "cas": 1, "count": 0 }, "stem": soft, "flex": "a" },
            { "param": { "cas": 2, "count": 0 }, "flex": "im" },
            { "param": { "cas": [3, 4, 6], "count": 0 }, "flex": "i" },
            { "param": { "cas": 5, "count": 0 }, "flex": "ī" },
            { "param": { "cas": [0, 1, 2, 3, 4, 5, 6], "count": 1 }, "stem": soft },
            { "param": { "cas": [0, 6], "count": 1 }, "flex": "i" },
            { "param": { "cas": 1, "count": 1 }, "flex": "u" },
            { "param": { "cas": [2, 4], "count": 1 }, "flex": "iem" },
            { "param": { "cas": 3, "count": 1 }, "flex": "us" },
            { "param": { "cas": 5, "count": 1 }, "flex": "os" }
        ]
    },
    {
        "rule": [23, 230, 231, 232],
        "transformations": [
            // DECLENSION
            { "param": { "cas": [0, 1], "count": 0 }, "flex": "us" },
            { "param": { "cas": 2, "count": 0 }, "flex": "um" },
            { "param": { "cas": [3, 4, 6], "count": 0 }, "flex": "u" },
            { "param": { "cas": 5, "count": 0 }, "flex": "ū" },
            { "param": { "cas": [0, 6], "count": 1 }, "flex": "i" },
            { "param": { "cas": 1, "count": 1 }, "flex": "u" },
            { "param": { "cas": [2, 4], "count": 1 }, "flex": "iem" },
            { "param": { "cas": 3, "count": 1 }, "flex": "us" },
            { "param": { "cas": 5, "count": 1 }, "flex": "os" }
        ]
    },
    {
        "rule": [24, 240, 241, 242],
        "transformations": [
            // DECLENSION
            { "param": { "cas": [0, 6], "count": 0 }, "flex": "a" },
            { "param": { "cas": 1, "count": 0 }, "flex": "as" },
            { "param": { "cas": 2, "count": 0 }, "flex": "ai" },
            { "param": { "cas": [3, 4], "count": 0 }, "flex": "u" },
            { "param": { "cas": 5, "count": 0 }, "flex": "ā" },
            { "param": { "cas": [0, 6], "count": 1 }, "flex": "as" },
            { "param": { "cas": 1, "count": 1 }, "flex": "u" },
            { "param": { "cas": [2, 4], "count": 1 }, "flex": "ām" },
            { "param": { "cas": 3, "count": 1 }, "flex": "as" },
            { "param": { "cas": 5, "count": 1 }, "flex": "ās" }
        ]
    },
    {
        "rule": [25, 250, 251, 252],
        "transformations": [
            // DECLENSION
            { "param": { "cas": [0, 6], "count": 0 }, "flex": "e" },
            { "param": { "cas": 1, "count": 0 }, "flex": "es" },
            { "param": { "cas": 2, "count": 0 }, "flex": "ei" },
            { "param": { "cas": [3, 4], "count": 0 }, "flex": "i" },
            { "param": { "cas": 5, "count": 0 }, "flex": "ē" },
            { "param": { "cas": [0, 6], "count": 1 }, "flex": "es" },
            { "param": { "cas": 1, "count": 1 }, "stem": soft, "flex": "u" },
            { "param": { "cas": [2, 4], "count": 1 }, "flex": "ēm" },
            { "param": { "cas": 3, "count": 1 }, "flex": "es" },
            { "param": { "cas": 5, "count": 1 }, "flex": "ēs" }
        ]
    },
    {
        "rule": [26, 260, 261, 262],
        "transformations": [
            // DECLENSION
            { "param": { "cas": [0, 6], "count": 0 }, "flex": "s" },
            { "param": { "cas": 1, "count": 0 }, "flex": "s" },
            { "param": { "cas": 2, "count": 0 }, "flex": "ij" },
            { "param": { "cas": [3, 4], "count": 0 }, "flex": "i" },
            { "param": { "cas": 5, "count": 0 }, "flex": "ī" },
            { "param": { "cas": [0, 6], "count": 1 }, "flex": "is" },
            { "param": { "cas": 1, "count": 1 }, "stem": soft, "flex": "u" },
            { "param": { "cas": [2, 4], "count": 1 }, "flex": "īm" },
            { "param": { "cas": 3, "count": 1 }, "flex": "is" },
            { "param": { "cas": 5, "count": 1 }, "flex": "īs" }
        ]
    },
    {
        "rule": [27, 270, 271, 272],
        "transformations": [
            // DECLENSION
            { "param": { "cas": 0, "count": [0, 1] }, "flex": "ās" },
            { "param": { "cas": 1, "count": 0 }, "flex": "ās" },
            { "param": { "cas": 1, "count": 1 }, "flex": "os" },
            { "param": { "cas": [3, 4], "count": 0 }, "flex": "os" },
            { "param": { "cas": 3, "count": 1 }, "flex": "ās" }
        ]
    },
    {
        "rule": [11, 12, 13],
        "transformations": [
            // INFINITIVE
            { "param": { "person": 0, "tense": 1 }, "flex": "t" }
        ]
    },
    {
        "rule": [111, 121, 131],
        "transformations": [
            // INFINITIVE
            { "param": { "person": 0, "tense": 1 }, "flex": ["t", "ies"] }
        ]
    },
    {
        "rule": [11, 111, 12, 121, 13, 131],
        "transformations": [
            { "param": { "person": [1, 2, 3, 4, 5, 6], "tense": [0, 1, 2] }, "root": alt },
            { "param": { "tense": [3, 4, 5] }, "root": alt }
        ]
    },
    {
        "rule": 11,
        "transformations": [
            { "param": { "person": 1, "tense": [0, 1] }, "flex": "u" },
            { "param": { "person": 1, "tense": 2 }, "flex": ["š", "u"] },
            { "param": { "person": 2, "tense": 0 }, "flex": "i" },
            { "param": { "person": 2, "tense": [1, 4] }, "root": verb11, "flex": verb11 },
            { "param": { "person": 2, "tense": 2 }, "flex": ["s", "i"] },
            { "param": { "person": [3, 6], "tense": 0 }, "flex": "a" },
            
            { "param": { "person": [3, 6], "tense": 1 }, "flex": "" },
            { "param": { "tense": 5 }, "flex": "" },
            
            { "param": { "person": [3, 6], "tense": 2 }, "flex": ["s", ""] },
            { "param": { "person": 4, "tense": 0 }, "flex": "ām" },
            { "param": { "person": 4, "tense": 1 }, "flex": "am" },
            { "param": { "person": 4, "tense": 2 }, "flex": ["s", "im"] },
            { "param": { "person": 5, "tense": 0 }, "flex": "āt" },
            { "param": { "person": 5, "tense": 1 }, "flex": "at" },
            { "param": { "person": 5, "tense": 2 }, "flex": ["s", "iet"] }
        ]
    },
    {
        "rule": 111,
        "transformations": [
            { "param": { "person": 1, "tense": [0, 1] }, "flex": "os" },
            { "param": { "person": 1, "tense": 2 }, "flex": ["š", "os"] },
            { "param": { "person": 2, "tense": 0 }, "flex": "ies" },
            { "param": { "person": 2, "tense": [1, 4] }, "root": verb11, "flex": "ies" },
            { "param": { "person": 2, "tense": 2 }, "flex": ["s", "ies"] },
            { "param": { "person": [3, 6], "tense": 0 }, "flex": "ās" },
            
            { "param": { "person": [3, 6], "tense": 1 }, "flex": "as" },
            { "param": { "tense": 5 }, "flex": "as" },
            
            { "param": { "person": [3, 6], "tense": 2 }, "flex": ["s", "ies"] },
            { "param": { "person": 4, "tense": 0 }, "flex": ["ām", "ies"] },
            { "param": { "person": 4, "tense": 1 }, "flex": ["am", "ies"] },
            { "param": { "person": 4, "tense": 2 }, "flex": ["s", "im", "ies"] },
            { "param": { "person": 5, "tense": 0 }, "flex": ["āt", "ies"] },
            { "param": { "person": 5, "tense": 1 }, "flex": ["at", "ies"] },
            { "param": { "person": 5, "tense": 2 }, "flex": ["s", "iet", "ies"] }
        ]
    },
    {
        "rule": 12,
        "transformations": [
            { "param": { "person": 1, "tense": [0, 1] }, "flex": ["j", "u"] },
            { "param": { "person": 1, "tense": 2 }, "flex": ["š", "u"] },
            { "param": { "person": 2, "tense": 0 }, "flex": ["j", "i"] },
            { "param": { "person": 2, "tense": [1, 4] }, "flex": "" },
            { "param": { "person": 2, "tense": 2 }, "flex": ["s", "i"] },
            { "param": { "person": [3, 6], "tense": 0 }, "flex": ["j", "a"] },
            
            { "param": { "person": [3, 6], "tense": 1 }, "flex": "" },
            { "param": { "tense": 5 }, "flex": "" },
            
            { "param": { "person": [3, 6], "tense": 2 }, "flex": "s" },
            { "param": { "person": 4, "tense": 0 }, "flex": ["j", "ām"] },
            { "param": { "person": 4, "tense": 1 }, "flex": ["j", "am"] },
            { "param": { "person": 4, "tense": 2 }, "flex": ["s", "im"] },
            { "param": { "person": 5, "tense": 0 }, "flex": ["j", "āt"] },
            { "param": { "person": 5, "tense": 1 }, "flex": ["j", "at"] },
            { "param": { "person": 5, "tense": 2 }, "flex": ["s", "iet"] }
        ]
    },
    {
        "rule": 121,
        "transformations": [
            { "param": { "person": 1, "tense": [0, 1] }, "flex": ["j", "os"] },
            { "param": { "person": 1, "tense": 2 }, "flex": ["š", "os"] },
            { "param": { "person": 2, "tense": 0 }, "flex": ["j", "ies"] },
            { "param": { "person": 2, "tense": [1, 4] }, "flex": ["j", "ies"] },
            { "param": { "person": 2, "tense": 2 }, "flex": ["s", "ies"] },
            { "param": { "person": [3, 6], "tense": 0 }, "flex": ["j", "ās"] },
            
            { "param": { "person": [3, 6], "tense": 1 }, "flex": ["j", "as"] },
            { "param": { "tense": 5 }, "flex": ["j", "as"] },
            
            { "param": { "person": [3, 6], "tense": 2 }, "flex": ["s", "ies"] },
            { "param": { "person": 4, "tense": 0 }, "flex": ["j", "ām", "ies"] },
            { "param": { "person": 4, "tense": 1 }, "flex": ["j", "am", "ies"] },
            { "param": { "person": 4, "tense": 2 }, "flex": ["s", "im", "ies"] },
            { "param": { "person": 5, "tense": 0 }, "flex": ["j", "āt", "ies"] },
            { "param": { "person": 5, "tense": 1 }, "flex": ["j", "at", "ies"] },
            { "param": { "person": 5, "tense": 2 }, "flex": ["s", "iet", "ies"] }
        ]
    },
    {
        "rule": [13, 131],
        "transformations": [
            { "param": { "person": [1, 2, 3, 4, 5, 6], "tense": 1 }, "postfix": "" },
            { "param": { "tense": 5 }, "postfix": "" }
        ]
    },
    {
        "rule": 13,
        "transformations": [
            { "param": { "person": 1, "tense": 0 }, "flex": ["j", "u"] },
            { "param": { "person": 1, "tense": 1 }, "flex": "u" },
            { "param": { "person": 1, "tense": 2 }, "flex": ["š", "u"] },
            { "param": { "person": 2, "tense": 0 }, "flex": ["j", "i"] },
            { "param": { "person": 2, "tense": 1 }, "flex": "i" },
            { "param": { "person": 2, "tense": 2 }, "flex": ["s", "i"] },
            { "param": { "person": [3, 6], "tense": 0 }, "flex": ["j", "a"] },
            
            { "param": { "person": [3, 6], "tense": 1 }, "flex": verb13 },
            { "param": { "tense": 5 }, "flex": verb13 },
            
            { "param": { "person": [3, 6], "tense": 2 }, "flex": "s" },
            { "param": { "person": 4, "tense": 0 }, "flex": ["j", "ām"] },
            { "param": { "person": 4, "tense": 1 }, "flex": verb13 },
            { "param": { "person": 4, "tense": 2 }, "flex": ["s", "im"] },
            { "param": { "person": 5, "tense": 0 }, "flex": ["j", "āt"] },
            { "param": { "person": 5, "tense": 1 }, "flex": verb13 },
            { "param": { "person": 5, "tense": 2 }, "flex": ["s", "iet"] }
        ]
    },
    {
        "rule": 131,
        "transformations": [
            { "param": { "person": 1, "tense": 0 }, "flex": ["j", "os"] },
            { "param": { "person": 1, "tense": 1 }, "flex": "os" },
            { "param": { "person": 1, "tense": 2 }, "flex": ["š", "os"] },
            { "param": { "person": 2, "tense": 0 }, "flex": ["j", "ies"] },
            { "param": { "person": 2, "tense": [1, 4] }, "flex": "ies" },
            { "param": { "person": 2, "tense": 2 }, "flex": ["s", "ies"] },
            { "param": { "person": [3, 6], "tense": 0 }, "flex": ["j", "ās"] },
            
            { "param": { "person": [3, 6], "tense": 1 }, "flex": verb13 },
            { "param": { "tense": 5 }, "flex": verb13 },
            
            { "param": { "person": [3, 6], "tense": 2 }, "flex": ["s", "ies"] },
            { "param": { "person": 4, "tense": 0 }, "flex": ["j", "ām", "ies"] },
            { "param": { "person": 4, "tense": 1 }, "flex": verb13 },
            { "param": { "person": 4, "tense": 2 }, "flex": ["s", "im", "ies"] },
            { "param": { "person": 5, "tense": 0 }, "flex": ["j", "āt", "ies"] },
            { "param": { "person": 5, "tense": 1 }, "flex": verb13 },
            { "param": { "person": 5, "tense": 2 }, "flex": ["s", "iet", "ies"] }
        ]
    },
    {
        "rule": 11,
        "transformations": [
            { "param": { "tense": 3, "gender": 0, "count": 0 }, "flex": "is" },
            { "param": { "tense": 3, "gender": 0, "count": 1 }, "flex": "uši" },
            { "param": { "tense": 3, "gender": 1, "count": 0 }, "flex": "usi" },
            { "param": { "tense": 3, "gender": 1, "count": 1 }, "flex": "ušas" }
        ]
    },
    {
        "rule": 111,
        "transformations": [
            { "param": { "tense": 3, "gender": 0, "count": 0 }, "flex": "ies" },
            { "param": { "tense": 3, "gender": 0, "count": 1 }, "flex": "ušies" },
            { "param": { "tense": 3, "gender": 1, "count": 0 }, "flex": "usies" },
            { "param": { "tense": 3, "gender": 1, "count": 1 }, "flex": "ušās" }
        ]
    },
    {
        "rule": [12, 13],
        "transformations": [
            { "param": { "tense": 3, "gender": 0, "count": 0 }, "flex": ["j", "is"] },
            { "param": { "tense": 3, "gender": 0, "count": 1 }, "flex": ["j", "uši"] },
            { "param": { "tense": 3, "gender": 1, "count": 0 }, "flex": ["j", "usi"] },
            { "param": { "tense": 3, "gender": 1, "count": 1 }, "flex": ["j", "ušas"] }
        ]
    },
    {
        "rule": [121, 131],
        "transformations": [
            { "param": { "tense": 3, "gender": 0, "count": 0 }, "flex": ["j", "ies"] },
            { "param": { "tense": 3, "gender": 0, "count": 1 }, "flex": ["j", "ušies"] },
            { "param": { "tense": 3, "gender": 1, "count": 0 }, "flex": ["j", "usies"] },
            { "param": { "tense": 3, "gender": 1, "count": 1 }, "flex": ["j", "ušās"] }
        ]
    },
    {
        "rule": 11,
        "transformations": [
            // IMPERATIVE
            { "param": { "person": 5, "tense": 4 }, "root": verb11, "flex": "iet" }
        ]
    },
    {
        "rule": 111,
        "transformations": [
            // IMPERATIVE
            { "param": { "person": 5, "tense": 4 }, "root": verb11, "flex": ["ie", "t", "ies"] }
        ]
    },
    {
        "rule": 12,
        "transformations": [
            // IMPERATIVE
            { "param": { "person": 5, "tense": 4 }, "flex": ["j", "iet"] }
        ]
    },
    {
        "rule": 121,
        "transformations": [
            // IMPERATIVE
            { "param": { "person": 5, "tense": 4 }, "flex": ["j", "ie", "t", "ies"] }
        ]
    },
    {
        "rule": [13, 131],
        "transformations": [
            // IMPERATIVE
            { "param": { "person": 5, "tense": 4 }, "postfix": "", "flex": verb13 }
        ]
    },
    {
        "rule": 6000,
        "transformaions": [
            { "param": { "basic": true } }
        ]
    }

];
