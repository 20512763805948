import React, { Component, useState } from 'react';

function Popup(props) {
    return (
        <div className='popup'>
            <h1>{props.text}</h1>
        </div>
    );
}

class Testbox extends Component {
    constructor(props) {
        super(props);
        this.state = { showPopup: false };
    }
    togglePopup() {
        this.setState({
            showPopup: true
        });
    }

    render() {
        console.log("test");
        console.log("popup");
        return (
            <div>
                <h1> Simple Popup Example In React Application </h1>
                <button onClick={this.togglePopup.bind(this)}> Click To Launch Popup</button>

                {this.state.showPopup ?
                    <Popup
                        text='Click "Close Button" to hide popup'
                    />
                    : null
                }
            </div>

        );
    }
}

function Testbox0(props) {

    const [showPopup, setShowpopup] = useState(false);

    function togglePopup() {
        setShowpopup(true);
    }

    console.log("test");
    console.log("popup");

    return (
        <div>
            <h1> Simple Popup Example In React Application </h1>
            <button onClick={togglePopup}> Click To Launch Popup</button>

            {showPopup ?
                <Popup
                    text='Click "Close Button" to hide popup'
                />
                : null
            }
        </div>

    );

}

export default Testbox;