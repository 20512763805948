import React, { useState, useEffect } from "react";
import { getType, objectsEqual, toggleClass } from "../../../prototypes";
import { icons } from "../../../i/icons";
import { removeResultClassNames, addResultClassNames } from "./qcomponents";
import { correctAnswers } from "../lesson/logic";

function Question({ question }) {

    //console.log("Question: question:", question);

    let res = [];

    question.forEach((part, i) => {

        if (getType(part) === "object") {
            res.push(part.usedForm);
        } else {
            if (/icon:[a-z]+/.test(part)) {

                const subparts = part.split(/(icon:[a-z]+)/).filter(s=>s!=="");

                //console.log("Question: subparts:", subparts);

                subparts.forEach((subpart, i) => {
                    if (/^icon:[a-z]+$/.test(subpart)) {
                        const name = /^icon:([a-z]+)$/.exec(subpart)[1];
                        //console.log("Question: icon name:", name);
                        if (icons.hasOwnProperty(name)) {
                            const Icon = icons[name];
                            res.push(<Icon key={i} width={11} height={22} />);
                        } else { res.push(`[${name}]`) }
                    } else {
                        res.push(subpart);
                    }
                });

            } else {
                res.push(part);
            }
        }

    });

    return (
        <>{res}</>
    )
}

const Radio = ({ classList, questions, checked, handleClick, state }) => {
    // console.log(`Radio: classList received:`, classList);
    return (
        <div className={classList.join(" ")}>{
            questions.map((question, i) => (
                <div className={`label${checked[i]? " checked":""}`} key={i} onClick={ (e)=> { handleClick(e, i) }}>
                    <Question key={i} question={question} />
                </div>
            ))
        }
        {state.giveup && <Answer {...{state, questions}} />}
        </div>
    )
}

const Answer = ({ state, questions }) => {

    const answers = correctAnswers("qradio", state.correct);

    // console.log(questions);

    const index = parseInt(answers[0].answer);

    // console.log("index of correct answer:", index);

    // console.log(`questions[${index}]:`, questions[index]);

    // const answer = questions[index].map(q => q.usedForm||q).join("");


    return (
        <div className="answer">{
            questions.map((question, i) => (
                <div className={`label${i===index? " checked":""}`} key={i}>
                    <Question question={question} />
                </div>
            ))
        }</div>
    )
};

const resultClass = (result) => (result === true ? "correct" : result === false ? "error" : "");

const QRadio = React.memo(({ id, exerciseId, state, dispatch, questions, ...props }) => {

    // console.log("============== QRadio render ===================")

    // console.log("QRadio additional props:", props);

    // console.log("=== QRadio: ===");
    // console.log("id:", id);
    // console.log("exerciseId:", exerciseId);
    // console.log("lessonId:", lessonId);

    const mDispatch = React.useCallback(dispatch, []);

    const radio = Array.apply(null, Array(questions.length)).map(Boolean.prototype.valueOf, false);

    // console.log("QRadio render", id);
    // console.log("QRadio state:", state);

    if (state.hasOwnProperty("given")) {
        // console.log("QRadio given answer:", state.given);
        if (state.given >-1) {
            radio[state.given] = true;
        }
    }

    const defaultClassList = ["qradio"];
    if (props) if (props.hasOwnProperty("split")) if (props.split !== false) defaultClassList.push("split");

    const [checked, setChecked] = useState(radio);

    const [classList, setClassList] = useState(
        defaultClassList
        /* (()=>{
            // console.log("useState: defaultClassList:", defaultClassList);
            return defaultClassList;
        })() */
    );

    // let reset = false;

    useEffect(()=>{
        // console.log("QRadio useEffect [state.result, state.flush, state]");
        if (state.result === true || state.result === false) {
            // console.log(`${id} has result`, state.result);
            setClassList(prev => {
                const updated = prev.filter(c => c!=="error"&&c!=="correct"&&c!=="");
                updated.push(resultClass(state.result));
                return updated;
            });
            // addResultClassNames(setClassList,  state.result);
        }
        if (state.result === null) {
            // console.log(`${id} result is null`);
            setClassList(prev => prev.filter(c => c!=="error"&&c!=="correct"&&c!==""));
            // removeResultClassNames(setClassList);
        }
    }, [state.flush, state.result]);

    useEffect(()=>{
        if (state.given === false) {
            // console.log("QRadio: state.given was externally set to false while the value was:");
            setClassList(prev => prev.filter(c => c!=="error"&&c!=="correct"&&c!==""));
            // removeResultClassNames(setClassList);
            setChecked(prev => prev.map(()=>false));
        }
    }, [state.given]);

    useEffect(()=>{
        if (state.giveup === true) {
            setClassList(prev => {
                if (!prev.includes("giveup")) {
                    const updated = [...prev];
                    updated.push("giveup");
                    return updated;
                }
                return prev;
            });
            // setClassList(prev => toggleClass("add", { classList: prev, className: "giveup" }));
            // console.log(correctAnswers("qtext", state.correct));
        } else {
            setClassList(prev => prev.filter(c => c!=="giveup"));
            // setClassList(prev => toggleClass("remove", { classList: prev, className: "giveup" }));
        }
    }, [state.giveup]);

    useEffect(()=>{
        const value = checked.findIndex(c => c===true);
        // console.log("QRadio useEffect [checked, id, mDispatch, exerciseId]");
        // removeResultClassNames(setClassList, `from ${id}`);
        // console.log("dispatch value:", value);
        // console.log("qradio give answer:");
        if (value > -1) {
            // console.log(`question id: ${id}`);
            // console.log("answer:", value);
        } else {
            setClassList(prev => prev.filter(c => c!=="error"&&c!=="correct"&&c!==""));
        }
        // console.log("calling mDispatch give");
        mDispatch({ type: "give", id, exerciseId, payload: value });
    }, [checked, id, mDispatch, exerciseId]);


    /* useEffect(()=>{
        console.log("state.given:", state.given);
    }, [state.given]); */

    const handleClick = (e, i) => {
        e.preventDefault();

        setChecked(prev => {
            const updated = [...prev];
            updated[i] = !updated[i];
            if (updated[i]) {
                for (let j=0; j<updated.length; j++) {
                    if (updated[j] && j!==i) updated[j] = false;
                }
            }
            return updated;
        });
    }

    return <Radio {...{ classList, questions, checked, handleClick, state } } />



        /* (()=>{
        // console.log("classList to Radio:", classList);
        // console.log("at this moment defaultClassList:", defaultClassList);
        // return classList;
        console.log("radio classList:", cl);
        return cl;
    })(), */
    
    

}, (prev, next) => {
    // console.log("qtext prev.state:", prev.state);
    // console.log("qtext next.state:", next.state);
    // console.log("states equal?", objectsEqual(prev.state, next.state));
    // return false;
    return objectsEqual(prev.state, next.state) && prev.exerciseId === next.exerciseId;
});

export default QRadio;
