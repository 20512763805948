import { entities2unicode, chain2str, chain2path } from "../../../lang/parse";
import { aiove } from "../../../prototypes";

const nav = (lessonsData) => {

    const defaultTopicName = "visādi";

    const topics = lessonsData.map(lesson => lesson.topic||defaultTopicName).flatten().unique().sort((a, b) => a.localeCompare(b));

    const menu = [];

    topics.forEach(topic => {
        const topicLessons = lessonsData.filter(lesson => aiove(lesson.topic||defaultTopicName, topic) );
        menu.push({
            title: { lv: topic },
            menu: topicLessons.map(lesson => {
                return {
                    title: { lv: chain2str({ lv: { default: entities2unicode(lesson.title) } }) },
                    href: chain2path({ lv: { default: lesson.title } }),
                    abc: ["A2", "B2"][[12, 22].findIndex(n => n === lesson.level)],
                    topic: lesson.topic,
                    default: true
                }
            }),
            className: "topic"
        });
    });

    return menu;
    
    /* return {
        title: entities2unicode(chain2str({lv:{default:lesson.title}})),
        page: "lesson",
        menu
    } */

};

export default nav;
