import React, { useEffect, useContext } from "react";
import Exercise from "./Exercise";
import DataContext from "../DataContext";

const Exercises = ({ exercises, state, dispatch, partNumber }) => {

    // window.sessionStorage.removeItem("qcount");

    /* const { setStatus } = useContext(DataContext);

    useEffect(()=>{
        console.log("all exercises loaded");
        setStatus("loaded");
    }, []); */


    // console.log("exercises render");
    // console.log("state:", state);

    const { lesson } = useContext(DataContext);

    const number = (exerciseNumber)=>(lesson.parts.length > 1 ?
        exercises.length > 1 ? (partNumber+1) + "." + (exerciseNumber+1) : "" :
        exercises.length > 1 ? exerciseNumber + 1 : null);

    return (
        exercises.map((exercise, i) => (
            <Exercise key={i} {...{ exercise, state, dispatch, number: number(i) }} />
        ))
    )

}

const OldExercises = ({ lessonId, part, state, dispatch, partNumber }) => {

    // window.sessionStorage.removeItem("qcount");

    /* const { setStatus } = useContext(DataContext);

    useEffect(()=>{
        console.log("all exercises loaded");
        setStatus("loaded");
    }, []); */


    // console.log("exercises render");
    // console.log("state:", state);

    return (
        part.exercises.map((exercise, exerciseNumber) => (
            <Exercise key={exerciseNumber} {...{ lessonId, exercise, part, state, dispatch, partNumber, exerciseNumber }} />
        ))
    )

}

export default Exercises;
