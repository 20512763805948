import React from "react";
import { camelCase, toggleClass } from "../../../prototypes";
import { parseChain } from "../../../lang/parse";

import QText from "./QText";
import QRadio from "./QRadio";
import QIcon from "./QIcon";
import QSVG from "./QSVG";
// import QLink from "./QLink";
import { splitAnswer } from "../lesson/logic";

export const html2node = (raw) => {

    let html = raw;

    qcomponents.forEach(qc => {
        const { tag } = qc;
        const rx = new RegExp(`<${tag}\\s[^>]+\\/>|<${tag}\\s*\\/>`);
        if (rx.test(html)) {
            const rx = new RegExp(`(<${tag}\\s[^>]+)\\/>`, "g");
            html = html.replace(rx, `$1></${tag}>`);
        }
    });

    const parser = new DOMParser();
    return [...parser.parseFromString(html.replace(/>\s+</g, "><"), "text/html").body.childNodes];
}

export function removeResultClassNames(setClassList, from) {
    setClassList(prev => {
        if (["error", "correct"].some(c => prev.includes(c))) {
            if (from) console.log("removeResultClassNames called from:", from);
            // console.log(`* * * remove class with result`, prev);
        }
        let res = toggleClass("remove", { classList: prev, className: "error" });
        res = toggleClass("remove", { classList: res, className: "correct" });
        // console.log("removeResultClassNames empty class:", res);
        return res;
    });
}
export function addResultClassNames(setClassList, result) {
    // console.log("addResultClassNames for result:", result);
    setClassList(prev => {
        const className = result === true ? "error" : "correct";
        const newClassName = result === true ? "correct" : "error";
        const res = toggleClass("replace", { classList: prev, className, newClassName });
        // console.log("addResultClassNames new class:", res);
        return res;
    });
}

export function splitState(qprops, id) {
    if (qprops.hasOwnProperty("state")) {
        if (Array.isArray(qprops.state)) {
            const splitState = qprops.state.find(state => state.id === id);
            if (splitState) {
                return {...qprops, state: splitState }
            } else {
                return {...qprops, state: {} }
            }
            
        }
    }
    return qprops;
}


export function getQuestions({node, data}) {

    // console.log(node);

    if (node.hasAttribute("q")) {

        // console.log("node has Q");

        const questionText = node.getAttribute("q").split("|");

        // console.log(questionText);

        if (node.nodeName.toLowerCase()==="qradio" && questionText.length < 2) {
            console.log("ERROR: qradio questions < 2");
            return null;
        }
        if (node.nodeName.toLowerCase()==="qtext" && questionText.length > 1) {
            console.log("ERROR: qtext questions > 1");
            return null;
        }

        /* console.log({ questions: questionText.map(text => (
            parseChain(data, {lv:{default: text}})
        ))}); */

        return { questions: questionText.map(text => (
            parseChain(data, {lv:{default: text}})
        ))};

    } else {
        return null;
    }
}

export const collectCorrectAnswers = (node, data) => {

    const answers = [];

    switch (node.nodeName.toLowerCase()) {

        case "qtext": {

            let donor = {};
            if (node.hasAttribute("q")) {
                const questions = parseChain(data, {lv: {default: node.getAttribute("q")}});
                donor = questions.find(q => q.hasOwnProperty("word")) || {};
                // console.log(`%ccollectCorrectAnswers questions, donor:`, questions, donor);
            }
            const answerTexts = node.getAttribute("a").split("|");

            const ignorance = (node.hasAttribute("ignore")) ?
                node.getAttribute("ignore").split(/\s*,\s*/) :
                ["uppercase"];

            answerTexts.forEach(answerText => {

                if (/\[[^\]]*[a-z]\*[^\]]*\]/.test(answerText)) {
                    const splittedAnswers = splitAnswer(answerText);
                    splittedAnswers.forEach(splittedAnswer => {
                        answers.push(
                            { answer: parseChain(data, {lv: {default: splittedAnswer}}, donor.word), ignore: ignorance }
                        );
                    });
                } else {
                    answers.push(
                        { answer: parseChain(data, {lv: {default: answerText}}, donor.word), ignore: ignorance }
                    );
                }
    
            });

            break;
        }

        case "qradio": {
            const answerStr = node.getAttribute("a");
            if (parseInt(answerStr).toString() === answerStr) {
                answers.push({ answer: [parseInt(answerStr)-1] });
            }
        }
    }

    return answers;

};

export function dispatchCorrectAnswers({ node, id, exerciseId, dispatch, data }) {

    const answers = collectCorrectAnswers(node, data);

    if (answers.length > 0) {
        // console.log(`dispatch correct answers for ${id}:`);
        // console.log({ type: "correct", id, payload: { answers, type: node.nodeName.toLowerCase() } });
        dispatch({ type: "correct", id, exerciseId, payload: { answers, type: node.nodeName.toLowerCase() } });
    } else {
        console.log(`no answers`);
    }

}


function handleQA({node, id, dispatch, data, box}) {
    dispatchCorrectAnswers({node, id, dispatch, data, box});
    return getQuestions({node, data});
}

const qcomponents = [
    { tag: "qtext", component: QText },
    { tag: "qradio", component: QRadio },
    { tag: "qicon", component: QIcon },
    { tag: "qsvg", component: QSVG }
    // { tag: "qlink", component: QLink }
];

export default qcomponents;