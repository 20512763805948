import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from 'react-router-dom';
import { fetchFiles } from "../io";
import Spinner from "./Spinner";
import ParsedText from "./ParsedText";
import { lemma2str } from "../lang/lang2";
import { lessonTags, parseExercise2 } from "../lang/lessons";
import { compareArrays, diffDays } from "../prototypes";
import { labels } from "../labels";

function compareDateArr({ arr, date }) {
    const originDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const arrDate = new Date(arr[0], arr[1], arr[2]);
    if (arrDate < originDate) return -1;
    if (arrDate > originDate) return 1;
    if (originDate.toDateString() === arrDate.toDateString()) return 0;
}

function diffDateArr({ arr1, arr2 }) {
    const date1 = new Date.UTC(arr1[0], arr1[1], arr1[2]);
    const date2 = new Date.UTC(arr2[0], arr2[1], arr2[2]);
    return diffDays(date1, date2);
}

export function Pager({ daysData, days, setDays, last, first }) {

    const lastIndex = daysData.findIndex(day => compareArrays(days[days.length-1].date, day.date));
    const firstIndex = daysData.findIndex(day => compareArrays(days[0].date, day.date));
    //const history = useHistory();
    //console.log("index of current last of two days:", lastIndex);
    //console.log("index of current first of two days:", firstIndex);

    let d;

    //console.log([1, 2, 3, 4, 5, 6].slice(1, 3));

    const prev = () => { if (!first) {
        d = daysData.slice( lastIndex + 1, lastIndex + 3 );
        //console.log(d.map(e=>e.date.join("/")));
        setDays(d);
    } };

    const next = () => { if (!last) {
        d = daysData.slice( (firstIndex > 2 ? firstIndex - 2 : 0), firstIndex );
        //console.log(d.map(e=>e.date.join("/")));
        setDays(d);
    } };

    return (
        <div className="pager">
            <button onClick={prev} disabled={first} title={labels.ui.buttonPrev[window.secLang]}>{labels.ui.buttonPrev.lv}</button>
            <button onClick={next} disabled={last} title={labels.ui.buttonNext[window.secLang]}>{labels.ui.buttonNext.lv}</button>
        </div>
    );
}

function Day({ day, data, box, media, blank, className }) {

    if (blank) return <div className={`theDay blank${className?" "+className:""}`}>&nbsp;</div>;

    const text = day.default.join("");

    const rx = new RegExp(`<(${lessonTags.join("|")})[\\s\\/]`);

    //console.log("Day: rx:", rx);

    //console.log(`Day: test str: ${text}: ${rx.test(text)}`);

    //console.log(data.filter(word=>word.hasOwnProperty("value");

    const date = `${day.date[2]}. ${lemma2str(data.words
        .filter(word=>word.hasOwnProperty("value"))
            .find(word=>word.value.month === day.date[1]).lemma)}`;

    //console.log("Day: date:", date);
    
    const theDay = rx.test(text) ? parseExercise2(day, { data, box, media }) : <ParsedText {...{ text, data, box }} />;

    return(
        <div className={`theDay${className?" "+className:""}`}>
            <div className="date">{date}</div>
            {theDay}
        </div>
    )
}

export function Days({ startDate, endDate, exactDays, bookmark, pager, setLastDayInDiary, data, box }) {

    //console.log("Days: render");
    const [ daysData, setDaysData ] = useState();
    const [ media, setMedia ] = useState();
    const [ days, setDays ] = useState();
    const [ statusDays, setStatusDays ] = useState({ wasLoaded: false, error: false });
    const [ statusMedia, setStatusMedia ] = useState({ wasLoaded: false, error: false });
    const history = useHistory();

    function sortDays(arrFunc){
        //console.log(arrFunc);
        const arr = arrFunc();
        //console.log(arr);
        arr.sort((a, b)=>{
            const A = new Date(a.date[0], a.date[1], a.date[2]);
            const B = new Date(b.date[0], b.date[1], b.date[2]);
            return B.getTime() - A.getTime();
        });
        if (pager && !bookmark) {
            arr.shift();
        }
        if (setLastDayInDiary) {
            // set day before the last day: arr[1]
            setLastDayInDiary(new Date(arr[1].date[0], arr[1].date[1], arr[1].date[2]));
        }
        setDaysData(() => arr);
    }

    let timeoutId;

    useMemo(()=>{
        //console.log("Days: fetch files");
        timeoutId = setTimeout(()=>{
        fetchFiles(
            "/json/v9/days/", "days1",
            { cache: "no-store" }, sortDays, setStatusDays
        );
        }, 10);
    }, []);
    useMemo(()=>{
        //console.log("Media: fetch files");
        fetchFiles(
            "/json/v9/", "media",
            { cache: "no-store" }, setMedia, setStatusMedia
        );
    }, []);

    useEffect(()=>{
        return (()=>{ clearTimeout(timeoutId) })
    },[]);

    useEffect(()=>{
        //console.log("useEffect[daysData]", daysData);
        if (daysData) {
            if (daysData.length > 0) {

                //console.log(daysData[0].date.join("/"));

                let actualBookmark;

                if (bookmark) {
                    actualBookmark = bookmark;
                    let nearestDayPast = daysData.find(day => compareDateArr({arr: day.date, date: bookmark}) <= 0);
                    if (!nearestDayPast) {
                        nearestDayPast = daysData.slice().reverse().find(day => compareDateArr({arr: day.date, date: bookmark}) > 0);
                    }
                    //console.log("day closest to bookmark:", nearestDayPast.date.join("/"));
                    if (compareDateArr({arr: nearestDayPast.date, date: bookmark}) !== 0) {
                        //console.log("is different from bookmark, replace history");
                        history.replace(`/dienasgrāmata/${nearestDayPast.date[0]}/${nearestDayPast.date[1]+1}/${nearestDayPast.date[2]}/`);
                        actualBookmark = new Date(nearestDayPast.date[0], nearestDayPast.date[1], nearestDayPast.date[2]);
                    }
                }

                setDays(()=>{
                    let filter = () => true;

                    if (pager) {
                        //const pagesCount = daysData.length;
                        //const spreadsCount = (pagesCount - pagesCount % 2) / 2 + pagesCount % 2;

                        const indexOfMay5th = daysData.findIndex(day => day.date[0]===2020 && day.date[1]===4 && day.date[2]===5);
                        //console.log(indexOfMay5th);
                        const lastIndex = 0;
                        //console.log(lastIndex);
                        //console.log("pages since 5th May 2020 including 5th May:", lastIndex - indexOfMay5th + 1);

                        let pagesCount = daysData.length;
                        if ((lastIndex - indexOfMay5th) % 2 === 0) {
                            pagesCount = 2
                        } else {
                            pagesCount = 1
                        }
                        filter = (day, index) => index < pagesCount;

                        if (actualBookmark) {
                            const dayIndex = daysData.findIndex(day => compareDateArr({arr: day.date, date: actualBookmark}) === 0);
                            //console.log("bookmark day index:", dayIndex);
                            //console.log(daysData[0].date.join("/"));
                            if ((dayIndex - indexOfMay5th) % 2 === 0) {
                                //console.log(`day ${daysData[dayIndex].date.join("/")} goes to the right`);
                                filter = (day, index) => index === dayIndex || index === dayIndex + 1;
                            } else {
                                //console.log(`day ${daysData[dayIndex].date.join("/")} goes to the left`);
                                filter = (day, index) => index === dayIndex || index === dayIndex - 1;
                            }
                        }
                        //console.log("days filtered:", daysData.filter(filter).length);
                    }

                    if (startDate && endDate) {
                        filter = (day) => {
                            return compareDateArr({ arr: day.date, date: startDate }) >= 0 &&
                                compareDateArr({ arr: day.date, date: endDate }) <= 0;
                        };
                    }
                    if (exactDays) {
                        return daysData.filter(filter).slice(0, exactDays);
                    }
                    return daysData.filter(filter);
                });
            }
        }
    }, [daysData]);

    const [last, setLast] = useState();
    const [first, setFirst] = useState();

    useEffect(()=>{
        if (days && daysData) {
            setLast(compareArrays(daysData[0].date, days[0].date));
            setFirst(compareArrays(daysData[daysData.length - 1].date, days[days.length - 1].date));
            if (bookmark) {
                const lastDay = days[0];
                //console.log(days);
                history.replace(`/dienasgrāmata/${lastDay.date[0]}/${lastDay.date[1] + 1}/${lastDay.date[2]}/`);
            }
            window.scrollTo(0, 0);
        }
    }, [days, daysData]);

    if (statusDays.error) return <div>Error: {statusDays.error.message} {window.navigator.userAgent}</div>;
    if (statusMedia.error) return <div>Error: {statusMedia.error.message} {window.navigator.userAgent}</div>;
    if (!statusDays.wasLoaded) return <Spinner local={!pager} />;
    if (!statusMedia.wasLoaded) return <Spinner local={!pager} />;

    function lr(index) {
        if (!pager) return null;
        if (days.length === 1) return last ? "left" : "right";
        if (days.length === 2) return index === 0 ? "right" : "left";
        return null;
    }

    return (
        days ?
        <>
        <div className="days">
            { pager && last && !first && days.length === 1 ? <Day className="right" blank /> : null }
            {
            days.map((day, i) => (
                <Day className={lr(i)} key={i} {...{ day, data, box, media }} />
            ))
            }
            { pager && first && days.length === 1 ? <Day className="left" blank /> : null }
        </div>
        { pager && <Pager {...{ daysData, days, setDays, last, first } } /> }
        </> : <div>Tukšs</div>
    )

}
