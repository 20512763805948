import React, { useEffect, useState, useRef } from "react";
import { generateId } from "../../prototypes";
import { parsed2html } from "../../lang/parse";
import { inputSizer } from "../Input";

function QText(props) {

    let savedAnswer = props.savedAnswers[props.id] ?
        props.savedAnswers[props.id].type === "qtext" ?
            props.savedAnswers[props.id].value : "" : "";

    //console.log("QText: props.id:", props.id);

    const ref = useRef(props.id);

    useEffect(()=>{
        props.setRefs(prev => {
            //console.log("QText useEffect(): prev refs length:", prev.length);
            let updated = [...prev];
            const index = updated.findIndex(ref => ref.id === props.id);
            if (index > -1) {
                updated[index] = { id: props.id, ref };
            } else {
                updated.push({ id: props.id, ref });
            }
            return updated;
            //{ id: props.id, ref }
        });

        return () => { clearTimeout(timeoutId) }
    });

    let timeoutId;

    const timeout = props.timeout;

    let localTimeout;

    function updateAnswers(value) {
        //console.log("setting value:", value);
        props.setAnswers(prev => {
            let updatedAnswers = prev ? {...prev} : {};
            updatedAnswers[props.id] = {
                type: "qtext",
                value: value.trim(),
                exercise: props.custom.id
            };
            return updatedAnswers;
        });
        savedAnswer = value;
    }

    function handleInput(e) {
        const input = e.currentTarget;
        const value = input.value;
        //console.log(value);
        let t = timeout;
        inputSizer(input, 0);
        //console.log(value);
        clearTimeout(timeoutId);
        localTimeout = value.trim() === "" ? 1 : timeout;
        timeoutId = setTimeout(() => {
            updateAnswers(value);
        }, localTimeout);
        //input.value = value;
    }

    function handleBlur(e) {
        const value = e.currentTarget.value;
        //console.log("value on blur:", value);
        clearTimeout(timeoutId);
        updateAnswers(value);
    }

    const tprops = { nolink: props.nolink };
    if (!props.nobox) tprops.box = props.box;
    //if (props.nolink) tprops.nolink = props.nolink;

    //console.log(props.questions);

    return (
        <div key={generateId()} className="qText" ref={ref} id={props.id}>
            
            <input autoComplete="off" defaultValue={savedAnswer} onChange={handleInput}
                   onBlur={handleBlur} size={props.size||"12"} name={props.id} />
            <div className="question">{
                props.questions ?
                parsed2html(tprops, props.questions) : "\u00A0"
            }</div>
            
        </div>
    )
}

/*<div className="questions">{parsed2html({ nolink: true }, props.questions)}</div>*/

export default QText;
