import React from "react";
import { Link } from 'react-router-dom';


export const bracket = <svg
    width="15" height="100%" viewBox="0 0 30 160"
    version="1.1" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve"
    preserveAspectRatio="none"
    style={{fillRule:"evenodd", clipRule: "evenodd", strokeMiterlimit: "1.5"}}>
    <path vectorEffect="non-scaling-stroke" d="M1,1c0,0 6.114,0.793 10.683,4.829c4.669,4.123 5.517,8.89 5.517,16.226l0,45.531c0,9.969 11.6,12.414 11.6,12.414c0,0 -11.6,2.445 -11.6,12.414l0,45.531c0,7.336 -0.848,12.103 -5.517,16.226c-4.569,4.036 -10.683,4.829 -10.683,4.829" style={{fill:"none", stroke: "var(--fgBright)", strokeWidth: "1.5px", transition: "stroke 1s ease"}} />
</svg>;

export const male = <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12" height="24"
    viewBox="0 0 12 24">
    <polygon style={{fill: "var(--blue)"}} points="5.5 24 10.5 10 0.5 10 5.5 24"/>
    <circle style={{fill: "var(--blue)"}} cx="5.5" cy="5" r="3"/>
</svg>;

const search = <svg
    xmlns="http://www.w3.org/2000/svg"
    width="45" height="45"
    viewBox="0 0 45 45">
    <polygon style={{fill: "var(--blue)"}} points="5.5 24 10.5 10 0.5 10 5.5 24"/>
    <circle style={{fill: "var(--blue)"}} cx="5.5" cy="5" r="3"/>
</svg>;

export function SearchIcon(props) {
    const icon = <svg
        width={props.width || 30} height={props.height || 30}
        viewBox="0 0 45 45"
        version="1.1" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve"
        preserveAspectRatio="none"
        style={{fillRule:"evenodd", clipRule: "evenodd", strokeMiterlimit: "2"}}
        xmlnsXlink="http://www.w3.org/1999/xlink">
        <rect x="0" y="0" width="45" height="45" style={{fill:"none"}} />
        <path d="M31.125,32.882C28.785,34.829 25.778,36 22.5,36C15.049,36 9,29.951 9,22.5C9,15.049 15.049,9 22.5,9C29.951,9 36,15.049 36,22.5C36,24.942 35.35,27.233 34.215,29.209L41.746,35.537L38.658,39.211L31.125,32.882ZM22.5,13.8C27.302,13.8 31.2,17.698 31.2,22.5C31.2,27.302 27.302,31.2 22.5,31.2C17.698,31.2 13.8,27.302 13.8,22.5C13.8,17.698 17.698,13.8 22.5,13.8Z" />
    </svg>;

    return (
        props.active ?
            <div className="searchIcon active">icon</div> : <Link to="/meklēšana/" className="searchIcon" >{icon}</Link>
    )
}

export function GenderIcon(props) {
    if (props.count === 0) {
        return props.gender === 0 ? (<Male width={props.width} height={props.height} />) : (<Female width={props.width} height={props.height} />)
    }

    if (props.count === 1) {
        return props.gender === 0 ?
            props.type === "lv" ? (<>
            <Male key="m1" width={props.width} height={props.height} />
            <Male key="m2" width={props.width} height={props.height} />
            </>) : (<>
            <Male key="m1" width={props.width} height={props.height} />
            <Female key="f1" width={props.width} height={props.height} />
            <Male key="m2" width={props.width} height={props.height} />
            <Female key="f2" width={props.width} height={props.height} />
            </>) : (<>
            <Female key="f1" width={props.width} height={props.height} />
            <Female key="f2" width={props.width} height={props.height} />
            </>
        )
    }
    return null;
}

export function Arrow({width, height, className, right, down, left, up, strokeWidth}) {

    // console.log(width, height, className, right, down, left, up, strokeWidth);
    
    let index = [right, down, left, up].indexOf(true);

    if (index < 0) index = [right, down, left, up].indexOf("");

    // console.log(index);
    
    const angle = index < 0 ? 0 : [0, 90, 180, 270][index];
    const dir = index < 0 ? "right" : ["right", "down", "left", "up"][index];
    
    const classList = ["icon arrow"];
    classList.push(dir);
    if (className) classList.push(className);
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeMiterlimit="1.5"
            clipRule="evenodd"
            viewBox="0 0 24 24"
            {...{width, height}}
            className={classList.join(" ")}
        >
            <path
                vectorEffect="non-scaling-stroke"
                fill="none"
                stroke="#000"
                strokeWidth={strokeWidth||3}
                d="M12.239 4l7.459 7.459-7.459 7.459M19.698 11.459H3.5"
                transform={`rotate(${angle},12,12)`}
            />
        </svg>
    );
}

export function Rarr({width, height, className}) {
    const classList = ["icon arrow right"];
    if (className) classList.push(className);
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeMiterlimit="1.5"
            clipRule="evenodd"
            viewBox="0 0 24 24"
            {...{width, height}}
            className={classList.join(" ")}
        >
            <path
                vectorEffect="non-scaling-stroke"
                fill="none"
                stroke="#000"
                strokeWidth="3"
                d="M12.239 4l7.459 7.459-7.459 7.459M19.698 11.459H3.5"
            />
        </svg>
    );
}

export function Male({ width, height }) {
    return (
        <svg
            className="icon male"
            xmlns="http://www.w3.org/2000/svg"
            width={width||12} height={height||24}
            viewBox="0 0 12 24">
            <g className="figure" style={{fill: "var(--blue)"}}>
                <polygon points="5.5 24 10.5 10 0.5 10 5.5 24"/>
                <circle cx="5.5" cy="5" r="3"/>
            </g>
        </svg>
    );
}

export function Female({ width, height }) {
    // console.log(width, height);
    return (
        <svg
            className="icon female"
            xmlns="http://www.w3.org/2000/svg"
            width={width||12} height={height||24}
            viewBox="0 0 12 24">
            <g className="figure" style={{fill: "var(--red)"}}>
                <polygon points="5.5 9 10.5 23 0.5 23 5.5 9"/>
                <circle cx="5.5" cy="5" r="3"/>
            </g>
        </svg>
    );
}

export function Neutral({ width, height }) {
    return (
        <svg
            className="icon neutral"
            xmlns="http://www.w3.org/2000/svg"
            width={width||12} height={height||24}
            viewBox="0 0 12 24">
            <g className="figure" style={{fill: "var(--fgDim)"}}>
                <rect x="1" y="10" width="9" height="13" />
                <circle cx="5.5" cy="5" r="3"/>
            </g>
        </svg>
    );
}

export const female = <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12" height="24"
    viewBox="0 0 12 24">
    <polygon style={{fill: "var(--red)"}} points="5.5 9 10.5 23 0.5 23 5.5 9"/>
    <circle style={{fill: "var(--red)"}} cx="5.5" cy="5" r="3"/>
</svg>;

const pathStyle = {
    fill:"none",
    stroke:"var(--navLink)",
    strokeWidth: "1.4px",
    strokeLinecap:"round",
    strokeMiterlimit:"1.5"
};

export const night = <svg
    width="24" height="24" viewBox="0 0 32 32"
    version="1.1" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve"
    style={{fillRule:"evenodd", clipRule:"evenodd", strokeLinejoin: "round", strokeMiterlimit: "2"}}>
    <path d="M6.639,24.106c3.409,1.594 7.64,2.581 11.217,0.896c7.082,-3.337 9.47,-13.216 3.315,-18.53" style={pathStyle} />
    <path d="M19.939,4.429c2.907,8.536 0.146,14.068 -2.9,16.508c-2.286,1.832 -5.777,3.23 -6.916,3.17c-1.373,-0.072 -2.585,0.186 -3.853,-0.332" style={pathStyle} />
</svg>;

export const day = <svg
    width="24" height="24" viewBox="0 0 32 32"
    version="1.1" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve"
    style={{fillRule:"evenodd", clipRule:"evenodd", strokeLinejoin: "round", strokeMiterlimit: "2"}}>
    <path d="M22.858,12.853c-1.395,-3.376 -5.309,-5.166 -8.976,-4.451c-4.713,0.919 -6.607,7.526 -3.83,11.134c2.287,2.971 6.141,3.849 9.492,2.26c3.879,-1.839 5.883,-6.875 2.95,-10.456" style={pathStyle} />
    <path d="M23.768,6.911c0.662,-1.004 1.343,-1.997 2.026,-2.987" style={pathStyle} />
    <path d="M15.928,5.379c0.022,-0.961 0.046,-1.922 0.068,-2.883" style={pathStyle} />
    <path d="M8.496,7.635c-1.014,-0.94 -2.189,-1.621 -3.407,-2.258" style={pathStyle} />
    <path d="M5.896,16.23c-1.41,0.061 -2.794,0.42 -4.163,0.738" style={pathStyle} />
    <path d="M8.636,22.669c-1.334,0.913 -2.619,2.014 -3.748,3.179" style={pathStyle} />
    <path d="M15.584,25.724c-0.129,1.437 -0.247,2.849 -0.28,4.292" style={pathStyle} />
    <path d="M22.706,24.519c0.848,0.701 1.621,1.509 2.346,2.334" style={pathStyle} />
    <path d="M26.46,15.353c1.338,0.084 2.697,0.228 3.982,0.634" style={pathStyle} />
</svg>;

export const icons = {
    "male": Male, "female": Female, "neutral": Neutral, "arrow": Arrow
};
