import React, { useEffect } from "react";

import { time2text } from "../lang/time";
import { getHumanPres } from "../lang/time";

import "../css/TimeText.css";

let timeoutId;

function TimeText(props) {

    //console.log("TimeText render");

    const { time, question, pres, data, box, adjustFontSize, setFontSize, index } = props;

    const lang = props.lang || "lv";

    const presentation = getHumanPres(time, lang||"lv", pres);

    useEffect(()=>{
        return ()=>{
            clearTimeout(timeoutId);
        }
    }, []);
    //console.log(presLv);
    //console.log(presSl);
    let className = props.className ? " "+props.className : "";

    const timeText = time2text(time, lang||"lv", question, presentation, data, box);

    let style = null;

    if (adjustFontSize) {
        //console.log(`${lang.toUpperCase()}: ${timeText.len}`);
        //console.log(timeText.len);
        const keys = Object.keys(adjustFontSize);
        const keysLower = keys.filter(key => adjustFontSize[key] <= timeText.len);
        if (keysLower.length > 0) {
            const fontSize = keysLower.reduce((prev, current) => (adjustFontSize[prev] > adjustFontSize[current]) ? prev : current);
            //console.log(`fontSize: ${fontSize}`);
            setTimeout(() => {
                setFontSize(prev => {
                    const updated = [...prev];
                    updated[index] = fontSize;
                    return updated;
                })
            }, 5);
        }
    }

    return (
        <div className={`timeText${className}`}>{timeText.res}</div>
    )
}

export default React.memo(TimeText, (prev, next) => (
    prev.time.mm === next.time.mm && prev.time.day === next.time.day && prev.question === next.question
));
