import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
    generateId, cap, _
} from "../prototypes";
import {
    rules, syn, wordType, lemma2str, detectLang,
    nounCount, nounGender, sortByShortlist
} from "../lang/lang2";
import {
    findChainsByWord, entities2unicode,
    chain2arr, parseWordStr, parseChainStr, isStrWord, isStrChain, str2chain,
    word2href, wordIndexOfChain
} from "../lang/parse";
import { GenderIcon } from "../i/icons";
//import { emo } from "../components/emo";

import { NewSearch } from "../components/Search";

import Content from "../components/Content";
import { Wordform, FamilyChain, TransWord, WordLong, Formula } from "../components/Wordform";

import Grammar from "../components/lang/Grammar";
import { Footer, CopyUrl } from "../components/Footer";
import { Lemma } from "../components/EntryLemma";

import "./Entry.css";

function Family(props) {

    function roots(word) {
        //console.log(word);
        let r = word.morphs.filter(morph => morph.origin.type==="root").map(morph => morph.origin.nick);

        if (word.hasOwnProperty("compoundForms")) {
            //console.log(word.compoundForms.flatten());
            r = r.concat(
                word.compoundForms.flatten()
                    .filter(cf => cf.hasOwnProperty("root"))
                    .map(cf => cf.origin.nick)
            );
            //console.log(r);
        }

        return r;
    }

    const wordRoots = roots(props.word);

    //console.log(wordRoots);

    const family = props.words.filter(w => (roots(w).some(root => wordRoots.includes(root))));

    //console.log(family);

    if (family.length < 2) return null;

    const rx = new RegExp(`^${ window.baseHref.replace(/\//g, "\\/") }(.+)`);

    const url = (decodeURIComponent(window.location.pathname)+"/")
            .replace("//", "/")
            .replace(rx, "$1")
            .toLowerCase();

    //console.log(url);

    //console.log(url.replace(rx, "$1"));


    /*let res0 = (<div className="family">
        {
            family.map((member, i) => {
                const link = `/vārdi/${wordType(member).full}/${lemma2str(member.lemma)}/`;
                return (
                    <div key={i} className={link === url ? "active" : null}>
                        {link === url ? lemma2str(props.word.lemma) :

                            <Link to={link}><Wordform key={i} form={member.lemma} /></Link>

                            }
                    </div>
                )
            })
        }
    </div>);*/

    return (<div className="family">
        {
            family.map((member, i) => {
                //console.log("Entry: member:", member);
                const link = word2href(member);
                return (
                    <div key={i} className={link === url ? "active" : null}>
                        {link === url ?
                            member.homonym ?
                                <>
                                {lemma2str(props.word.lemma)}
                                {entities2unicode(`&thinsp;&#${9311+member.homonym};`)}
                                </> :
                                lemma2str(props.word.lemma)
                            :
                            <WordLong word={member} box={props.box} />
                        }
                    </div>
                )
            })
        }
    </div>);

}

function Chains(props) {

    const { word, chains, relatedChains, top, rest } = props;

    if (relatedChains === null) return <div className="chains">Meklēju izteicienus...</div>;

    const portion = top!==undefined ? relatedChains.slice(0, top) : rest!==undefined ? relatedChains.slice(rest) : [];

    //const max = 3;

    if (portion.length > 0) {

        function hi(chain, className) {
            let res = [];
            //console.log(chain2arr(chain));
            chain2arr(chain).forEach((part, i) => {
                if (isStrWord(part)) {
                    const wordStr = parseWordStr(part);
                    const wordMatch =
                        (wordStr.lemma === lemma2str(word.lemma) &&
                        wordStr.wordType === wordType(word).short) ?

                            (wordStr.homonym) ? word.homonym===wordStr.homonym :
                                (()=>{
                                    if (word.homonym) {
                                        console.log("WARNING: word has homonym, but reference in chain doesn't");
                                    }
                                    return true;
                                })()

                            : false;
                    if (wordMatch) {
                        res.push(<span key={generateId()} className={className}>{
                            _(i===0, entities2unicode(wordStr.form), cap)
                        }</span>);
                    } else {
                        res.push(<span key={generateId()}>{
                            _(i===0, entities2unicode(wordStr.form), cap)
                        }</span>);
                    }
                } else {
                    if (isStrChain(part)) {
                        const subchain = str2chain(chains, part);
                        const highlight = (wordIndexOfChain(word, subchain) > -1);
                        res.push(<span className={highlight ? className : undefined} key={generateId()}>{
                            _(i===0, entities2unicode(parseChainStr(part).form), cap)
                        }</span>);
                    } else {
                        res.push(<React.Fragment key={generateId()}>{
                            _(i===0, entities2unicode(part), cap)
                        }</React.Fragment>);
                    }
                }
            });
            //console.log(res.flatten());
            return res.flatten();
        }

        return (
        <>
            { rest !==undefined &&
            <header>
                <h2>Vēl {portion.length>1?"frāzes":"frāze"} ar &bdquo;<Wordform className="lemma" form={word.lemma} />&ldquo;</h2>
                <div className="trans">еще {portion.length>1?"фразы":"фраза"} с «<Wordform className="lemma" form={word.lemma} />»</div>
            </header>
            }
            <div className={"chains " + (props.className||"") }>
            { portion.map((chain, i) => (
                <FamilyChain key={i} chain={chain}>{hi(chain, "highlight")}</FamilyChain>
            )) }
            </div>
        </>
        );
    } else { return null; }
}

function Entry(props) {

    //console.log("Entry render");

    useEffect(()=>{
        props.setNav(null);
    }, [props.url.match.params]);

    const [relatedChains, setRelatedChains] = useState(null);

    const [queryStr, setQueryStr] = useState();

    const id = useRef();

    const homonym =
        props.url.match.params.homonym ? parseInt(props.url.match.params.homonym) : false;

    const { type, lemma } = props.url.match.params;
    const { morphs, words, chains } = props.data;
    const box = props.box;

    //console.log(words.filter(word => word.rule.toString()[0]==="3"));

    const t = type.length > 2 ? Object.keys(syn.type).find(key=>syn.type[key]===type) : type;

    let word = words
        .filter(w => rules[t].indexOf(w.rule) > -1);
        //console.log(word.length);
        word = word.filter(w => {
            //console.log(w.lemma.map(m => m[getKey(m, morphNames)]).flatten().join(""));
            /*if (w.rule.toString()[0]==="7") {
                console.log(w);
            }*/

            return lemma2str(w.lemma).toLowerCase() === lemma.toLowerCase()
        });

    //console.log(word);

    let theWord = word[0];

    if (word.length > 1) {
        if (!homonym) {
            props.url.history.replace(`/vārdi/${type}/${lemma}/1/`);
        } else {
            theWord = word[homonym-1];
        }
    }

    console.log(theWord);

    const top = 3;

    let tid;

    useEffect(() => {

        //console.log("Entry: useEffect on lemma, homonym");

        if (theWord) {
            //setRelatedChains(null);

            //console.log("after render");
            //console.log((new Date).getSeconds());
            
            props.setNav({
                component: "navEntry",
                menu: [],
                active: {}
            });
            
            document.title = lemma;

            /*if (lemma === "krāsot") {
                if (document.body.classList.contains("day")) {
                    document.body.style.backgroundImage = "url('https://runajiet.lv/bg/splat/01.jpg')";
                }
            } else {
                if (document.body.style.backgroundImage !== "") document.body.style.backgroundImage = "";
            }*/
            //console.log(chains.length);

            //console.log(word[0]);

            //setRelatedChains(sortByShortlist(theWord, findChainsByWord(chains, words, theWord)));

            tid = setTimeout(() => {
                //console.log("Entry: timeOut useEffect on lemma, homonym");
                setRelatedChains(sortByShortlist(theWord, findChainsByWord(chains, words, theWord)))
            }, 100);

            //setRelatedChains(findChainsByWord(chains, words, word[0]));
        }

        return (() => {
            //console.log("Entry: unmount useEffect on lemma, homonym");
            //clearTimeout(tid);
            setRelatedChains(null);
        });

    },
        [lemma, homonym]
        //[lemma, homonym, chains, theWord, words]
    );

    /*useEffect(()=>{
        return (() => {
            console.log("Entry: unmount useEffect[]");
            clearTimeout(tid);
            //setRelatedChains(null);
        });
    }, []);*/

    //console.log(theWord);

    function handleKeyUp(e) {
        e.persist();
        if (e.ctrlKey || e.altKey || e.key.length > 1) return;
        const lang = detectLang(e.key);
        if (lang) {
            //console.log(e.key);
            if (document.activeElement) {
                //console.log([...document.activeElement.classList]);
                if ([...document.activeElement.classList].indexOf("content") > -1) {
                    //console.log("keyUp on Content: catch query:", e.key);
                    //if (id.current) { id.current.focus() }
                    setQueryStr(p => ( (p||"") + e.key ) );
                }
            }
        }
    }

    return(
        <Content onKeyUp={handleKeyUp} className="entry">{
            theWord ?
                <>
                    <Lemma word={theWord} id={id} queryStr={queryStr} data={props.data} box={box} />
                    <Family word={theWord} homonym={homonym} words={words} box={box} />
                    <Chains word={theWord} relatedChains={relatedChains} chains={chains} words={words} top={top} />
                    <Grammar word={theWord} morphs={morphs} words={words} />
                    <Chains className="part2" word={theWord} relatedChains={relatedChains} chains={chains} words={words} rest={top} />
                    <Footer><CopyUrl /></Footer>
                    <div className="hidden">Nekādā gadījumā nepievērsiet uzmanību šiem cipariem:<br />
                        {theWord.id}<br/>
                    Paldies!</div>
                </> : <div>Nav tāda vārda :(</div>
        }</Content>
    );
}

export default Entry;