import React, { useState, useMemo, useLayoutEffect, useEffect, useRef, useContext } from "react";
import { labels } from "../labels";
import { fetchFiles } from "../io";
import { getType, aovmap, cap } from "../prototypes";
import { str2word, chain2words, chain2str, entities2unicode } from "../lang/parse";
import { transform, getKey, morphNames, lemma2str } from "../lang/lang2";
import Content from "../components/Content";
import ParsedText from "../components/ParsedText";
import { Words } from "../components/grammar/LiveWords";
import { Arrow, Male, Female } from "../i/icons";
import Spinner from "../components/Spinner";

import "../css/Grammar.css";
import "../components/grammar/nouns/LiveTable.css";
import NavContext from "../components/common/nav/NavContext";

const soften = (flex) => {

    return (
        () => {
            return <><Soft /><span className="flex">{flex}</span></>;
        }
    )
};

const cases = [
    {
        cas: 0,
        flex: [
            [["s", "š"], "i"], ["is", soften("i")], ["us", "i"], ["a", "as"], ["e", "es"], ["s", "is"]
        ]
    },
    {
        cas: 1,
        preps: [
            "aiz[pv]", "ārpus[av]", "bez[pv]", "dēļ[pv]", "iekš[pv]", "kopš[pv]", "labad[pv]",
            "no[pv]", "pēc[pv]", "pie[pv]", "pirms[pv]", "priekš[pv]", "uz[pv]", "virs[pv]", "zem[pv]"
        ],
        flex: [
            ["a", "u"], [soften("a"), soften("u")], ["us", "u"], ["as", "u"], ["es", soften("u")], ["s", soften("u")]
        ]
    },
    {
        cas: 2, preps: ["apkārt[av]", "blakus[av]", "cauri[av]", "garām[av]", "līdz[pv]", "pa[pv]", "pretī[av]", "pāri[av]"],
        flex: [
            ["am", "iem"], ["im", soften("iem")], ["um", "iem"], ["ai", "ām"], ["ei", "ēm"], ["ij", "im"]
        ]
    },
    {
        cas: 3,
        preps: [
            "ap[pv]", "ar[pv]", "caur[pv]", "gar[pv]", "pa[pv]", "par[pv]", "pār[pv]", "pret[pv]", "starp[pv]", "uz[pv]"
        ],
        flex: [
            ["u", "us"], ["i", soften("us")], ["u", "us"], ["u", "as"], ["i", "es"], ["i", "is"]
        ]
    },
    {
        cas: 4,
        flex: [
            ["u", "iem"], ["i", soften("iem")], ["u", "iem"], ["u", "ām"], ["i", "ēm"], ["i", "īm"]
        ]
    },
    {
        cas: 5,
        flex: [
            ["ā", "os"], ["ī", soften("os")], ["ū", "os"], ["ā", "ās"], ["ē", "ēs"], ["ī", "īs"]
        ]
    }
];

let nouns;

function getPreps({data, word, theCase, count}) {
    if (word) {
        const noun = nouns.find(noun => noun.id === word.id);
        const declension = noun.declension.find(dec => dec.param.cas === theCase.cas);

        if (declension) {
            if (declension.hasOwnProperty("preps")) {
                return declension.preps;
            }
        }
    }

    if (theCase.hasOwnProperty("preps")) {
        //console.log("case preps:", theCase.preps);
        return theCase.preps.map(p=> ({ lv: p }));
    } else {
        return null
    }
}

function Preps({data, box, word, theCase, declension, preps, setPreps }) {

    const prepositions = getPreps({data, word, theCase});

    //const decCandidates = declensions.filter(dec => dec.hasOwnProperty("preps"));
    //const declension = decCandidates[0];

    //console.log("prepositions:", prepositions);

    function handlePreps(value) {
        setPreps((prev)=>{
            const updated = [...prev];
            const declensions = updated.filter(p => p.declension===declension.id);
            if (declensions.length === 0) {
                updated.push({ noun: word.id, declension: declension.id, prep: value });
            } else {
                declensions.forEach(d => { d.prep = value })
            }
            return updated;
        })
    }

    const res = [];
    let onClick = null;
    let classList = ["prep"];

    if (prepositions) {
        if (prepositions.length > 0) {
            /*console.log("PREPOSITIONS:");
            prepositions.forEach(p => {
                console.log(p);
            });*/

            if (word && prepositions.every(p=>p.hasOwnProperty("id"))) {

                //console.log("word selected, preps taken from word");

                //classList.push("form");

                prepositions.forEach((prep, i) => {
                    onClick = null;
                    classList = ["prep", "form"];
                    if (preps.find(p=>p.declension===declension.id).prep === prep.id) {
                        //console.log("is active");
                        classList.push("active");
                    } else {
                        //console.log("is not active");
                        onClick = ()=>{ handlePreps(prep.id) };
                    }
                    res.push(
                        <div key={i} className={classList.join(" ")} onClick={onClick}>
                            <ParsedText {...{ data, text: prep.lv, nolink: true }} />
                            { prep.hasOwnProperty(window.secLang) ? <div className="trans">{prep[window.secLang]}</div> : null }
                        </div>
                    )
                });

                classList = ["prep", "form"];
                if (preps.find(p=>p.declension===declension.id).prep === false) {
                    //console.log("clear is active");
                    classList.push("active");
                } else {
                    //console.log("clear is not active");
                    onClick = ()=>{ handlePreps(false) };
                }

                res.push(
                    <div key="clear" className={classList.join(" ")+" clear"} onClick={onClick}>
                        <span>&times;</span>
                    </div>
                );

            } else {

                classList.push("symbol");
                prepositions.forEach((prep, i) => {
                    res.push(
                        <React.Fragment key={i}>
                        <div className={classList.join(" ")}>
                            <ParsedText {...{ data, box, text: prep.lv, nolink: true }} />
                        </div>
                            {" "}
                        </React.Fragment>
                    )
                });

            }

        }
    }

    return <div className="preps">{res}</div>;

    /*
    return prepositions ? prepositions.map((prep, i) => {

        let onClick = null;
        const classList = ["prep"];
        if (word && prep.id) {
            //console.log("word prep.id:", prep.id);
            //console.log(prep.lv);
            if (preps.find(p=>p.declension===declension.id).prep === prep.id) {
                //console.log("is active");
                classList.push("active");
            } else {
                //console.log("is not active");
                onClick = ()=>{ handlePreps(prep.id) };
            }
        } else { classList.push("symbol") }

        return (
            <div key={i} className={classList.join(" ")} onClick={onClick}>
                <ParsedText {...{ data, box: prep[window.secLang] ? false : box, text: prep.lv, nolink: true }} />
                { prep.hasOwnProperty(window.secLang) ? <div className="trans">{prep[window.secLang]}</div> : null }
            </div>
        )
    }) : null
    */

}

function Row({ data, box, word, theCase, count, preps, setPreps }) {

    //console.log(nouns);

    const noun = word ? nouns.find(noun => noun.id===word.id) : false;
    const declension = noun ? noun.declension.find(dec => dec.param.cas === theCase.cas && dec.param.count === count) : [];
    //const param = declension ? declension.param : { };

    //console.log(declension);
    //if (declension) console.log(param);

    return (
        <tr className={["singular", "plural"][count]}>
            { count === 0 &&
            <td rowSpan="2" className="cas">
                <div className="cas">
                    <div>{
                        cap(chain2str( { lv: { default: labels.cas[theCase.cas].lv } } ))
                    }{ ": " + labels.casQuestionShort[theCase.cas].lv }</div>
                    <div className="trans">{
                        labels.cas[theCase.cas][window.secLang]
                    }{ ": " + labels.casQuestionShort[theCase.cas][window.secLang] }</div>
                </div>
            </td>
            }
            {
                theCase.cas === 0 ?
                    <td className="header">
                        <div className="title">
                            <div>
                                <div>{ chain2str( { lv: { default: labels.count[count].lv } } ) }</div>
                                <div className="trans">{labels.count[count][window.secLang]}</div>
                            </div>
                            <Arrow right />
                        </div>
                    </td> :
                    count === 0 &&
                        <td rowSpan="2" className="prep">
                            <Preps {...{data, box, word, theCase, declension, preps, setPreps}} />
                        </td>
            }
            {
                theCase.flex.map((flex, i) => (
                    <td key={i}>{
                        declension && noun.group===i+1 ?
                            <Form {...{data, word, declension, preps}} />
                            :
                            <div className="symbol">{
                                getType(flex[count]) === "function" ?
                                    flex[count]() :
                                    aovmap(flex[count], (e, i)=><span key={i} className="flex">{e}</span>)
                            }</div>

                    }</td>
                ))
            }
        </tr>
    )
}

function Soft({ soft }) {

    const classList = ["soft"];
    if (!soft) classList.push("symbol");

    return (
        <span className={classList.join(" ")}>{soft||"\u00A0"}</span>
    )
}

function Form({ data, word, declension, preps }) {

    const softens = [
        "bj", "mj", "ļ", "pj", "vj", "š", "ž", "č", "ņ"
    ];

    let thePrep, trans, activePrep = {};

    if (declension.hasOwnProperty("preps")) {
        //console.log("declension:", declension);
        activePrep = preps.find(p => p.declension === declension.id);
        //console.log("activePrep:", activePrep);
    }
    if (activePrep.prep) {
        const prep = declension.preps.find(p => p.id === activePrep.prep);
        //console.log("prep:", prep);
        thePrep = {
            component: <span key={0} className="prep">{
                entities2unicode(chain2str({ lv: { default: prep.lv } }))
            }</span>,
            postpos: prep.postpos
        };
        if (/\[(pv|av)\]$/.test(prep.lv)) {
            thePrep.wordType = "pv"
        }
        if (prep.subj.hasOwnProperty("lv")) {
            thePrep.lv = prep.subj.lv;
        }
        trans = <div className="trans">{prep.subj[window.secLang]}</div>;
    } else {
        trans = <div className="trans">{declension.subj[window.secLang]}</div>;
    }

    /*if (thePrep && declension.param.count === 1 && !thePrep.postpos) {
        console.log("THE PREP:", thePrep);
    }*/

    const param = thePrep && declension.param.count === 1 && !thePrep.postpos && thePrep.wordType==="pv" ?
        { cas: 2, count: 1 } : declension.param;

    const form = transform(data.morphs, word, param);
    //console.log("word form:", form);
    //console.log("word lemma:", word.lemma);
    const stemForm = form.filter(f=>!f.hasOwnProperty("flex")).map(morph => morph[getKey(morph, morphNames)]).join("");
    const stemLemma = word.lemma
        .filter(f=>!f.hasOwnProperty("flex"))
        .map(morph => morph[getKey(morph, morphNames)]).join("");
    //console.log("word form stem:", stemForm);
    //console.log("word lemma stem:", stemLemma);
    let soft;
    if (stemForm !== stemLemma) {
        for (let i=0, l=softens.length; i<l; i++) {
            const rx = new RegExp(`${softens[i]}$`);
            //console.log(rx);
            if (rx.test(stemForm)) {
                soft = softens[i];
                break;
            }
        }
    }

    let wordParts = [];

    if (param.cas === 4) {
        wordParts.push(<span key={4}>ar&nbsp;</span>);
    }

    if (soft) {
        //console.log("stem was soften");
        const rx = new RegExp(`${soft}$`);
        wordParts.push(<span key={1} className="stem">{stemForm.replace(rx, "")}</span>);
        wordParts.push(<Soft key={2} soft={soft} />);
    } else {
        wordParts.push(<span key={1} className="stem">{stemForm}</span>);
    }
    wordParts.push(<span key={3} className="flex">{form.filter(f=>f.hasOwnProperty("flex")).map(morph=>morph.flex).join("")}</span>);

    if (thePrep) {

        if (thePrep.lv) {
            if (/\$1/.test(thePrep.lv)) {
                //console.log(thePrep.lv.split(/(\$1)/).filter(part=>part!==""));
                const customForm = thePrep.lv.split(/(\$1)/).filter(part=>part!=="").map((part, i) => (
                    part === "$1" ?
                        wordParts :
                        <span key={"custom"+i}>{part}</span>
                )).flatten();
                //console.log(customForm);
                wordParts = customForm;
            }
        } else {
            if (thePrep.postpos) {
                wordParts.push(
                    <React.Fragment key={0}>&nbsp;{thePrep.component}</React.Fragment>
                )
            } else {
                wordParts.unshift(<React.Fragment key={0}>{thePrep.component}&nbsp;</React.Fragment>)
            }
        }
    }

    return (
        <div className="form">
            {wordParts}
            {trans}
        </div>
    )
}

function Table({ data, box, word, preps, setPreps }) {

    return (
        <table className={word ? "form" : null}>
            <tbody>
            <tr className="header">
                <td colSpan="2">
                    <div className="title">
                        <div>
                            <Arrow down />
                            <div>{labels.cases.lv}</div>
                            <div className="trans">{labels.cases[window.secLang]}</div>
                        </div>
    
                        <div>
                            <div>{labels.nouns.groups.lv}</div>
                            <div className="trans">{labels.nouns.groups[window.secLang]}</div>
                            <Arrow right />
                        </div>
                    </div>
                </td>
                {
                ["I", "II", "III", "IV", "V", "VI"].map((group, i) => (
                    <td key={i}>{
                        group
                    }{
                        i > 2 ? <Female /> : <Male />
                    }</td>
                ))
                }
            </tr>
            {
                cases.map((theCase, i) => (
                    [0, 1].map((count, j) => (
                        <Row {...{data, box, word, theCase, count, preps, setPreps }} key={`cas${i}count${j}`} />
                    ))
                ))
            }</tbody>
        </table>
    )
}

function LiveTable({ data, box, groups, preps, setPreps }) {
    
    const [word, setWord] = useState();

    const handleWord = word => {
        //console.log(word);
        setWord(word);
        if (word) {
            const noun = nouns.find(noun=>noun.id===word.id);
            //console.log("noun selected:", noun);
        }
    };

    return(
        <div className="live">
            <Words {...{ word, setWord: handleWord, data, box, groups }} />
            <Table {...{ word, data, box, preps, setPreps }} />
        </div>
    )
}

function Declension({ data, box }) {

    console.log("Declension render");

    const getWords = (strArr) => {
        let words = [];
        if (strArr.length > 0) {
            strArr.forEach(wordStr => {
                const chainWords = chain2words(data, { lv: { default: wordStr } });
                //console.log("chain2words:", chainWords);
                if (chainWords.length > 0) {
                    chainWords.forEach(w => {words.push(w)});
                }
            });
        }
        return words;
    };

    const casePreps = cases.map(cas => cas.preps||[]).flatten();
    //console.log(casePreps);

    const [preps, setPreps] = useState();

    const [rawNouns, setRawNouns] = useState();

    const [status, setStatus] = useState({ wasLoaded: false, error: false });

    let timeoutId;

    useMemo(()=>{
        //console.log("Days: fetch files");
        timeoutId = setTimeout(()=>{
            fetchFiles(
                "/json/v9/grammar/", "livenouns",
                { cache: "no-store" }, setRawNouns, setStatus
            );
        }, 1);
    }, []);

    

    useEffect(()=>{
        document.title = "Lietvārdu locīšana";
        return (()=>{ clearTimeout(timeoutId) })
    },[]);

    /*useLayoutEffect(()=>{
        async function fetchData() {
            const nouns = await fetch("/json/v9/grammar/livenouns.json")
                .then(res => {
                    console.log(res.text());
                    return res;
                })
                .then(res => res.json());
            setRawNouns(nouns);
        }
        fetchData();
    }, []);*/

    //const [nounsCopy, setNounsCopy] = useState(JSON.stringify(nouns, null, "\t"));

    const words = useMemo(()=>{

        if (rawNouns) {

            nouns = JSON.parse(JSON.stringify(rawNouns));

            const casePreps = cases.map(cas => cas.preps || []).flatten();
            //console.log(casePreps);
            let words = getWords(casePreps);
            for (let i = 0, l = nouns.length; i < l; i++) {
                const word = str2word(data, nouns[i].wordStr).word;
                nouns[i].id = word.id;
                nouns[i].group = parseInt(word.rule.toString()[1]);
                words.push(word);
                const wordPreps = nouns[i].declension
                    .filter(d=>d.hasOwnProperty("preps"))
                    .map(d=>d.preps.map(prep=>prep.lv)).flatten()
                    .filter(p=>!casePreps.includes(p));
                //console.log("word preps not in case preps:", wordPreps);
                words = words.concat(getWords(wordPreps));
            }
            return words.uniqueByKey("id");

        }

    }, [rawNouns]);

    const groups = useMemo(()=> {
        if (words) {
            return [1, 2, 3, 4, 5, 6].map((dec) => (
            {
                title: labels.nouns.groups[dec],
                words: nouns.filter(noun => noun.group === dec).map(noun => noun.wordStr)
            }))
        }
    }, [words]);

    useMemo(()=>{
        if (words) {
            //window.sessionStorage.removeItem("liveNouns");
            setPreps(()=> {
                const preps = JSON.parse(window.sessionStorage.getItem("liveNouns")) || [];

                nouns.forEach(noun => {
                    [0, 1, 2, 3, 4, 5, 6].forEach(cas => {
                        const caseDeclensions = noun.declension
                            .filter(d => d.param.cas === cas && d.hasOwnProperty("preps"));
                        caseDeclensions.forEach(declension => {
                            declension.id = noun.id + cas;
                            declension.preps.forEach((p, i) => { p.id = declension.id + i });
                            if (!preps.find(s =>
                                s.declension === declension.id)) {
                                preps.push({
                                    declension: declension.id,
                                    prep: false // declension.preps[0].id
                                });
                            }
                        })
                    });
                });

                return preps;
            });
            //console.log("updated nouns:", nouns);
        }
    }, [words]);

    useMemo(()=>{
        if (preps) {
            //console.log("state changed, saving new to sessionStorage...");
            window.sessionStorage.setItem("liveNouns", JSON.stringify(preps));
        }
    }, [preps]);


    //console.log(words.map(w=>lemma2str(w.lemma)));
    //console.log(words.map(w=>lemma2str(w.lemma)));

    //console.log(groups);

    if (status.error) return <div>Error: {status.error.message} {window.navigator.userAgent}</div>;
    if (!status.wasLoaded) return <Spinner />;

    return (
        <Content className="grammar nouns">
            <div className="legend">
                <span className="soft">&nbsp;</span>&nbsp;&mdash;&nbsp;
                <span className="trans">смягчение согласных</span>
                <span className="soften">c <Arrow right /> č</span>
                <span className="soften">d <Arrow right /> ž</span>
                <span className="soften">l <Arrow right /> ļ</span>
                <span className="soften">m <Arrow right /> mj</span>
                <span className="soften">n <Arrow right /> ņ</span>
                <span className="soften">p <Arrow right /> pj</span>
                <span className="soften">s, t <Arrow right /> š</span>
                <span className="soften">v <Arrow right /> vj</span>
            </div>
            <header>
                <h1>Lietvārdu locīšana</h1>
                <h1 className="trans">склонение существительных</h1>
            </header>
            <LiveTable {...{
                data: {morphs: data.morphs, words},
                box, groups, preps, setPreps
            }} />

        </Content>
    )
}

export default Declension;
