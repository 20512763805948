import React, { useState, useEffect } from 'react';
import { getStartDate, addDays, abbr } from "../lang/time";
import { Word } from "./Wordform";

function Day(props) {

    const { startDate, week, weekday, setTouch, range } = props;

    const today = new Date();

    const addendum = ( (weekday===0?7:weekday)  + week * 7) - 1;

    const date = addDays(startDate, addendum);

    const touchstart = (e)=>{
        console.log("touchStart");
        setTouch(()=>{ return { touched: date, hovered: date } })
    };
    const touchmove = (e)=>{
        console.log("touchMove");
        setTouch((prev)=>{ return {...prev, hovered: date } })
    };
    const touchend = (e)=>{
        console.log("touchEnd");
        setTouch((prev)=>{ return {...prev, released: date } })
    };

    const mousedown = (e)=>{
        //console.log("mouse down");
        //console.log(e.type);
        e.preventDefault();
        setTouch((prev)=>{
            return {...prev, touched: date, released: false, hovered: date }
        })
    };
    const mouseup = (e)=>{
        //console.log("mouse up");
        e.preventDefault();
        setTouch((prev)=>{ return {...prev, released: date, touched: false } })
    };
    const mouseenter = (e)=>{
        e.preventDefault();
        //console.log(e.type, date2text(date));
        if (e.type === "touchmove") {
            const touch = e.changedTouches[0];
            const elem = document.elementFromPoint(touch.clientX, touch.clientY);
            if (elem) {
                //console.log(elem.tagName);
                if (elem.hasAttribute("data-date")) {
                    const dateFromAttr = new Date(elem.getAttribute("data-date"));
                    setTouch((prev)=> {
                        return {...prev, hovered: dateFromAttr}
                    });
                    //console.log(elem.getAttribute("data-date"));
                    //console.log(date2text(new Date(elem.getAttribute("data-date"))));
                }
            }
        } else {
            setTouch((prev)=>{ return {...prev, hovered: date } })
        }
    };

    const events = {
        onMouseDown: mousedown,
        onTouchStart: mousedown,
        onMouseUp: mouseup,
        onTouchEnd: mouseup,
        onMouseEnter: mouseenter,
        onTouchMove: mouseenter
    };

    let className = "day";

    if (range.start && range.end) {
        const start = range.start < range.end ? range.start : range.end;
        const end = range.start < range.end ? range.end : range.start;
        if (date >= start && date <= end) {
            className += " selected"
        }
    }

    if (date.toDateString() === today.toDateString()) {
        className += " today";
    }

    if (date < today && date.getMonth() !== today.getMonth()) {
        className += " past";
    }
    if (date > today && date.getMonth() !== today.getMonth()) {
        className += " future";
    }
    if (weekday === 6 || weekday === 0) {
        className += " weekend";
    }

    return (
        <td {...events} className={className}>
            <div className="date" data-date={date.toDateString()}>
                <div className="number">{date.getDate()}</div>
            </div>
        </td>
    )

}

function date2text(date) {
    return (
        Object.prototype.toString.call(date) === '[object Date]' ?
            `${date.getDate()}/${(()=>{const m = date.getMonth() + 1; return m < 10 ? "0"+m : m })()}` :
            ""
    )
}

function Calendar(props) {

    const { date, range, setRange } = props;

    //const date = new Date(time.year, time.month, time.day);

    const daysOfWeek = props.data.words
        .filter(word => word.hasOwnProperty("value") ? word.value.hasOwnProperty("weekday") : false);

    const startDate = getStartDate(date);

    //console.log("Calendar: daysOfWeek:", daysOfWeek);

    const [touch, setTouch] = useState({});
    //const [range, setRange] = useState({});


    useEffect(()=>{
        if (touch.touched) {

            setRange((p)=> {
                return {...p, start: touch.touched
                    //singleDayTouched: touch.touched.toDateString() === p.end.toDateString()
                }
            });
            if (touch.hovered) {
                setRange((p)=> {
                    const newRange = {...p};
                    if (newRange.start > touch.hovered) {
                        newRange.end = newRange.start;
                        newRange.start = touch.hovered;
                    } else {
                        newRange.end = touch.hovered;
                    }
                    return newRange;
                    //return {...p, end: touch.hovered }
                });
            }
        }

        if (touch.released) {
            //console.log("touch released");
            if (range.start && range.end) {
                if (range.start.toDateString() === range.end.toDateString()) {
                    if (range.start.toDateString() === touch.released.toDateString()) {
                        if (range.singleDay) {
                            //console.log("released on single selected day");
                            if (range.singleDay.toDateString() === touch.released.toDateString()) {
                                //console.log("...second time");
                                setRange({});
                            } else {
                                //console.log("...first time");
                                setRange(p => { return {...p, singleDay: touch.released } });
                            }
                        } else {
                            setRange(p => { return {...p, singleDay: touch.released } });
                        }
                    }
                }
            }
            setTouch({});
        }

    }, [touch]);

    /*useEffect(()=>{
        if (range.start && range.end) {
            const start = range.start < range.end ? range.start : range.end;
            const end = range.start < range.end ? range.end : range.start;
            //console.log(`${date2text(start)} — ${date2text(end)}`);
        }
    }, [range.start, range.end]);*/


    return (
        
            <table className="touchObject">
                <tbody>
                <tr>{
                    [1, 2, 3, 4, 5, 6, 0].map(weekday => (
                        <th key={weekday} className={weekday===0||weekday===6?"weekend":null}>
                            <Word
                                form={abbr.daysOfWeek[weekday].lv}
                                word={daysOfWeek.find(d => d.value.weekday === weekday)}
                                box={props.box}
                            />
                        </th>
                    ))
                }</tr>
                {
                    [0, 1, 2, 3, 4, 5].map(week => (
                        <tr key={week}>{
                            [1, 2, 3, 4, 5, 6, 0].map(weekday => (
                                <Day setTouch={setTouch} key={weekday}
                                     range={range}
                                     startDate={startDate} week={week} weekday={weekday}/>
                            ))
                        }</tr>
                    ))
                }</tbody>
            </table>
    )
}

export default React.memo(Calendar, (prev, next)=>(prev.range === next.range));
