
/*eslint no-extend-native: ["error", { "exceptions": ["Array", "Object"] }]*/

/*if (!Array.prototype.flatten0) Object.defineProperty(Array.prototype, "flatten0", {
    value: function () {
        // https://stackoverflow.com/questions/10865025/merge-flatten-an-array-of-arrays-in-javascript
        return this.reduce(function (flat, toFlatten) {
            return flat.concat(Array.isArray(toFlatten) ? toFlatten.flatten0() : toFlatten);
        }, []);
    }
});*/

if (!Array.prototype.flatten) Object.defineProperty(Array.prototype, "flatten", {
    value: function(result = []) {
        for (let i = 0, length = this.length; i < length; i++) {
            const value = this[i];
            if (Array.isArray(value)) {
                value.flatten(result);
            } else {
                result.push(value);
            }
        }
        return result;
    }
});

if (!Array.prototype.unique) Object.defineProperty(Array.prototype, "unique", {
    value: function () {
        return this.filter(function (c, index, self) { return self.indexOf(c) === index });
    }
});

if (!String.prototype.cap) Object.defineProperty(Array.prototype, "cap", {
    value: function () {
        return this.charAt(0).toUpperCase() + this.slice(1);
    }
});

export function cap(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function _(cond, obj, func) {
    return cond ? func(obj) : obj;
}

if (!Array.prototype.uniqueByKey) Object.defineProperty(Array.prototype, "uniqueByKey", {
    value: function (key) {
        // https://dev.to/saigowthamr/how-to-remove-duplicate-objects-from-an-array-javascript-48ok
        return this
            .map(e => e[key])
            // store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)
            // eliminate the dead keys & store unique objects
            .filter(e => this[e]).map(e => this[e]);
    }
});

export function aiove(val, inc) {
    return Array.isArray(val) ? val.indexOf(inc) !== -1 : val === inc;
}

export function aov(obj, func) {
    if (Array.isArray(obj)) {
        let l = obj.length, x;
        for (let i = 0; i < l; i++) {
            x = func(obj[i], i);
            if (x===true) break;
        }
    } else {
        func(obj);
    }
}

export function aovmap(obj, func) {
    if (Array.isArray(obj)) {
        //console.log(obj);
        //console.log("is array");
        /*const res = obj.map((elem, i) => {
            console.log(elem);
            return func(elem, i)
        });
        console.log(res);*/
        return obj.map((elem, i) => func(elem, i))
    } else {
        return func(obj, 0);
    }
}

export const partition = (array, filter) => {
    // https://stackoverflow.com/questions/11731072/dividing-an-array-by-filter-function
    let pass = [], fail = [];
    array.forEach((e, idx, arr) => (filter(e, idx, arr) ? pass : fail).push(e));
    return [pass, fail];
}

//console.log(!Array.prototype.flatten ? "flatten undefined" : "flatten defined");

export function generateId() {
    function S4() {
        return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    }
    return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
}

export function getType(obj) {
    return /^\[object (\w+)]$/.exec(Object.prototype.toString.call(obj))[1].toLowerCase();
}

export function getKeyByVal(obj, val) {
    const keys = Object.keys(obj);
    for (var i=0; i<keys.length; i++) {
        if ([].concat(obj[keys[i]]).includes(val)) return keys[i];
    }
    return false;
}

export function filterKeysByVal(obj, val) {
    let res = [];
    const keys = Object.keys(obj);
    for (let i=0; i<keys.length; i++) {
        if ([].concat(obj[keys[i]]).includes(val)) res.push(keys[i]);
    }
    return res;
}

export function getValFromFuncOrVar(from) {
    if (getType(from) === "function") return from.apply(null, [].slice.call(arguments, 1));
    return from;
}

export function printObj(obj) {

    //let str = "";

    Object.keys(obj).forEach(key => {
        if (getType(obj[key]) === "array") {
            console.log(key + ": [");
            obj[key].forEach(e => {
                if (getType(e) === "object") {
                    /*console.log("{");
                     printObj(e);
                     console.log("}");*/
                    console.log(JSON.stringify(e));
                } else {
                    console.log(e);
                }
            });
            console.log("]");
        }
        if (getType(obj[key]) === "object") {
            /*console.log("{");
             printObj(obj[key]);
             console.log("}");*/
            console.log(key + ": " + JSON.stringify(obj[key]));
        }
        if (getType(obj[key]) === "string") {
            console.log(key + ": '" + obj[key] + "'");
        }
        if (getType(obj[key]) === "number") {
            console.log(key + ": %c" + obj[key], "color: blue;");
        }
    });

}

export function hasDeepProperty(sourceObj, propString) {
    if (/[^.]+\.[^.]+/.test(propString)) {
        const prop = propString.split(".");
        let obj = sourceObj;
        for (let i = 0; i < prop.length; i++) {
            //console.log("checking object:", obj);
            //console.log("property:", prop[i]);
            if (obj.hasOwnProperty(prop[i])) {
                //console.log("TRUE");
                obj = obj[prop[i]];
            } else {
                return false;
            }
        }
        return true;
    } else {
        //console.log("checking object:", sourceObj);
        //console.log("prop:", propString);
        //console.log(sourceObj.hasOwnProperty(propString));
        return sourceObj.hasOwnProperty(propString);
    }
}

export function getDeepPropValue(obj, propString) {
    var prop = propString.split("."), res = obj;
    for (let i=0; i<prop.length; i++) {
        res = res[prop[i]];
        if (res === undefined) break;
    }
    return res;
}

export function shuffleArray(arr) {
    // https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
    const array = deepCopy(arr);
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

export function findParentOfValue(obj, levelKey, key, val) {
    //console.log(obj);
    let res = false;
    if (Array.isArray(obj)) {
        for (let i=0, len=obj.length; i<len; i++) {
            res = findParentOfValue(obj[i], levelKey, key, val);
            if (res !== false) return res;
        }
    } else {
        if (obj.hasOwnProperty(key)) {
            if (obj[key]===val) return obj;
        }
        if (!obj.hasOwnProperty(levelKey)) {
            return false;
        }
        res = findParentOfValue(obj[levelKey], levelKey, key, val);
    }
    return res;
}

export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function randomStr(arr) {
    const sum = arr.reduce((acc, e) => acc + e.chance, 0);
    //console.log(sum);
    const random = getRandomInt(0, sum-1);
    //console.log(random);
    let acc = 0;

    for (let i=0; i<arr.length; i++) {
        const min = acc;
        const max = arr[i].chance + acc - 1;
        //console.log(`for ${arr[i].str}: ${min}-${max}`);
        acc += arr[i].chance;
        if (random >= min && random <=max) return arr[i].str;
    }
    return null;
}

export function absorbEvent(e) {

    const event = e.nativeEvent || e;

    event.preventDefault();
    e.nativeEvent ? event.stopImmediatePropagation() : event.stopPropagation();
    event.cancelBubble = true;
    event.returnValue = false;
    return false;
}

export function diffDays(date1, date2) {
    // https://stackoverflow.com/questions/3224834/get-difference-between-2-dates-in-javascript
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utcDate1 = new Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const utcDate2 = new Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
    return Math.floor((utcDate2 - utcDate1) / _MS_PER_DAY);
}

export function compareArrays(arr1, arr2) {
    if (arr1.length !== arr2.length) { return false }
    for (let i=0; i<arr1.length; i++) {
        if (arr1[i] !== arr2[i]) { return false }
    }
    return true;
}

export function objectsEqual(x, y) {
    // https://stackoverflow.com/questions/1068834/object-comparison-in-javascript
    if ( x === y ) return true;
    // if both x and y are null or undefined and exactly the same

    if ( ! ( x instanceof Object ) || ! ( y instanceof Object ) ) return false;
    // if they are not strictly equal, they both need to be Objects

    if ( x.constructor !== y.constructor ) return false;
    // they must have the exact same prototype chain, the closest we can do is
    // test there constructor.

    for ( var p in x ) {
        if ( ! x.hasOwnProperty( p ) ) continue;
        // other properties were tested using x.constructor === y.constructor

        if ( ! y.hasOwnProperty( p ) ) return false;
        // allows to compare x[ p ] and y[ p ] when set to undefined

        if ( x[ p ] === y[ p ] ) continue;
        // if they have the same strict value or identity then they are equal

        if ( typeof( x[ p ] ) !== "object" ) return false;
        // Numbers, Strings, Functions, Booleans must be strictly equal

        if ( ! objectsEqual( x[ p ],  y[ p ] ) ) return false;
        // Objects and Arrays must be tested recursively
    }

    for ( p in y ) {
        if ( y.hasOwnProperty( p ) && ! x.hasOwnProperty( p ) ) return false;
        // allows x[ p ] to be set to undefined
    }
    return true;

}

export function rad2deg(rad) { return rad * (180 / Math.PI) }

export function sameCase(origin, str) {
    if (origin.toUpperCase() === origin && origin.toLowerCase() !== origin) return str.toUpperCase();
    if (origin.toLowerCase() === origin && origin.toUpperCase() !== origin) return str.toLowerCase();
    return str;
}

export function cartesian(arg) {
    var r = [], max = arg.length-1;
    function helper(arr, i) {
        for (var j=0, l=arg[i].length; j<l; j++) {
            var a = arr.slice(0); // clone arr
            a.push(arg[i][j]);
            if (i==max)
                r.push(a);
            else
                helper(a, i+1);
        }
    }
    helper([], 0);
    return r;
}

export function rxToggleClass(element, rx, force) {
    if (element) {
        const classList = [...element.classList];
        const className = classList.find(c => rx.test(c));
        //console.log(className);
        element.classList.toggle(className, force);
    }
}

export function toggleClass(operator, {classList, className, newClassName}) {
    const updated = [...classList];
    switch (operator) {
        case "add": {
            if (updated.indexOf(className) < 0) {
                updated.push(className)
            }
            break;
        }
        case "remove": {
            const index = updated.indexOf(className);
            if (index !== -1) updated.splice(index, 1);
            break;
        }
        case "toggle": {
            const index = updated.indexOf(className);
            if (index !== -1) {
                updated.splice(index, 1)
            } else {
                updated.push(className)
            }
            break;
        }
        case "replace": {
            const index = updated.indexOf(className);
            if (index !== -1) updated.splice(index, 1);
            if (updated.indexOf(newClassName) < 0) {
                updated.push(newClassName)
            }
            break;
        }
        default: {

        }
    }
    return updated;
}

export function camelCase(str) {
    const attrs = {
        "class": "className",
        "rowspan": "rowSpan",
        "colspan": "colSpan",
        "srcset": "srcSet",
        "strokewidth": "strokeWidth"
    };
    if (attrs.hasOwnProperty(str)) return attrs[str];
    // https://stackoverflow.com/questions/2970525/converting-any-string-into-camel-case/37041217
    return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

export const deepCopy = (inObject) => {
    let outObject, value, key
    // https://medium.com/javascript-in-plain-english/how-to-deep-copy-objects-and-arrays-in-javascript-7c911359b089

    if (typeof inObject !== "object" || inObject === null) {
      return inObject // Return the value if inObject is not an object
    }
  
    // Create an array or object to hold the values
    outObject = Array.isArray(inObject) ? [] : {}
  
    for (key in inObject) {
      value = inObject[key]
  
      // Recursively (deep) copy for nested objects, including arrays
      outObject[key] = deepCopy(value)
    }
  
    return outObject
}
