import React from 'react';
import { lemma2str, punctuation } from "../lang/lang2";
import { removeDiactritics } from "../lang/parse";
import { generateId } from "../prototypes";
import { SnipCard, SnipWide, SnipWordShort } from "../components/Snippets";

export function removeLeadingPunctuation(str) {
    const rx = new RegExp(
        `^[${punctuation}]+`
    );
    return str.replace(rx, "");
}

export function removePunctuation(str) {
    const rx = new RegExp(
        `[${punctuation}]+`, "g"
    );
    
    // keep spaces
    const parts = str.split(" ");
    return parts.map(s => s.replace(rx, "")).join(" ");
}

export function removeEntities(str) {
    return str.replace(/&nbsp;/g, " ").replace(/&[^&;]+;/g, "");
}

function clean(value) {
    //console.log(value);
    return removeDiactritics(
        removeLeadingPunctuation(
            removeEntities(
                value.toLowerCase().trim().replace(/\u0301/g, "")
            )
        )
    );
}

export const comparators = {
    "startsWith": (query, value) => {
        //console.log(query);
        const rx = new RegExp(`^${clean(query.str)}`);

        if (Array.isArray(value)) {
            for (let i=0; i<value.length; i++) {
                if (rx.test(clean(value[i]))) return true;
            }
            return false;
        } else {
            return rx.test(clean(value))
        }
    },
    "oneOfWordsEquals": (query, value) => {
        const rx = new RegExp(`[${punctuation}]`);
        const words = clean(value).split(rx).filter(s=>s!=="");
        for (let i=0; i<words.length; i++) {
            if (clean(query.str) === words[i] || "ne"+clean(query.str) === words[i]) return true;
        }
        return false;
    },
    "equals": (query, value) => {
        //console.log("EQUALS");
        /*if (query.str === "meg") {
            console.log(query, value);
        }*/
        if (Array.isArray(value)) {
            for (let i=0; i<value.length; i++) {
                if (clean(query.str) === clean(value[i])) return true;
            }
            return false;
        } else {
            return clean(query.str) === clean(value)
        }
    }
};

export const converters = {
    "lemma": (value)=>{ return lemma2str(value) },
    "chainLemma": (value)=>{
        return value
            .replace(/\[[^\]]+\]/g, "")
            .replace(/::[^\{\}]+\}/g, "")
            .replace(/[\{\}]/g, "");
    },
    "trans": (value)=>{
        return Array.isArray(value) ? value.flatten() : value
    },
    "roots": (value)=>{
        //console.log(value.filter(v=>v.hasOwnProperty("root")).map(v=>v.root));
        return value.filter(v=>v.hasOwnProperty("root")).map(v=>v.root);
    }
};

export const seekers = {
    "lv": [
        {
            type: ["lv", "dv", "iv", "sv", "av"],
            func: { comparator: "equals", converter: "roots" },
            key: "lemma",
            component: props => <SnipCard key={generateId()} {...props} />,
            desc: "(lat) VERB, NOUN, ADJECTIVE, NUMERAL: lemma is equal to query",
            search: "general"
        },
        {
            type: ["lv", "dv", "iv", "sv", "av", "pv"],
            func: { comparator: "startsWith", converter: "lemma"},
            key: "lemma",
            component: props => <SnipCard key={generateId()} {...props} />,
            desc: "(lat) VERB, NOUN, ADJECTIVE, NUMERAL: lemma starts with query",
            search: "general"
        },
        {
            type: "ke",
            func: { comparator: ["startsWith", "oneOfWordsEquals"], converter: "chainLemma" },
            key: ["lv.default"],
            component: props => <SnipWide key={generateId()} {...props} />,
            desc: "(lat) CHAIN: lemma starts with query",
            search: "general"
        },
        {
            type: "ke",
            func: { comparator: "equals" },
            key: "lv.keywords",
            component: props => <SnipWide key={generateId()} {...props} />,
            desc: "(lat) CHAIN: one of keywords is equal to query",
            search: "general"
        },

        {
            type: "dv",
            func: { comparator: "startsWith", converter: "lemma"},
            key: "lemma",
            component: props => <SnipWordShort className="short" key={generateId()} {...props} />,
            desc: "(lat) VERB: lemma starts with query",
            search: "conjugation"
        }

    ],
    "ru": [
        {
            type: ["lv", "dv", "iv", "av", "pv", "sv"],
            func: { comparator: "startsWith", converter: "trans"},
            key: ["ru.trans"],
            component: props => <SnipCard key={generateId()} {...props} />,
            desc: "(ru) VERB, NOUN: translation starts with query",
            search: "general"
        },
        {
            type: "ke",
            func: { comparator: ["startsWith", "oneOfWordsEquals"] },
            key: "ru.default",
            component: props => <SnipWide key={generateId()} {...props} />,
            desc: "(ru) CHAIN: translation starts with query",
            search: "general"
        },
        {
            type: "ke",
            func: { comparator: "equals" },
            key: "ru.keywords",
            component: props => <SnipWide key={generateId()} {...props} />,
            desc: "(lat) CHAIN: one of keywords is equal to query",
            search: "general"
        }
    ]
};
