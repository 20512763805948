import React, { useState, useEffect, useRef } from 'react';
import Content from "../components/Content";
import { Chainform, TransWord, ChainLong } from "../components/Wordform";
import { Google, CopyUrl, Footer } from "../components/Footer";
import { getChainByPath, chain2str, parseChain, entities2unicode } from "../lang/parse";
import { wordType } from "../lang/lang2";
import "./Chain.css";
import { emo } from "../components/emo";
import { SnipCard, SnipWide } from "../components/Snippets";
import { NewSearch } from "../components/Search";
import { Lemma } from "../components/EntryLemma";
import { Media } from "../components/Media";

function Lemma0(props) {

    /*console.log(chain2path({
        lv: {default: "&bdquo;Dāmas[lv:dāma] sarosās[dv:sarosīties]! Krievu miljardieris Abramovičš {izšķī&shy;ries no&nbsp;sievas::izšķirties no sievas}&ldquo; (nra.lv)"}
    }));

    const test = {
        lv: {default: "&bdquo;Dāmas[lv:dāma] sarosās[dv:sarosīties]! Krievu miljardieris Abramovičš {izšķī&shy;ries no&nbsp;sievas::izšķirties no sievas}&ldquo; (nra.lv)"}
    };

    console.log(removeDiactritics(removeLeadingPunctuation(removeEntities(test.lv.default))));*/

    return (
        <>
        <h1 className="lemma">
            <NewSearch search="general"><Chainform form={props.chain.lv.default} />{emo(props.chain)}</NewSearch>
        </h1>
        <div className="trans"><TransWord word={props.chain} /></div>
        </>
    );
}

function Decompose(props) {

    const chain = props.chain;

    let ids = [];

    function decompose(chain) {
        let res = [];
        parseChain(props.data, chain).forEach(part => {
            //console.log(part);
            if (part.hasOwnProperty("chain")) {
                //console.log(part.chain);
                //console.log(`${part.usedForm} - ${chain2str(part.chain)}`);
                if (!ids.includes(part.chain.id)) {
                    res.push(part.chain);
                    ids.push(chain.id);
                    res.push(decompose(part.chain));
                }
            } else {
                if (part.hasOwnProperty("word")) {
                    //console.log(`${part.usedForm} - ${lemma2str(part.word.lemma)}`);
                    if (!ids.includes(part.word.id)) { res.push(part.word); ids.push(part.word.id) }
                }
            }
        });
        return res;
    }

    const items = decompose(chain);

    //console.log(items);

    return <div className="decompose">{
        items.map((item, i) => (
            Array.isArray(item) ? item.map((subitem, j) => (
                <SnipCard className="sublevel" key={"sub"+j} item={subitem} data={props.data} transLimit={false} />
            )) : wordType(item).short === "ke" ?
                <SnipWide key={i} item={item} data={props.data} /> :
                <SnipCard key={i} item={item} data={props.data} transLimit={false} />
        ))
    }</div>

}

function Chain(props) {

    const { chains } = props.data;
    const box = props.box;

    const chain = getChainByPath(chains, props.url.match.params.lemma);

    const lemma = chain2str(chain);

    const [media, setMedia] = useState();
    
    const id = useRef();

    useEffect(()=>{

        fetch("/json/v9/media.json", { cache: "no-store" })
            .then(res => res.json())
            .then(data => {
                setMedia(data);
            });

    }, []);

    //console.log(chain);

    useEffect(()=>{
        document.title = entities2unicode(chain2str(chain));
    }, [chain]);

    return(
        <>
        <Content className="chain">
            <Lemma word={chain} id={id} />
            <Decompose chain={chain} data={props.data} />
            <Media mediaHeader={<><h2>Ko rakstīja avīzes</h2><div className="trans">Что писали газеты</div></>}
                   media={media} chain={chain} data={props.data} box={box} />
            <Footer><Google lemma={lemma} /><CopyUrl /></Footer>
            <div className="hidden">Nekādā gadījumā nepievērsiet uzmanību šiem cipariem:<br />
                {chain.id}<br/>
                Paldies!</div>
        </Content>
        </>
    );
    
}

export default Chain;