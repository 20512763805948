import React from 'react';
import { wordType } from "../../lang/lang2";
import Declension from "./Declension";
import DeclensionAdjective from "./DeclensionAdjective";
import DeclensionAdverb from "./DeclensionAdverb";
import DeclensionNumeral from "./DeclensionNumeral";
import SimpleTense from "./SimpleTense";
import CompoundTense from "./CompoundTense";
import Debitive from "./Debitive";
import Imperative from "./Imperative";
import { RectionCas } from "./Rection";
import "./Grammar.css";

function Grammar(props) {

    const { morphs, word, words } = props;
    
    const version = props.version || "entry";

    let grammar;

    const aux = words.find(w=>w.id==="780c5816-70ab-5706-2a5a-9a4a2f51b606");

    const parts = {
        dv: {
            entry: <>
            <SimpleTense morphs={morphs} word={word} />
            <CompoundTense morphs={morphs} word={word} aux={aux} />
            <Imperative morphs={morphs} word={word} />
            <Debitive morphs={morphs} word={word} aux={aux} />
            </>,
            box: <SimpleTense morphs={morphs} word={word} />
        },
        lv: {
            entry: <Declension morphs={morphs} word={word} />,
            box: <Declension morphs={morphs} word={word} />
        },
        iv: {
            entry: <DeclensionAdjective morphs={morphs} word={word} />,
            box: <DeclensionAdjective morphs={morphs} word={word} />
        },
        av: {
            entry: <>
            <DeclensionAdverb morphs={morphs} word={word} />
            <RectionCas word={word} />
            </>,
            box: <DeclensionAdverb morphs={morphs} word={word} />
        },
        sv: {
            entry: <DeclensionNumeral morphs={morphs} word={word} />,
            box: <DeclensionNumeral morphs={morphs} word={word} />
        },
        pv: {
            entry: <RectionCas word={word} />,
            box: <RectionCas word={word} />
        }
    };

    switch (wordType(word).short) {
        case "dv": {
            grammar = parts.dv[version];
            break;
        }
        case "lv": {
            grammar = parts.lv[version];
            break;
        }
        case "iv": {
            grammar = parts.iv[version];
            break;
        }
        case "av": {
            grammar = parts.av[version];
            break;
        }
        case "sv": {
            grammar = parts.sv[version];
            break;
        }
        case "pv": {
            grammar = parts.pv[version];
            break;
        }
        default:
            grammar = <>
                Was ist das?
            </>
    }

    return(<div className="part grammar">{grammar}</div>);
}


export default Grammar;