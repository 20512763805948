import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import Spinner from "./Spinner";

function HTML(props) {
    console.log(props);
    const [data, setData] = useState({});
    const [status, setStatus] = useState({ wasLoaded: false, error: false });

    useEffect(() => {
        //console.log("fetching", `/html/v9/${props.file}.html?${Math.random()}`);
        const fetchFile = () => {
            //fetch(`/html/v9/${props.file}.html?${Math.random()}`)
            fetch(`/html/v9/${props.file}.html`, {cache: "default"})
                .then((response) => {
                    //console.log(response.status);
                    //console.log(response);
                    return response.text();
                })
                .then((html) => {
                    const parser = new DOMParser();
                    setData({ html: parse(parser.parseFromString(html, "text/html").body.innerHTML) });
                    setStatus({ wasLoaded: true });
                })
                .catch(err => {
                    console.log(err);
                    setStatus({ error: err });
                });
        };

        fetchFile();

    }, [props]);

    if (status.error) {
        console.log("error");
        return <div>Kļūda: {status.error.message}</div>;
    }
    if (!status.wasLoaded) return <Spinner />;
    
    return(
        <>
        <div>{data.html}</div>
        </>
    );
    
}

export default HTML;