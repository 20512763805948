import React, { useEffect } from "react";
import qcomponents, { getQuestions } from "./qcomponents";
import { objectsEqual } from "../../../prototypes";
import DataContext from "../DataContext";
import { attrs2props } from "./renderNode";

const QNode = React.memo(({ node, qprops }) => {

    const { data, box } = React.useContext(DataContext);

    // console.log("QNode qprops.exerciseId:", qprops.exerciseId);
    // console.log("QNode counter:", qprops.counter);

    const QComponent = qcomponents.find(qc => qc.tag === node.nodeName.toLowerCase());

    return (
        <QComponent.component key={qprops.id} {...{...attrs2props(node), ...qprops } } {...getQuestions({node, data}) } />
    )
}, (prev, next) => {
    
    // console.log("prev state:", prev.qprops.state);
    // console.log("next state:", next.qprops.state);

    // const res = objectsEqual(prev.qprops.state, next.qprops.state);
    // console.log("prev state === next state ?", res);
    /* if (res === true) {
        console.log(`---- QNode ${next.qprops.id} NOT RENDER ----`);
    } */

    // return false;

    return (
        objectsEqual(prev.qprops.state, next.qprops.state)
    )

});

export default QNode;
