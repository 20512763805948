import React, { useEffect, useRef } from "react";
import { entities2unicode } from "../../lang/parse";

const checkedAll = (state) => {
    return state.every(item => item.checked);
}
const uncheckedAllExcept = (state, label) => {
    return state.filter(item => item.label !== label).every(item => !item.checked);
}

export const Radio = ({ state, setState, labelAll, className }) => {
    
    const handleClick = (label) => {

        const index = state.findIndex(item => item.label === label);

        setState(prev => prev.map((item, i) => {
            return i===index ? {...item, checked: true } : {...item, checked: false }
        }))    
    }

    const classList = ["checkbox"];
    if (className) classList.push(className);
    
    return (
        <div className={classList.join(" ")}>

            { labelAll &&
            <div onClick={()=>{
                setState(prev=>prev.map(item=>({...item, checked: true})))
            }} className={`label all${state.every(item => item.checked)?" checked":""}`}>{entities2unicode(labelAll||"all")}</div>
            }

            { state.map((item, i) => (
                <div key={i} onClick={()=>{ handleClick(item.label) }} className={`label${item.checked?" checked":""}`}>
                    {entities2unicode(item.label)}
                </div>
            )) }
        </div>
    )
};

export const Checkbox = ({ state, setState, labelAll }) => {

    const started = useRef(false);

    const handleClick = (label) => {

        const index = state.findIndex(item => item.label === label);

        /* if (checkedAll(state) && !started.current) {
            setState((prev)=>{
                // started.current = true;
                return prev.map(item => item.label !== label ? ({...item, checked: false}) : item)
            });
            return;
        }

        if (uncheckedAllExcept(state, label)) {
            setState((prev)=>{
                return prev.map(item => ({...item, checked: true}))
            });
            return;
        } */

        setState(prev => prev.map((item, i) => {
            return i===index ? {...item, checked: !item.checked } : item
        }))    

    }

    return (
        <div className="checkbox">
            <div onClick={()=>{ setState(prev=>prev.map(item=>({...item, checked: true}))) }} className={`label all${state.every(item => item.checked)?" checked":""}`}>{labelAll||"all"}</div>
            { state.map((item, i) => (
                <div key={i} onClick={()=>{ handleClick(item.label) }} className={`label${item.checked?" checked":""}`}><span>{"\u2714"}</span>{item.label}</div>
            )) }
        </div>
    )
};

export const Ghost = ({ children, className, to }) => {

    const childrenRef = useRef();
    const shadowRef = useRef();

    const timeouts = useRef([]);
    

    useEffect(()=>{
        if (childrenRef.current && shadowRef.current) {

            {
                const rect = childrenRef.current.getBoundingClientRect();
                // console.log(rect);
                shadowRef.current.style.left = `${rect.left}px`;
                shadowRef.current.style.top = `${rect.top}px`;
                const compStyles = window.getComputedStyle(childrenRef.current);
                ["width", "height", "border", "border-radius", "background-color"].forEach(property => {
                    // console.log(compStyles.getPropertyValue(property));
                    shadowRef.current.style.setProperty(
                        property,
                        compStyles.getPropertyValue(property),
                        compStyles.getPropertyPriority(property)
                    );
                });

            }

            if (to) {
                // console.log(to);

                // console.log("set visibility to visible");
                shadowRef.current.style.visibility = "visible";
                // console.log(shadowRef.current.style.visibility);

                shadowRef.current.style.transition = "all 0.25s ease";

                timeouts.current.push(setTimeout(()=>{
                    shadowRef.current.style.removeProperty("transition");
                    // console.log("set visibility to hidden");
                    shadowRef.current.style.visibility = "hidden";
                    // console.log(shadowRef.current.style.visibility);
                }, 400));

                timeouts.current.push(setTimeout(()=>{

                    const rect = to.getBoundingClientRect();
                    shadowRef.current.style.left = `${rect.left}px`;
                    shadowRef.current.style.top = `${rect.top}px`;
                    const compStyles = window.getComputedStyle(to);
                    ["width", "height", "border-width", "border-radius"].forEach(property => {
                        // console.log(property, compStyles.getPropertyValue(property));
                        shadowRef.current.style.setProperty(
                            property,
                            compStyles.getPropertyValue(property),
                            compStyles.getPropertyPriority(property)
                        );
                    });

                }, 200));
                
            } 

        }
        return ()=> {
            // console.log("clear timeouts");
            shadowRef.current.style.visibility = "hidden";
            timeouts.current.forEach(t=>{clearTimeout(t)});
        }
    }, [childrenRef, shadowRef, className]);

    return (
        <>
    
        <div className={className} ref={childrenRef} style={{ position: "relative", zIndex: 101 }}>
            { children }
        </div>
    
        <div ref={shadowRef} style={{
            position: "absolute", left: 0, top: 0, zIndex: 1,
            lineHeight: "1px", visibility: "hidden", fontSize: "1px"
        }}>&nbsp;</div>

        </>
    )
}