import { cartesian, getType, deepCopy, objectsEqual, partition } from "../../../prototypes";
import { parseParams, removeDiactritics } from "../../../lang/parse";
import { useRef, useEffect } from "react";

const ignore = {
    "accents": removeDiactritics,
    "uppercase": (a) => a.toLowerCase(),
    "strict": (a) => a,
    "fullstop": a => a.replace(/\.$/, ""),
    "everything": () => 1
};

export const correctAnswers = (qtype, arr) => {
    return mutators[qtype].correctAnswers(arr);
};

const mutators = {
    qtext: {
        answer: (a) => a.trim() === "" ? null : a,
        correctAnswers: a => {
            // console.log("mutate correct answers:", a);
            // console.log("text answer:", a.map(a => ({...a, answer:  correctAnswer2text(a)})))
            return a.map(a => ({...a, answer: correctAnswer2text(a)}))
        }
    },
    qradio: {
        answer: a => a === -1 ? null : a,
        correctAnswers: a => {
            // console.log("correct answers qradio:", a);
            // console.log("num answer:", a.map(a => ({ answer: a.answer[0] })));
            return a.map(a => ({ answer: a.answer[0] }));
        }
    }
};

export function loadAnswers(lessonId) {
    // console.log("loadAnswers fired");
    const lessonStates = JSON.parse(localStorage.getItem("lessons"));
    let res;
    if (lessonStates) {
        const lessonState = lessonStates.find(ls => ls.lesson === lessonId);
        // console.log("lessonState:", lessonState);
        if (lessonState) {
            res = {...lessonState.answers} || {}
        } else {
            res = {}
        }
    } else {
        res = {}
    }
    // console.log(res);
    return Object.keys(res).map(key => (
        { id: key, given: res[key].value, type: res[key].type, lessonId, exerciseId: res[key].exercise }
        ));
}

export function deleteAnswers(lessonId) {
    const lessonStates = JSON.parse(localStorage.getItem("lessons000"));
    console.log(lessonStates);
}

export function saveAnswers(answers, lessonId) {

    // console.log("Save:");
    // console.log("given answers:", answers);

    const lessonStates = JSON.parse(localStorage.getItem("lessons"))||[];

    const index = lessonStates.findIndex(ls => ls.lesson === lessonId);

    const lessonState = index < 0 ? { lesson: lessonId } : lessonStates[index];

    // console.log(lessonState);

    let saved = lessonState.answers||{};
    
    // console.log("already saved answers", saved);

    const updated = answers.reduce((obj, answer) => (
        answer.hasOwnProperty("given") && ![-1, false, null, ""].includes(answer.given) ?
        {...obj, [answer.id]: { value: answer.given, type: answer.type, exercise: answer.exerciseId } } : obj
    ), {});

    // console.log("updated answers:", updated);

    // console.log("are they equal?", objectsEqual(saved, updated));

    if (objectsEqual(saved, updated)) return;

    lessonState.answers = updated;

    // console.log("new lessonState:", lessonState);

    if (index < 0) {
        lessonStates.push(lessonState);
    } else {
        lessonStates[index] = lessonState;
    }

    localStorage.setItem("lessons", JSON.stringify(lessonStates));

}

export function splitAnswer(answerText) {

    let originArr = answerText.split(/(\[[^\]]*[a-z]\*[^\]]*\])/).filter(s=>s!=="");

    //console.log(originArr);

    let originToFork = [];

    originArr.forEach((entry, index) => {

        if (/^\[[^\]]*[a-z]\*[^\]]*\]$/.test(entry)) {
            const count = entry.match(/[a-z]\*/g).length;
            //console.log(`${entry} has ${count} star(s)`);
            const params = entry.split(/([a-z]\*)/);
            //console.log(params);
            let paramsToFork = [];
            params.forEach((param, paramIndex) => {
                if (/^[a-z]\*$/.test(param)) {
                    const count = parseParams[param[0]].values.length;
                    //console.log(`${parseParams[param[0]].param} has ${count} possible values`);
                    paramsToFork.push(parseParams[param[0]].values.map(v=>param[0]+v));
                } else {
                    paramsToFork.push([param]);
                }
            });
            //console.log(paramsToFork);
            //console.log(cartesian(paramsToFork).map(e => e.join("")));
            originToFork.push(cartesian(paramsToFork).map(e => e.join("")));
        } else {
            originToFork.push([entry]);
        }
    });

    //console.log(cartesian(originToFork).map(e => e.join("")));

    return cartesian(originToFork).map(e => e.join(""));
}

const match = (a, b, ignoramus=[]) => {
    //console.log("match: ignoramus:", ignoramus);
    let res, mutantA = a, mutantB = b;
    if (ignoramus.length > 0) {
        ignoramus.forEach(ignorance => {
            mutantA = ignore[ignorance](mutantA); mutantB = ignore[ignorance](mutantB);
        });
        //console.log("match: compare with ignorami applied:", mutantA, mutantB);
        res = mutantA === mutantB;
    } else {
        res = a === b;
    }
    return res;
};

function correctAnswer2text(answerObjWithAnswerArr) {
    return answerObjWithAnswerArr.answer.map(a => getType(a) === "object" ? a.usedForm : a).join("");
}

const matchAinB = (a, b, mutatorsA=[], mutatorsB=[]) => {

    // console.log("matchAinB: a:", a);
    // console.log("matchAinB: b:", b);

    let mutantA = a, mutantB = b;

    mutatorsA.forEach(mutator => {
        mutantA = mutator(mutantA);
    });
    mutatorsB.forEach(mutator => {
        mutantB = mutator(mutantB); 
    });

    //console.log(mutantA, mutantB);

    // console.log("matchAinB: mutantB:", mutantB);


    return mutantB.some((mb, i) => {

        // console.log("matchAinB: mutantA:", mutantA);
        // console.log("matchAinB: compare to mb:", mb.answer);
        // console.log("matchAinB: not mutated b:", b[i]);
 
        return match(mutantA, mb.answer, b[i] ? b[i].ignore||[] : [])
    });
};

export function check(answer) {

    // const answer = state.find(s => s.id === id);

    //console.log("check:", answer);

    if (answer) {
        if (!answer.hasOwnProperty("given") || !answer.hasOwnProperty("correct")) {
            // dispatch({ type: "check", id, result: null });
            return null;
        }
        if (answer.given === false || answer.given === "" || answer.given === -1 || answer.given === null) {
            // dispatch({ type: "check", id, result: null });
            return null;
        }
        const qtype = answer.type;
        // console.log("check:", answer.given, answer.correct);
        const result = matchAinB(answer.given, answer.correct, [mutators[qtype].answer], [mutators[qtype].correctAnswers]);
        // console.log(result);
        return result;
        // dispatch({ type: "check", id, result });
    }
    return null;
    
}

export function checkAll(state, dispatch) {

    const answers = deepCopy(state);

    for (let i=0, l=answers.length; i < l; i ++) {
        const answer = answers[i];
        if (!answer.hasOwnProperty("given")) {
            answer.result = null;
            continue;
        }
        if (answer.given === false || answer.given === "" || answer.given === -1) {
            answer.result = null;
            continue;
        }

        const qtype = answer.type;

        answer.result = matchAinB(answer.given, answer.correct, [mutators[qtype].answer], [mutators[qtype].correctAnswers]);
    
    }

    console.log(answers.map(a => a.result));

    dispatch({ type: "checkAll", payload: answers });

    return;
    
}

export const reducer = (answers, action) => {
    
    const updated = answers ? deepCopy(answers) : [];
    const index = updated.findIndex(answer => answer.id===action.id);
    switch (action.type) {
        case "load": {
            const ids = updated.map(a=>a.id);
            const [existing, nonexisting] = partition(action.payload, a=>ids.includes(a.id));
            return [...updated.map(answer => {
                // if type mismatch, give nothing
                const loaded = existing.find(a=>a.id===answer.id&&a.type===answer.type);
                if (loaded) {
                    const updatedAnswer = {...answer, given: loaded.given };
                    updatedAnswer.result = check(updatedAnswer);
                    return updatedAnswer;
                }
                return answer;
            }), ...nonexisting];
        }
        case "give": {
            // console.log("give action:");
            const { id, exerciseId } = action;
            // console.log("give: exerciseId", exerciseId);
            // console.log(`give: payload: "${action.payload}"`);
            if (index < 0) {
                // console.log("add new answer");
                const updatedAnswer = { id, exerciseId, given: action.payload };
                updatedAnswer.result = check(updatedAnswer);
                // console.log(`result: ${updatedAnswer.result}`);
                updated.push(updatedAnswer);
                // console.log(`id: ${exerciseId}/${id.replace(exerciseId,"/")}`);
                // console.log("answer:", updatedAnswer.given);
            } else {
                // console.log("update prev answer");
                updated[index].given = action.payload;
                updated[index].result = check(updated[index]);
                // console.log(`result: ${updated[index].result}`);
                // console.log(`id: ${exerciseId}/${id.replace(exerciseId,"/")}`);
                // console.log("answer:",  updated[index].given);
                // console.log("check result:", updated[index].result);
            }
            // saveAnswers(updated, lessonId);
            return updated;
        }
        case "correct": {
            // console.log(`%c***WARNING: set correct answers for ${action.id}`, "color:orange");
            const { id, exerciseId } = action;
            // console.log("correct: exerciseId", exerciseId);
            if (index < 0) {
                updated.push({ id, exerciseId, correct: action.payload.answers, type: action.payload.type });
            } else {
                updated[index].correct = action.payload.answers;
                updated[index].type = action.payload.type;
                updated[index].result = check(updated[index]);
            }
            // console.log("answers with correct:", updated);
            return updated;
        }
        case "check": {
            // updated = action.payload;
            if (index < 0) {
                updated.push({ id: action.id, result: action.payload });
            } else {
                updated[index].result = action.payload;
            }
            return updated;
        }
        case "flush": {
            return [
                ...updated.map(a => (
                    {...a, ...(action.payload.find(b => b.id===a.id)||{}), flush: !a.flush }
                )),
                ...action.payload.filter(b => !updated.some(a => a.id===b.id))
            ].map(answer => ({...answer, result: check(answer)}));
        }
        case "giveup": {
            return updated.map(answer => ({...answer, giveup: true}));
        }
        case "reset": {
            return updated.map(answer => ({...answer, giveup: false}));
        }
        case "clearall": {
            return updated.map(answer => ({...answer, given: false, result: null}));
        }
        case "removeall": {
            // console.log("remove state");
            return [];
        }
        case "replace": {
            // console.log(`%creplace state with ${action.payload}`, "color:red");
            return action.payload;
        }
        case "test": {
            updated.push(action.payload + Date.now());
            return updated;
        }
        default: return answers;
    }
}


