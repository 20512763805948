import React from 'react';
import { labels } from "../../labels";
import { Wordform, Label } from "../Wordform";
import { countExists, transform, morphNames } from "../../lang/lang2";

function Declension(props) {
    const { morphs, word } = props;

    return (
        <>
        <h2><Label>{labels.transformations.declension}</Label></h2>
        <table className="transform">
            <tbody>
            <tr>
                <th />
                {[0, 1].map((count, i) => (
                    <th key={i}>
                        {countExists(count, word) ?
                            <Label>{labels.count[count]}</Label> :
                            <Label>{
                                [
                                    {lv: "vienskaitļa nav", ru: "единственного числа нет"},
                                    {lv: "daudzskaitļa nav", ru: "множественного числа нет"}
                                ][count]
                            }</Label>}
                    </th>
                ))}
            </tr>
            {[0, 1, 2, 3, 4, 5, 6].map((cas, r) => (
                <tr key={r}>
                    <th><Label>{labels.cas[cas]}</Label></th>
                    {[0, 1].map((count, i) => (
                        <td key={i}>{
                            (()=>{
                                if (word.rule.toString().length > 2) {
                                    //console.log("rule NNN");
                                    if (word.rule.toString()[2] === "0" && count === 1) return r===0 ? "¯\\_(ツ)_/¯" : "";
                                    if (word.rule.toString()[2] === "1" && count === 0) return r===0 ? "¯\\_(ツ)_/¯" : "";
                                }
                                return (
                                    (()=>{
                                        const form = transform(morphs, word, {cas, count});
                                        //console.log(form==="");
                                        let empty = form==="";
                                        if (!empty) {
                                            empty = form.every(m => m[morphNames
                                                .find(n => m.hasOwnProperty(n))] === "");
                                        }
                                        //console.log("empty?", empty);
                                        return (
                                            <>
                                            {
                                                ((cas===4 && word.rule.toString()[1]!=="7") ||
                                                (cas===4 && word.rule.toString()[1]==="7" && count===0)) && !empty ?
                                                    "ar " : ""
                                            }
                                            <Wordform form={form} />
                                            </>
                                        )
                                    })()
                                )
                            })()
                        }</td>
                    ))}
                </tr>
            ))}
            </tbody>
        </table>
        </>
    )
}

export default Declension;