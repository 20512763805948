
export const alternation = [
    
    //verb roots
    { "0": /^ie$/, "forms": ["gāj", "ej", "ie"], "name": "gāj-ej-ie" },
    { "1": /do/, "forms": ["dev", "dod", "do"], "name": "dev-dod-do" },
    //{ "2": /teik/, "forms": ["teic", "sak", "teik"], "name": "teic-sak-teik" },
    { "3": /^([^i]+)i([^i]+)$/, "forms": ["$1i$2", "$1ē$2", "$1i$2"], "name": "i-ē-i" },
    { "4": /irs$/, "forms": ["irt", "ērt", "irtī"], "name": "i-ē-i" },
    { "5": /i/, "forms": ["i", "ie", "i"], "name": "i-ie-i" },
    { "6": /is/, "forms": ["it", "iet", "itī"], "name": "i-ie-i" },
    { "7": /a/, "forms": ["a", "o", "a"], "name": "a-o-a" },
    { "8": /as/, "forms": ["at", "ot", "atī"], "name": ["a-o-a", "t-t-tī"] },
    { "9": /as/, "forms": ["ad", "od", "adī"], "name": ["a-o-a", "d-d-dī"] },
    { "10": /ie/, "forms": ["ēj", "ien", "ie"], "name": "ēj-ien-ie" },
    { "11": /i/, "forms": ["i", "ī", "i"], "name": "i-ī-i" },
    { "12": /is/, "forms": ["it", "īt", "itī"], "name": ["i-ī-i", "t-t-tī"] },

    { "13": /ā/, "forms": ["āj", "āj", "ā"], "name": "āj-āj-ā" },
    { "14": /ē/, "forms": ["ēj", "ēj", "ē"], "name": "ēj-ēj-ē" },
    { "15": /au/, "forms": ["āv", "auj", "au"], "name": "āv-auj-au" },
    { "16": /ū/, "forms": ["uv", "uj", "ū"], "name": "uv-uj-ū" },
    { "17": /p$/, "forms": ["p", "pj", "p"], "name": "p-pj-p" },
    { "18": /b$/, "forms": ["b", "bj", "b"], "name": "b-bj-b" },
    { "19": /um$/, "forms": ["ūm","umj","um"], "name": ["m-mj-m", "ū-u-u"] },
    { "20": /(.+)k$/, "forms": ["$1c", "$1c", "$1k"], "name": "c-c-k" },
    { "22": /(.+)k$/, "forms": ["$1c", "$1k", "$1k"], "name": "c-k-k" },
    { "23": /(.+)g$/, "forms": ["$1dz", "$1dz", "$1g"], "name": "dz-dz-g" },

    { "25": /z/, "forms": ["z", "ž", "zī"], "name": "z-ž-zī" },
    { "26": /s$/, "forms":["s", "š", "sī"], "name": "s-š-sī" },
    
    { "32": /^([^e]+)e([^e]+)$/, "forms": ["$1ē$2", "$1e$2", "$1e$2"], "name": "ē-e-e" },
    { "33": /^(.+)$/, "forms": ["$1", "$1st", "$1"], "name": "-st-" },
    { "37": /ē/, "forms": ["ē", "e", "ē"], "name": "ē-e-ē" },
    { "38": /(.+)s$/, "forms": ["$1d", "$1d", "$1dī"], "name": "d-d-dī" },
    { "39": /ī/, "forms": ["in", "īst", "ī"], "name": "in-īst-ī" },

    { "41": /s$/, "forms": ["s", "st", "sī"], "name": "s-st-sī" },
    { "42": /s$/, "forms": ["t", "t", "tī"], "name": "t-t-tī" },

    { "44": /^bū$/, "forms": ["bij", "es", "bū"], "name": "bij-es-bū" },
    { "45": /i/, "forms": ["i", "e", "i"], "name": "i-e-i" },
    { "49": /i/, "forms": ["ī", "i", "i"], "name": "ī-i-i" },

    { "51": /ī/, "forms": ["ij", "ij", "ī"], "name": "ij-ij-ī" },
    { "52": /ī/, "forms": ["in", "in", "ī"], "name": "in-in-ī" },
    { "58": /us$/, "forms": ["ut", "ūt", "utī"], "name": ["u-ū-u", "t-t-tī"] },
    { "60": /is/, "forms": ["id", "ied", "idī"], "name": ["i-ie-i", "d-d-dī"] },


    // verb new
    { "101": /(.+)k$/, "forms": ["$1c", "$1k", "$1c"] },
    { "102": /c$/, "forms": ["c", "k", "c"] },
    { "103": /n$/, "forms": ["n", "n", "n", "ņ"] },
    { "104": /g$/, "forms": ["g", "g", "g", "dz"] },
    { "105": /dz$/, "forms": ["dz", "g", "dz"] },
    { "106": /d$/, "forms": ["d", "ž", "d"] }, // sēdēt

    // noun roots
    { "201": /(.+)l$/, "forms": ["$1l", "$1ļ"] },
    { "202": /(.+)z$/, "forms": ["$1z", "$1ž"] },
    { "203": /(.+)n$/, "forms": ["$1n", "$1ņ"] },
    { "203": /(.+)n$/, "forms": ["$1n", "$1ņ"] },
    { "204": /^([^e]+)ē([^e]+)$/, "forms": ["$1ē$2", "$1e$2"] }, // problēma -> problemātika
    { "205": /(.+)g$/, "forms": ["$1g", "$1dz"] },
    { "206": /(.+)ln$/, "forms": ["$1ln", "$1ļņ"] },
    { "207": /^desmit$/, "forms": ["desmit", "dsmit"] },
    { "208": /(.+)t$/, "forms": ["$1t", "$1š"] }, // tūkstot -> tūsktoš, pati - paši
    { "209": /^trī$/, "forms": ["trī", "tre", "tri"] }, // trīs
    { "210": /^piec$/, "forms": ["piec", "piek"] },
    { "211": /^četr$/, "forms": ["četr", "cetur"] },
    { "212": /^seš$/, "forms": ["seš", "ses"] },
    { "213": /^mūzik$/, "forms": ["mūzik", "muzik", "muzic"] },
    { "214": /(.+)s$/, "forms": ["$1s", "$1d"] }, // dziesma -> dziedāt
    { "215": /^daudz$/, "forms": ["daudz", "vair"] }, // daudzināt - vairot
    { "216": /(.+)k$/, "forms": ["$1k", "$1c"] },
    { "216A": /(.+)k$/, "forms": ["$1k", "$1k", "$1k", "$1k", "$1k", "$1c"] },
    { "217": /^māt$/, "forms": ["mām", "mamm"] },
    { "218": /^tēv$/, "forms": ["tēv", "tēt"] },


    // UNCHECKED

    //{"20":/k/,"forms":["c","c","k"], "name": "c-c-k"},
    {"21":/ēk/,"forms":["ēc","ec","ek"], "name": ["c-c-k", "ē-e-e"]},
    //{"22":/k/,"forms":["c","k","k"], "name": "c-c-k"},
    //{"23":/g/,"forms":["dz","dz","g"], "name": "dz-dz-g"},

    { "24": /s$/, "forms": ["d","ž","dī"], "name": "d-ž-dī" },

    { "27": /s$/, "forms": ["t","š","tī"], "name": "t-š-tī" },
    
    {"28":/el/,"forms":["ēl","eļ","el"], "name": ["l-ļ-l", "ē-e-e"]},
    {"29":/l/,"forms":["l","ļ","l"], "name": "l-ļ-l"},
    {"30":/ul/,"forms":["ūl","uļ","ul"], "name": ["l-ļ-l", "ū-u-u"]},
    {"31":/il/,"forms":["īl","iļ","il"], "name": ["l-ļ-l", "ī-i-i"]},
    // 33
    {"34":/ī/,"forms":["ij","īst","ī"], "name": ["-st-", "ij-ī-ī"]},
    
    {"35":/ū/,"forms":["uv","ūst","ū"], "name": "uv-ūst-ū"},
    
    {"36":/s$/,"forms":["d","st","dī"], "name": "d-st-dī"},
    //{"38":/s/,"forms":["d","d","dī"]},
    {"40":/s/,"forms":["t","st","tī"], "name": "t-st-tī"},
    {"43":/is/,"forms":["it","īt","itī"], "name": ["t-t-tī", "i-ī-i"]},
    //{"44":/bū/,"forms":["bij","es","bū"]},
    {"46":/s/,"forms":["s","s","sī"], "name": "s-s-sī"},
    {"47":/ā/,"forms":["ā","a","ā"], "name": "ā-a-ā"},
    {"48":/em/,"forms":["ēm","emj","em"], "name": ["m-mj-m", "ē-e-e"]},
    {"50":/u/,"forms":["u","ū","u"], "name": "u-ū-u"},
    {"53":/īs/,"forms":["īd","ien","īdī"], "name": ["i-ien-ī", "d--dī"]},
    {"54":/us/,"forms":["ud","ūd","udī"], "name": ["d-d-dī", "u-ū-u"]},
    {"55":/ī/,"forms":["in","en","ī"], "name": "in-en-ī"},
    {"56":/ie/,"forms":["ēj","ej","ie"], "name": "ēj-ej-ie"},
    {"57":/u/,"forms":["ū","u","u"], "name": "ū-u-u"},
    {"59":/ī/,"forms":["ij","īst","ī"], "name": "ij-īst-ī"},
    {"61":/is/,"forms":["id","ien","idī"], "name": ["i-ien-i", "d--dī"]},
    {"62":/ā/,"forms":["ā","a","a"], "name": "ā-a-a"},
    {"63":/a/,"forms":["ā","a","a"], "name": "ā-a-a"},
    {"64":/us/,"forms":["us","ūst","usī"], "name": ["-st-", "u-ū-u"]},
    {"65":/z/,"forms":["z","st","zī"], "name": "z-st-zī"},
    {"66":/ēb/,"forms":["ēb","ebj","ēb"], "name": ["b-bj-b", "ē-e-ē"]},
    {"67":/is/,"forms":["is","iest","isī"], "name": ["s-st-sī", "i-ie-i"]},
    {"68":/ī/,"forms":["in","in","ī"], "name": "in-in-ī"}
    

];

export function convertFormulas() {
    return (formulas.sort((a,b)=>a.number-b.number).map(f => {
        let obj = {};
        const rxKey = f.number.toString();
        obj[rxKey] = `/${f.flexes[3].replace(/\*/g, "")}/`;
        obj.forms = f.flexes.map(flex => flex.replace(/\*/g, ""));
        obj.forms.pop();
        return JSON.stringify(obj);
    }));
}

const formulas = [

    //{ number: 41, flexes: ["s", "st", "sī", "s"], name: "s–st–sī" },
    //{ number: 2, flexes: ["teic", "sak", "teik", "teik"], name: "teic–sak–teik" },
    //{ number: 51, flexes: ["ij", "ij", "ī", "ī"], name: "ij–ij–ī" },
    //{ number: 52, flexes: ["in", "in", "ī", "ī"], name: "in–in–ī" },
    //{ number: 18, flexes: ["b", "bj", "b", "b"], name: "b–bj–b"},
    //{ number: 17, flexes: ["p", "pj", "p", "p"], name: "p–pj–p"},
    //{ number: 13, flexes: ["āj", "āj", "ā", "ā"], name: "āj–āj–ā" },
    //{ number: 42, flexes: ["t", "t", "tī", "s"], name: "t–t–tī" },
    //{ number: 58, flexes: ["*u*t", "*ū*t", "*u*tī", "*u*s"], name: "u–ū–u", altName: "t–t–tī" },
    //{ number: 25, flexes: ["z", "ž", "zī", "z"], name: "z–ž–zī" },
    //{ number: 39, flexes: ["in", "īst", "ī", "ī"], name: "in–īst–ī" },
    //{ number: 0, flexes: ["gāj", "ej", "ie", "ie"], name: "gāj–ej–ie" },
    //{ number: 1, flexes: ["dev", "dod", "do", "do"], name: "dev–dod–do" },
    //{ number: 3, flexes: ["i", "ē", "i", "i"], name: "i–ē–i" },
    //{ number: 4, flexes: ["*i*rt", "*ē*rt", "*i*rtī", "*i*rs"], name: "i–ē–i" },
    //{ number: 5, flexes: ["i", "ie", "i", "i"], name: "i–ie–i" },
    //{ number: 6, flexes: ["*i*t", "*ie*t", "*i*tī", "*i*s"], name: "i–ie–i" },
    //{ number: 7, flexes: ["a", "o", "a", "a"], name: "a–o–a" },
    //{ number: 8, flexes: ["*a*t", "*o*t", "*a*tī", "*a*s"], name: "a–o–a", altName: "t–t–tī" },
    //{ number: 9, flexes: ["*a*d", "*o*d", "*a*dī", "*a*s"], name: "a–o–a", altName: "d–d–dī" },
    //{ number: 10, flexes: ["ēj", "ien", "ie", "ie"], name: "ēj–ien–ie" },
    //{ number: 11, flexes: ["i", "ī", "i", "i"], name: "i–ī–i" },
    //{ number: 12, flexes: ["*i*t", "*ī*t", "*i*tī", "*i*s"], name: "i–ī–i", altName: "t–t–tī" },
    //{ number: 60, flexes: ["*i*d", "*ie*d", "*i*dī", "*i*s"], name: "i–ie–i" },
    //{ number: 45, flexes: ["i", "e", "i", "i"], name: "i–e–i", hideInFirstVersion: false },
    //{ number: 49, flexes: ["ī", "i", "i", "i"], name: "ī–i–i" },
    //{ number: 32, flexes: ["ē", "e", "e", "e"], name: "ē–e–e" },
    //{ number: 37, flexes: ["ē", "e", "ē", "ē"], name: "ē–e–ē" },
    //{ number: 14, flexes: ["ēj", "ēj", "ē", "ē"], name: "ēj–ēj–ē" },
    //{ number: 15, flexes: ["āv", "auj", "au", "au"], name: "āv–auj–au" },
    //{ number: 16, flexes: ["uv", "uj", "ū", "ū"], name: "uv–uj–ū"},

    { number: 35, flexes: ["uv", "ūst", "ū", "ū"], name: "uv–ūst–ū"},

    { number: 66, flexes: ["ē*b*", "e*bj*", "ē*b*", "ē*b*"], name: "b–bj–b"},

    { number: 19, flexes: ["ū*m*", "u*mj*", "u*m*", "u*m*"], name: "m–mj–m" },
    { number: 48, flexes: ["ē*m*", "e*mj*", "e*m*", "e*m*"], name: "m–mj–m" },

    { number: 20, flexes: ["c", "c", "k", "k"], name: "c–c–k" },
    { number: 21, flexes: ["ē*c*", "e*c*", "e*k*", "ē*k*"], name: "c–c–k" },

    { number: 22, flexes: ["c", "k", "k", "k"], name: "c–k–k" },

    { number: 23, flexes: ["dz", "dz", "g", "g"], name: "dz–dz–g" },

    { number: 24, flexes: ["d", "ž", "dī", "s"], name: "d–ž–dī" },

    { number: 26, flexes: ["s", "š", "sī", "s"], name: "s–š–sī" },

    { number: 27, flexes: ["t", "š", "tī", "s"], name: "t–š–tī" },

    { number: 28, flexes: ["ē*l*", "e*ļ*", "e*l*", "e*l*"], name: "l–ļ–l" },
    { number: 29, flexes: ["l", "ļ", "l", "l"], name: "l–ļ–l" },
    { number: 30, flexes: ["ū*l*", "u*ļ*", "u*l*", "u*l*"], name: "l–ļ–l" },
    { number: 31, flexes: ["ī*l*", "i*ļ*", "i*l*", "i*l*"], name: "l–ļ–l" },

    { number: 33, flexes: ["", "st", "", ""], name: "––st––" },

    { number: 64, flexes: ["us", "ūst", "usī", "us"], name: "––st––", hideInFirstVersion: true },

    { number: 65, flexes: ["z", "st", "zī", "z"], name: "z–st–zī" },

    { number: 34, flexes: ["ij**", "ī*st*", "ī**", "ī**"], name: "––st––" },

    { number: 36, flexes: ["d", "st", "dī", "s"], name: "d–st–dī" },

    { number: 38, flexes: ["d", "d", "dī", "s"], name: "d–d–dī" },

    { number: 40, flexes: ["t", "st", "tī", "s"], name: "t–st–tī" },


    { number: 67, flexes: ["i*s*", "ie*st*", "i*sī*", "i*s*"], name: "s–st–sī" },

    { number: 43, flexes: ["i*t*", "ī*t*", "i*tī*", "i*s*"], name: "t–t–tī" },
    { number: 44, flexes: ["bij", "es", "bū", "bū"], name: "bij–es–bū", hideInFirstVersion: true },
    { number: 46, flexes: ["s", "s", "sī", "s"], name: "s–s–sī" },
    { number: 47, flexes: ["ā", "a", "ā", "ā"], name: "ā–a–ā" },
    { number: 62, flexes: ["ā", "a", "a", "ā"], name: "ā–a–a" },
    { number: 63, flexes: ["ā", "a", "a", "a"], name: "ā–a–a" },
    { number: 50, flexes: ["u", "ū", "u", "u"], name: "u–ū–u" },
    { number: 57, flexes: ["ū", "u", "u", "u"], name: "ū–u–u" },
    { number: 53, flexes: ["īd", "ien", "īdī", "īs"], name: "ī–ien–ī" },
    { number: 61, flexes: ["id", "ien", "idī", "is"], name: "i–ien–i" },

    { number: 54, flexes: ["u*d*", "ū*d*", "u*dī*", "u*s*"], name: "d–d–dī" },

    { number: 55, flexes: ["in", "en", "ī", "ī"], name: "in–en–ī" },

    { number: 68, flexes: ["in", "in", "ī", "ī"], name: "in–in–ī" },

    { number: 56, flexes: ["ēj", "ej", "ie", "ie"], name: "ēj–ej–ie" },

    { number: 59, flexes: ["ij", "īst", "ī", "ī"], name: "ij–īst–ī", hideInFirstVersion: true }

];