
import React from 'react';
import HTML from "../components/HTML";

function Gramatika(props) {
    console.log(props.match);

    return(
        <>
        <h1>
            Gramatika
        </h1>
        { props.match.params.file ? <HTML file={props.match.params.file} /> : <div>some text</div> }
        </>
    );
}

export default Gramatika;
