import React from "react";
import parse from 'html-react-parser';
import { getType, sameCase, generateId } from "../prototypes";
import { rules, lemma2str, wordType, transform, syn, hyphenate } from "./lang2";
import { Word } from "../components/Wordform";

export const parseParams = {
    "p": { param: "person", values: [1, 2, 3, 4, 5, 6] },
    "t": { param: "tense", values: [0, 1, 2, 3, 4, 5] },
    "g": { param: "gender", values: [0, 1] },
    "q": { param: "count", values: [0, 1] },
    "c": { param: "cas", values: [0, 1, 2, 3, 4, 5, 6] },
    "d": { param: "definite", values: [false, true] }
};

export function removeDiactritics(str) {
    return str.split("")
        .map(c =>
            sameCase(c,
                (syn.alpha.lv.find(pair => c.toLowerCase() === pair[1]) || c)[0]
            )
        ).join("");
}

function numVal(arrOfStr) {
    const n = arrOfStr.find(e => (/^\d+$/.test(e)));
    if (n) return parseInt(n);
    return n;
}
function strVal(arrOfStr) {
    return arrOfStr.find(e => (!/^\d+$/.test(e)));
}

export function isStrWord(str) {
    return (/[^\[]+\[[^\]]+\]$/.test(str)); //eslint-disable-line
}

export function isStrChain(str) {
    return (/^\{[^\{\}]+\}$/.test(str)); //eslint-disable-line
}

export function parseWordStr(str) {
    if (/[^\[]+\[[^\]]+\]$/.test(str)) {  //eslint-disable-line
        const param = /\[([^\]]+)\]$/.exec(str)[1].split(":");
        const wordType = param[0];
        const form = /([^\[]+)\[[^\]]+\]$/.exec(str)[1];  //eslint-disable-line
        const lemma = strVal(param.slice(1)) || form.toLowerCase().replace(/&shy;/g, "");
        const homonym = numVal(param.slice(1));
        return {
            form, lemma, homonym, wordType
        }
    } else {
        return { form: str }
    }
}

export function wordIndexOfChain(word, chain) {
    let index = 0;
    for (const part of chain2arr(chain)) {
        if (isStrWord(part)) {
            const wordStr = parseWordStr(part);
            const wordMatch =
                (wordStr.lemma === lemma2str(word.lemma) && wordStr.wordType === wordType(word).short) ?
                    (wordStr.homonym) ? word.homonym===wordStr.homonym :
                        (()=>{
                            if (word.homonym) {
                                console.log("WARNING: word has homonym, but reference in chain doesn't");
                            }
                            return true;
                        })()
                    : false;
            if (wordMatch) return index;
        }
        index++;
    }
    return -1;
}

export function parseWordStr2(str) {
    if (!/\[[^\]]+\]$/.test(str)) {
        console.log(`WARNING: ${str} is not a word object`);
        return { usedForm: str };
    }
    
    let usedForm, lemma;

    if (/[^\[]+\[[^\]]+\]$/.test(str)) { //eslint-disable-line
        // used form is specified
        usedForm = /([^\[]+)\[[^\]]+\]$/.exec(str)[1]; //eslint-disable-line
    }

    let param = /\[([^\]]+)\]$/.exec(str)[1].split(":");

    let type = param[0];

    if (!/^[a-z][a-z]$/.test(type)) {
        console.log(`WARNING: ${type} is not a valid type`);
        return { usedForm: str };
    } else {
        param = param.slice(1);
    }

    const homonym = param.find(p => /^\d+$/.test(p));

    lemma = param.find(p => /^[a-zāčēģīķļņšūž&;]+$/i.test(p));

    if (lemma === undefined) {
        if (usedForm) {
            lemma = usedForm.toLowerCase().replace(/&shy;/g, "");
        } else {
            // console.log(`WARNING: no lemma for word ${str}`);
            return { usedForm: str };
        }
    }
    
    return {
        usedForm, lemma, type, homonym
    }
    
    
}

export function str2word(data, str, donor) {
    if (!/\[[^\]]+\]$/.test(str)) {
        console.log(`WARNING: ${str} is not a word object`);
        return str;
    }
    //console.log(str);

    let usedForm, word, lemma;

    if (/[^\[]+\[[^\]]+\]$/.test(str)) { //eslint-disable-line
        // used form is specified
        usedForm = /([^\[]+)\[[^\]]+\]$/.exec(str)[1]; //eslint-disable-line
    }

    let param = /\[([^\]]+)\]$/.exec(str)[1].split(":");
    //console.log(param);

    let type = param[0];
    if (!/^[a-z][a-z]$/.test(type)) {
        if (donor) {
            type = Object.keys(rules).find(key => rules[key].includes(donor.rule));
            if (type === undefined) {
                console.log("ERROR: donor word type undefined");
                return str;
            }
            //console.log("INFO: donor type used");
        } else {
            console.log(`ERROR: ${type} is not a valid type`);
            return str;
        }
    } else {
        param = param.slice(1);
    }

    //console.log("type:", type);

    const homonym = param.find(p => /^\d+$/.test(p));

    //console.log("homonym:", homonym);

    lemma = param.find(p => /^[a-zāčēģīķļņšūž&;]+$/i.test(p));

    if (lemma === undefined) {
        if (usedForm) {
            lemma = usedForm.toLowerCase().replace(/&shy;/g, "");
        } else {
            if (donor) {
                lemma = lemma2str(donor.lemma);
                if (lemma === undefined) {
                    console.log("ERROR: donor word lemma undefined");
                    return str;
                }
                //console.log("INFO: donor lemma used");
            } else {
                console.log(`ERROR: no lemma for word ${str}`);
                return str;
            }
        }
    }

    //console.log("lemma:", lemma);

    if (rules[type]) {

        const candidates = data.words
            .filter(w => (rules[type].includes(w.rule)))
            .filter(w => (lemma2str(w.lemma) === lemma));

        //console.log(candidates);
        if (candidates.length > 1) {
            if (homonym) {
                const candidate = candidates.find(w => {
                    if (!w.hasOwnProperty("homonym")) {
                        console.log("WARNING: one of homonyms has no homonym param");
                        return false
                    }
                    return w.homonym === homonym;
                });
                if (candidate) {
                    word = candidate;
                } else {
                    console.log(`WARNING:
                no words ${type}:${lemma} with homonym=${homonym}. Return first found`);
                    word = candidates[0];
                }
            } else {
                console.log(`WARNING:
            two or more words ${type}:${lemma} found, but no homonym specified.
            Return first found`);
                word = candidates[0];
            }
        }

        if (candidates.length === 1) {
            word = candidates[0];
        }

        if (candidates.length < 1) {
            console.log(`WARNING: no words ${type}:${lemma} found`);
        }

    } else {
        console.log(`WARNING: no rules for type ${type}`);
    }

    //console.log("word:", word);

    let transParam = {};

    param.filter(p => /^\w[\d]+$/.test(p)).forEach(p => {
        //const key = paramNames[p[0]];
        const key = parseParams[p[0]].param;
        transParam[key] = parseInt(
            /([\d]+)$/.exec(p)[1]
        );
    });

    //console.log(transParam);

    if (usedForm === undefined) {
        if (Object.keys(transParam).length > 0) {
            if (word) {
                //console.log(word);
                //console.log(transParam);
                //console.log(transform(data.morphs, word, transParam));
                usedForm = lemma2str( transform(data.morphs, word, transParam) );
            } else {
                console.log(`ERROR: no word found, no form used`);
                return lemma;
            }
        } else {
            usedForm = lemma;
        }
    }

    //console.log("usedForm:", usedForm);

    let res = {};

    res.usedForm = usedForm;
    if (word) res.word = word;
    if (Object.keys(transParam).length > 0) res.param = transParam;

    return res;

    
}

export function chain2path(chain) {
    return chain.lv.default
        .replace(/\[[^\]]+\]/g, "") //eslint-disable-line
        .replace(/::[^\{\}]+\}/g, "") //eslint-disable-line
        .replace(/[\{\}]/g, "") //eslint-disable-line
        .toLowerCase()
        .replace(/&nbsp;/g, " ")
        .replace(/&[^&;]+;/g, "")
        .match(/[a-zāčēģīķļņšūžа-я0-9]+/gi).join("-");
}

export function getChainByPath(chains, path) {
    let chain = chains.filter(chain => chain2path(chain)===path);
    if (chain.length > 1) console.log(`WARNING: found ${chain.length} chains with this path`);
    return chain[0];
}

export function word2href(word) {
    //console.log(word);
    const t = wordType(word);
    const h = word.homonym ? word.homonym + "/" : "";
    return (`/vārdi/${t.full}/${t.short==="ke"?chain2path(word):lemma2str(word.lemma)}/${h}`).toLowerCase();
}

export function chain2str(chain, form) {
    return (form||chain.lv.default)
        .replace(/\[[^\]]+\]/g, "") //eslint-disable-line
        .replace(/::[^\{\}]+\}/g, "") //eslint-disable-line
        .replace(/[\{\}]/g, ""); //eslint-disable-line
}

export function entities2unicode(str) {
    let res = str;
    [
        { e: "#9312", u: "\u2460" },
        { e: "#9313", u: "\u2461" },
        { e: "#9314", u: "\u2462" },
        { e: "#9315", u: "\u2463" },
        { e: "#9316", u: "\u2464" },
        { e: "#9317", u: "\u2465" },
        { e: "#9318", u: "\u2466" },
        { e: "#9319", u: "\u2467" },
        { e: "#9320", u: "\u2468" },
        
        { e: "times", u: "\u00D7" },
        { e: "thinsp", u: "\u2009" },
        { e: "copy", u: "\u00A9" },
        { e: "nbsp", u: "\u00A0" },
        { e: "mdash", u: "\u2014" },
        { e: "ndash", u: "\u2013" },
        { e: "shy", u: "\u00AD" },
        { e: "bdquo", u: "\u201E" }, { e: "ldquo", u: "\u201C" },
        { e: "laquo", u: "\u00AB" }, { e: "raquo", u: "\u00BB" }
    ].forEach(entity => { res = res.replace(new RegExp(`&${entity.e};`, "g"), entity.u) });
    return res;
}

const delimiters = [
    /(\{[^\{\}]+\})/, //eslint-disable-line
    /(<[^>]+\/>)/,
    /((?!&shy;)&[a-z]+;)/,
    /([a-zāčēģīķļņšūž&;-]+\[[a-zāčēģīķļņšūž:\d]+\])/i,
    /(\[[a-zāčēģīķļņšūž:\d]+\])/i,
//    /([\.!?]$)/,
//    /(\.\s)/,
//    /([,!?:;]\s)/,
    /([a-z]+\.(?:lv|com|net|org|ru))/
];

export function chain2arr(chain) {
    //console.log(chain);
    let result = [];
    const source = chain.lv.default;
    function convert(source, delimiters, result) {
        if (getType(source) === "string") {
            //console.log(`split ${chain} by`, delimiters[0]);
            convert(source.split(delimiters[0]).filter(c=>c !== ""), delimiters, result);
        } else {
            //console.log(chain);
            source.forEach(part => {
                if (delimiters[0].test(part)) {
                    //console.log(`${part} is not to be changed`);
                    result.push(part);
                } else {
                    if (delimiters.length > 1) {
                        convert(part, delimiters.slice(1), result);
                    } else {
                        result.push(part);
                    }
                }
            });
        }
    }
    convert(source, delimiters, result);
    //console.log(result);
    return result;
}

export function parseChain(data, chain, donor) {
    const { chains } = data;
    let arr = chain2arr(chain);
    // console.log(arr);
    //const wrds = arr.map(entry => parseWordStr(entry)).filter(entry => entry.hasOwnProperty("lemma"));
    //console.log(wrds);
    arr.forEach((str, index, arr) => {
        // console.log(str);
        if (/\{[^\{\}]+\}/.test(str)) { //eslint-disable-line
            let subchain = str.replace(/[\{\}]/g, "").split("::");
            const usedForm = subchain[0];
            //console.log("subchain used form:", usedForm);
            const lemma = subchain[1] || usedForm;
            // console.log("subchain lemma:", lemma);
            subchain = chains.find(chain => chain2str(chain) === lemma); //eslint-disable-line
            //console.log("subchain:", subchain);
            if (subchain) {
                let subchainWords = parseChain(data, subchain);
                //console.log(subchainWords);
                arr[index] = {
                    usedForm,
                    word: subchainWords,
                    chain: subchain
                }
            } else {
                console.log(`WARNING: used subchain {${lemma}} doesn't exist`)
            }
        }
        //if (/[a-zāčēģīķļņšūž&;-]+\[[a-zāčēģīķļņšūž:\d]+\]/i.test(str)) {
        if (/\[[a-zāčēģīķļņšūž:\d]+\]$/i.test(str)) {
            //console.log(str);
            arr[index] = str2word(data, str, donor);
            //console.log("word", arr[index]);
        }
    });
    return arr;
}

export function chain2words(data, chain) {
    return parseChain(data, chain)
        .filter(part => part.hasOwnProperty("word")).flatten()
        .map(part => {
            if (getType(part.word) === "array") {
                return part.word.filter(p => p.hasOwnProperty("word")).map(p => p.word);
            } else {
                return part.word;
            }
        }).flatten();
}

export function parseChainStr(str) {
    if (isStrChain(str)) {
        const s = str.replace(/[\{\}]/g, "").split("::");
        return { lemma: s[1] || s[0], form: s[0] }
    } else {
        console.log(`WARNING: ${str} is not a chain`);
        return { form: str }
    }
}

export function str2chain(chains, str) {
    const s = str.replace(/[\{\}]/g, "").split("::");
    const lemma = s[1] || s[0];
    //console.log(lemma);
    //console.log("zaļais pūķis[lv]".replace(/\[[^\]]+\]/g, ""));
    //console.log("zaļais pūķis[lv]".replace(/\[[^\]]+\]/g, "")===lemma);
    //console.log(chains.length);
    const chain = chains
        .find(chain => chain.lv.default.replace(/\[[^\]]+\]/g, "") === lemma);
    if (chain) {
        return chain;
    } else {
        console.log(`WARNING: chain {${lemma}} doesn't exist`);
        return false;
    }
}

export function filterWords(str, words) {

    if (/\[[^\[\]]+\]/.test(str)) {
        //console.log("parseExercise2: filter words");
        //console.log(html);
        const arr = str
            .match(/[a-zāčēģīķļņšūž&;-]*\[[a-zāčēģīķļņšūž:\d\*]+\]/ig).unique();
        // console.log(arr);
        const lemmas = arr.map(entry => parseWordStr2(entry).lemma||"").filter(s=>s!=="").unique();
        //console.log(lemmas);
        const filteredWords = words.filter(w => lemmas.includes(lemma2str(w.lemma)));
        //console.log(words);
        if (filteredWords.length !== lemmas.length) {
            // console.log("WARNING: filtered words length mismatch");
            // console.log("encoded words:", lemmas, "filtered data.words:", filteredWords.map(w=>lemma2str(w.lemma)));
        }
        return filteredWords;
        //console.log(tprops);
    }
    return []
}

export function parsed2html(props, parsedChain) {
    return parsedChain.map((part, i) => {
        if (getType(part) === "string") {
            if (/^<[^>]+\/>$/.test(part)) {
                //console.log(part);
                const parser = new DOMParser();
                let parent = parser.parseFromString(part, "text/html").body;
                parent.childNodes[0].setAttribute("key", "h" + i);
                return parse(parent.innerHTML);
            } else {
                //return <React.Fragment key={"h"+i}>{entities2unicode(part)}</React.Fragment>;
                if (part.length > 8) {
                    // console.log(part);
                    return hyphenate(entities2unicode(part));
                }
                return entities2unicode(part);
            }
        } else {
            if (part.hasOwnProperty("chain") || part.hasOwnProperty("word")) {
                let wordProps = {...props, form: part.usedForm};
                wordProps.word = part.chain ? part.chain : part.word;
                return (<Word key={"c"+i} {...wordProps} />);
            } else {
                //return <React.Fragment key={"c"+i}>{part.usedForm}</React.Fragment>;
                return part.usedForm;
            }
        }
    })
}

export function camelCase(str) {
    const attrs = {
        "class": "className",
        "rowspan": "rowSpan",
        "colspan": "colSpan",
        "srcset": "srcSet"
    };
    if (attrs.hasOwnProperty(str)) return attrs[str];
    // https://stackoverflow.com/questions/2970525/converting-any-string-into-camel-case/37041217
    return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

export function renderNode(node, props) {
    //console.log(node);
    if (getType(node) === "array") {
        //console.log("node is an array");
        return node.map((n, i) => {
            const re = renderNode(n, props);
            //console.log(`node in array #${i}`);
            //console.log(re);
            return (re);
        });
    } else {
        //console.log("node is a node");
        //console.log(node);
        const tprops = { key: generateId() };
        if (node.nodeType === 1) {
            if (node.hasAttributes()) {
                [...node.attributes].forEach(attr => {
                    //console.log("renderNode: attr:", attr);
                    tprops[camelCase(attr.name)] = attr.value;
                })
            }
        }

        if (node.hasChildNodes()) {
            //return traverseReact([...node.childNodes], data, props);
            //console.log("node has children");
            if (node.nodeType === 1) {

                if (node.nodeName.toLowerCase() === "a") {
                    tprops.rel = "noopener noreferrer";
                }

                //console.log("node is element");
                if (node.childNodes.length > 1) {
                    //if ([...node.childNodes])
                    //console.log("element has children > 1");
                    //console.log(node.nodeName.toLowerCase());

                    // const re =
                    return React.createElement(
                        node.nodeName.toLowerCase(),
                        tprops,
                        renderNode([...node.childNodes], props)
                    );
                    //console.log(re);
                    //return re;
                    //return traverseReact([...node.childNodes], data, props);

                } else {
                    //console.log("element has child");
                    if (node.childNodes[0].nodeType === 3) {

                        //console.log("element with single text node:", node.nodeName.toLowerCase());

                        /*if (node.nodeName.toLowerCase() !== "p") {
                            console.log(tprops);
                        }*/

                        //console.log("child is a text node");
                        //const re =
                        return React.createElement(
                            node.nodeName.toLowerCase(),
                            tprops,
                            parsed2html(
                                {data: props.data, box: props.box}, parseChain(props.data, {lv:{default:node.childNodes[0].nodeValue}})
                            )
                        );
                        //console.log(re);
                        //return re;

                    } else {
                        //console.log("child is an element");
                        //const re =
                        return React.createElement(
                            node.nodeName.toLowerCase(),
                            tprops,
                            renderNode(node.childNodes[0], props)
                        );
                        //console.log(re);
                        //return re;

                    }
                }

            }

        } else {

            //console.log("node has no children");

            if (node.nodeType === 3) {
                //console.log("node is a text node");
                //console.log(props);

                return <span key={generateId()}>{parsed2html(
                    { data: props.data, box: props.box }, parseChain(props.data, {lv:{default:node.nodeValue}})
                )}</span>;

                /*return <React.Fragment key={generateId()}>{parsed2html(
                    props, parseChain(props.data, {lv:{default:node.nodeValue}})
                )}</React.Fragment>*/
            }
            if (node.nodeType === 1) {
                //console.log("node is an element");
                //const re = parse(node.parentNode.innerHTML);
                //console.log("return react element:");
                //console.log(re);

                if (props.custom) {
                    if (props.custom.tags.includes(node.nodeName.toLowerCase())) {
                        //console.log(props.custom.id + props.custom.counter);
                        //console.log(props.custom.customize(data, node, props.custom));

                        /*{
                         data: props.data, box: props.box,
                         nolink: true,
                         nobox: true,
                         correctAnswers: ca,
                         setAnswers, answersState
                         },
                         custom: {
                         id: exercise.id,
                         tags: lessonTags,
                         counter: 0,
                         customize: renderQnode
                         }
                         */
                        
                        const { customize, ...custom } = props.custom;
                        const id = props.custom.id + props.custom.counter;
                        //console.log("assign id to custom element:", id);
                        const tprops = { ...props, ...custom, id, key: id };

                        //console.log(tprops);

                        if (tprops.nobox) {
                            //console.log("no box");
                            //delete tprops.box
                        }
                        
                        //console.log(tprops);
                        
                        const re = props.custom.customize(node, tprops);
                        props.custom.counter++;
                        //console.log(re);
                        return re;
                    }
                }

                /*if (node.nodeName.toLowerCase() === "qtext") {
                    const re = <span key={generateId()}>QTEXT</span>;
                    //console.log(re);
                    return re;
                }*/

                //const re =
                return React.createElement(
                    node.nodeName.toLowerCase(),
                    tprops
                );
                //console.log(re);
                //return re;
            }
            //console.log(node.nodeType);
        }
    }
}

export function extractSubchains(chains, chain) {
    let subchains = [];
    chain.lv.default.match(/\{[^\{\}]+\}/g)
        .forEach(c => {
            const subchain = str2chain(chains, c);
            if (subchain) {
                subchains.push(subchain);
            }
        });

    return subchains;
}

export function alignStr(before, str, after) {

    const parts = str.split("|");
    for (let i=0; i<parts.length; i++) {
        if (before && /^\[[^\]]+\][^\[\]]+$/.test(parts[i])) {
            // before
            const parsed = /^\[([^\]]+)\]([^\[\]]+)$/.exec(parts[i]);
            //console.log(parsed);
            if (parsed[1].split(",").some(c => c === before)) {
                //console.log("Before!");
                return parsed[2];
            }
        }
        if (after && /^[^\[\]]+\[[^\]]+\]$/.test(parts[i])) {
            // after
            const parsed = /^([^\[\]]+)\[([^\]]+)\]$/.exec(parts[i]);
            //console.log(parsed);
            if (parsed[2].split(",").some(c => c === after)) {
                //console.log("After!");
                return parsed[1];
            }
        }
    }

    const partsWithoutCondition = parts.filter(part => !/\[/.test(part) );

    //console.log(partsWithoutCondition);

    return partsWithoutCondition.length > 0 ? partsWithoutCondition[0] : "";

}

export function extractSubchains0(chains, chain) {
    let subchains = [];
    chain.lv.default.match(/\{[^\{\}]+\}/g)
        .map(c=>c.replace(/[\{\}]/g, ""))
        .forEach(c => {
            const s = c.split("::");
            const lemma = s[1] || s[0];
            //console.log(lemma);
            //console.log("zaļais pūķis[lv]".replace(/\[[^\]]+\]/g, ""));
            //console.log("zaļais pūķis[lv]".replace(/\[[^\]]+\]/g, "")===lemma);
            //console.log(chains.length);
            const subchain = chains
                .find(chain => chain.lv.default.replace(/\[[^\]]+\]/g, "") === lemma);
            if (subchain) {
                subchains.push(subchain);
            } else {
                console.log(`WARNING: used subchain {${lemma}} doesn't exist`)
            }
        });

    return subchains;
}

export function findChainsByWord(chains, words, word) {
    const lemma = lemma2str(word.lemma);
    const wt = wordType(word).short;

    const rx = word.homonym ? [
        new RegExp(`\\[${wt}:${lemma}:${word.homonym}\\]`)
    ] : [
        new RegExp(`(^|[^a-zāčēģīķļņšūž])${lemma}\\[${wt}\\]`, "i"),
        new RegExp(`\\[${wt}:${lemma}\\]`)
    ];

    // `\\[${wt}:${lemma}[:\\]]`

    //console.log(rx[0]);
    //console.log(rx[1]);

    /*const chainsWithChains = chains
        .filter(chain => /\{[^\{\}]+\}/.test(chain.lv.default))
        .filter(chain => extractSubchains(chains, chain)
            .some(subchain => chain2words(chains, words, subchain)
                .some(w => w.id === word.id))
        );*/

    const relatedChains = chains
        .filter(chain => rx.some(exp => exp.test(chain.lv.default)));

    const parentChains = chains
        .filter(chain => relatedChains.every(relatedChain => relatedChain.id !== chain.id))
        .filter(chain => relatedChains.some(relatedChain => {
            const rx = [
                new RegExp(`\\{${relatedChain.lv.default.replace(/\[[^\]]+\]/g, "")}\\}`),
                new RegExp(`::${relatedChain.lv.default.replace(/\[[^\]]+\]/g, "")}\\}`)
            ];
            return rx.some(exp => exp.test(chain.lv.default));
        }));

    //console.log(parentChains);

    return relatedChains.concat(parentChains);
        //.concat(chainsWithChains)
        //.uniqueByKey("id");
}

