
import { getType, getValFromFuncOrVar, hasDeepProperty, aiove, cap, getRandomInt } from "../prototypes";
import { transformations } from "./transformations";
import { alternation } from "./alternation";

export const morphNames = ["prefix", "root", "postfix", "flex"];
export const wordNames = ["nouns", "verbs"];

export const rules = {
    "lv": [21, 210, 211, 212, 22, 220, 221, 222, 23, 230, 231, 232, 24,
        240, 241, 242, 25, 250, 251, 252, 26, 260, 261, 262, 27, 270, 271, 272],
    "dv": [11, 111, 12, 121, 13, 131],
    "iv": [3, 30, 31],
    "av": [40, 41, 42],
    "pv": [60],
    "sv": [7001, 7011, 7012, 7015, 702, 71]
    /*
    "av": 40,
    "vv": 50,
    "pv": 60*/
};

const lemmaParams = [
    { "rule": [21, 210, 22, 220, 23, 230, 24, 240, 25, 250, 26, 260, 27, 270], "param": { "cas": 0, "count": 0 } },
    { "rule": [211, 212, 221, 222, 231, 232, 241, 242, 251, 252, 261, 262, 271, 272],
        "param": { "cas": 0, "count": 1 } },
    { "rule": [11, 12, 13, 111, 121, 131], "param": { "person": 0, "tense": 1 } },
    { "rule": [3, 30], "param": { "cas": 0, "count": 0, "gender": 0, "definite": false } },
    { "rule": [31], "param": { "cas": 0, "count": 0, "gender": 0, "definite": true } },
    { "rule": [40], "param": { "basic": true } },
    { "rule": [41, 42], "param": { "basic": false } },
    //{ "rule": [60], "param": { "basic": true } },
    // 7001 - viens
    // 7011 - desmits, simts, miljons, miljards
    // 7012 - tūkstotis
    // 7015 - nulle
    { "rule": [7001, 7012, 7015], "param": { "cas": 0, "count": 0, "gender": 0, "applied": false } },
    { "rule": [7011], "param": { "cas": 0, "count": 1, "gender": 0, "applied": true } },
    { "rule": [702], "param": { "cas": 0, "count": 1, "gender": 0, "applied": true, "definite": false } },
    { "rule": [71], "param": { "cas": 0, "count": 0, "gender": 0 } }
];

const ruleKeys = [
    { "rule": [11, 12, 13, 111, 121, 131], keys: ["tense", "person", "gender", "count"] },
    { "rule": [21, 212, 22, 222, 23, 232, 24, 242, 25, 252, 26, 262, 27, 272], keys: ["count", "cas"] },
    { "rule": [3, 30, 31], keys: ["count", "cas", "gender", "definite", "comp"] },
    { "rule": [40, 41, 42], keys: ["basic", "comp"] },
    //{ "rule": [60], keys: ["basic"] },
    { "rule": [211, 221, 231, 241, 251, 261, 271], keys: ["cas"] },
    { "rule": [7001, 7011, 7012, 7015, 702], keys: ["count", "cas", "gender", "definite", "applied"] },
    { "rule": [71], keys: ["count", "cas", "gender"] }
];

let rt = {};
Object.keys(rules).forEach(key => {
    rules[key].forEach(val => {
        rt[val] = key;
    });
});
//console.log(rt);

export const syn = {
    type: {
        "lv": "lietvārds",
        "dv": "darbības",
        "iv": "īpašības",
        "av": "apstākļa",
        "sv": "skaitļa",
        "pv": "prievārds",
        "ke": "ķēde"
    },
    alpha: {
        "lv": ["aā", "cč", "eē", "gģ", "iī", "kķ", "lļ", "nņ", "rŗ", "sš", "uū", "zž"],
        "ru": ["её"],
        "es": ["aá", "eé", "ií", "nñ", "oó", "uú", "uü"]
    }
};

// "ёл": еле, ел, ёлки
// "ел": еле, ел, ёлки
// "ģēr": ģērbt,
// "ģer": ģērbt, ģerānija
// "ger": ģērbt, ģerānija, gerontoģija

export const alphabets = {
    "lv": "aābcčdeēfgģhiījkķlļmnņoprsštuūvxzž",
    "ru": "аáбвгдеёжзийклмноóпрстуýфхцчшщъыьэюя",
    "es": "aábcdeéfghiíjklmnñoópqrstuüúvwxyz"
};

const defLang = {
    "ru": /[абвгдеёжзийклмнопрстуфхцчшщъыьэюя]/i,
    "lv": /[āčēģīķļņšūž]/i,
    "es": /[áéñóüú]/i
}

export const containsLang = (str, lang) => {
    return defLang[lang].test(str);
}

export const hyphenate = (str) => {

    // http://xpoint.ru/know-how/VebAlgoritmyi/RabotaSTekstami/RasstanovkaPerenosov

    let text = str;

    const RusA = "[абвгдеёжзийклмнопрстуфхцчшщъыьэюя]";
    const RusV = "[аеёиоуыэю\я]";
    const RusN = "[бвгджзклмнпрстфхцчшщ]";
    const RusX = "[йъь]";
    const Hyphen = "\xAD";

    const re1 = new RegExp("("+RusX+")("+RusA+RusA+")","ig");
    const re2 = new RegExp("("+RusV+")("+RusV+RusA+")","ig");
    const re3 = new RegExp("("+RusV+RusN+")("+RusN+RusV+")","ig");
    const re4 = new RegExp("("+RusN+RusV+")("+RusN+RusV+")","ig");
    const re5 = new RegExp("("+RusV+RusN+")("+RusN+RusN+RusV+")","ig");
    const re6 = new RegExp("("+RusV+RusN+RusN+")("+RusN+RusN+RusV+")","ig");

    text = text.replace(re1, "$1"+Hyphen+"$2");
    text = text.replace(re2, "$1"+Hyphen+"$2");
    text = text.replace(re3, "$1"+Hyphen+"$2");
    text = text.replace(re4, "$1"+Hyphen+"$2");
    text = text.replace(re5, "$1"+Hyphen+"$2");
    text = text.replace(re6, "$1"+Hyphen+"$2");

    return text;

}

export const pronounsDative = [
    { lv: "man", person: 1, gender: [0, 1], ru: "мне" },
    { lv: "tev", person: 2, gender: [0, 1], ru: "тебе" },
    { lv: "viņam", person: 3, gender: 0, ru: "ему" },
    { lv: "viņai", person: 3, gender: 1, ru: "ей" },
    { lv: "mums", person: 4, gender: [0, 1], ru: "нам" },
    { lv: "jums", person: 5, gender: [0, 1], ru: "вам" },
    { lv: "viņiem", person: 6, gender: 0, ru: "им" },
    { lv: "viņām", person: 6, gender: 1, ru: "им" },
    { lv: "visiem", person: 0, gender: [0, 1], ru: "всем" }
];

export const punctuation = `.,:;!=+—–\\"\\'\\?\\s\\(\\)\\-`;

export function detectLang(str) {
    if (/^\d+$/.test(str.trim())) return "lv";
    //const alpha = str.replace(/[.,:;\"\'\?!\-=+\(\)—–\s0-9]+/g, "");
    const alpha = str.replace(new RegExp("[" + punctuation + "0-9]+", "g"), "");

    if (alpha === "") return "lv";
    return (function (lv, secondary) {
        var prim = (new RegExp("^[" + lv + "]+$", "i")).test(alpha),
            sec = (new RegExp("^[" + secondary + "]+$", "i")).test(alpha);
        return prim !== sec ? (prim ? "lv" : window.secLang) : (prim ? ["lv", window.secLang] : false);
    })(alphabets.lv, alphabets[window.secLang]);
}

export function countExists(count, word) {
    if (word.rule.toString().length > 2) {
        if (word.rule.toString()[2] === "0" && count === 1) return false;
        if (word.rule.toString()[2] === "1" && count === 0) return false;
    }
    return true;
}

export function nounGender(word) {
    if (!rules.lv.includes(word.rule)) { console.log("ERROR: word is not a noun, has no gender"); return false; }
    const g = parseInt(word.rule.toString()[1]);
    return g < 4 ? 0 : g < 7 ? 1 : 2;
}

export function nounCount(word) {
    if (!rules.lv.includes(word.rule)) { console.log("ERROR: word is not a noun, has no count"); return false; }
    const rule = word.rule.toString();
    if (rule.length < 3 || rule[2] === "0") return 0;
    return 1;
}

export function wordType(word) {
    //if (!word.hasOwnProperty("lemma") && hasDeepProperty(word, "lv.default")) return {
    if (!word.hasOwnProperty("lemma")) return {
        short: "ke", full: syn.type.ke
    };
    //const short = Object.keys(rules).find(key => [].concat(rules[key]).includes(word.rule));
    //const short = Object.keys(rules).find(key => aiove(rules[key], word.rule));
    const short = rt[word.rule];
    return {
        short,
        full: syn.type[short]
    }
}

export function sortByShortlist(word, arr) {

    const wordCode = word2code(word);
    
    return arr.sort((a, b) => {
        const A = a.hasOwnProperty("shortlist") ?
            a.shortlist.some(code => wordCode === code) : false;
        const B = b.hasOwnProperty("shortlist") ?
            b.shortlist.some(code => wordCode === code) : false;
        if (A && !B) return -1;
        if (B && !A) return 1;
        return 0;
    })
}

export function word2code(word) {
    return `${wordType(word).short}:${lemma2str(word.lemma)}${word.hasOwnProperty("homonym")?":"+word.homonym:""}`
}
export function word2wordStr(word) {
    return `${lemma2str(word.lemma)}[${wordType(word).short}${word.hasOwnProperty("homonym")?":"+word.homonym:""}]`
}

export function lemma2str(lemma) {
    return lemma.map(l => l[getKey(l, morphNames)]).flatten().join("");
}

const softenPairs = [
    [/b$/, "bj"],
    [/m$/, "mj"],
    [/^l$/, "ļ"],
    [/p$/, "pj"],
    [/v$/, "vj"],
    [/([^k][^s])t$/, "$1š"],
    [/^t$/, "š"],
    [/(^[aāeēiīouū])t$/, "$1š"],
    [/d$/, "ž"],
    [/c$/, "č"],
    [/dz$/, "dž"],
    [/s$/, "š"],
    [/z$/, "ž"],
    [/([^slz])n$/, "$1ņ"],
    [/^n$/, "ņ"],
    [/ll$/, "ļļ"],
    [/([^sz])l$/, "$1ļ"],
    [/sn$/, "šņ"],
    [/zn$/, "žņ"],
    [/sl$/, "šļ"],
    [/zl$/, "žļ"],
    [/ln$/, "ļņ"],
    [/kst$/, "kš"],
    [/k$/, "c"],
    [/g$/, "dz"]
];

const exceptions = {
    soft: [
        {
            "rule": [22, 221, 222], "cas": [0, 1, 2, 3, 4, 5, 6], "count": 1,
            exception: [/^tēt$/, /^vies$/, /.+ast$/, /skat$/]
        },
        {
            "rule": [22, 220], "cas": 1, "count": 0,
            exception: [/^tēt$/, /^vies$/, /.+ast$/, /skat$/, /akmen$/,
                /asmen$/, /ruden$/, /ziben$/, /ūden$/, /^sāl$/]
        },
        {
            "rule": [25, 251, 252], "cas": 1, "count": 1,
            exception: [/.+ast$/, /.+mat$/, /.+pēd$/, /[^k]st$/,
                /apaļmut$/, /apšaud$/, /balamut$/, /ball$/, /bāz$/,
                /bis$/, /bot$/, /brīz$/, /flot$/, /front$/,
                /^gid$/, /^kas$/, /kušet$/, /mis$/, /mut$/, /pas$/, /piešaud$/,
                /planšet$/, /ras$/, /sarakst$/, /šprot$/, /taks$/, /tirād$/]

            // /gāz$/ ?

        },
        {
            "rule": [26, 261, 262], "cas": 1, "count": 1,
            exception: [/^ac$/, /akt$/, /^as$/, /^aus$/, /bals$/, /brokast$/,
                /Cēs$/, /^dakt$/, /debes$/, /dzelz$/, /kūt$/, /makst$/, /pirt$/,
                /šalt$/, /takt$/, /^uts$/, /^uzac$/, /valst$/, /vēst$/, /^zos$/, /žult$/]
        }

        /*{ grupa: 2, locijums: app.lv.loc.nom, skaitlis: app.lv.loc.ds,
         iznemumi: [/^tēt$/, /^vies$/, /.+ast$/, /skat$/] },
         { grupa: 2, locijums: app.lv.loc.gen, skaitlis: app.lv.loc.ds,
         iznemumi: [/^tēt$/, /^vies$/, /.+ast$/, /skat$/]},
         { grupa: 2, locijums: app.lv.loc.dat, skaitlis: app.lv.loc.ds,
         iznemumi: [/^tēt$/, /^vies$/, /.+ast$/, /skat$/]},
         { grupa: 2, locijums: app.lv.loc.akk, skaitlis: app.lv.loc.ds,
         iznemumi: [/^tēt$/, /^vies$/, /.+ast$/, /skat$/]},
         { grupa: 2, locijums: app.lv.loc.ins, skaitlis: app.lv.loc.ds,
         iznemumi: [/^tēt$/, /^vies$/, /.+ast$/, /skat$/]},
         { grupa: 2, locijums: app.lv.loc.lok, skaitlis: app.lv.loc.ds,
         iznemumi: [/^tēt$/, /^vies$/, /.+ast$/, /skat$/]},*/
        /*{ grupa: 2, locijums: app.lv.loc.gen, skaitlis: app.lv.loc.vs,
         iznemumi: [/akmen$/, /asmen$/, /ruden$/, /ziben$/, /ūden$/, /mēnes$/, /^sāl$/, /^tēt$/, /^vies$/, /.+ast$/, /.+skat$/]},*/
        /*{ grupa: 5, locijums: app.lv.loc.gen, skaitlis: app.lv.loc.ds,
         iznemumi: [/.+ast$/, /.+mat$/, /.+pēd$/, /[^k]st$/,
         /apaļmut$/, /apšaud$/, /balamut$/, /ball$/, /bāz$/,
         /bis$/, /bot$/, /brīz$/, /flot$/, /front$/, /gāz$/,
         /^gid$/, /^kas$/, /kušet$/, /mis$/, /mut$/, /pas$/, /piešaud$/,
         /planšet$/, /ras$/, /sarakst$/, /šprot$/, /taks$/, /tirād$/] },*/
        /*{ grupa: 6, locijums: app.lv.loc.gen, skaitlis: app.lv.loc.ds,
         iznemumi: [/^ac$/, /akt$/, /^as$/, /^aus$/, /bals$/, /brokast$/,
         /Cēs$/, /^dakt$/, /debes$/, /dzelz$/, /kūt$/, /makst$/, /pirt$/,
         /šalt$/, /takt$/, /^uts$/, /^uzac$/, /valst$/, /vēst$/, /^zos$/, /žult$/] }*/
    ],
    alt: [
        {}
    ]
};

function soften(str) {
    for (const pair of softenPairs) {
        if (pair[0].test(str)) {
            return str.replace(pair[0], pair[1]);
        }
    }
    return str;
}

export function soft(morph, wordForm, word, param) {

    let str = morph[getKey(morph, morphNames)];

    let stem = wordForm.filter(m => !m.hasOwnProperty("flex")).map(m => m[getKey(m, morphNames)]).join("");

    /*if (stem === "lell") {
        console.log("soft: stem:", stem);
        console.log("soft: str:", str);
    }*/

    //console.log("morph:", str);
    //console.log("stem:", stem);

    //let excepts = exceptions.soft.filter(e => [].concat(e.rule).includes(word.rule));
    let excepts = exceptions.soft.filter(e => aiove(e.rule, word.rule));
    //console.log(exceptions);

    excepts = excepts.filter(e => Object.keys(e).filter(key => key !== "rule" && key !== "exception").every(key => param.hasOwnProperty(key)));
    //console.log(exceptions);

    //excepts = excepts.filter(e => Object.keys(param).every(key => [].concat(e[key]).includes(param[key])));
    excepts = excepts.filter(e => Object.keys(param).every(key => aiove(e[key], param[key])));
    //console.log(exceptions);

    let except = excepts.find(e => e.exception.find(rx => rx.test(stem)));

    //if (except) console.log("found exception:", except);

    //console.log(wordForm);
    //console.log("soften:", soften(str));

    return except ? str : soften(str);
}

export function alt(morph, wordForm, word, param) {

    if (morph.hasOwnProperty("alt")) {
        //console.log("morph has own alt");
        return altMorph(morph);
    }

    let str = morph.root, tense = (()=>{
        // saliktais
        if (param.tense === 3) return 0;
        // pavēles
        if (param.tense === 4) return 1;
        // vajadzības
        if (param.tense === 5) return 1;
        return param.tense;
    })();

    if (!morph.hasOwnProperty("origin")) return str;
    if (!morph.origin.hasOwnProperty("alt")) return str;

    let altName = morph.origin.alt.toString();
    let alter = alternation.find(a => a.hasOwnProperty(altName));

    //console.log(alter);

    if (alter[altName].test(morph.origin.morph)) {
        str = morph.origin.morph.replace(alter[altName], alter.forms[tense]);
    }

    if ([11, 111].includes(word.rule)) {
        if (param.person === 2 && param.tense === 1) {
            if (/[gk]$/.test(str)) {
                //console.log(morph);
                //console.log(param);
                //console.log(`must change ${str} to ${soften(str)}`);
                str = soften(str);
            }
        }
    }

    return str;

}

export function verb11(morph, wordForm, word, param, morphType) {

    //console.log(JSON.stringify(morph));

    let res;

    //console.log(morphType);
    if (morphType === "flex") {
        res = "";
        const lastRoot = wordForm.filter(f => f.hasOwnProperty("root")).pop().root;
        if ((param.tense === 1 || param.tense === 4) && param.person === 2) {
            if (/st$/.test(lastRoot)) {
                console.log("st -> sti");
                res = "i";
            }
        }
    }
    if (morph.hasOwnProperty("root")) {
        //console.log(lastRoot);
        res = morph.root;
        if (
            ((param.tense === 1 || param.tense === 4) && param.person === 2) ||
            (param.tense === 4 && param.person === 5)
        ) {
            if (/[aāeēiīouū][gk]$/.test(morph.root)) {
                res = res.substring(0, res.length-1) + soften(res[res.length-1]);
                console.log(`${morph.root} -> ${res}`);
            }
            if (/[aāeēiīouū][ļ]$/.test(morph.root)) {
                res = res.substring(0, res.length-1) + "l";
                console.log(`${morph.root} -> ${res}`);
            }
            if (/.+š$/.test(morph.root)) {
                res = res.substring(0, res.length-1) + "t";
                console.log(`${morph.root} -> ${res}`);
            }
        }
    }
    return res;
}

export function voc21(morph, wordForm, word, param) {
    //console.log("voc21: wordForm:", wordForm);
    const postfixes = wordForm.filter(m => m.hasOwnProperty("postfix"));
    if (postfixes.length > 0) {
        const postfix = postfixes[postfixes.length-1];
        if (postfix.origin) {
            if (["Āj", "Tāj", "Ēj", "Niek", "Um", "Iņ", "Ēn"].includes(postfix.origin.nick)) {
                return "";
            }
        }
    }
    return "s";
}

export function verb13(morph, wordForm, word, param) {
    //console.log(param);
    //console.log(word);
    let id;
    for (const m of wordForm) {
        if (m.hasOwnProperty("postfix")) id = m.origin.id;
    }
    //console.log(id);
    if (id) {
        const index = wordForm.findIndex(m => m.origin.id === id);
        //console.log(wordForm[index].origin.morph);

        if (param.tense === 4) return word.rule === 131 ? ["ie", "t", "ie", "s"] : ["ie", "t"];

        if (["ī", "ā"].includes(wordForm[index].origin.morph)) {
            if ([3, 6].includes(param.person)) return word.rule === 131 ? "ās" : "a";
            if (param.tense === 5) return word.rule === 131 ? "ās" : "a";

            if (param.person === 4) return word.rule === 131 ? ["ām", "ies"] : "ām";
            if (param.person === 5) return word.rule === 131 ? ["āt", "ies"] : "āt";
        }

        if (wordForm[index].origin.morph === "ē") {
            if ([3, 6].includes(param.person)) return word.rule === 131 ? "as" : "";
            if (param.tense === 5) return word.rule === 131 ? "as" : "";

            if (param.person === 4) return word.rule === 131 ? ["am", "ies"] : "am";
            if (param.person === 5) return word.rule === 131 ? ["at", "ies"] : "at";
        }

        //console.log(flex);
        //wordForm.splice(index, 1);
    } else {
        console.log("ERROR: no morph id in verb13");
        return "?";
    }

    console.log("something wrong in verb13");
    console.log("morph:", JSON.stringify(morph));
    console.log("param:", param);
    return "?";
}

/*const flexExceptions = [
    { "rule": [22, 220], "cas": [0, 1, 6], "stem": [/akmen$/, /asmen$/, /ruden$/, /ūden$/, /ziben$/, /mēnes$/, /sāl$/], "flex": "s" },
    { "rule": [22, 220], "cas": 0, "stem": /sun$/, "flex": "s" },
    { "rule": [22, 220], "cas": 6, "stem": [/sun$/, /zirnekl$/], "flex": "i" }
];*/


export function getLemma(morphs, word) {
    const lemmObj = word.hasOwnProperty("lemmaParam") ? word.lemmaParam : lemmaParams.find(p => (p.rule.indexOf(word.rule)>-1)) || { param: {} };

    /*if (lemmObj.param === undefined) {
        console.log(word);
        console.log(transform(morphs, word, lemmObj.param));
    }*/

    if (lemmObj.param) {
        if (Object.keys(lemmObj.param).length === 0) {
            //console.log(word);
            const lemma = word.morphs.map((morph) => {
                const m = {};
                m[morph.origin.type] = morph.origin.morph;
                m.origin = { nick: morph.origin.nick };
                return m;
            });
            //console.log(lemma);
            return lemma;
        }
    }

/*
    if (word.rule===60) {
        console.log(word);
        console.log(lemmObj);
    }
*/

    return transform(morphs, word, lemmObj.param);
}

export function except(word, parameters) {

    //console.log("EXCEPT");

    if (!word.hasOwnProperty("exception")) return;

    let param = parameters || (() => word.hasOwnProperty("lemmaParam") ? word.lemmaParam : lemmaParams.find(p => p.rule.includes(word.rule) || { param: false } ))().param;
    //console.log(param);
    if (param) {

        let paramKeys = Object.keys(param);

        let exceptions = [].concat(word.exception).filter(e => paramKeys.every(key => Object.keys(e).includes(key)));

        console.log(exceptions);

        for (const exception of exceptions) {
            if (Object.keys(exception)
                    .filter(key => !morphNames.includes(key))
                    .every(key => aiove(exception[key], param[key]))) {
                    //.every(key => [].concat(exception[key]).includes(param[key]))) {
                console.log("exception true");
                Object.keys(exception).filter(key => morphNames.includes(key)).forEach(key => {
                    word.morphs.find(m => m.origin.type === key).form = exception[key];
                })
            }
        }

    }
}

export function getKey(obj, order) {
    let key = Object.keys(obj).filter(k => order.includes(k));
    if (key.length > 1) console.log("WARNING: more than one key found");
    if (key.length < 1) { console.log("WARNING: no valid keys in the object"); return false; }
    return key[0];
}

export function altMorph(morph) {
    if (morph.hasOwnProperty("alt")) {
        if (getType(morph.alt) === "number") {
            if (morph.origin.hasOwnProperty("alt")) {
                const altName = morph.origin.alt.toString();
                const alter = alternation.find(a => a.hasOwnProperty(altName));
                //console.log(alter);
                if (alter) {
                    return morph.origin.morph.replace(alter[altName], alter.forms[morph.alt]);
                }
            } else {
                console.log("WARNING: morph origin has no alternation");
                return morph.origin.morph;
            }
        } else {
            return morph.alt;
        }
    }
    return morph.origin.morph;
}

function getTrans(transArr, param) {
    //if (fuck) console.log(JSON.stringify(transArr), JSON.stringify(param));
    let keys = Object.keys(param);
    return transArr
        .filter(t => {
            return Object.keys(t.param).every(k => keys.includes(k));
        })
        .filter(t => {
            for (const key of Object.keys(t.param)) {
                //if (![].concat(t.param[key]).includes(param[key])) return false;

                /*if (fuck) {
                    console.log("compare", t.param[key], param[key]);
                }*/

                if (!aiove(t.param[key], param[key])) return false;
            }
            return true;
        });
    //if (fuck) console.log(res);
    //return res;
}

function insertElem(elem, arr, order) {
    const elemKey = getKey(elem, order);
    if (elemKey === false) return;
    //console.log(elemKey);
    let afterIndex = -1;
    for (let i=0; i<arr.length; i++) {
        if (order.indexOf(getKey(arr[i], order)) <= order.indexOf(elemKey)) {
            afterIndex = i;
        } else {
            break;
        }
    }
    if (afterIndex > -1) {
        //console.log("index of element to insert after:", afterIndex);
        arr.splice(afterIndex+1, 0, elem);
        return;
    }
    let beforeIndex = -1;
    for (let i=0; i<arr.length; i++) {
        if (order.indexOf(getKey(arr[i], order)) > order.indexOf(elemKey)) {
            beforeIndex = i;
            break;
        }
    }
    if (beforeIndex > -1) {
        //console.log("index of element to insert before:", beforeIndex);
        arr.splice(beforeIndex, 0, elem);
        return;
    }
    arr.push(elem);
}


function ar(val) {
    return Array.isArray(val) ? val : [val];
}

function flex(morphs, word, param, trans) {

    let wordForm = [];

    // Create wordForm from word morphs respecting alt
    let len = word.morphs.length;
    for (let i=0; i < len; i++) {
        const morph = word.morphs[i];
        const form = {};
        form[morph.origin.type] = altMorph(morph);
        form.origin = morph.origin;
        if (morph.hasOwnProperty("alt")) form.alt = morph.alt;
        wordForm.push(form);
    }

    len = trans.length;
    for (let i=0; i < len; i++) {
        const tran = trans[i];
        const keys = Object.keys(tran);
        const keysLen = keys.length;
        if (keysLen === 1) {
            //console.log("flex(): transformation has only param, return empty wordForm");
            //return "";
            wordForm.forEach(m => { m[morphNames.find(n => m.hasOwnProperty(n))]="" });
            //console.log(wordForm);
            return wordForm;
        }
        for (let j=0; j<keysLen; j++) {
            if (keys[j] === "param") continue;
            const morphType = keys[j];
            let morphs = wordForm.filter(f => f.hasOwnProperty(morphType));
            //console.log(`flex(): all morphs of type ${morphType}:`, morphs);
            //let copy = JSON.stringify(wordForm);
            if (morphs.length > 0) {

                // wordForm contains morphs of this type

                const form = {};
                form[morphType] = getValFromFuncOrVar(tran[morphType], form, wordForm, word, param, morphType);

                if (/\+/.test(form[morphType])) {
                    // add morph inside wordForm
                    //console.log(`flex(): form contains +`, form);
                    if (/^\+/.test(form[morphType])) {
                        const morphTypeSeq = wordForm.map(f => Object.keys(f).find(key => morphNames.includes(key)));
                        //console.log(morphTypeSeq);
                        const index = morphTypeSeq.lastIndexOf(morphType);
                        //console.log(`last index of ${morphType}: ${index}`);
                        form[morphType] = form[morphType].replace(/\+/g, "");
                        wordForm.splice(index + 1, 0, form);
                        //console.log(wordForm);
                    }
                    if (/\+$/.test(form[morphType])) {
                        const index = wordForm.findIndex(f => f.hasOwnProperty(morphType));
                        //console.log(`first index of ${morphType}: ${index}`);
                        form[morphType] = form[morphType].replace(/\+/g, "");
                        wordForm.splice(index, 0, form);
                        //console.log(wordForm);
                    }
                } else {
                    // modify last morph of this type
                    let lastMorph = morphs[morphs.length - 1];
                    //console.log(morphType);
                    //console.log("flex(): lastMorph", lastMorph);
                    lastMorph[morphType] = getValFromFuncOrVar(tran[morphType], lastMorph, wordForm, word, param, morphType);
                    /*if (JSON.stringify(wordForm) !== copy) {
                        console.log(`flex(): last morph of type ${morphType} changed inside wordForm`);
                        console.log("wordForm:", wordForm);
                    }*/
                }

            } else {
                if (morphType === "stem") {
                    //console.log("stem!");
                    let stem = wordForm.filter(f => !f.hasOwnProperty("flex"));
                    let lastMorph = stem[stem.length-1];
                    //console.log("morph to change:", lastMorph);
                    //console.log("type to change:", getKey(lastMorph, morphNames));
                    let val = getValFromFuncOrVar(tran.stem, lastMorph, wordForm, word, param);
                    if (getType(val)==="string") { val = val.replace(/\+/g, "") }
                    lastMorph[getKey(lastMorph, morphNames)] = val;
                    //console.log("last morph of stem:", lastMorph[getKey(lastMorph, morphNames)]);
                } else {
                    const form = {};
                    let val = getValFromFuncOrVar(tran[morphType], form, wordForm, word, param, morphType);
                    if (getType(val)==="string") { val = val.replace(/\+/g, "") }
                    form[morphType] = val;
                    //console.log(`form of morph type ${morphType}:`, form[morphType]);
                    insertElem(form, wordForm, morphNames);
                }
            }
        }
    }

    if (word.hasOwnProperty("compoundForms")) {
        let res = [];
        word.compoundForms.flatten().forEach(f => {res.push(f)});
        wordForm.forEach(f => {res.push(f)});
        wordForm = res.flatten();
    }

    connect(morphs, word, wordForm, param);

    let clean = [];

    if (param.tense === 5) {
        if (word.rule.toString()[0] === "1") {
            clean.push({ prefix: "jā" });
        }
    }

    len = wordForm.length;
    for (let i=0; i < len; i++) {
        const morph = wordForm[i];
        const key = getKey(morph, morphNames);
        let obj = {};
        if (hasDeepProperty(morph, "origin.nick")) obj.origin = {nick: morph.origin.nick};
        obj[key] = morph[key];
        clean.push(obj);
    }

    if (word.proper === true) {
        //console.log("PROPER NOUN!!! :~~~~-))");
        const key = getKey(clean[0], morphNames);
        clean[0][key] = cap(clean[0][key]);
        //console.log(clean[0][key]);
    }

    //if (ref && word.compoundForms) console.log(clean);

    return clean;

}

export function transform(originMorphs, word, param = word.hasOwnProperty("lemmaParam") ? word.lemmaParam : lemmaParams.find(p => p.rule.includes(word.rule)).param, variant=0) {

    let morphs;

    if (Object.keys(originMorphs).length === 0) {
        console.log("transform(): no morphs passed, trying to take from the word");
        morphs = {};
        word.morphs
            .forEach(m => {
                morphs[m.origin.type] = [m.origin];
            });
        //console.log(morphs);
    } else {
        morphs = originMorphs;
    }

    //if (word.rule.toString()[0]==="7") console.log(param);

    let res;

    let trans = word.hasOwnProperty("exception") ? getTrans(ar(word.exception), param) : [];

    // mark exceptions as exceptions
    //if (trans.length > 0) { trans.forEach(t => { t.exception = true }) }

    // Find transformations and add them before exceptions
    trans = (()=>{
        const arr = getTrans(transformations
            .filter(t => aiove(t.rule, word.rule)).map(t => t.transformations).flatten(), param);
        for (let i=0; i<trans.length; i++) arr.push(trans[i]);
        return arr;
    })();

    //console.log("transform: trans[]:", trans);

    if (trans.length === 0) {
        //console.log("WORD WITHOUT TRANSFORMATIONS:", word);
        return "";
    }

    if (trans.find(t => t.hasOwnProperty("variant"))) {
        //console.log("transform: exception variants found");
        let variableTran = trans.filter(t => t.hasOwnProperty("variant"));
        if (variableTran.length > 1) {
            //console.log("ERROR: transform: more than one transformation with variants for word:", word);
            throw "transform: more than one transformation with variants for word:" + word.id;
        } else {
            res = [];
            variableTran = variableTran[0];
            let variableTranIndex = trans.findIndex(t => t.hasOwnProperty("variant"));
            // const count = variableTran.variant.length;
            variableTran.variant.forEach((variant) => {
                let transClone = [...trans];
                transClone[variableTranIndex] = {
                    param: variableTran.param,
                    ...variant
                };
                //console.log("transform: cloned trans:", transClone);
                res.push(flex(morphs, word, param, transClone));
            });
        }

    } else {
        res = flex(morphs, word, param, trans);
    }

    if (Array.isArray(res[0])) {
        //console.log("transform: variable transformations results");
        if (getType(variant) === "number") {
            //console.log("transform: variant is number", variant);
            if (variant > res.length-1) {
                //console.log("ERROR: transform: requested variant not found, return last");
                return res[res.length-1];
            } else {
                return res[variant];
            }
        } else {
            //console.log("transform: variant is not a number");
            switch (variant) {
                case "random":
                    return res[getRandomInt(0, res.length-1)];
                case "all":
                    //console.log("transform: return all variants");
                    return res;
                default:
                    console.log("ERROR: transform: unknown variant requested, return first");
                    return res[0];
            }
        }

    } else {
        return variant === "all" ? [res] : res;
    }

}

export function transform6000(morphs, word, parameters) {

    if ((parameters!==undefined && Object.keys(parameters).length > 0) && word.rule.toString()[0]==="7") {
        const x = transform(morphs, word, parameters);
        console.log("transform: transformation result:", x);
    }

    //console.log(parameters);

    let wordForm = [], form,
        param = parameters || lemmaParams.find(p => p.rule.includes(word.rule)).param;

    //console.log(word);

    let len = word.morphs.length;

    for (let i=0; i < len; i++) {
        const morph = word.morphs[i];
        form = {};
        form[morph.origin.type] = altMorph(morph);
        form.origin = morph.origin;
        if (morph.hasOwnProperty("alt")) form.alt = morph.alt;
        wordForm.push(form);
    }

    /*word.morphs.forEach(morph => {
        form = {};
        //console.log(morph);
        form[morph.origin.type] = altMorph(morph);
        form.origin = morph.origin;
        if (morph.hasOwnProperty("alt")) form.alt = morph.alt;
        wordForm.push(form);
    });*/

    let trans;

    trans = (word.hasOwnProperty("exception") && word.rule.toString()[0]!=="7") ? getTrans(ar(word.exception), param, "fuck") : [];

    trans = (()=>{
        const arr = getTrans(transformations
            .filter(t => aiove(t.rule, word.rule)).map(t => t.transformations).flatten(), param);
        for (let i=0; i<trans.length; i++) arr.push(trans[i]);
        return arr;
    })();

    if (trans.length === 0) {
        //console.log("WORD WITHOUT TRANSFORMATIONS:", word);
        return "";
    }

    len = trans.length;

    for (let i=0; i < len; i++) {
        const tran = trans[i];
        const keys = Object.keys(tran);
        const keysLen = keys.length;
        if (keysLen === 1) {
            //console.log("transformation has only param, return empty string");
            return "";
        }
        for (let j=0; j<keysLen; j++) {
            if (keys[j] === "param") continue;
            const morphType = keys[j];
            let morphs = wordForm.filter(f => f.hasOwnProperty(morphType));
            if (morphs.length > 0) {
                let lastMorph = morphs[morphs.length-1];
                //console.log(morphType);
                lastMorph[morphType] = getValFromFuncOrVar(tran[morphType], lastMorph, wordForm, word, param, morphType);
            } else {
                if (morphType === "stem") {
                    //console.log("stem!");
                    let stem = wordForm.filter(f => !f.hasOwnProperty("flex"));
                    let lastMorph = stem[stem.length-1];
                    //console.log("morph to change:", lastMorph);
                    //console.log("type to change:", getKey(lastMorph, morphNames));
                    lastMorph[getKey(lastMorph, morphNames)] =
                        getValFromFuncOrVar(tran.stem, lastMorph, wordForm, word, param);
                } else {
                    form = {};
                    form[morphType] = getValFromFuncOrVar(tran[morphType], form, wordForm, word, param, morphType);
                    insertElem(form, wordForm, morphNames);
                }
            }
        }

    }

    /*
    trans.forEach(tran => {

        Object.keys(tran).forEach(key => { if (key !== "param") { let morphType = key;

            let morphs = wordForm.filter(f => f.hasOwnProperty(morphType));
            if (morphs.length > 0) {
                let lastMorph = morphs[morphs.length-1];
                lastMorph[morphType] = getValFromFuncOrVar(tran[morphType], lastMorph, wordForm, word, param);
            } else {

                if (morphType === "stem") {
                    //console.log("stem!");
                    let stem = wordForm.filter(f => !f.hasOwnProperty("flex"));
                    let lastMorph = stem[stem.length-1];
                    //console.log("morph to change:", lastMorph);
                    //console.log("type to change:", getKey(lastMorph, morphNames));
                    lastMorph[getKey(lastMorph, morphNames)] =
                        getValFromFuncOrVar(tran.stem, lastMorph, wordForm, word, param);
                } else {
                    form = {};
                    form[morphType] = getValFromFuncOrVar(tran[morphType], form, wordForm, word, param);
                    insertElem(form, wordForm, morphNames);
                }
            }

        }});

    });
    */

    /*if (word.hasOwnProperty("compoundForms"))
        wordForm = [].concat(word.compoundForms.flatten(), wordForm).flatten();*/

    // NO CONCAT:
    if (word.hasOwnProperty("compoundForms")) {
        let res = [];
        word.compoundForms.flatten().forEach(f => {res.push(f)});
        wordForm.forEach(f => {res.push(f)});
        wordForm = res.flatten();
    }

    connect(morphs, word, wordForm, param);

    let clean = [];

    if (param.tense === 5) {
        if (word.rule.toString()[0] === "1") {
            clean.push({ prefix: "jā" });
        }
    }

    len = wordForm.length;
    for (let i=0; i < len; i++) {
        const morph = wordForm[i];
        const key = getKey(morph, morphNames);
        let obj = {};
        if (hasDeepProperty(morph, "origin.nick")) obj.origin = {nick: morph.origin.nick};
        obj[key] = morph[key];
        clean.push(obj);
    }

    if (word.proper === true) {
        //console.log("PROPER NOUN!!! :~~~~-))");
        const key = getKey(clean[0], morphNames);
        clean[0][key] = cap(clean[0][key]);
        //console.log(clean[0][key]);
    }

    //if (ref && word.compoundForms) console.log(clean);

    return clean;

}

const connections = [
    { "postfix": "Um", "before": /[aāeēiīouū]$/, "change": "j,$1", "rule": rules.lv },
    { "postfix": "Āj", "before": /[aāeēiīouū]$/, "change": "t,$1", "rule": rules.lv },
    { "postfix": "Niek", "after": /^[eēiī]/, "change": soften, "rule": [25, 250, 251, 252] },

    //{ "root": "Brauk", "after": /^[eēiī]/, "change": soften, "rule": rules.lv },

    // TEIK SAKT SAVE
    { "root": /^(sa)k$/, "after": /^i/, "change": "$1y", "rule": [11, 111], "tense": 4 },

    { "root": /(.+)k$/, "after": /^i/, "change": "$1c", "rule": [11, 111], "tense": [3, 4] },

    { "root": /(.+)c$/, "after": /^u/, "change": "$1k", "rule": [11, 111], "tense": 3 },
    { "root": /(.+)dz$/, "after": /^u/, "change": "$1g", "rule": [11, 111], "tense": 3 },
    { "root": /(.+)g$/, "after": /^i/, "change": "$1dz", "rule": [11, 111], "tense": [3, 4] },

    { "root": /(.+)ž$/, "change": "$1z", "rule": [11, 111], "tense": [1, 4], "person": 2 },
    { "root": /(.+)ž$/, "change": "$1z", "rule": [11, 111], "tense": 4, "person": 5 },

    { "root": /(.+)([pb])j$/, "change": "$1$2", "rule": [11, 111], "tense": [1, 4], "person": 2 },
    { "root": /(.+)([pb])j$/, "change": "$1$2", "rule": [11, 111], "tense": 4, "person": 5 },

    // TEIK SAKT RESTORE
    { "root": /^(sa)y$/, "after": /^i/, "change": "$1k", "rule": [11, 111], "tense": 4 },

    { "flex": /^aj/, "before": /ēj$/, "change": "", "rule": [3, 31] }

    /*{ "flex": "t", "before": /st$|sak$|prot$/, "change": "i", "rule": 11, "tense": 1, "person": 2 },
    { "flex": "t", "before": /ē$/, "change": "", "rule": 13, "tense": 1, "person": 3 },
    { "postfix": /^ē$/, "change": "", "rule": 13, "tense": 1 },
    { "postfix": /^inā$/, "change": "in", "rule": 13, "tense": 1 },
    { "postfix": /^ī$/, "change": "", "rule": 13, "tense": 1, "person": [2, 3] },
    { "root": /(.+)g$/, "change": "$1dz", "rule": 11, "tense": 1, "person": 2 },
    { "root": /(.+)k$/, "change": "$1c", "rule": 11, "tense": 1, "person": 2 }*/
];

export function connect(morphs, word, wordForm, parameters) {

    let connected, param = parameters || {};

    const len = wordForm.length;

    for (let index=0; index < len; index++) {
        const morph = wordForm[index];

        const morphKey = getKey(morph, morphNames);

        const morphOrigin = morph.hasOwnProperty("origin") ? morph.origin : getMorph(morphs, morphKey, morph[morphKey]);

        connected = connections
            .filter( c => c.hasOwnProperty(morphKey) );

        connected = connected
            .filter(
                c => (() => {
                    if (getType(c[morphKey]) === "string")

                        if (c[morphKey][0].toUpperCase() === c[morphKey][0]) {

                            /*if (morphs.root.length < 3) {
                                console.log(JSON.stringify(morphs));
                                console.log(morphKey);
                                console.log(morphs[morphKey]);
                            }*/

                            const morphConn = morphs[morphKey].find(m => m.nick === c[morphKey]);
                            return morphConn ? morphConn.nick === morphOrigin.nick : false;
                        } else {
                            return c[morphKey] === morph[morphKey];
                        }

                } )() ||
                c[morphKey]==="*" || getType(c[morphKey]) === "regexp" );

        connected = connected
            .filter( c => c.hasOwnProperty("rule") ? aiove(c.rule, word.rule) : true );

        connected = connected
            .filter(conn => {
                let properties = ruleKeys.find(rk => rk.rule.includes(word.rule));
                let res = true;
                if (properties) {
                    properties.keys.forEach(key => {
                        res = res && (conn.hasOwnProperty(key) ? aiove(conn[key], param[key]) : true);
                    });
                }
                return res;
            });

        connected.forEach(conn => {
            let change = true;
            if (conn.hasOwnProperty("before")) {
                if (index > 0) {
                    change = change && conn.before.test(wordForm.slice(0, index).map(m=>m[getKey(m, morphNames)]).join(""));
                }
            }
            if (conn.hasOwnProperty("after")) {
                if (index < wordForm.length-1) {
                    change = change && conn.after.test(wordForm.slice(index+1).map(m=>m[getKey(m, morphNames)]).join(""));
                }
            }
            if (getType(conn[morphKey]) === "regexp") {
                change = change && conn[morphKey].test(morph[morphKey]);
            }
            if (change) {
                const rx = getType(conn[morphKey]) === "regexp" ?
                    conn[morphKey] : new RegExp("(" + morph[morphKey] + ")");
                // const prev = morph[morphKey];
                morph[morphKey] = morph[morphKey].replace(rx,
                    (getType(conn.change) === "function" ? conn.change(morph[morphKey]) : conn.change)
                );
                if (/,/.test(morph[morphKey])) morph[morphKey] = morph[morphKey].split(",");
                //console.log("changed " + morphKey + " from " + prev + " to", morph[morphKey]);
                //console.log(morph);
            }
        });
    }

/*

    wordForm.forEach((morph, index) => {
        
        const morphKey = getKey(morph, morphNames);
        //console.log("search connections for " + morphKey + ": ", morph[morphKey]);

        const morphOrigin = morph.hasOwnProperty("origin") ? morph.origin : getMorph(morphs, morphKey, morph[morphKey]);
        //console.log("origin:", morphOrigin);

        connected = connections
            .filter( c => c.hasOwnProperty(morphKey) );

        //if (connected.length > 0) console.log("1. morph:", morph[morphKey], "connections fit:", connected);

        connected = connected
            .filter(
                c => (() => {
                    if (getType(c[morphKey]) === "string") if (c[morphKey][0].toUpperCase() === c[morphKey][0]) {
                        const morphConn = morphs[morphKey].find(m => m.nick === c[morphKey]);
                        return morphConn ? morphConn.nick === morphOrigin.nick : false;
                    } else {
                        return c[morphKey] === morph[morphKey];
                    }
                } )() ||
                c[morphKey]==="*" || getType(c[morphKey]) === "regexp" );

        //if (connected.length > 0) console.log("2. morph:", morph[morphKey], "connections fit:", connected);

        /!*connected = connected
            .filter( c => c.hasOwnProperty("rule") ? [].concat(c.rule).includes(word.rule) : true );*!/
        connected = connected
            .filter( c => c.hasOwnProperty("rule") ? aiove(c.rule, word.rule) : true );

        //if (connected.length > 0) console.log("3. morph:", morph[morphKey], "connections fit:", connected);
        
        connected = connected
            .filter(conn => {
                let properties = ruleKeys.find(rk => rk.rule.includes(word.rule));
                let res = true;
                if (properties) {
                    properties.keys.forEach(key => {
                        //res = res && (conn.hasOwnProperty(key) ? [].concat(conn[key]).includes(param[key]) : true);
                        res = res && (conn.hasOwnProperty(key) ? aiove(conn[key], param[key]) : true);
                    });
                }
                return res;
            });

        //if (connected.length > 0) console.log(morphKey + ": " + morph[morphKey], "connections fit:", connected);

        connected.forEach(conn => {
            change = true;
            if (conn.hasOwnProperty("before")) {
                //console.log("before");
                if (index > 0) {
                    //console.log("str from beginning:", word.morphs.slice(0, index).map(m=>m.form).join(""));
                    change = change && conn.before.test(wordForm.slice(0, index).map(m=>m[getKey(m, morphNames)]).join(""));
                }
            }
            if (conn.hasOwnProperty("after")) {
                //console.log("after");
                if (index < wordForm.length-1) {
                    //console.log("str after this:", word.morphs.slice(index+1).map(m=>m.form).join(""));
                    change = change && conn.after.test(wordForm.slice(index+1).map(m=>m[getKey(m, morphNames)]).join(""));
                }
            }
            if (getType(conn[morphKey]) === "regexp") {
                change = change && conn[morphKey].test(morph[morphKey]);
            }
            if (change) {
                rx = getType(conn[morphKey]) === "regexp" ?
                    conn[morphKey] : new RegExp("(" + morph[morphKey] + ")");
                const prev = morph[morphKey];
                morph[morphKey] = morph[morphKey].replace(rx,
                    (getType(conn.change) === "function" ? conn.change(morph[morphKey]) : conn.change)
                );
                if (/,/.test(morph[morphKey])) morph[morphKey] = morph[morphKey].split(",");
                //console.log("changed " + morphKey + " from " + prev + " to", morph[morphKey]);
                //console.log(morph);
            }
        });

    });
*/

}

export function getAlternation(morph, param) {
    if (!morph.hasOwnProperty("alt")) { console.log("ERROR: morph has no alternation"); return morph.morph; }
    let form = morph.morph;
    alternation.filter(alt => alt.hasOwnProperty(morph.alt.toString())).forEach(alt => {
        form = form.replace(alt[morph.alt.toString()], alt.forms[param]);
    });
    return form;
}

export function getMorph(morphs, morphName, val) {

    if (val === "") return { origin: { morph: "", type: morphName } };

    let morphObj, value = val, param, alt, origin;

    //console.log(val);

    if (/^[^:]+:[^:]+$/.test(val)) {
        value = val.split(":")[0];
        param = val.split(":")[1];
    }

    if (value[0].toUpperCase() === value[0]) {

        origin = morphs[morphName].find(m => m.nick === value);

        if (origin === undefined) {
            console.log("ERROR: morph with nick '" + value + "' not found");
            return {}
        }

        morphObj = { origin };

        if (param !== undefined) {
            //console.log("param ir");
            if (/^\d+$/.test(param)) {
                alt = parseInt(param);
            } else {
                alt = param;
            }
            morphObj.alt = alt;
        }
        //console.log(morphObj);

    } else {
        morphObj = { origin: { morph: value, type: morphName } };
    }

    return morphObj;

}

export function getRuleByMorph(morphs) {
    let rule;
    morphs.forEach(morph => {
        if (morph.origin.hasOwnProperty("rule")) {
            rule = morph.origin.rule;
        } else {
            //console.log(`WARNING: morph ${morph.origin.morph} has no rule, set 11 if has .alt or false`);
            if (rule === undefined) rule = morph.origin.hasOwnProperty("alt") ? 11 : false;
        }
    });
    return rule;
}

export function getFlex(morphs, word) {
    let flex = morphs.flex.find(f => [].concat(f.rule).includes(word.rule));
    if (flex === undefined) flex = morphs.flex.find(m => m.nick === "Empty");
    return { origin: flex, form: flex.morph };
}
