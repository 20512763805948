import React from "react";
import { randomStr } from "../../prototypes";
import { entities2unicode } from "../../lang/parse";
import { Media } from "../Media";
/*
<Media mediaHeader={<><h2>Ko rakstīja avīzes</h2><div className="trans">Что писали газеты</div></>}
wordsListHeader={<h3>nepieciešamie vārdi</h3>}
translate listwords
media={media} chain={chain} data={props.data} box={box} />
*/

function QMedia({ media, id, listwords, data, box }) {

    const mediaHeaders = [
        { str: {
            lv: "Ko rakstīja avīzes",
            ru: "Что писали газеты"
        }, chance: 50 },
        { str: {
            lv: "Par šo rakstīja avīzēs",
            ru: "Об этом писали в&nbsp;газетах"
        }, chance: 50 },
        { str: {
            lv: "Tika atspoguļots medijos",
            ru: "Было отражено в прессе"
        }, chance: 50 }
    ];

    const m = randomStr(mediaHeaders);
    const mediaHeader = <>
        <div className="media">{entities2unicode(m.lv)}</div>
        <div className="media trans">{entities2unicode(m[window.secLang])}</div>
    </>;

    const wordsListHeaders = [
        { str: {
            lv: "Nepieciešamie vārdi",
            ru: "Необходимые слова"
        }, chance: 50 },
        { str: {
            lv: "Nepieciešamie pasaulei vārdi",
            ru: "Необходимые миру слова"
        }, chance: 10 }
    ];

    const w = randomStr(wordsListHeaders);
    const wordsListHeader = <>
        <div className="wordsList">{entities2unicode(w.lv)}</div>
        <div className="wordsList trans">{entities2unicode(w[window.secLang])}</div>
    </>;

    //console.log("QMedia: props:", props);

    const chain = data.chains.find(chain => chain.id === id);

    //console.log(chain);

    return (
        chain ? <Media translate
            {...{
                media, chain, data, box,
                mediaHeader, wordsListHeader, listwords
            }} /> : null
    )

}

export default QMedia;
