import React from 'react';
import { parseChain, parseParams, removeDiactritics, renderNode, parseWordStr2, camelCase } from "./parse";
import { lemma2str } from "./lang2";
import { cartesian, getType, generateId } from "../prototypes";
import QText from "../components/lessons/QText";
import QRadio from "../components/lessons/QRadio";
import QMedia from "../components/lessons/QMedia";
import QSVG from "../components/lessons/QSVG";
import QImage from "../components/lessons/QImage";
import QLink from "../components/lessons/QLink";

export const lessonTags = ["qtext", "qradio", "qmedia", "qlink", "qsvg", "qimage"];

const components = {
    qtext: QText,
    qradio: QRadio,
    qmedia: QMedia,
    qlink: QLink,
    qimage: QImage,
    qsvg: QSVG
};

const ignore = {
    "accents": removeDiactritics,
    "uppercase": (a) => a.toLowerCase(),
    "strict": (a) => a,
    "fullstop": a => a.replace(/\.$/, ""),
    "everything": () => 1
};

const match = (a, b, ignoramus=[]) => {
    //console.log("match: ignoramus:", ignoramus);
    let res, mutantA = a, mutantB = b;
    if (ignoramus.length > 0) {
        ignoramus.forEach(ignorance => {
            mutantA = ignore[ignorance](mutantA); mutantB = ignore[ignorance](mutantB);
        });
        //console.log("match: compare with ignorami applied:", mutantA, mutantB);
        res = mutantA === mutantB;
    } else {
        res = a === b;
    }
    return res;
};

export function correctAnswer2text(answerObjWithAnswersArr) {
    return answerObjWithAnswersArr.answers.map(a => getType(a) === "object" ? a.usedForm : a).join("");
}

const mutators = {
    qtext: {
        answer: (a) => a.trim() === "" ? null : a,
        correctAnswers: a => a.map(a => correctAnswer2text(a))
    },
    qradio: {
        answer: a => a === -1 ? null : a,
        correctAnswers: b => [b]
    }
};

const matchAinB = (a, b, mutatorsA=[], mutatorsB=[]) => {

    //console.log("matchAinB: b:", b);

    let mutantA = a, mutantB = b;

    mutatorsA.forEach(mutator => {
        mutantA = mutator(mutantA);
    });
    mutatorsB.forEach(mutator => {
        mutantB = mutator(mutantB);
    });

    //console.log(mutantA, mutantB);

    //console.log("matchAinB: mutantB:", mutantB);


    return mutantB.some((mb, i) => {

        //console.log("matchAinB: mutantA:", mutantA);
        //console.log("matchAinB: compare to mb:", mb);
        //console.log("matchAinB: not mutated b:", b[i]);

        return match(mutantA, mb, b[i] ? b[i].ignore||[] : [])
    });
};

export function clearQElements(refs) {
    refs.forEach(ref => {
        if (ref.ref.current) {
            //console.log(ref.ref.current);
            const element = ref.ref.current;
            element.classList.remove("error");
            element.classList.remove("correct");
            if (element.classList.contains("qRadio")) {
                [...element.querySelectorAll("span")].forEach(s => s.classList.remove("checked"))
            }
            //console.log(element.querySelectorAll(`[name="${element.id}"]`));
            const inputs = [...element.querySelectorAll("input")];
            inputs.forEach(input => {
                //console.log(input.type);
                if (input.type === "radio") {
                    //console.log("radio defaultChecked?", input.defaultChecked);
                    input.defaultChecked = false;
                    //input.checked = false;
                    //console.log("now radio defaultChecked?", input.defaultChecked);
                }
                if (input.type === "text") {
                    input.value = "";
                }
            });
        } else {
            console.log("ERROR: no element to clear");
        }
    });
}

export function check(answers, correctAnswers, answersState) {

    const updatedAnswersState = {...answersState};

    //console.log(correctAnswers);
    
    Object.keys(answers).forEach(key => {
        
        //console.log(key, ":", answers[key]);

        const relatedCorrectAnswers = correctAnswers[key];
        //const ignoramus = correctAnswers[key].hasOwnProperty("ignore") ? correctAnswers.ignore : [];

        //console.log("lessons.js check: correctAnswers[key]:", correctAnswers[key]);
        //console.log("lessons.js check:", relatedCorrectAnswers);

        const qType = answers[key].type;

        //console.log(mutators[qType].answer(answers[key].value));

        //console.log(mutators[qType].correctAnswers(relatedCorrectAnswers));

        if (mutators[qType].answer(answers[key].value) === null) {

            updatedAnswersState[key] = {
                res: null, warnings: []
            };

        } else {

            //console.log(matchAinB(answers[key].value, relatedCorrectAnswers, [mutators[qType].answer], [mutators[qType].correctAnswers]));

            const res = matchAinB(
                answers[key].value, relatedCorrectAnswers, [mutators[qType].answer], [mutators[qType].correctAnswers]
            );

            updatedAnswersState[key] = {
                res, warnings: []
            };
        }
        
    });
    
    return updatedAnswersState;
    
}

export function splitAnswer(answerText) {

    let originArr = answerText.split(/(\[[^\]]*[a-z]\*[^\]]*\])/).filter(s=>s!=="");

    //console.log(originArr);

    let originToFork = [];

    originArr.forEach((entry, index) => {

        if (/^\[[^\]]*[a-z]\*[^\]]*\]$/.test(entry)) {
            const count = entry.match(/[a-z]\*/g).length;
            //console.log(`${entry} has ${count} star(s)`);
            const params = entry.split(/([a-z]\*)/);
            //console.log(params);
            let paramsToFork = [];
            params.forEach((param, paramIndex) => {
                if (/^[a-z]\*$/.test(param)) {
                    const count = parseParams[param[0]].values.length;
                    //console.log(`${parseParams[param[0]].param} has ${count} possible values`);
                    paramsToFork.push(parseParams[param[0]].values.map(v=>param[0]+v));
                } else {
                    paramsToFork.push([param]);
                }
            });
            //console.log(paramsToFork);
            //console.log(cartesian(paramsToFork).map(e => e.join("")));
            originToFork.push(cartesian(paramsToFork).map(e => e.join("")));
        } else {
            originToFork.push([entry]);
        }
    });

    //console.log(cartesian(originToFork).map(e => e.join("")));

    return cartesian(originToFork).map(e => e.join(""));
}

export function renderQnode(node, props) {

    //console.log("renderQnode: props:", props);

    const tag = node.nodeName.toLowerCase();
    
    let qprops;

    const data = props.data;

    if (tag === "qmedia") {

        if (!node.hasAttribute("id")) {
            console.log(`ERROR: missing params: ${!node.hasAttribute("id")?"id":"unknown"}`);
            return null;
        }

        qprops = {...props, id: node.getAttribute("id") };
    }

    if (tag === "qlink") {

        //console.log("renderQnode: qlink");

        if (!node.hasAttribute("href") || !node.hasAttribute("text")) {
            console.log(`ERROR: missing params: ${!node.hasAttribute("href")?"href":"text"}`);
            return null;
        }

        qprops = { };
        [...node.attributes].forEach(attr => qprops[camelCase(attr.nodeName)] = attr.nodeValue);

    }

    if (tag === "qsvg") {

        //console.log("renderQnode: qlink");

        if (!node.hasAttribute("src")) {
            console.log(`ERROR: missing params: src`);
            return null;
        }
        //console.log(node.attributes);

        qprops = { };
        [...node.attributes].forEach(attr => qprops[camelCase(attr.nodeName)] = attr.nodeValue);

        //console.log(qprops);

        //qprops = {...props, src: node.getAttribute("src") };
    }

    if (tag === "qimage") {

        //console.log("renderQnode: qlink");

        if (!node.hasAttribute("src")) {
            console.log(`ERROR: missing params: src`);
            return null;
        }
        //console.log(node.attributes);

        qprops = { };
        [...node.attributes].forEach(attr => {
            //console.log(camelCase(attr.nodeName));
            //console.log(attr.nodeValue);
            qprops[camelCase(attr.nodeName)] = attr.nodeValue
        });

    }

    if (tag === "qradio") {
        if (!node.hasAttribute("a") || !node.hasAttribute("q")) {
            console.log(`ERROR: missing params: ${!node.hasAttribute("a")?"answer":!node.hasAttribute("q")?"questions":"unknown"}`);
            return {}
        }

        let questionTexts = node.getAttribute("q").split("|"), questions = [], answers;

        if (questionTexts.length < 2) { throw "questions < 2" }

        questionTexts.forEach(questionText => {
            questions.push(
                parseChain(data, {lv:{default: questionText}})
            );
        });
        
        answers = parseInt(node.getAttribute("a"));

        if (answers.toString() !== node.getAttribute("a")) {
            throw `answer ${answers} is not a number`;
        }

        answers--;

        props.correctAnswers[props.id] = answers;

        qprops = {...props, questions, answers };

        if (node.hasAttribute("split")) qprops.split = node.getAttribute("split")===""?true:node.getAttribute("split");

    }

    if (tag === "qtext") {

        if (!node.hasAttribute("a")) {
            console.log("ERROR: answer missing in node", node);
            return {}
        }

        //console.time("Qnode");

        let questions, donor = {};
        if (node.hasAttribute("q")) {
            questions = parseChain(data, {lv: {default: node.getAttribute("q")}});
            donor = questions.find(q => q.hasOwnProperty("word")) || {};
        }
        
        //console.log(questions);
        const answerTexts = node.getAttribute("a").split("|");
        //console.log(answerTexts);

        let answers = [], answer;

        const ignorance = (node.hasAttribute("ignore")) ? node.getAttribute("ignore").split(/\s*,\s*/) : ["uppercase"];

        //console.log("renderQnode: ignorance:", ignorance);


        answerTexts.forEach(answerText => {

            if (/\[[^\]]*[a-z]\*[^\]]*\]/.test(answerText)) {
                const splittedAnswers = splitAnswer(answerText);
                splittedAnswers.forEach(splittedAnswer => {
                    answers.push(
                        { answers: parseChain(data, {lv: {default: splittedAnswer}}, donor.word), ignore: ignorance }
                    );
                });
            } else {
                answers.push(
                    { answers: parseChain(data, {lv: {default: answerText}}, donor.word), ignore: ignorance }
                );
            }

        });

        //console.timeEnd("Qnode");

        props.correctAnswers[props.id] = answers;

        //console.log(props.key);

        //console.log(questions);

        qprops = {...props, questions, answers };

        if (node.hasAttribute("size")) qprops.size = node.getAttribute("size");

    }

    const QComponent = components[tag];

    return <QComponent key={generateId} {...qprops} />


}

export function parseExercise2(exercise, props) {

    //console.time("parseExercise");

    /*{
        data: props.data, box: props.box,
        nolink: true,
        correctAnswers: ca,
        setAnswers, answersState
    }*/

    const parser = new DOMParser();

    let html = exercise.default.join("");

    lessonTags.forEach(tag => {
        const rx = new RegExp(`<${tag}\\s[^>]+\\/>`);
        //console.log(rx);
        //let test = "aaa <qtext a=1 b='2' /> bbb <qtext q='1 3y' b='2'/>";
        if (rx.test(html)) {
            const rx = new RegExp(`(<${tag}\\s[^>]+)\\/>`, "g");
            html = html.replace(rx, `$1></${tag}>`);
        }
    });

    const nodes = [...parser.parseFromString(html, "text/html").body.childNodes];

    //console.log(nodes);

    const tprops = {
        ...props,
        custom: {
            id: exercise.id,
            tags: lessonTags,
            counter: 0,
            customize: renderQnode
        }
    };

    if (/\[[^\[\]]+\]/.test(html) && props.filtered === true) {
        //console.log("parseExercise2: filter words");
        //console.log(html);
        const arr = html
            .match(/[a-zāčēģīķļņšūž&;-]*\[[a-zāčēģīķļņšūž:\d\*]+\]/ig).unique();
        //console.log(arr);
        const lemmas = arr.map(entry => parseWordStr2(entry).lemma||"").filter(s=>s!=="").unique();
        //console.log(lemmas);
        const words = props.data.words.filter(w => lemmas.includes(lemma2str(w.lemma)));
        //console.log(words);
        if (words.length !== lemmas.length) {
            console.log("WARNING: filtered words length mismatch");
            console.log("encoded words:", lemmas, "filtered data.words:", words.map(w=>lemma2str(w.lemma)));
        }
        tprops.data = {...props.data, words};
        //console.log(tprops);
    }

    //console.timeEnd("parseExercise");

    //console.time("renderNode");

    return renderNode(nodes, tprops);

    //console.timeEnd("renderNode");

}
//