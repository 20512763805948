import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Content from "../components/Content";
import { detectLang, wordType } from "../lang/lang2";
import { entities2unicode } from "../lang/parse";
import { hasDeepProperty, getDeepPropValue, aiove, aov, getRandomInt } from "../prototypes";
import { seekers, converters, comparators } from "../search/seekers";
import { finder } from "../search/finder";
import { searches } from "../search/searches";
import { Textarea, IconX, sizer } from "./Input";

/*
 // use: <element ref={func} />
 const addSelectStart = node => {
 if (!node) return;
 node.addEventListener("selectstart", (event) => {
 console.log("select start");
 console.log(window.getSelection().toString());
 });
 };
 */

export function NewSearch(props) {
    let history = useHistory();
    let prevSelection = "";
    const newSearch = ()=>{
        //console.log("window.selection:", window.getSelection().toString());
        //console.log("prev selection:", prevSelection);
        if (window.getSelection().toString().length === 0 && prevSelection === "") {
            //console.log("both are empty, going to search");
            //localStorage.removeItem(searches[props.search].cookieName);
            history.push("/meklēšana/");
        } else {
            //console.log("one of them is not empty, assign window.selection to prev selection if it differs, else assign empty string");
            prevSelection = prevSelection === window.getSelection().toString() ? "" : window.getSelection().toString();
            //console.log("now prev selection = ", prevSelection);
        }
    };
    return (<span className={props.className} onMouseUp={newSearch}>{props.children}</span>)
}

export function SearchResults({ className, searchResults, children }) {
    useEffect(() => { console.log(`SearchResults: length:`, searchResults && searchResults.length); }, [searchResults]);
    return (
        <div className={className || "serp"}>{
            searchResults ?
                searchResults.length > 0 ?
                    searchResults :
                    "nekas netika atrasts" : children
        }</div>
    );
}

function SearchForm(props) {

    useEffect(()=>{
        //console.log("SearchForm mount");
        if (props.id) {
            //console.log(props.id);
            if (props.id.current) {
                const selectionStart = sessionStorage.getItem("selectionStart");
                if (selectionStart !== null) {
                    props.id.current.selectionStart = parseInt(selectionStart);
                    sessionStorage.removeItem("selectionStart");
                } else {
                    props.id.current.selectionStart = props.id.current.value.length;
                }
            }
        }
    }, []);

    function handleChange(event) {
        props.onInputChange(event.target.value);
    }

    const inputValue = props.inputValue;

    const [x, setX] = useState(inputValue.trim().length > 0);

    useEffect(()=>{
        setX(inputValue.trim().length>0);
        if (props.id.current) {
            sizer(props.id.current);
        }
    }, [inputValue]);

    return (
        <div className="searchForm">
            <Textarea id={props.id}
                      className={`search ${detectLang(inputValue)||"unknown"}`}
                      value={inputValue} onChange={handleChange} />
            <IconX x={x} onClick={()=>{ props.onInputChange("") }} />
        </div>
    )

}

function getQueryFromStr(str, prevQuery, searchName) {
    if (str.trim() === "") {
        //console.log("empty query");
        localStorage.removeItem(searches[searchName].cookieName);
        return null;
    }
    //console.log("getQuery:");
    const lang = detectLang(str);
    if (lang === false && str !== "" && prevQuery) if ( prevQuery.str !== "") return prevQuery;
    //console.log({ str, lang });
    return { str, lang };
}

function getQueryTimeout(query) {
    const defaultTimeout = 333;
    const timeouts = [4000, 3000, 600];
    return query.str.length <= timeouts.length ? timeouts[query.str.length-1] : defaultTimeout;
}

const Search = React.memo(
    function (props) {

        const { box, data } = props;
        const cookieName = searches[props.search].cookieName;
        const loader = searches[props.search].loader;

        useEffect(() => {
            //console.log("setting data...");
            let cdata = [];
            cdata.length = data.words.length + data.chains.length;
            let i, len = data.words.length;
            for(i = 0; i < len; i++) { cdata[i] = data.words[i] }
            let len0 = len;
            len = data.chains.length;
            for(i = 0; i < len; i++) { cdata[len0 + i] = data.chains[i] }
            setCombinedData(cdata);
            //console.log("...done");
            //console.log(data[0]);
            // 4d0bce71-c2c3-c0f5-cfa0-1994fb6b8e81

            /*return ()=>{
                console.log("Search component useEffect /[]/ clean up fired");
                stopSearch();
            }*/

        }, []);

        //console.log(localStorage.getItem("query"));

        const [query, setQuery] = useState(
            cookieName!==undefined?localStorage.getItem(cookieName)?JSON.parse(localStorage.getItem(cookieName)):null:null
        );

        const [inputValue, setInputValue] = useState(
            (()=>{
                let q = localStorage.getItem(cookieName);
                if (q) {
                    q = JSON.parse(q);
                    if (q) return q.str || "";
                }
                return "";
            })()
        );
        const [combinedData, setCombinedData] = useState([]);

        function handleInputChange(value) {
            //console.log(value);
            setInputValue(value);
            setQuery(getQueryFromStr(value, query, props.search));
        }

        let timeout = {
            finder: "", process: ""
        };

        function stopSearch(clear) {
            //console.log("stopping search...");
            //console.timeEnd("process");
            clearTimeout(timeout.process);
            clearTimeout(timeout.finder);
            if (clear) props.handleSearchResults(null);
            //setSearchStatus(false);
            //console.log("...search stopped");
        }

        function clearResults() {
            props.handleSearchResults(null);
        }

        function startSearch() {
            //console.log("starting search after:");
            //console.log(getQueryTimeout(query) + "ms");
            props.handleSearchResults(loader);
            timeout.finder =
                setTimeout(()=>{finder(data, combinedData, query, props.handleSearchResults, box, timeout, props.search, props.handleClick)},
                    getQueryTimeout(query));
        }

        useEffect(() => {
            //console.log("useEffect on query, data fired");
            if (query) {
                //console.log("before starting search");
                //console.log(query.lang);
                if (combinedData.length > 0 && query.lang) {
                    //console.log("data not empty, query lang is defined");
                    document.title = query.str;
                    stopSearch();
                    startSearch();
                } else {
                    //console.log(query);
                }
            } else {
                document.title = "meklēšana";
                //clearResults();
                //stopSearch();
            }

            return () => {
                //console.log("Search component useEffect /query, combinedData/ clean up fired");
                //console.log("inputValue was", inputValue);
                //localStorage.setItem(cookieName, JSON.stringify(query));
                //setTimeout(clearResults, 100);
                stopSearch(true);
            }

        }, [query, combinedData]);

        return (
            <SearchForm inputValue={inputValue||""} onInputChange={handleInputChange} id={props.id} />
        )
    }, ()=>true);

export function Something() {
    const something = [
        { adjective: { lv: "jauku", ru: "прекрасное" }, noun: { lv: "nauda", ru: "деньги" } },
        { adjective: { lv: "pozitīvu", ru: "позитивное" }, noun: { lv: "protons", ru: "протон" } },
        { adjective: { lv: "derīgu", ru: "полезное" }, noun: { lv: "urbis", ru: "дрель" } },
        { adjective: { lv: "derīgu", ru: "полезное" }, noun: { lv: "bikses", ru: "штаны" } },
        { adjective: { lv: "diženu", ru: "великое" }, noun: { lv: "simfonija", ru: "симфония" } },
        { adjective: { lv: "gudru", ru: "умное" }, noun: { lv: "nezinu", ru: "не знаю" } },
        { adjective: { lv: "gudru", ru: "умное" }, noun: { lv: "problemātika", ru: "проблематика" } },
        { adjective: { lv: "grūtu", ru: "трудное" }, noun: { lv: "man nebija taisnība", ru: "я был неправ" } },
        { adjective: { lv: "noslēpumainu", ru: "таинственное" },
            noun: { lv: "turpinājums sekos", ru: "продолжение следует" } }
    ];
    const index = getRandomInt(0, something.length-1);

    return (<>
    {entities2unicode(`Rakstiet kaut ko ${something[index].adjective.lv}. Piemēram, &bdquo;${something[index].noun.lv}&ldquo;`)}<br/>{entities2unicode(`Напишите что-нибудь ${something[index].adjective.ru}. Например, «${something[index].noun.ru}»`)}
    </>);
}

export default Search;
