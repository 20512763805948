import { useRef, useReducer, useEffect, useState, useMemo, useLayoutEffect } from 'react';
import { getType, deepCopy } from "./prototypes";

export function fetchFiles(path, fileNames, cacheParam, setData, setStatus) {
    const files = getType(fileNames) === "string" ? [fileNames] : fileNames;
    Promise.all(
        (() => {
            let f = [];
            for (const file of files) { f.push(fetch( `${path||""}${file}.json`, cacheParam)) }
            return f;
        })()
    ).then(source =>
        Promise.all(
            (() => {
                let f = [];
                for (const s of source) { f.push(s.json()) }
                return f;
            })()
        )
    ).then(source => {
        //console.log([].concat(source).flatten());
        setData(prev => (prev || []).concat(source).flatten());
        setStatus(prev => { return { ...prev, wasLoaded: true }});
    }).catch(err => {
        setStatus((prev) => { return {...prev, error: err } });
    })
}

export function fetchData({ path, fileNames, cacheParam, setData }) {
    const files = getType(fileNames) === "string" ? [fileNames] : fileNames;
    let prev;
    Promise.all(
        (() => {
            let f = [];
            for (const file of files) { f.push(fetch( `${path||""}${file}.json`, cacheParam||{cache: "no-store"})) }
            return f;
        })()
    ).then(source =>
        Promise.all(
            (() => {
                let f = [];
                for (const s of source) { f.push(s.json()) }
                return f;
            })()
        )
    ).then(source => {
        setData(prev => (prev || []).concat(source).flatten())
    }).catch(error => {
        setData({ error })
    })
}

export const useMapToExternalData = (arrayOfObjects, keyWithFileName, cacheParam={ cache: "default" }) => {

    // console.log("useMapToExternalData arrayOfObjects:", arrayOfObjects);

    const arr = JSON.stringify(Array.isArray(arrayOfObjects) ? arrayOfObjects : [arrayOfObjects]);

    // const { current: arr } = useRef(Array.isArray(arrayOfObjects) ? arrayOfObjects : [arrayOfObjects]);

    // const arr = useMemo(()=>(Array.isArray(arrayOfObjects) ? arrayOfObjects : [arrayOfObjects]), [arrayOfObjects]);

    // console.log("useMapToExternalData arr:", arr);

    const initialState = arrayOfObjects.map(obj => ({...obj, status: "idle", error: null }));

    // console.log("useMapToExternalData initialState:", initialState);

    const [state, dispatch] = useReducer((state, action) => {

        const updated = deepCopy(state);

        switch (action.type) {
            case 'INIT': {
                return initialState;
            }
			case 'FETCHING': {
                updated[action.payload.index].status = "fetching";
                return updated;
            }
			case 'FETCHED': {
                updated[action.payload.index][keyWithFileName] = action.payload.data;
                updated[action.payload.index].status = "fetched";
                return updated;
            }
			case 'FETCH_ERROR': {
                updated[action.payload.index].status = "error";
                updated[action.payload.index].error = action.payload.error;
                return updated;
            }
			default:
				return state;
		}

    }, []);

    useEffect(()=>{

        let cancelRequest = false;

        const pArr = JSON.parse(arr);

        const fetchData = async (obj, index) => {
            dispatch({ type: 'FETCHING', payload: { index } });
            try {
                const response = await fetch(obj[keyWithFileName], cacheParam);
                const data = await response.text();
                if (cancelRequest) return;
                dispatch({ type: "FETCHED", payload: { index, data } })

            } catch (error) {
                if (cancelRequest) return;
                dispatch({ type: "FETCH_ERROR", payload: { index, error: error.message } });
            }
        }

        dispatch({ type: "INIT" });

        for (let i=0; i<pArr.length; i++) {
            fetchData(pArr[i], i);
        }

        return () => {
			cancelRequest = true;
		};

    }, [arr])

    // console.log("useMapToExternalData state:", state);

    return state;

}

export const useFetch = (url, mimeType="application/json", cacheParam={cache: "default"}) => {

    // console.log(`%cuseFetch: ${url}`, "color:orange");

	const cache = useRef({});

	const initialState = {
		status: 'idle',
		error: null,
		data: [],
	};

	const [state, dispatch] = useReducer((state, action) => {
		switch (action.type) {
			case 'FETCHING':
				return { ...initialState, status: 'fetching' };
			case 'FETCHED':
				return { ...initialState, status: 'fetched', data: action.payload };
			case 'FETCH_ERROR':
				return { ...initialState, status: 'error', error: action.payload };
			default:
				return state;
		}
    }, initialState);
    
	useEffect(() => {
		let cancelRequest = false;
		if (!url) return;

		const fetchData = async () => {
			dispatch({ type: 'FETCHING' });
			if (cache.current[url]) {
				const data = cache.current[url];
				dispatch({ type: 'FETCHED', payload: data });
			} else {
				try {
                    const response = await fetch(url, cacheParam);
                    
                    let data;

                    switch (mimeType) {
                        case "application/json": {
                            data = await response.json();
                            break;
                        }
                        default: {
                            data = await response.text();
                        }
                    }
                    
					cache.current[url] = data;
					if (cancelRequest) return;
					dispatch({ type: 'FETCHED', payload: data });
				} catch (error) {
					if (cancelRequest) return;
					dispatch({ type: 'FETCH_ERROR', payload: error.message });
				}
			}
		};

		fetchData();

		return function cleanup() {
			cancelRequest = true;
		};
    }, [url]);
    
    // console.log(state.data);

	return [state.data, state.status, state.error];
};

const mime = (response, mimeType) => {
    switch (mimeType) {
        case "application/json": {
            return response.json();
        }
        default: {
            return response.text();
        }
    }
}


export const useFetches = ({
    fileNames, mappers,
    path="", mimeType="application/json", cacheParam={cache: "default"}
}) => {

    const { current: files } = useRef(Array.isArray(fileNames) ? fileNames : [fileNames]);
    const { current: refCacheParam } = useRef(cacheParam);

    // console.log(args);
    // console.log(`%cuseFetch: ${args.current.files}`, "color:orange");

	const initialState = {
		status: 'idle',
		error: null,
        data: []
	};

	const [state, dispatch] = useReducer((state, action) => {
		switch (action.type) {
			case 'FETCHING':
				return { ...initialState, status: 'fetching' };
			case 'FETCHED':
				return { ...initialState, status: 'fetched', data: action.payload };
			case 'FETCH_ERROR':
				return { ...initialState, status: 'error', error: action.payload };
			default:
				return state;
		}
	}, initialState);

	useEffect(() => {
		let cancelRequest = false;
        if (!files) return;

        // console.log(files);
        
        const fetchData = async () => {
			dispatch({ type: 'FETCHING' });
            try {
                const fetches = [];
                for (let i=0; i<files.length; i++) {
                    // console.log(path + fileName);
                    fetches.push(
                        fetch(path + files[i], refCacheParam)
                        .then(response => mime(response, mimeType))
                    );
                }
                const data = await Promise.all(fetches);

                // console.log(data);
                if (cancelRequest) return;

                if (mappers) {
                    for (let i=0; i<files.length; i++) {
                        if (mappers[i]) {
                            data[i] = mappers[i](data[i]);
                        }
                    }
                }
                
                dispatch({ type: 'FETCHED', payload: data });
            } catch (error) {
                if (cancelRequest) return;
                dispatch({ type: 'FETCH_ERROR', payload: error.message });
            }
		};

		fetchData();

		return function cleanup() {
			cancelRequest = true;
		};
	}, [files, path, mimeType, refCacheParam]);

	return [state.data.flatten(), state.status, state.error];
};