import React, { useContext, useEffect, useMemo, useReducer, useLayoutEffect, useState, useRef } from "react";
import { Switch, Route, Redirect, useRouteMatch, useParams, withRouter, useHistory } from 'react-router-dom';
import DataContext from "../DataContext";
import { loadAnswers, saveAnswers, reducer } from "../lesson/logic";
import { objectsEqual, partition } from "../../../prototypes";
import QHTML from "../q/QHTML";
import { Node, MemNode } from "../q/renderNode";

// import { menuer, collect, multicollect, collectAll } from "../nav/logic";

import Exercise from "../lesson/Exercise";
import useExercises from "../lesson/useExercises";

import "../../../scss/desktop/Lesson.scss";
import "../../../scss/desktop/Theory.scss";

const Theory = React.memo(({ section }) => {

    // const { current: section } = useRef(section);

    const { path } = useRouteMatch();
    

    // const params = useParams();
    // const { setNav, data } = useContext(DataContext);

    // const { location } = useHistory();

    const [state, dispatch] = useReducer(reducer, null);

    const [exercises, status] = useExercises({ json: section, dispatch });

    // console.log("Theory render");
    // console.log("loaded:", loaded);
    // console.log("state:", state);

    // console.log(location);

    useEffect(()=>{
        // console.log("useEffect section.id");
        dispatch({ type: "replace", payload: null });
        // setLoaded(false);
    }, [section.id]);

    /* useEffect(()=>{

        // console.log(location.pathname);

    }, [location]); */

    /* useEffect(()=>{
        console.log(state);
    }, [state]); */

    /* useEffect(()=>{
        if (section) {

            console.log(section);

            const nav = {...menuer(section, "parts", "menu", ["title", "href", "default"]), page: section.page }

            console.log(nav);

            setNav(nav);

        }
    }, [section, setNav]); */


    // if ((collection.length > 0 && exercises && state) || (collection.length === 0)) {
    if (status === "fetched") {
        return (
            <Switch>
                <Route exact path={path}>
                    {
                        (()=>{
                            // console.log(`Route ${path}`);
                        })()
                    }
                    <Part from="Loaded route 1" parts={[section]} {...{ state, dispatch, exercises }} />
                </Route>
                <Route path={`${path}/:partName`}>
                    <Part from="Loaded route 2" parts={section.parts} {...{ state, dispatch, exercises }} />
                </Route>
            </Switch>
        )
    } else {
        return <div>{status}</div>;
    }
}, (prev, next) => {
    console.log(`prev section id: ${prev.section.id}`);
    return prev.section.id === next.section.id
});

const Part = React.memo(({ from, parts, state, dispatch, exercises }) => {

    const { path, url } = useRouteMatch();
    const { partName } = useParams();

    const { data, box } = useContext(DataContext);

    // console.log("----------------------------------------------------------------");
    // console.log(`%cPart render ${url}`, "color:red");

    const part = parts.find(p => p.href===partName);

    if (!part) return <div>part not found</div>;

    const subparts = part.hasOwnProperty("parts") ? part.parts : [];

    return (
        <Switch>
            <Route exact path={path}>
                {(()=>{
                    if (part.hasOwnProperty("default")) {
                        // console.log("Part: Route exact: part.default.html:", part.default.html);
                        return (
                            <div id={part.id} className={part.className||null}>
                                {
                                    part.css && <link rel="stylesheet" type="text/css" href={`/assets/v9/css/${part.id}.css`} />
                                }
                                {
                                    Array.isArray(part.default.html) ?
                                        part.default.html.map((src, i) => (
                                            <QHTML className="theory" key={i} src={src} />
                                        )) :
                                    <QHTML className="theory" src={part.default.html} />
                                }
                                {
                                    part.hasOwnProperty("exercises") && (
                                        part.exercises.map((exercise, i) => {
                                            const parsed = exercises.find(e => e.id === exercise.id);
                                            // console.log(parsed);
                                            if (parsed.node.length > 1) {
                                                const [staticNodes, qNodes] = partition(parsed.node, n=>n.className!=="default");
                                                // console.log(staticNodes, qNodes);
                                                return (<React.Fragment key={i}>
                                                    <MemNode {...{ node: staticNodes, props: { data, box } }}/>
                                                    <Exercise {...{ exercise: {...parsed, node: qNodes}, state, dispatch }} />
                                                </React.Fragment>)
                                                // 
                                            } else {
                                                return (
                                                    <Exercise key={i} {...{ exercise: parsed, state, dispatch }} />
                                                )
                                            }
                                        })
                                    )
                                }
                            </div>
                        )
                    } else {
                        if (subparts.length > 0) {
                            const subpart = subparts[0];
                            const subpartName = subpart.href + "/";
                            let to = `${url}/${subpartName}`.replace(/\/\//g, "/");
                            return <Redirect to={to} />
                        } else {
                            return <div>no default, no subparts</div>
                        }
                    }
                })()}
            </Route>
            <Route path={`${path}/:partName`}>
                <Part from="Part level" parts={subparts} {...{state, dispatch, exercises }} />
            </Route>
        </Switch>
    )
}, (prev, next)=> {
    // return false;
    return objectsEqual(prev.parts, next.parts) && objectsEqual(prev.state, next.state);
});

export default Theory;
