import React from 'react';
//import React, { useState, useEffect } from 'react';
//import ReactDOMServer from 'react-dom/server';
//import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { getType } from "../prototypes";
import { str2word, chain2path, chain2arr, entities2unicode, parseChain } from "../lang/parse";
//import { morphNames, getKey, lemma2str } from "../lang/lang2";
import Content from "../components/Content";
import { WordLong, ChainLong, LongLink } from "../components/Wordform";
import "./Test.css";
//import useLongPress from "../components/useLongPress";
//import Box from "../components/Box";
import Spinner from "../components/Spinner";
import ParsedText from "../components/ParsedText";
import { Cloud } from "../i/clouds";

function Test({ setNav }) {
    
    
    return (
        <>
        <Content className="test">
            <h1>
                Brālītis un Karlsons
            </h1>
            <Cloud width="500" height="350">Vienreiz Brālītis un Karlsons
            staigāja pa jumtiem</Cloud>
        </Content>
        </>
    )
}

export default Test;
