import React, { useState, useEffect, useMemo } from "react";
import { Link } from 'react-router-dom';

function QImage({ src, srcSet, href, className, width, height }) {

    let style = null;

    if (width && height) {
        style = { width: width+"px", height: height+"px" }
    }

    const classList = ["qimage"];

    if (className) {
        classList.push(className);
    }

    const img = <img src={src} srcSet={srcSet} style={style} />;

    //console.log(srcSet);

    return (
        <div className={classList.join(" ")}>{
            href ? <Link to={href}>{img}</Link> : img
        }</div>

    )

}

export default QImage;
