import React, { useEffect, useReducer, useMemo, useState, useCallback, useLayoutEffect, useRef, useContext } from "react";
import { filterWords } from "../../../lang/parse";
import { deepCopy, objectsEqual } from "../../../prototypes";
import Exercises from "./Exercises";
import Results from "./Results";
import { check, reducer, loadAnswers, saveAnswers } from "./logic";
import DataContext from "../DataContext";

import "../../../scss/desktop/Lesson.scss";
import ParsedText from "../../ParsedText";
import useExercises from "./useExercises";
import { lemma2str } from "../../../lang/lang2";


export const numerals = {
    default: {
        questions: "jautājumi[lv:jautājums]",
        answers: "atbildes[lv:atbilde]",
        error: "kļūdas[lv:kļūda]",
        correct: "pareizas[iv:pareizs] atbildes[lv:atbilde]"
    },
    cas: [
        {
            count: [1, 21, 31, 41, 51, 61, 71, 81, 91, 101],
            questions: "jautājums[lv]",
            answers: "atbilde[lv]",
            error: "kļūda[lv]", correct:
            "pareiza[iv:pareizs] atbilde[lv]"
        },
        {
            count: [11, 12, 13, 14, 15, 16, 17, 18, 19],
            questions: "jautājumu[lv:jautājums]",
            answers: "atbilžu[lv:atbilde]",
            error: "kļūdu[lv:kļūda]",
            correct: "pareizu[iv:pareizs] atbilžu[lv:atbilde]"
        },
        {
            count: [0],
            questions: "jautājumu[lv:jautājums] nav",
            answers: "atbilžu[lv:atbilde] nav",
            error: "kļūdu[lv:kļūda] nav",
            correct: "nav pareizu[iv:pareizs] atbilžu[lv:atbilde]"
        }
    ]
};

function _(arrOrStr) {
    return arrOrStr ?
        Array.isArray(arrOrStr) ? arrOrStr.join("") : arrOrStr
        : "";
}

const Lesson = React.memo(({ lesson }) => {

    // console.log(lesson);

    const { data, box } = useContext(DataContext);

    // const { current: lesson } = useRef(lesson);

    // console.log("Lesson render");

    const [loaded, setLoaded] = useState(false);

    const allLessonText = useMemo(()=>(_(lesson.title) + " " + _(lesson.intro) + " " + (
        lesson.parts.map(part => (
            _(part.title) + " " +
            _(part.intro)
        )).join(" ")
    ) + " jautājums[lv] atbilde[lv] kļūda[lv] pareizs[iv]"), [lesson]);

    // console.log(allLessonText);

    const className = "lesson" + (lesson.hasOwnProperty("className") ? " "+lesson.className : "");

    /* const className = useMemo(
        ()=>("lesson" + (lesson.hasOwnProperty("className") ? " "+lesson.className : "")),
        [lesson]
    ); */

    // const memReducer = useCallback(reducer, [loadedAnswers]);

    /* const [state, dispatch] = useReducer(memReducer,
        (()=>{ console.log("set loadedAnswers to reducer:", loadedAnswers); return loadedAnswers})()); */

    const [state, dispatch] = useReducer(reducer, null);

    // console.log(lesson);

    const [exercises, status, filteredWords] = useExercises({ json: lesson, dispatch });

    const localData = {...data, words: filteredWords.concat(filterWords(allLessonText, data.words)) };

    useEffect(()=>{

        dispatch({ type: "replace", payload: null });
        setLoaded(false);

    }, [lesson.id]);

    useEffect(()=>{

        if (status === "fetched") {

            const loadedAnswers = loadAnswers(lesson.id);
            if (loadedAnswers) {
                dispatch({ type: "load", payload: loadedAnswers });
            }
            setLoaded(true);

        }

    }, [status, lesson.id]);

    useEffect(()=>{
        // console.log(state);
        if (state) {
            const given = state.filter(answer =>
                answer.hasOwnProperty("given") && ![-1, false, null, ""].includes(answer.given));
            // console.log(given);
            saveAnswers(given, lesson.id);
        }
    }, [state]);

    // console.log(localData);

    /* useEffect(()=>{
        // return ()=>{setLoaded(false)}
    }, []); */

    // if (loaded) console.log(localData.words.map(word=>lemma2str(word.lemma)));


    return (
        loaded ?
        <DataContext.Provider value={{ data: localData, box, lesson: lesson }}>
            {
                lesson.css && <link rel="stylesheet" type="text/css" href={`/assets/v9/css/${lesson.id}.css`} />
            }
            <div className={className} id={lesson.id}>
                {
                    <>
                    <h1>
                        <ParsedText text={lesson.title} {...{ data: localData, box }} />
                    </h1>
                    </>
                }
                {
                    lesson.intro && <div className="intro" key="intro">{
                        <ParsedText text={lesson.intro} {...{ data: localData, box }} />
                    }</div>
                }
                { lesson.parts.map((part, partNumber) => (
                    <div key={partNumber} className="part">
                        <h2>{
                                lesson.parts.length > 1 && <span className="number">{(partNumber+1)}</span>
                            }
                            <ParsedText text={part.title} {...{ data: localData, box }} />
                        </h2>
                        {
                            part.intro && <div className="intro" key={`intro${partNumber}`}>{
                                <ParsedText text={part.intro} {...{ data: localData, box }} />
                            }</div>
                        }
                        <Exercises lessonId={lesson.id} {...{ exercises: exercises.filter(x => part.exercises.find(px => px.id == x.id)), state, dispatch, partNumber }} />
                    </div>
                )) }
            </div>
            { state.length > 0 && <Results {...{ state, dispatch }} />}
            {/* { state.length === allQA ? <Results {...{ state, dispatch }} /> : null} */}
        </DataContext.Provider> : null
        
    )
}, (prev, next)=>{
    // return false;
    // console.log("prev id === next id?", prev.lesson.id === next.lesson.id);
    return prev.lesson.id === next.lesson.id;
})

export default Lesson;
