import { getType } from "../prototypes";

export function foreignTransform(word, group, param) {
    const transformation = group.transformations.find(transformation => {
        const paramKeys = Object.keys(param);
        for (let i=0; i<paramKeys.length; i++) {
            let key = paramKeys[i];
            if (transformation.param.hasOwnProperty(key)) {

                if (getType(transformation.param[key]) === "function") {

                    /* console.log("f result:", transformation.param[key](param));
                    console.log("param value:", param[key]); */

                    return transformation.param[key](param) === param[key];

                } else {

                    if (Array.isArray(transformation.param[key])) {
                        if (!transformation.param[key].includes(param[key])) { return false }
                    } else {
                        if (transformation.param[key] !== param[key]) { return false }
                    }
                }

            }
        }
        return true;
    });

    //console.log("num2text: related transformation:", transformation);

    if (transformation) {
        //console.log(`${word.stem}${transformation.flex}`);
        return `${word.stem}${transformation.flex}`;
    } else {
        console.log(word);
        console.log(param);
        console.log(`no transformation found for word ${word.stem}`);
        return word.stem;
    }
}

export function getForeignWord(lang, group, value) {
    // console.log(group, lang);
    let res = false;
    const wordSet  = foreign[group][lang].find(g => g.words.some(word=>word.value===value));
    if (wordSet) {
        const word = wordSet.words.find(word=>word.value===value);
        if (word) {
            res = {
                word,
                transformations: wordSet.transformations
            };
        } else { console.log(`foreign word ${value} not found`) }
    } else { console.log(`word group .${group}.${lang} not found`) }
    return res;
}

export const quantity2count = (quantity) => {
    const qs = quantity.toString();
    if (qs[qs.length-1] === "0") return 1;
    if (/1$/.test(qs) && !/11$/.test(qs)) return 0;
    if (/[234]$/.test(qs) && qs[qs.length-2] !=="1") return 0;
    return 1;
}
export const quantity2cas = (quantity) => {
    if (quantity === 0) return 1;
    if (quantity > 1 && quantity < 21) return 1;
    const qs = quantity.toString();
    if (/1$/.test(qs) && !/11$/.test(qs)) return 0;
    return 1;
}

export const foreign = {
    ui: {
        ru: [
            {
                words: [
                    { value: "round", stem: "раунд" }
                ],
                transformations: [
                    { param: { cas: [0, 3], count: 0 }, flex: "" },

                    { param: { cas: 1, count: 0 }, flex: "а" },

                    { param: { cas: 2, count: 0 }, flex: "у" },
                    { param: { cas: 5, count: 0 }, flex: "е" },

                    { param: { cas: [0, 3], count: 1 }, flex: "ы" },
                    { param: { cas: 1, count: 1 }, flex: "ов" },
                    { param: { cas: 2, count: 1 }, flex: "ам" },
                    { param: { cas: 5, count: 1 }, flex: "ах" }
                ]
            }
        ]
    },
    month: {
        ru: [
            {
                words: [
                    {value: 0, stem: "январ"},
                    {value: 1, stem: "феврал"},
                    {value: 3, stem: "апрел"},
                    {value: 5, stem: "июн"},
                    {value: 6, stem: "июл"},
                    {value: 8, stem: "сентябр"},
                    {value: 9, stem: "октябр"},
                    {value: 10, stem: "ноябр"},
                    {value: 11, stem: "декабр"}
                ],
                transformations: [
                    { param: { cas: [0, 3], count: 0 }, flex: "ь" },
                    { param: { cas: 1, count: 0 }, flex: "я" },
                    { param: { cas: 2, count: 0 }, flex: "ю" },
                    { param: { cas: 5, count: 0 }, flex: "е" }
                ]
            },
            {
                words: [
                    {value: 2, stem: "март"},
                    {value: 7, stem: "август"}
                ],
                transformations: [
                    { param: { cas: [0, 3], count: 0 }, flex: "" },
                    { param: { cas: 1, count: 0 }, flex: "а" },
                    { param: { cas: 2, count: 0 }, flex: "у" },
                    { param: { cas: 5, count: 0 }, flex: "е" }
                ]
            },
            {
                words: [
                    {value: 4, stem: "ма"}
                ],
                transformations: [
                    { param: { cas: [0, 3], count: 0 }, flex: "й" },
                    { param: { cas: 1, count: 0 }, flex: "я" },
                    { param: { cas: 2, count: 0 }, flex: "ю" },
                    { param: { cas: 5, count: 0 }, flex: "е" }
                ]
            }
        ]
    },
    number: {
        ru: {
            cardinal: [
                {
                    words: [
                        {value: 0, stem: "нол"}
                    ],
                    transformations: [
                        {param: {cas: [0, 3], gender: [0, 1, 2], count: 1}, flex: "ь"},
                        {param: {cas: 1, gender: [0, 1, 2], count: 1}, flex: "я"}
                    ]
                },
                {
                    words: [
                        {value: 1, stem: "од"}
                    ],
                    transformations: [
                        {param: {cas: [0, 3], gender: 0, count: 0}, flex: "ин"},
                        {param: {cas: [0, 3], gender: 2, count: 0}, flex: "но"},
                        {param: {cas: 0, gender: 1, count: 0}, flex: "на"},
                        {param: {cas: 1, gender: [0, 2], count: 0}, flex: "ного"},
                        {param: {cas: 5, gender: [0, 2], count: 0}, flex: "ном"},
                        {param: {cas: [1, 5], gender: 1, count: 0}, flex: "ной"},
                        {param: {cas: 3, gender: 1, count: 0}, flex: "ну"}
                    ]
                },
                {
                    words: [
                        {value: 2, stem: "дв"}
                    ],
                    transformations: [
                        {param: {cas: [0, 3], gender: [0, 2], count: 1}, flex: "а"},
                        {param: {cas: 0, gender: 1, count: 1}, flex: "е"},
                        {param: {cas: [1, 5], gender: [0, 1, 2], count: 1}, flex: "ух"},
                        {param: {cas: 3, gender: 1, count: 1}, flex: "е"}
                    ]
                },
                {
                    words: [
                        {value: 3, stem: "тр"}
                    ],
                    transformations: [
                        {param: {cas: [0, 3], gender: [0, 1, 2], count: 1}, flex: "и"},
                        {param: {cas: [1, 5], gender: [0, 1, 2], count: 1}, flex: "ех"}
                    ]
                },
                {
                    words: [
                        {value: 4, stem: "четыр"}
                    ],
                    transformations: [
                        {param: {cas: [0, 3], gender: [0, 1, 2], count: 1}, flex: "е"},
                        {param: {cas: [1, 5], gender: [0, 1, 2], count: 1}, flex: "ех"}
                    ]
                },
                {
                    words: [
                        {value: 5, stem: "пят"},
                        {value: 6, stem: "шест"},
                        {value: 7, stem: "сем"},
                        {value: 9, stem: "девят"},
                        {value: 10, stem: "десят"},
                        {value: 11, stem: "одиннадцат"},
                        {value: 12, stem: "двенадцат"},
                        {value: 13, stem: "тринадцат"},
                        {value: 14, stem: "четырнадцат"},
                        {value: 15, stem: "пятнадцат"},
                        {value: 16, stem: "шестнадцат"},
                        {value: 17, stem: "семнадцат"},
                        {value: 18, stem: "восемнадцат"},
                        {value: 19, stem: "девятнадцат"},
                        {value: 20, stem: "двадцат"},
                        {value: 30, stem: "тридцат"}
                    ],
                    transformations: [
                        {param: {cas: [0, 3], gender: [0, 1, 2], count: 1}, flex: "ь"},
                        {param: {cas: [1, 5], gender: [0, 1, 2], count: 1}, flex: "и"}
                    ]
                },
                {
                    words: [
                        {value: 8, stem: "вос"}
                    ],
                    transformations: [
                        {param: {cas: [0, 3], gender: [0, 1, 2], count: 1}, flex: "емь"},
                        {param: {cas: [1, 5], gender: [0, 1, 2], count: 1}, flex: "ьми"}
                    ]
                },
                {
                    words: [
                        {value: 40, stem: "сорок"}
                    ],
                    transformations: [
                        {param: {cas: [0, 3], gender: [0, 1, 2], count: 1}, flex: ""},
                        {param: {cas: [1, 5], gender: [0, 1, 2], count: 1}, flex: "а"}
                    ]
                },
                {
                    words: [
                        {value: 50, stem: "пят"},
                        {value: 60, stem: "шест"}
                    ],
                    transformations: [
                        {param: {cas: [0, 3], gender: [0, 1, 2], count: 1}, flex: "ьдесят"},
                        {param: {cas: [1, 5], gender: [0, 1, 2], count: 1}, flex: "идесяти"}
                    ]
                }
            ],
            ordinal: [
                {
                    words: [
                        {value: 0, stem: "нулев"},
                        {value: 2, stem: "втор"},
                        {value: 6, stem: "шест"},
                        {value: 7, stem: "седьм"},
                        {value: 8, stem: "восьм"},
                        {value: 40, stem: "сороков"}
                    ],
                    transformations: [
                        {param: {cas: [0, 3], gender: 0, count: 0}, flex: "ой"},
                        {param: {cas: 1, gender: [0, 2], count: 0}, flex: "ого"},
                        {param: {cas: 2, gender: [0, 2], count: 0}, flex: "ому"},
                        {param: {cas: 5, gender: [0, 2], count: 0}, flex: "ом"},

                        {param: {cas: 0, gender: 1, count: 0}, flex: "ая"},
                        {param: {cas: 3, gender: 1, count: 0}, flex: "ую"},
                        {param: {cas: [1, 2, 5], gender: 1, count: 0}, flex: "ой"},

                        {param: {cas: [0, 3], gender: 2, count: 0}, flex: "ое"},

                        {param: {cas: [0, 3], gender: [0, 1, 2], count: 1}, flex: "ые"},
                        {param: {cas: [1, 5], gender: [0, 1, 2], count: 1}, flex: "ых"},
                        {param: {cas: 2, gender: [0, 1, 2], count: 1}, flex: "ым"}
                    ]
                },
                {
                    words: [
                        {value: 1, stem: "перв"},
                        {value: 4, stem: "четверт"},
                        {value: 5, stem: "пят"},
                        {value: 9, stem: "девят"},
                        {value: 10, stem: "десят"},
                        {value: 11, stem: "одиннадцат"},
                        {value: 12, stem: "двенадцат"},
                        {value: 13, stem: "тринадцат"},
                        {value: 14, stem: "четырнадцат"},
                        {value: 15, stem: "пятнадцат"},
                        {value: 16, stem: "шестандцат"},
                        {value: 17, stem: "семнадцат"},
                        {value: 18, stem: "восемнадцат"},
                        {value: 19, stem: "девятнадцат"},
                        {value: 20, stem: "двадцат"},
                        {value: 30, stem: "тридцат"}
                    ],
                    transformations: [
                        {param: {cas: [0, 3], gender: 0, count: 0}, flex: "ый"},
                        {param: {cas: 1, gender: [0, 2], count: 0}, flex: "ого"},
                        {param: {cas: 2, gender: [0, 2], count: 0}, flex: "ому"},
                        {param: {cas: 5, gender: [0, 2], count: 0}, flex: "ом"},

                        {param: {cas: 0, gender: 1, count: 0}, flex: "ая"},
                        {param: {cas: 3, gender: 1, count: 0}, flex: "ую"},
                        {param: {cas: [1, 2, 5], gender: 1, count: 0}, flex: "ой"},

                        {param: {cas: [0, 3], gender: 2, count: 0}, flex: "ое"},

                        {param: {cas: [0, 3], gender: [0, 1, 2], count: 1}, flex: "ые"},
                        {param: {cas: [1, 5], gender: [0, 1, 2], count: 1}, flex: "ых"},
                        {param: {cas: 2, gender: [0, 1, 2], count: 1}, flex: "ым"}
                    ]
                },
                {
                    words: [
                        {value: 3, stem: "трет"}
                    ],
                    transformations: [
                        {param: {cas: [0, 3], gender: 0, count: 0}, flex: "ий"},
                        {param: {cas: 1, gender: [0, 2], count: 0}, flex: "ьего"},
                        {param: {cas: 2, gender: [0, 2], count: 0}, flex: "ьему"},
                        {param: {cas: 5, gender: [0, 2], count: 0}, flex: "ьем"},

                        {param: {cas: 0, gender: 1, count: 0}, flex: "ья"},
                        {param: {cas: 3, gender: 1, count: 0}, flex: "ью"},
                        {param: {cas: [1, 2, 5], gender: 1, count: 0}, flex: "ьей"},

                        {param: {cas: [0, 3], gender: 2, count: 0}, flex: "ье"},

                        {param: {cas: [0, 3], gender: [0, 1, 2], count: 1}, flex: "ьи"},
                        {param: {cas: [1, 5], gender: [0, 1, 2], count: 1}, flex: "ьих"},
                        {param: {cas: 2, gender: [0, 1, 2], count: 1}, flex: "ьим"}
                    ]
                }
            ]
        }
    }
};

