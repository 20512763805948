import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

function QSVG({ src, href, className, width, height }) {

    const [data, setData] = useState();

    let style = null;

    if (width && height) {
        style = { width: width+"px", height: height+"px" }
    }

    useEffect(() => {
        async function fetchData() {
            const svg = await fetch(src)
                .then(res => res.text())
                .then(res => {

                    //console.log(parse(res));

                    return parse(res);

                    //const parser = new DOMParser();

                    //return parser.parseFromString(res, "image/svg+xml");
                });
            setData(svg);
        }
        fetchData();
    }, [src]);

    const cn = "qsvg" + (className ? " "+className : "");

    return (
        data ? <div className={cn} style={style}>{
            href ? <Link to={href}>{data}</Link> : data
        }</div> : null
    )

}

export default QSVG;
