import React from "react";
import { icons } from "../../../i/icons";
import { camelCase } from "../../../prototypes";

const QIcon = React.memo((props)=>{

    const key = Object.keys(props).find(key => icons.hasOwnProperty(key));

    // console.log(key);

    const Icon = key ? icons[key] : ()=><span>[missing icon]</span>

    // console.log(props);

    return (
        <Icon {...props} />
    )
}, (prev, next) => (
    Object.keys(prev).find(key => icons.hasOwnProperty(key)) ===
    Object.keys(next).find(key => icons.hasOwnProperty(key))
));

export default QIcon;
