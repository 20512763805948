import React, { useRef, useState, useEffect } from "react";
import { detectLang, wordType, nounCount, nounGender } from "../lang/lang2";
import { Formula } from "./Wordform";
import { GenderIcon } from "../i/icons";

import "../css/Input.css";

export function sizer(targetElement, copyElement) {

    //console.log("sizer: started with targetElement.value", targetElement.value);

    if (!window.sizer) {
        window.sizer = {};
        window.sizer.element = document.createElement("div");
        //window.sizer.element.style.position = "absolute";
        //window.sizer.element.style.top = "0";
        //window.sizer.element.style.left = "0";
        //window.sizer.element.style.visibility = "hidden";
        window.sizer.element.classList.add("InputCopy");
        document.body.appendChild(window.sizer.element);
    }
    if (targetElement !== window.sizer.targetElement) {
        //console.log("element changed");
        const style = window.getComputedStyle(targetElement);
        //window.sizer.minWidth = targetElement.offsetWidth;
        ["padding", "border", "outline", "font-family", "font-size",
            "font-weight", "line-height", "word-wrap"].forEach(function (prop) {
            //console.log("let " + prop + " = " + style.getPropertyValue(prop));
            window.sizer.element.style[prop] = style.getPropertyValue(prop);
        });
        window.sizer.targetElement = targetElement;
    }

    window.sizer.element.innerHTML = targetElement.value.replace(/ /g, "&nbsp;");

    if (!targetElement.hasOwnProperty("initialHeight")) {
        const tRect = targetElement.getBoundingClientRect();
        //console.log("sizer: tRect.width:", tRect.width);
        //targetElement.style.width = tRect.width + 10 + "px";
        targetElement.initialHeight = tRect.height;
    }

    if (!targetElement.autoResize) {
        //console.log("sizer: no autoResize attached");
        //targetElement.addEventListener("keyup", ()=>{ sizer(targetElement, copyElement) });
        targetElement.addEventListener("keydown", (e)=>{ if (e.keyCode === 13) e.preventDefault() });
        targetElement.autoResize = true;
        //return;
    }

    const rect = window.sizer.element.getBoundingClientRect();
    targetElement.style.width = (rect.width + 15) + "px";
    //console.log("sizer: NEW targetElement style.width:", targetElement.style.width);
    //console.log(tRect.height);
    targetElement.style.height = targetElement.initialHeight + "px";
    targetElement.style.height = targetElement.scrollHeight + "px";
}

export function inputSizer(targetElement, reservedWidth) {
    if (!targetElement.sizerMinWidth) {
        targetElement.sizerMinWidth = targetElement.offsetWidth;
    }

    if (!targetElement.sizerMaxWidth) {
        targetElement.sizerMaxWidth =
            parseInt(window.getComputedStyle(targetElement).getPropertyValue("max-width"));
        if (isNaN(targetElement.sizerMaxWidth)) {
            targetElement.sizerMaxWidth = targetElement.sizerMinWidth * 2;
        }
    }
    //console.log("maxWidth:", targetElement.sizerMaxWidth);
    if (!window.sizer) {
        window.sizer = {};
        window.sizer.element = document.createElement("div");
        window.sizer.element.style.position = "absolute";
        window.sizer.element.style.top = "0";
        window.sizer.element.style.left = "0";
        window.sizer.element.style.visibility = "hidden";
        document.body.appendChild(window.sizer.element);
    }
    if (targetElement !== window.sizer.targetElement) {
        //console.log("element changed");
        const style = window.getComputedStyle(targetElement);
        //window.sizer.minWidth = targetElement.offsetWidth;
        ["padding", "border", "outline", "font-family", "font-size"].forEach(function (prop) {
            //console.log("let " + prop + " = " + style.getPropertyValue(prop));
            window.sizer.element.style[prop] = style.getPropertyValue(prop);
        });
        window.sizer.targetElement = targetElement;
    }

    window.sizer.element.innerHTML = targetElement.value.replace(/ /g, "&nbsp;");

    /*console.log(window.sizer.targetElement.sizerMinWidth);
     console.log(window.sizer.targetElement.sizerMaxWidth);
     console.log(window.sizer.element.offsetWidth);*/

    if (window.sizer.element.offsetWidth > (window.sizer.targetElement.sizerMinWidth - reservedWidth) &&
        window.sizer.element.offsetWidth <= targetElement.sizerMaxWidth) {
        window.sizer.targetElement.style.width = (window.sizer.element.offsetWidth + reservedWidth) + "px";
    }
}

export function Input(props) {

    // Input ref={props.id} className={`search ${detectLang(inputValue)||"unknown"}`} value={inputValue} onChange={handleChange}

    const { id, onChange, ...inputProps } = props;

    console.log(id);

    if (id.current) {
        inputSizer(id.current, 0);
    }

    function handleChange(e) {
        //console.log(value);
        const input = e.currentTarget;
        inputSizer(input, 0);
        if (onChange) {
            onChange(e);
        }
    }

    return (
        <input {...inputProps} ref={id} onChange={handleChange} />
    )
}

export function IconX(props) {

    //console.log("IconX render");

    //console.log("IconX props.x", props.x);

    //if (!props.word) return null;

    if (!props.word) {

        return (
            <div className="iconX">{
                props.x ?
                    <button onClick={props.onClick}>&times;</button> :
                    null
            }</div>
        )

    } else {

        const word = props.word;
        const type = wordType(word).short;

        const formula = type === "dv" && <span className="formulas"><Formula word={word}/></span>;
        const gender = (type === "lv" &&
        <span className="icon"><GenderIcon
            type={type}
            key={word.id}
            width={12} height={24}
            count={nounCount(word)} gender={nounGender(word)} /></span>);

        return (
            <div className="iconX">{
                props.x ?
                    <button onMouseDown={props.onMouseDown} onClick={()=>{ props.handleClick.x() }}>&times;</button> :
                    <>{formula||gender}</>
            }</div>
        )

    }
}

export function Textarea(props) {

    //console.log("Textarea render");

    // Input ref={props.id} className={`search ${detectLang(inputValue)||"unknown"}`} value={inputValue} onChange={handleChange}

    const { id, className, onChange, ...inputProps } = props;

    //const copy = useRef();

    //console.log("Textarea: id received:", id);

    /*if (id.current) {
        if (copy.current) {
            console.log("Textarea: call sizer");
            sizer(id.current, copy.current);
        }
    }*/

    /*useEffect(()=>{
        console.log("Textarea: useEffect");

        //console.log("Textarea: inputProps:", inputProps);

        if (id.current) {
            if (copy.current) {
                console.log("Textarea: call sizer");
                sizer(id.current, copy.current);
            }
        }

        /!*return ()=> {
            console.log("Textarea: unmount hide X");
            setX(true);
            //sizer(id.current, copy.current);
        };*!/
        //console.log("")
    }, [word]);*/

    function handleChange(e) {
        const input = e.currentTarget;
        //console.log(input.value);
        //sizer(id.current, copy.current);
        if (onChange) {
            input.classList.remove("lv");
            input.classList.remove(window.secLang);
            input.classList.remove("unknown");
            input.classList.add(detectLang(input.value)||"unknown");
            sessionStorage.setItem("selectionStart", input.selectionStart);
            onChange(e);
        }
    }
    
    // <div className={"InputCopy " + (className||"")} ref={copy}>{inputProps.value}</div>

    return (
        <textarea spellCheck="false"
            className={"InputText " + (className||"")} {...inputProps}
            ref={id} onChange={handleChange}
        />
    )
}
