import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SnipWord, SnipChain } from "./Snippets";
import { wordType } from "../lang/lang2";
import "./Box.css";

function BoxContent(props) {

    let history = useHistory();

    const { id, dim, box, href } = props;

    let link;

    if (href) {
        const rx = new RegExp("^\\" + window.baseHref);
        link = href.replace(rx, "");
    }

    //console.log(link);

    return <div ref={id} style={{...dim}} onMouseLeave={box.hide}
                onClick={()=>{
                if (link) {
                    history.push(link);
                    box.hide();
                }
                }} className={`box ${href?"href":""}`}>{props.children}</div>
}

function Box(props) {

    let boxRef = useRef();

    const [dim, setDim] = useState({
        left: 40, top: 60
    });

    const [href, setHref] = useState(false);

    const parent = document.getElementById("root");

    const { show, word, event } = props.wordBox;

    useLayoutEffect(() => {
        if (boxRef.current) {

            //console.log(event.target.hasAttribute("href"));
            if (event.target.hasAttribute("href")) setHref(event.target.getAttribute("href"));

            let point = { left: 20, top: 20 };
            const linkRect = event.target.getBoundingClientRect();
            const parentRect = parent.getBoundingClientRect();
            const contentWidth = parentRect.width - parseFloat(getComputedStyle(parent).paddingRight);
            const boxRect = boxRef.current.getBoundingClientRect();

            //console.log(boxRef.current.innerHTML);
            //console.log(boxRect.width, boxRect.height);

            if ( linkRect.left + (linkRect.width / 2) < contentWidth / 2 ) {
                //console.log("LEFT");
                point.left = Math.round(linkRect.left);
                if ( (point.left + boxRect.width) > (window.innerWidth - 23) )
                    point.left = (window.innerWidth - 23) - boxRect.width;
            } else {
                //console.log("RIGHT");
                point.left = Math.round(linkRect.right - boxRect.width);
                if (point.left < 20) point.left = 17;
            }

            const visibleSpaceUnderLink = window.innerHeight - linkRect.bottom;
            //const totalSpaceUnderLink = parentRect.height - window.pageYOffset - linkRect.bottom;
            const visibleSpaceAboveLink = linkRect.top;
            //const totalSpaceAboveLink = linkRect.top + window.pageYOffset;

            if (visibleSpaceAboveLink > boxRect.height + 10) {
                //console.log("ABOVE");
                point.top = Math.round(linkRect.top - boxRect.height + window.pageYOffset);
            } else {
                if (visibleSpaceUnderLink > boxRect.height + 10) {
                    //console.log("BELOW");
                    point.top = Math.round(linkRect.bottom + window.pageYOffset);
                } else {
                    console.log("DISASTER");
                }
            }

            setDim(point);
        }

    }, [word, event]);

    useLayoutEffect(() => {
        if (boxRef.current) {
            //console.log(boxRef.current.getBoundingClientRect());
            if (show === undefined) {
                //console.log("event changed, show undefined");
                const boxRect = boxRef.current.getBoundingClientRect();
                const x = event.clientX, y = event.clientY;

                //console.log(boxRect.width, boxRect.height);

                //console.log(boxRect);
                //console.log(x, y);
                if (x >= Math.round(boxRect.left) &&
                    x < Math.round(boxRect.width) + Math.round(boxRect.left)) {
                    //console.log("X inside");

                    if (y >= Math.round(boxRect.top) &&
                        y < Math.round(boxRect.height) + Math.round(boxRect.top)) {
                        //console.log("Y inside");
                    } else {
                        //console.log("Y outside");
                        props.box.hide();
                    }

                } else {
                    //console.log("X outside");
                    props.box.hide();
                }
            }
        }
    }, [event, props.box, show]);

    if (show===false) return null;

    return <BoxContent dim={dim} box={props.box} id={boxRef} href={href}>
        <div>
        {
            wordType(word).short === "ke" ?
                <SnipChain item={word} data={props.data} /> :
                <SnipWord item={word} data={props.data} />
        }
        </div>
    </BoxContent>

}


export default Box;
