import { useMemo, useEffect, useState, useContext } from "react";
import { collectAll } from "../nav/logic";
import { useMapToExternalData } from "../../../io";
import { filterWords } from "../../../lang/parse";
import qcomponents, { html2node, dispatchCorrectAnswers } from "../q/qcomponents";
import { findNodes } from "../q/renderNode";
import { aov } from "../../../prototypes";
import DataContext from "../DataContext";

const useExercises = ({ json, dispatch }) => {

    // console.log("useExercises json:",json);

    const { data: globalData } = useContext(DataContext);

    const collection = collectAll(json, "exercises");

    const jsonExercises = useMemo(()=>{
        return collection.map(obj => ({...obj, default: obj.default.html }));
    }, [collection]);

    const htmlExercises = useMapToExternalData(jsonExercises, "default", { cache: "no-store" });

    const [data, setData] = useState([]);
    const [status, setStatus] = useState("idle");
    const [filteredWords, setFilteredWords] = useState([]);


    useEffect(()=>{

        if (htmlExercises.length > 0) {

            if (htmlExercises.some(x => x.status === "error")) {
                setStatus("error");
            } else {
                if (htmlExercises.some(x => x.status === "fetching")) {
                    setStatus("fetching");
                } else {
                    if (htmlExercises.every(x => x.status === "fetched")) {

                        const allText = htmlExercises.map(x => x.default).join(" ");
                        let plainText = allText.replace(/<[^>]+>/g, " ");
                        const qaAttrs = allText.match(/[qa]=['"]([^'"]+)['"]/g);
                        if (qaAttrs) plainText += qaAttrs
                            .map(str => str.replace(/[qa]=['"]([^'"]+)['"]/, "$1"))
                            .join(" ");

                        // console.log("plainText:", plainText);
                        const fw = filterWords(plainText, globalData.words);
                        // console.log("fw:", fw);

                        const parsed = htmlExercises.map(exercise => ({
                            ...exercise, node: html2node(exercise.default)
                        }));

                        parsed.forEach(exercise => {
                            const qnodes = findNodes(exercise.node, qcomponents.map(qc=>qc.tag));
                            if (qnodes) {
                                aov(qnodes, (qnode, index=0) => {
                                    if (qnode.hasAttribute("a")) {
                                        qnode.setAttribute("id", exercise.id + index);
                                        dispatchCorrectAnswers({
                                            node: qnode, id: qnode.id, exerciseId: exercise.id, dispatch,
                                            data: {...globalData, words: fw }
                                        })
                                    }
                                });
                            }
                        });

                        setData(parsed);
                        setStatus("fetched");
                        setFilteredWords(fw);

                    }
                }
            }
        }

        return () => {
            setData([]); setStatus("idle"); setFilteredWords([]);
        }

    }, [htmlExercises, globalData, dispatch]);

    // console.log("useExercises data, status, filteredWords:", [data, status, filteredWords]);

    return [data, status, filteredWords];


}

export default useExercises;