import { getType } from "../prototypes";
import { lemma2str, transform, rules } from "./lang2";
import { parseChain, parsed2html, alignStr } from "./parse";
import { foreign, foreignTransform } from "./foreign";
import { presentations } from "./timepres";

const codeType = {
    "$AA": "cardinal", "$HH": "cardinal", "$MM": "cardinal", "$SS": "cardinal",
    "$aa": "ordinal", "$hh": "ordinal", "$mm": "ordinal", "$ss": "ordinal", "$day": "ordinal", "$year": "ordinal",
    "$month": "lv:month", "$season": "lv:season", "$weekday": "lv:weekday", "$relativeday": "av:relativeday", "$daytime": "lv:daytime"
};

const codeKey = {
    "$AA": "hh", "$aa": "hh", "$HH": "hh",
    "$MM": "mm", "$mm": "mm",
    "$SS": "ss", "$ss": "ss",
    "$year": "year", "$month": "month", "$day": "day"
};

function codeTest(code, value) {
    if (code === "*") return true;
    //console.log("CODE:", code);
    if (/^\d+$/.test(code)) {
        if (getType(value) === "string") {
            return parseInt(value) === parseInt(code);
        }
        if (getType(value) === "number") {
            return value === parseInt(code);
        }
    }
    if (/\|/.test(code)) {
        const arr = code.split("|");
        return arr.some(c => codeTest(c, value));
    }
    return code === value;
}

export function getHumanPres(time, lang, pres) {

    //console.log(codeTest("5|10|aaa", +5));

    //console.log(pres);

    if (presentations[pres].hasOwnProperty("human")) {

        //console.log(presentations[pres].human);

        const humanPres = presentations[presentations[pres].human.pres];
        const mask = presentations[pres].human.mask;
        //console.log(mask);

        if (!humanPres.hasOwnProperty(lang)) {
            //console.log("getHumanTime: return no lang for human pres found, return default pres:", pres);
            return presentations[pres];
        }

        const rx = new RegExp(`(${codes.map(c =>
            c.code.replace("$", "\\$")
        ).join("|")})`);

        //console.log("getHumanPres: regex:", rx);

        const arr = mask.split(rx).filter(s=>s !== "");
        const darr = mask.split(rx).filter(s=>s !== "");

        //console.log(arr);

        for (let i = 0; i < arr.length; i++) {
            codes.forEach(code => {
                if (code.code === arr[i]) {
                    arr[i] = code.timeStr(time);
                    darr[i] = code.time(time);
                }
            });
        }

        const value = arr.join("");
        //console.log(value);

        //const masks = humanPres[lang].map(p => p.mask);
        //console.log(masks);

        const thePres = {};

            thePres[lang] = humanPres[lang].find(p => {

                if (getType(p.mask) === "string") {
                    const rx = new RegExp(p.mask);
                    //console.log(rx);
                    //console.log(`${rx}.test(${value}): ${rx.test(value)}`);
                    return rx.test(value);
                }

                if (getType(p.mask) === "object") {
                    const fromHH = p.mask.from[0], fromMM = p.mask.from[1];
                    const toHH = p.mask.to[0], toMM = p.mask.to[1];
                    const HH = darr[0], MM = darr[2];
                    //console.log(p.mask);
                    //console.log(darr);
                    return (HH > fromHH || (HH === fromHH && MM >= fromMM)) && (HH < toHH || (HH === toHH && MM <= toMM));
                    //console.log(res);
                }

                return false;

            });

        if (thePres[lang]) {
            //console.log("getHumanPres: found presentation:", thePres);
            return thePres;
        }

    }

    return presentations[pres];
}

export function date2time(date) {
    return {
        year: date.getFullYear(),
        month: date.getMonth(),
        day: date.getDate(),
        weekday: date.getDay(),
        hh: date.getHours(),
        mm: date.getMinutes(),
        ss: date.getSeconds(),
        ms: date.getMilliseconds()
    }
}

function time2date(time) {
    return new Date(time.year, time.month, time.day, time.hh, time.mm, time.ss, time.ms);
}

export function addTime(time, key, n, math=false) {

    if (math) {
        const newTime = {...time };
        switch (math) {
            case "t+n": {
                newTime[key] += n;
                break;
            }
            case "n-t": {
                newTime[key] = n - newTime[key];
                break;
            }
        }
        return newTime;
    }

    const date = time2date(time);
    switch(key) {
        case "year": {
            date.setYear(date.getYear() + n);
            break;
        }
        case "month": {
            date.setMonth(date.getMonth() + n);
            break;
        }
        case "hh": {
            date.setHours(date.getHours() + n);
            break;
        }
        case "mm": {
            date.setMinutes(date.getMinutes() + n);
            break;
        }
        case "ss": {
            date.setSeconds(date.getSeconds() + n);
            break;
        }
        case "ms": {
            date.setMilliseconds(date.getMilliseconds() + n);
            break;
        }
    }
    return date2time(date);
}

function num2word(n, type, lang, param, data) {
    let res = [];
    const valueType = type.split(":")[1];

    switch (lang) {
        case "lv": {
            const wordType = type.split(":")[0];
            const word = data.words
                .filter(word => rules[wordType].includes(word.rule))
                .filter(word => word.value.hasOwnProperty(valueType))
                .find(word => word.value[valueType] === n);

            //console.log(word);
            //console.log(param);

            const form = transform(data.morphs, word, param);

            //console.log(lemma2str(form));

            res.push({
                usedForm: lemma2str(form),
                word
            });

            break;
        }
        case "ru": {
            let word;
            const group = foreign[valueType][lang]
                .find(group => group.words.find(w => {
                    if (w.value === n) { word = w; return true }
                }));
            //console.log(group, word);
            res.push(foreignTransform(word, group, param));
        }
    }

    return res;
}

function num2text(n, type, lang, param, data) {

    //console.log("num2text: n type:", type);

    //const rxRule = type === "cardinal" ? /^70/ : type === "ordinal" ? /^71/ : (()=>{throw `${type} is not cardinal nor ordinal`})();
    
    let number = [], res = [];

    if (n > 20 && n < 60 && n % 10 !== 0) {
        number[0] = n - (n % 10);
        number[1] = n % 10;
    } else { number[0] = n }

    //console.log(number);

    number.forEach((value, index, arr) => {

        const numberIsFirstOfTwo = arr.length === 2 && index === 0;

        const theCount = numberIsFirstOfTwo ? 1 : param.count;

        switch (lang) {
            case "lv": {

                let rxRule = /^70/;
                if (type === "ordinal" && !numberIsFirstOfTwo) {
                    rxRule = /^71/;
                }

                const word = data.words.find(word => word.value === value && rxRule.test(word.rule.toString()));

                let theCase = numberIsFirstOfTwo ? 0 : param.cas === 12 ? param.count === 0 ? 1 : 2 : param.cas;

                // exception for zero and cas=12 NO NULLES MINŪTĒM
                if (type === "cardinal" && n === 0 && param.cas === 12) theCase = 1;

                const theParam = {
                    cas: theCase, count: theCount, gender: param.gender
                };
                if (type === "cardinal" || numberIsFirstOfTwo) {
                    theParam.applied = true; theParam.definite = false;
                }
                //console.log("num2text: parameter:", prm);

                const forms = transform(data.morphs, word, theParam, "all");
                //console.log("num2text: forms:", forms);

                // if variant exists and gender==0 (hours) time form must be the second
                const form = forms.length > 1 && param.gender === 0 ? forms[1] : forms[0];

                //console.log(lemma2str(form));
                res.push({
                    usedForm: lemma2str(form),
                    word
                });
                if (index < arr.length-1) { res.push(" ") }
                break;
            }
            case "ru": {
                let theType = type;
                if (type === "ordinal" && numberIsFirstOfTwo) {
                    theType = "cardinal";
                }
                const num = foreign.number.ru[theType].find(n => n.words.find(w => w.value === value));

                //console.log(num);

                if (num) {
                    //console.log("num2text: ru num:", num);

                    const theCase = numberIsFirstOfTwo ? 0 : param.cas;
                    
                    const theParam = {...param, count: theCount, cas: theCase };
                    
                    //console.log("num2text: param:", theParam);

                    const word = num.words.find(w => w.value === value);

                    //console.log("num2text: word:", word);

                    res.push(foreignTransform(word, num, theParam));

                    if (index < arr.length-1) { res.push(" ") }

                    /*
                    const transformation = num.transformations.find(transformation => {
                        const paramKeys = Object.keys(theParam);
                        for (let i=0; i<paramKeys.length; i++) {
                            let key = paramKeys[i];
                            if (transformation.param.hasOwnProperty(key)) {
                                if (getType(transformation.param[key]) === "array") {
                                    if (!transformation.param[key].includes(theParam[key])) { return false }
                                } else {
                                    if (transformation.param[key] !== theParam[key]) { return false }
                                }
                            }
                        }
                        return true;
                    });

                    //console.log("num2text: related transformation:", transformation);

                    if (transformation) {
                        //console.log(`${word.stem}${transformation.flex}`);
                        res.push(`${word.stem}${transformation.flex}`);
                        if (index < arr.length-1) { res.push(" ") }
                    } else {
                        throw `no transformation found for word ${word}`
                    }
                    */

                } else {
                    throw `foreign word not found for value ${value}`
                }
                break;
            }
        }
    });

    return res;
    
}

function season(month) {
    const s = [[11, 0, 1], [2, 3, 4], [5, 6, 7], [8, 9, 10]];
    return s.findIndex(q => q.includes(month));
}

export const abbr = {
    daysOfWeek: [
        { lv: "sv", ru: "вс" },
        { lv: "p", ru: "пн" },
        { lv: "o", ru: "вт" },
        { lv: "t", ru: "ср" },
        { lv: "c", ru: "чт" },
        { lv: "pk", ru: "пт" },
        { lv: "s", ru: "сб" }
    ]
};

const codes = [
    {
        code: "$AA",
        time: (t)=> t.hh > 12 ? t.hh-12 : t.hh === 0 ? 12 : t.hh,
        timeStr: (t) => {
            const value = t.hh > 12 ? t.hh-12 : t.hh === 0 ? 12 : t.hh;
            return value < 10 ? "0" + value : value.toString();
        },
        param: [{
            lang: ["lv", "ru"],
            value: (t)=> {
                return {
                    count: (t.hh > 12 ? t.hh - 12 : t.hh === 0 ? 12 : t.hh) > 1 ? 1 : 0,
                    gender: 0
                }
            }
        }],
        converter: num2text
    },
    {
        code: "$aa",
        time: (t)=> t.hh > 12 ? t.hh-12 : t.hh === 0 ? 12 : t.hh,
        timeStr: (t) => {
            const value = t.hh > 12 ? t.hh-12 : t.hh === 0 ? 12 : t.hh;
            return value < 10 ? "0" + value : value.toString();
        },
        param: [{
            lang: "ru",
            value: (t)=> {
                return {
                    // ordinal hour always male
                    count: 0
                }
            }
        }],
        converter: num2text
    },
    {
        code: "$HH",
        time: (t)=> t.hh,
        timeStr: (t) => t.hh < 10 ? "0" + t.hh : t.hh.toString(),
        param: [{
            lang: ["lv", "ru"],
            value: (t)=> {
                return {count: t.hh !== 1 ? 1 : 0, gender: 0}
            }
        }]
    },
    {
        code: "$MM",
        time: (t)=> t.mm,
        timeStr: (t) => t.mm < 10 ? "0" + t.mm : t.mm.toString(),
        param: [{
            lang: ["lv", "ru"],
            value: (t)=> {
                return {count: [1, 21, 31, 41, 51].includes(t.mm) ? 0 : 1, gender: 1}
            }
        }],
        converter: num2text
    },
    {
        code: "$day",
        time: (t)=> t.day,
        param: [
            {
                lang: "lv",
                value: ()=> { return {count: 0, gender: 0} }
            },
            {
                lang: "ru",
                value: ()=> { return {count: 0, gender: 2} }
            }
        ],
        converter: num2text
    },
    {
        code: "$month",
        time: (t)=> t.month,
        param: [
            {
                lang: ["lv", "ru"],
                value: ()=> { return {count: 0} }
            }
        ],
        converter: num2word
    },
    { code: "$season", time: (t)=> season(t.month) }
];

function codeStr2objects(str, time, lang, data) {

    const rx = new RegExp(`((?:${codes.map(c =>
        "[+-\\d]*" + c.code.replace("$", "\\$") + "[+-\\d]*"
    ).join("|")})\\[[^\\]]+\\])`);

    //console.log("codeStr2objects: regex:", rx);

    const arr = str.split(rx);

    //console.log("codeStr2objects: arr:", arr);

    for (let index=0; index < arr.length; index++) {
        const s = arr[index];
        if (rx.test(s)) {
            let codeStr = /([^\[]+)\[[^\]]+\]/.exec(s)[1];
            let addendum;
            //console.log(codeStr);
            let theTime = {...time };

            if (/[+-]\d+$/.test(codeStr)) {
                //console.log(codeStr);
                //console.log("codeStr2objects: +-N found");
                addendum = parseInt(/([+-]\d+$)/.exec(codeStr)[1]);
                codeStr = codeStr.replace(/[+-]\d+$/, "");
                //console.log("removed +/-N:", codeStr);
                //console.log("addendum:", addendum);
                theTime = addTime(time, codeKey[codeStr], addendum);
            }
            if (/^\d+[+-]/.test(codeStr)) {
                addendum = parseInt(/^(\d+)[+-]/.exec(codeStr)[1]);
                const operator = codeStr.match(/([+-])/)[1];
                //console.log(operator);
                codeStr = codeStr.replace(/^\d+[+-]/, "");
                theTime = addTime(time, codeKey[codeStr], addendum, `n${operator}t`);
            }

            const code = codes.find(c => c.code === codeStr);
            //console.log(code);
            //console.log("codeStr2objects: code time value:", code.time(time));
            let param = {};
            if (code.hasOwnProperty("param")) {
                const langParam = code.param.find(p => getType(p.lang)==="array" ? p.lang.includes(lang) : p.lang === lang);
                if (langParam) param = langParam.value(theTime);
            }
            //console.log("codeStr2objects: param before apply:", JSON.stringify(param));
            /\[([^\]]+)\]/.exec(s)[1].split(",").map(pair=>pair.trim()).forEach(pair => {
                const a = pair.split(":").map(k=>k.trim());
                param[a[0]] = /^\d+$/.test(a[1]) ? parseInt(a[1]) : a[1];
            });
            //if (code.hasOwnProperty("param")) { param = { ...param, ...code.param(time) } }
            //console.log("codeStr2objects: code converter res:", code.converter(code.time(time), lang, param, data));
            arr[index] = code.converter(code.time(theTime), codeType[code.code], lang, param, data);
        }
    }

    for (let index=0; index < arr.length; index++) {
        const s = arr[index];
        if (/\([^\)]+\)/.test(s)) {
            //console.log("codeStr2objects: has (x):", s);
            //console.log("codeStr2object: arr.flatten after converter:", JSON.stringify(arr.flatten()));
            //console.log("codeStr2object: s:", arr[index]);

            if (index > 0 || index < arr.length-1) {
                let before = index > 0 && arr[index-1];
                if (before !== false) {
                    if (getType(before) === "array") {
                        before = before[before.length - 1]
                    }
                    if (getType(before) === "object") {
                        before = before.usedForm
                    }
                    if (getType(before) !== "string") {
                        throw `${before} is not a string`
                    }
                    before = before.trim();
                }
                let after = index < arr.length-1 && arr[index+1];
                if (after !== false) {
                    if (getType(after) === "array") {
                        after = after[after.length - 1]
                    }
                    if (getType(after) === "object") {
                        after = after.usedForm
                    }
                    if (getType(after) !== "string") {
                        throw `${after} is not a string`
                    }
                    after = after.trim();
                }
                const condStr = /\(([^\)]+)\)/.exec(s)[1];
                //console.log("codeStr2objects: before:", before);
                //console.log("codeStr2objects: after:", after);
                //console.log("codeStr2objects: condStr:", condStr);
                arr[index] = s.replace(`(${condStr})`, alignStr(before, condStr, after));
            }
        }
    }

    for (let index=0; index<arr.length; index++) {
        if (getType(arr[index]) === "string" && !rx.test(arr[index]) && /[^\]]+\[[^\]]+\]/.test(arr[index])) {
            //console.log(JSON.stringify(arr[index]), "is a chain");
            arr[index] = parseChain(data, {lv: {default: arr[index]}});
        }
    }

    const resArr = arr.flatten();
    const len = resArr.map(elem => getType(elem)==="object"?elem.usedForm:elem).join("").length;

    return { res: resArr, len }

}

export function getStartDate(date) {
    const d = new Date(date.getFullYear(), date.getMonth(), 1);
    const w = d.getDay() === 0 ? -6 : -(d.getDay()-1);
    return addDays(d, w);
    //console.log("start cal:", r.getDate() + "." + (r.getMonth()+1) + "." + r.getFullYear() );
    //return r;
}

export function addDays(date, days) {
    // https://stackoverflow.com/questions/563406/add-days-to-javascript-date
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export function time2text(time, lang, question, pres, data, box) {

    //console.log(pres);
    const presentation = pres[lang][question];

    //console.log(codeStr2objects(presentation, time, lang, data));
    const objects = codeStr2objects(presentation, time, lang, data);
    
    return {
        res: parsed2html({ box }, objects.res),
        len: objects.len
    };

}