import React, { useContext, useEffect, useLayoutEffect, useState, useRef } from "react";
import { Link, Route, Switch, useLocation } from "react-router-dom";
import DataContext from "../common/DataContext";
import { findChild, inject, isNotEmpty, menuer } from "../common/nav/logic";
import QHTML, { useHTML } from "../common/q/QHTML";

import "../../scss/mobile/content.scss";
import "../../scss/mobile/trains.scss";

import { aov, aovmap, getType, objectsEqual } from "../../prototypes";
import { Radio } from "../common/ui";
import QParse from "../common/q/QParse";
import Train from "../common/train/Train";
import { useFetch } from "../../io";

import Nav from "./Nav";

const Tabs = React.memo(({ items, cacheRandom }) => {

    const [tabs, setTabs] = useState(
        items.map((item, i)=>({
            label: item.title,
            checked: i===0
        }))
    );

    return (
        <div className="tabs">
            <Radio className="split" {...{ state: tabs, setState: setTabs }} />
            {tabs.map((tab, i) => (
                <div key={i} className={tab.checked?"tab active":"tab"}>
                    {
                        aovmap(items[i].html, (x, i) => (<QHTML src={x + "?" + cacheRandom} key={i} />))
                    }
                    {/* <QHTML src={`${items[i].html}?${cacheRandom}`} /> */}
                </div>
            ))}
        </div>
    )
}, (prev, next) => objectsEqual(prev.items, next.items));


const External = ({ item }) => {

    const { current: cacheRandom } = useRef(Math.random());

    const [content, setContent] = useState(null);

    useEffect(()=>{

        if (isNotEmpty(item, "default")) {
            setContent((prev)=>{

                // console.log("External setContent");

                let res = [];

                aov(item.default, (element, i) => {

                    const j = i===undefined ? 0 : i;

                    Object.keys(element).forEach((key, i) => {
                        switch (key) {
                            case "html": {
                                res.push(aovmap(element.html, (x, i) => (<QHTML src={x + "?" + cacheRandom} key={i} />)));
                                break;
                            }
                            case "switch": {
                                res.push(<Tabs key={`tabs${j}-${i}`} items={element.switch} cacheRandom={cacheRandom} />);
                                break;
                            }
                            case "text": {
                                res.push(<QParse key={`text${j}-${i}`} text={element.text} />);
                                break;
                            }
                            case "link": {
                                // console.log(element);
                                res.push(
                                    <div key={`link${j}-${i}`} className="links">
                                        <Link to={{ pathname: element.link.to, state: { href: item.href, title: item.title } }} className="crosslink">
                                            <QParse text={element.link.text} nowrap />
                                        </Link>
                                    </div>
                                );
                                break;
                            }
                            case "train": {
                                res.push(
                                    <Train key={`train${j}-${i}`} train={element.train} />
                                )
                            }
                            default: {}
                        }
                    });

                });

                res = res.flatten();

                // console.log(res);

                if (res.length === 0 || res.indexOf(null) > -1) {
                    console.log("res contains empty entries");
                    return prev;
                }

                return res;

            })
        }

    }, [item]);

    /* if (!item) return null;
    if (!item.hasOwnProperty("default")) return null;
    if (getType(item.default) !== "object") return null;
    if (Object.keys(item.default).length === 0) return null; */


    return (
        isNotEmpty(item, "default") ?
        <div className="content">
            {content}
        </div> : null
    )
};

const Content = () => {
    
    const { nav } = useContext(DataContext);

    let { pathname } = useLocation();

    if (pathname[pathname.length-1]!=="/") pathname += "/";

    // console.log(pathname);

    const item = findChild(nav, x => x.href === pathname, "menu");

    // console.log(item);

    return <External item={item} />
    

};

export default Content;