import React from 'react';
import { labels } from "../../labels";
import { Wordform, Label } from "../Wordform";
import { transform } from "../../lang/lang2";
import { GenderIcon } from "../../i/icons";

function Ordinal(props) {

    const { morphs, word } = props;

    return (
        <table className="transform">
            <tbody>
            <tr>
                <th />
                {[0, 1].map((gender, i) => (
                    [0, 1].map((count, j) => (
                        <th key={`${i}${j}`}>
                            <GenderIcon width="10" height="20" gender={gender} count={count} />
                        </th>
                    ))
                ))}
            </tr>
            { [0, 1, 2, 3, 4, 5, 6].map((cas, r) => (
                <tr key={r}>
                    <th><Label>{labels.cas[cas]}</Label></th>
                    {[0, 1].map((gender, i) => (
                        [0, 1].map((count, j) => (
                            <td key={`${i}${j}`}>
                                {
                                    (()=>{
                                        const form =
                                            transform(
                                                morphs, word,
                                                {cas, count, gender }
                                            );
                                        if (cas === 4 && form) {
                                            return (
                                                <>
                                                <span>ar </span>
                                                <Wordform form={form} />
                                                </>
                                            )
                                        }
                                        return <Wordform form={form} />
                                    })()
                                }
                            </td>
                        ))
                    ))}
                </tr>
            ))}
            </tbody>
        </table>
    )
}

function Standalone(props) {

    const { morphs, word } = props;

    const declension = parseInt(word.rule.toString()[3]);

    const gender = [0, 0, 0, 0, 1, 1, 1][declension];

    const counts = [0, 1];

    const cases = [0, 1, 2, 3, 4, 5];

    return (
        <React.Fragment>
            <h2>
                <Wordform className="lemma" form={transform(morphs, word, {cas: 0, count: 0, applied: false})} />
                <GenderIcon width="11" height="22" type="lv" gender={gender} count={0} />
            </h2>
            <table className="transform">
                <tbody>
                <tr>
                    <th />
                    {
                        counts.map((count, i) => (
                            <th key={i}><Label>{labels.count[count]}</Label></th>
                        ))
                    }
                </tr>
                {
                    cases.map((cas, i) => (
                        <tr key={i}>
                            <th><Label>{labels.cas[cas]}</Label></th>
                            {
                                counts.map((count, j) => (
                                    <td key={j}>
                                        { cas === 4 && "ar " }
                                        <Wordform form={transform(morphs, word, {cas, count, applied: false})} />
                                    </td>
                                ))
                            }
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </React.Fragment>
    )
}

function Applied(props) {
    const { morphs, word } = props;

    const genders = [0, 1], cases = [0, 1, 2, 3, 4, 5];

    const countMark = parseInt(word.rule.toString()[2]);

    const counts = countMark === 0 ? [0, 1] : [1];

    return (
        <React.Fragment>
            <table className="transform">
                <tbody>
                <tr>
                    <th />
                    {
                        genders.map((gender, i) => (
                            counts.map((count, j) => (
                                <th key={j}><GenderIcon width="10" height="20" gender={gender} count={count} /></th>
                            ))
                        ))
                    }
                </tr>
                {
                    cases.map((cas, i) => (
                        <tr key={i}>
                            <th><Label>{labels.cas[cas]}</Label></th>
                            {
                                genders.map((gender, i) => (
                                    counts.map((count, j) => (
                                        <td key={j}>
                                            { cas === 4 && "ar " }
                                            {
                                                (()=>{
                                                    const t = transform(
                                                        morphs, word, {cas, count, gender, applied: true, definite: false}, "all"
                                                    );
                                                    //console.log(t);
                                                    //console.log(Array.isArray(t));
                                                    return t.map((form, k, arr) => (
                                                        k < arr.length-1 ?
                                                            <React.Fragment key={"f"+k}>
                                                                <Wordform key={k} form={form} />{", "}
                                                            </React.Fragment> :
                                                            <Wordform key={k} form={form} />
                                                    ))
                                                })()

                                            }
                                        </td>
                                    ))
                                ))
                            }
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </React.Fragment>
    )
}

function DeclensionNumeral(props) {
    const { morphs, word } = props;

    //console.log("DeclensionNumeral:", /^70[01]/.test(word.rule.toString()) && "fuck");

    return (
        <React.Fragment>
            <h2><Label>{labels.transformations.declension}</Label></h2>
            {
                word.rule === 71 ?
                    <Ordinal {...props} /> :
                    <>
                    <Applied {...props} />
                    { /^701/.test(word.rule.toString()) && (<Standalone {...props} />)}
                    </>
            }
        </React.Fragment>
    )
}

export default DeclensionNumeral;