//import { getRandomInt } from './prototypes.js';
import React, { useState, useEffect, useRef, createContext } from 'react';
// import { useMediaQuery } from "react-responsive";
import Routes from "./Routes";
import { mapper } from "./mapper";
import Box from "./components/Box";
import Sidebar from "./components/Sidebar";
// import Nav from "./components/Nav";
// import { Logo } from "./components/Logo";
import Moon from "./components/Moon";
import { WhereIsEverything } from "./components/Copyright";
// import Content from "./components/Content";
import Spinner from "./components/Spinner";
import { SearchIcon } from "./i/icons";
import {
    lemma2str,
    morphNames,
    //wordNames, lemma2str,
    wordType
} from "./lang/lang2";
//import { chain2str } from "./lang/parse";
import { oldIds } from "./old/x-lv";
import "./App.css";
import { useFetch } from './io';

import Nav from "./components/common/nav/Nav2";
import useNav from './components/common/nav/useNav';

import { isMobileOnly } from "react-device-detect";

import MobileRoutes from "./components/mobile/Routes";
import DataContext from './components/common/DataContext';
import { getDeepPropValue, getType, hasDeepProperty } from './prototypes';

/*function saveLocal(morphs, words, version) {
    console.time("save");
    const data = JSON.stringify(mapper(morphs, words));
    console.log(`Version ${version}: saving ${data.length} bytes`);
    localStorage.setItem("savedVersion", version.toString());
    localStorage.setItem("words", data);
    console.timeEnd("save");
}*/

function loadAndSave(morphs, words, currentVersion) {
    return mapper(morphs, words);
}

function App() {
    
    window.secLang = "ru";
    window.longPress = 700;

    const currentVersion = 28;
    const [data, setData] = useState();
    const [status, setStatus] = useState({ wasLoaded: false, error: false });
    const [wordBox, setWordBox] = useState({ show: false });
    const [theme, setTheme] = useState(localStorage.getItem("theme")||"day");

    const [nav0, setNav] = useState();

    const [nav, navStatus] = useNav({});

    const inputRef = useRef();

    //console.log(inputRef);

    function setFocus() {
        if (inputRef.current) inputRef.current.focus();
    }

    const box = {
        show: (event, word) => {
            setWordBox({word, event, show: true});
            //console.log(event.clientX, event.clientY);
        },
        hide: (event, word) => {
            //console.log("box visibility:", wordBox);
            setWordBox({ show: false });
        },
        mouseMove: (event, word) => {
            //console.log(event);
            //console.log(event.target.parentNode);
            //console.log("box visibility:", wordBox);
            setWordBox({ word, event });
        }
    };

    useEffect(() => {

        console.time("file load");

        const cacheParam = { cache: "no-store" };
        //const cacheParam = { cache: "default" };

        const datasource = {
            morphs: [
                { prefix: "prefix"} ,
                { root: ["root", "root-new"] },
                { postfix: ["postfix", "postfix-new"] },
                { flex: "flex" }
            ],
            words: [
                "nouns", "nouns-compound", "nouns-new",
                "verbs", "verbs-new", "adjectives-new", "numerals", "adverbs",
                "prepositions"
            ],
            chains: [ "chains", "chains2" ]
        };

        const files =
            morphNames.map(n => datasource.morphs
                .find(m => m.hasOwnProperty(n))[n]).flatten()
                .concat(datasource.words)
                .concat(datasource.chains);

        //console.log("files:", files);

        const path = "/json/v9/";

        setTimeout(()=>{

         Promise.all(
             (() => {
                 let f = [];
                 for (const file of files) { f.push(fetch( `${path}${file}.json`, cacheParam)) }
                 return f;
             })()
         )
             .then(
                 (source) =>
                     Promise.all(
                         (() => {
                             let f = [];
                             for (const s of source) { f.push(s.json()) }
                             return f;
                         })()
                     )
             )
             .then((source) => {
                 console.timeEnd("file load");
                 //console.log(source);
                 //console.time("map");
                 function getIndexes(arr, key) {
                     let indexes = [];
                     if (key) {
                         [].concat(arr.find(o => o.hasOwnProperty(key))[key])
                             .forEach(f => { indexes.push(files.indexOf(f)) });
                     } else {
                         arr.forEach(f => { indexes.push(files.indexOf(f)) });
                     }
                     return indexes;
                 }
                 //console.log("postfix indexes:", getIndexes(datasource.morphs, "postfix"));

                 let morphs = {};
                 morphNames.forEach(mn => {
                     morphs[mn] = getIndexes(datasource.morphs, mn).map(i => source[i]).flatten();
                 });
                 //console.log(morphs);
                 let words = getIndexes(datasource.words).map(i => source[i]).flatten();
                 let chains = getIndexes(datasource.chains).map(i => source[i]).flatten();

                 //console.log(words.filter(w => w.words.length===0).length);
                //  console.log("chains:", chains);

                 function allKeys(obj, parentKey="") {
                    if (Array.isArray(obj)) return obj.map(elem => allKeys(elem, parentKey))
                    .flatten().unique().filter(elem => elem !== null);
                    return getType(obj) === "object"
                    ? Object.keys(obj).reduce((keys, key) => (
                        getType(obj[key]) === "object" ||
                        getType(obj[key]) === "array"
                        ? [...keys, parentKey + key, allKeys(obj[key], key + ".")].flatten()
                        : [...keys, parentKey + key]
                    ), [])
                    : null;
                 }

                //  const allChainKeys = allKeys(chains);

                //  console.log("chains all keys:", allKeys(chains));

                //  allChainKeys.forEach(key => {
                //      if (!["id", "lv", "ru", "lv.default", "ru.default"].includes(key)) {
                //          const group = chains.filter(chain => hasDeepProperty(chain, key));
                //          if (group.length > 0) {
                //              console.log(`==== ${key.toUpperCase()} ====`);
                //              group.forEach(chain => {
                //                  console.log(chain.lv.default);
                //                  console.log(getDeepPropValue(chain, key));
                //              });
                //          }
                //      }
                //  });

                 Object.keys(morphs)
                     .forEach(key => { morphs[key].forEach(morph => { morph.type = key }); });
                 setData({ 
                     morphs,
                     words: loadAndSave(morphs, words, currentVersion),
                     //words: mapper(morphs, words),
                     chains
                 });

                 //console.timeEnd("map");
                 setStatus({ wasLoaded: true });

             })
             .catch(err => {
                 console.log(err);
                 setStatus({ error: err });
             });

        }, 1);

        //return (()=>{ clearTimeout(id); })

    }, []);

    // useEffect(() => { if (data) console.log(data) }, [data])

    /* useEffect(()=>{
        if (data) {

            const newIds = data.words.filter(word => wordType(word).short === "lv").map(word => word.id);
            const missing = oldIds.filter(id => !newIds.includes(id));
            console.log("lv left:", missing.length);

            console.log("exceptions:", data.words
                .filter(word => word.hasOwnProperty("exception"))
                .filter(word => (
                    ( Array.isArray(word.exception) && word.exception.some(x => !x.hasOwnProperty("param")) ) ||
                    ( !Array.isArray(word.exception) && !word.exception.hasOwnProperty("param") )
                )));

            console.log(JSON.stringify(missing, null, 2));

           console.log("words:", data.words.length);
            console.log("  verbs:", data.words.filter(word=>wordType(word).short==="dv").length);
            console.log("  nouns:", data.words.filter(word=>wordType(word).short==="lv").length);
            console.log("chains:", data.chains.length);

        }

    }, [data]); */

/*     useEffect(()=>{
        if (data) {
            ["words", "chains"].forEach(dataSet => {
                console.log(`checking ${dataSet} for id duplicates`);
                const dups = data[dataSet].reduce(function (acc, el, i, arr) {
                    if (arr.findIndex(e=>e.id === el.id) !== i && acc.findIndex(e=>e.id === el.id) < 0)
                        acc.push(el);
                    return acc;
                }, []);

                dups.forEach(dup => {
                    const duplicates = data[dataSet].filter(item => item.id === dup.id);
                    console.log(`- ${duplicates.length} duplicates ${dup.id}: -`);
                    duplicates.forEach(d => {
                        console.log(d.lemma ?
                            `${lemma2str(d.lemma)} - ${[].concat(d.ru.trans).flatten().join(", ")}` :
                            `${chain2str(d)} - ${d.ru.default}`);
                    });
                });

                if (data[dataSet].find(item => !item.id)) {
                    data[dataSet].filter(item => !item.id).forEach(d => {
                        console.log("missing id:");
                        console.log(d.lemma ?
                            `${lemma2str(d.lemma)} - ${[].concat(d.ru.trans).flatten().join(", ")}` :
                            `${chain2str(d)} - ${d.ru.default}`);
                    });
                }

            });
        }
    }, [data]); */

    /* useEffect(()=>{
        if (data) {
            const nouns3 = data.words.filter(w=>w.rule===22||w.rule===220);
            console.log(nouns3.map(word=>lemma2str(word.lemma)));
        }
    }, [data]) */

    useEffect(()=>{
        if (theme && !isMobileOnly) {
            // console.log("theme:", theme);
            const oldTheme = theme === "night" ? "day" : "night";
            document.body.classList.remove(oldTheme);
            document.body.classList.add(theme);
            localStorage.setItem("theme", theme);
        }
    }, [theme, isMobileOnly]);

    //const isDesktop = useMediaQuery({ minDeviceWidth: 1224 });

    if (status.error) return <div>Error: {status.error.message} {window.navigator.userAgent}</div>;

    if (!status.wasLoaded) return <Spinner />;

    // console.log("App render");
    
    if (isMobileOnly) {
        if (!document.body.classList.contains("mobile")) document.body.classList.add("mobile");
        return (
            <Mobile data={data} />
        )
    }

    return (
        <>
            <Box wordBox={wordBox} box={box} data={data} />
            <Sidebar box={box} data={data}>
                <SearchIcon />
                { navStatus === "fetched" && <Nav nav={nav} /> }
                <Moon setTheme={setTheme} theme={theme} />
                <WhereIsEverything />
            </Sidebar>
            <Routes box={box} data={data} setFocus={setFocus} inputRef={inputRef} nav={nav0} setNav={setNav} />
        </>
    );
}

const Mobile = ({ data }) => {
    const [nav, status, error] = useNav({ isMobile: true });
    if (error) console.log(error);
    if (status !== "fetched") return null;

    return (
        <DataContext.Provider value={{data, nav, lang: (window.secLang||"ru") }}>
            <div className="mobile">
                <MobileRoutes />
            </div>
        </DataContext.Provider>
    )
};

export default App;
