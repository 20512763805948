
import { deepCopy, getType, objectsEqual } from "../../../prototypes";

export const collect = (obj, key, tag, collection=[], maxLevel) => {
    
    let res;

    if (Array.isArray(obj)) {
        res = obj.reduce((acc, e) => (collect(e, key, tag, acc) || acc), collection);
    } else {
        if (obj.hasOwnProperty(tag)) {
            res = Array.isArray(obj[tag]) ? [...collection, ...obj[tag]] : [...collection, obj[tag]];
        }
        if (obj.hasOwnProperty(key)) res = collect(obj[key], key, tag, res);        
    }
    return res ? res.unique().sort((a, b) => a.localeCompare(b)) : null;

};

export const collectAll = (obj, key, collection=[]) => {
    
    let res = [];

    // console.log("parse obj:", obj);
    // console.log("obj keys:", Object.keys(obj));

    if (Array.isArray(obj)) {
        res = obj.reduce((acc, e) => (collectAll(e, key, acc) || acc), collection);
    } else {

        if (getType(obj) === "object") {

            res = [...collection];

            if (obj.hasOwnProperty(key)) res.push(obj[key]);

            const keys = Object.keys(obj);
            keys.forEach(okey => {
                if (okey !== key) {
                    if (getType(obj[okey]) === "object" || getType(obj[okey]) === "array") {
                        res.push(collectAll(obj[okey], key, collection));
                    }
                }
            });

        }

    }

    res = res.flatten();

    if (res.length > 1) {
        res = res.reduce((acc, e) => acc.some(obj => objectsEqual(obj, e)) ? acc : [...acc, e], []);
    }

    return res.flatten();

};

export const isNotEmpty = (obj, key) => {
    return (!!obj && obj.hasOwnProperty(key) &&
    (
        (getType(obj[key]) === "object" && Object.keys(obj[key]).length > 0) ||
        (getType(obj[key]) === "array" && obj[key].length > 0)
    )
    );
}

export const menuer = (obj, key, name=key, keys=[], accs=[], values=[], separator="", tag, collection) => {

    // key - key to traverse, name - its new name, keys - keys to copy/true=all
    // accs - keys to accumulate values
    // tag - tag key name, collection - tag collection key name

    let res;

    if (Array.isArray(obj)) {
        res = obj.map(e => (menuer(e, key, name, keys, accs, values, separator, tag, collection)))
    } else {
        const origin = deepCopy(obj);
        const updatedValues = deepCopy(values);
        // console.log(Object.keys(obj));
        res = Object.keys(obj)
            .reduce((acc, key)=> {

                // console.log("key:", key, "val:", origin[key]);

                if (Array.isArray(keys) && keys.includes(key)) return {...acc, [key]: origin[key] }
                if (accs.includes(key)) {
                    const index = accs.indexOf(key);
                    if (values.length===accs.length) {
                        updatedValues[index] = values[index] + origin[key] + separator;
                        return {...acc, [key]: updatedValues[index] }
                    }
                }
                if (keys===true) 
                    if (!accs.includes(key)) 
                        return {...acc, [key]: origin[key] }
                    
                return acc;
            }, {});

        if (obj.hasOwnProperty(key)) {
            res[name] = menuer(obj[key], key, name, keys, accs, updatedValues, separator, tag, collection);

            if (tag && collection) {

                // console.log(obj[key]);

                // res[collection] = collect(obj[key], key, tag);
                
                res[collection] = collectAll(obj[key], tag).unique().sort((a, b) => a.localeCompare(b));

                // const coll = collectAll(obj[key], tag);

                // console.log(`collection of ${collection}:`, coll);

                // if (coll) res[collection] = res[collection].concat(coll);

                // collect collections



                /* if (!obj.hasOwnProperty(tag) && ( Array.isArray(obj[key]) ? obj[key].some(e => e.hasOwnProperty(tag)) : obj[key].hasOwnProperty(tag) )) {
                    // if this object doesn't have tag, but sublevel objects do, collect tags
                    res[collection] = collect(obj[key], key, tag);
                } */
            }
        }
    }
    return res;
}

export const inject = (obj, key, child, childrenKey, condition) => {

    let updated = deepCopy(obj);

    if (Array.isArray(updated)) return updated.map(item => inject(item, key, child, childrenKey, condition));

    if (condition(updated, child)) {
        if (updated.hasOwnProperty(childrenKey)) {
            if (Array.isArray(child)) {
                updated[childrenKey] = child.concat(updated[childrenKey]);
                // for (let i=0, l=child.length; i<l; i++) { updated[childrenKey].unshift(child[i]); }
            } else {
                updated[childrenKey].unshift(child);
            }
        } else {
            updated[childrenKey] = Array.isArray(child) ? child : [child];
        }
        return updated;
    }

    if (updated.hasOwnProperty(key)) {
        updated[key] = inject(updated[key], key, child, childrenKey, condition);
    }

    return updated;
};

export const addChild = (obj, child, childrenKey, accKey, separator) => {

    if (Object.keys(obj).length === 0) return child;

    const updated = deepCopy(obj);

    if (Array.isArray(obj)) return updated.map(item => addChild(item, child, childrenKey, accKey, separator));

    const path = child[accKey].split(separator).filter(s=>s!=="");
    // console.log("child path:",path);
    path.pop();

    // console.log("parent href:", obj[accKey].split(separator).join(""));
    // console.log("child href:",path);

    const descendant = obj[accKey].split(separator).join("") === path.join("");
    if (descendant) {
        if (updated.hasOwnProperty(childrenKey)) {
            updated[childrenKey].push(child);
        } else {
            updated[childrenKey] = [child];
        }
    } else {
        if (updated.hasOwnProperty(childrenKey)) {
            updated[childrenKey] = addChild(updated[childrenKey], child, childrenKey, accKey, separator);
        }
    }
    return updated;
}

export const removeChild = (obj, child, childrenKey, idKey) => {

    let updated = deepCopy(obj);

    if (Array.isArray(obj)) return updated.map(item => removeChild(item, child, childrenKey, idKey));

    if (obj[idKey] === child[idKey]) return {};

    if (updated.hasOwnProperty(childrenKey)) {
        const index = updated[childrenKey].findIndex(item => item[idKey] === child[idKey]);
        if (index > -1) {
            updated[childrenKey].splice(index, 1);
            if (updated[childrenKey].length < 1) delete updated[childrenKey];
        } else {
            const res = removeChild(updated[childrenKey], child, childrenKey, idKey);
            if (res.length > 0) {
                // console.log(updated);
                updated[childrenKey] = res;
            } else {
                // console.log(updated);
                delete updated[childrenKey];
            }
        }
    }

    return updated;
};

export const findChild = (obj, condition, childrenKey="children")=> {
    if (Array.isArray(obj)) {
        for (let i=0, l=obj.length; i<l; i++) {
            const res = findChild(obj[i], condition, childrenKey);
            if (res !== null) return res;
        }
        return null;
    }
    if (condition(obj)) return obj;
    if (obj.hasOwnProperty(childrenKey)) {
        return findChild(obj[childrenKey], condition, childrenKey)  
    }
    return null;
}

export const getChildLevel = (obj, child, childrenKey="children", idKey="id", level=0)=> {
    if (Array.isArray(obj)) {
        for (let i=0, l=obj.length; i<l; i++) {
            const res = getChildLevel(obj[i], child, childrenKey, idKey, level);
            if (res > -1) return res;
        }
        return -1;
    }
    if (obj[idKey] === child[idKey]) return level;
    if (obj.hasOwnProperty(childrenKey)) {
        if (obj[childrenKey].includes(item => item[idKey] === child[idKey])) {
            return level + 1;
        } else {
            return getChildLevel(obj[childrenKey], child, childrenKey, idKey, level + 1)
        }
    }
    return -1;
}

export const getMaxLevel = (obj, childrenKey="children", level=0) => {

    if (Array.isArray(obj)) {
        const res = [level];
        for (let i=0, l=obj.length; i<l; i++) {
            res.push(getMaxLevel(obj[i], childrenKey, level));
        }
        return Math.max(...res);
    }

    if (obj.hasOwnProperty(childrenKey)) {
        if (obj[childrenKey].length > 0) {
            return getMaxLevel(obj[childrenKey], childrenKey, level + 1)
        }
    }
    return level;
};

export const childrenReducer = (children, action) => {
    const updated = deepCopy(children);
    switch (action.type) {
        case "add": {
            // console.log("add:", action.payload);
            return addChild(updated, action.payload, "children", "href", "/");
        }
        case "remove": {
            // console.log("remove:", action.payload);
            return removeChild(updated, action.payload, "children", "id");
        }
        default:
            return children;
    }
};