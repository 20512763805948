
const emoji = require("emoji-dictionary");

export function emo(item) {
    return item.emo ? (
        Array.isArray(item.emo) ? (
            item.emo.map(e => (emoji.getUnicode(e)))
        ) : emoji.getUnicode(item.emo)
    ) : null;
}
