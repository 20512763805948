import React from 'react';
import { labels } from "../../labels";
import { Wordform, Label } from "../Wordform";
import { transform } from "../../lang/lang2";

function SimpleTense(props) {
    const { morphs, word } = props;
    function row(person) {
        return (
            <>
            {person.map((person, i) => (
                <tr key={i}>
                    <th key={i}><Label>{labels.person.find(p=>p.person===person)}</Label></th>
                    {[0, 1, 2].map((tense, i) => (
                        <td key={i}>
                            <Wordform
                                form={transform(morphs, word, {tense, person})} rule={word.rule} />
                        </td>
                    ))}
                </tr>
            ))}
            </>
        );
    }
    return (
        <>
        <h2><Label>{labels.transformations.tenses.simple}</Label></h2>
        <table className="transform">
            <tbody>
            <tr>
                <th />
                {[0, 1, 2].map((t, i) => (
                    <th key={i}>
                        <Label>{labels.tense[t]}</Label>
                    </th>
                ))}
            </tr>
            {row([1, 2])}
            <tr>
                <th width="100">{
                    labels.person.filter(p=>p.person===3||p.person===6).map((p, i)=>(<Label key={i}>{p}</Label>))
                }</th>
                {[0, 1, 2].map((tense, i) => (
                    <td key={i}>
                        <Wordform
                            form={transform(morphs, word, {tense, person: 3})} rule={word.rule} />
                    </td>
                ))}
            </tr>
            {row([4, 5])}
            </tbody>
        </table>
        </>
    );
}

export default SimpleTense;