import React from 'react';
import { labels } from "../../labels";
import { Wordform, Label } from "../Wordform";
import { transform } from "../../lang/lang2";
import { bracket, male, female } from "../../i/icons";

function CompoundTense(props) {
    const { morphs, word } = props;
    let aux = [], row;
    for (const person of [1, 2, 3, 4, 5, 6]) {
        row = [];
        for (const tense of [0, 1, 2]) {
            row.push(
                <span key={tense} className="aux">
                    <Wordform key={tense} form={transform(morphs, props.aux, {person, tense})} />
                </span>
            );
        }
        aux.push(row);
    }
    //console.log(aux);
    return (
        <>
        <h2><Label>{labels.transformations.tenses.compound}</Label></h2>
        <div className="tables">
            {[0, 1].map((gender, i) => (
                <table className="stretch" key={i}>
                    <tbody>
                    <tr>
                        <td className="gender" colSpan="3">{[male, female][gender]}</td>
                    </tr>
                    <tr>
                        <td>
                            <table className="transform">
                                <tbody>
                                {[1, 2, 3].map((person, i)=>(
                                    <tr key={i}>
                                        <th width="50">
                                            <Label>{labels.person.find(p=>p.person===person&&[].concat(p.gender).includes(gender))}</Label>
                                        </th>
                                        <td>{aux[person-1]}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </td>
                        <td className="bracket"><div>{bracket}</div></td>
                        <td className="big"><Wordform
                            form={transform(morphs, word, {tense: 3, count: 0, gender})} rule={word.rule} />
                        </td>
                    </tr>
                    <tr><td title={gender===0?"мальчики или девочки и мальчики":"девочки"} className="gender plural" colSpan="3">{
                        gender === 0 ?
                            <><span>{male}{male}</span><span>{male}{female}</span></> :
                            [female, female]
                    }</td></tr>
                    <tr>
                        <td>
                            <table className="transform">
                                <tbody>
                                {[4, 5, 6].map((person, i)=>(
                                    <tr key={i}>
                                        <th width="50">
                                            <Label>{labels.person.find(p=>p.person===person&&[].concat(p.gender).includes(gender))}</Label>
                                        </th>
                                        <td>{aux[person-1]}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </td>
                        <td className="bracket"><div>{bracket}</div></td>
                        <td className="big"><Wordform
                            form={transform(morphs, word, {tense: 3, count: 1, gender})} rule={word.rule} />
                        </td>
                    </tr>
                    </tbody>
                </table>
            ))}
        </div>
        </>
    )
}


export default CompoundTense;