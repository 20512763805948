import React, { useState, useEffect } from 'react';
import { date2time } from "../lang/time";
import Content from "../components/Content";
import { Clock, PlayPause } from "../components/Clock";

function Tools(props) {

    const [time, setTime] = useState(
        date2time(new Date())
    );

    const [started, start] = useState(false);

    useEffect(()=>{
        start(true);
        return ()=>{start(false)}
    }, []);

    return (
        <Content>
            <Clock width="250" height="250" { ...{ time, setTime, start, started } } />
            <PlayPause
                width="50" height="50"
                started={started}
                onClick={()=>{ start( prevState => !prevState ) }} />
        </Content>
    );
}

export default Tools;