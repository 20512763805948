import React, { useState, useEffect } from 'react';
import { labels } from "../labels";

export function ButtonConfirm(props) {

    let {
        handleClick, reset,
        disabled,
        label, labelYes, labelNo,
        className
    } = props;

    if (labelYes === undefined) labelYes = labels.ui.buttonSure;
    if (labelNo === undefined) labelNo = labels.ui.buttonNo;

    const [once, setOnce] = useState(false);
    
    useEffect(()=>{
        setOnce(false);
    }, [reset]);

    const button =
        <button disabled={disabled()} title={label[window.secLang]}
            className={className||null}
            onClick={()=>{ setOnce(true) }}>{label.lv}</button>;

    const confirm = (
        <>
        <button key="1" className={`${className?className+" ":""}yes`}
                title={labelYes[window.secLang]}
                onClick={()=>{ handleClick(); setOnce(false) }}>{labelYes.lv}</button>
        <button key="2" className={`${className?className+" ":""}no`}
                title={labelNo[window.secLang]}
                onClick={()=>{ setOnce(false) }}>{labelNo.lv}</button>
        </>
    );

    return (once ? confirm : button)
}

function C(props) {

    return (
        <input
            defaultChecked={props.checked}
            name={props.name}
            type="radio"/>
    )
}

export function Radio(props) {
    
    //console.log("Radio render");

    const [checked, setChecked] = useState(props.menu.map((entry, index) => props.checked === index));

    const { setState } = props;

    function handleClick(e, i) {
        e.preventDefault();

        setChecked(prev => {
            const updated = [...prev];
            updated[i] = !updated[i];
            if (updated[i]) {
                for (let j=0; j<updated.length; j++) {
                    if (updated[j] && j!==i) updated[j] = false;
                }
            }
            return updated;
        });
    }
    
    useEffect(()=>{
        //console.log("checked changed", checked);
        const index = checked.findIndex(c => c===true);
        setState(index > -1 ? index : null);
    }, [checked, setState]);

    return (
        <div className={`radio${props.split?" split":""}`}>
            {props.menu.map((entry, index) => (
                <label onClick={(e) => { handleClick(e, index) }}
                       key={index}><C name={props.name} checked={checked[index]} /><span className={checked[index]?"checked":null}>{entry.label}</span></label>
            ))}
        </div>
    )

}
